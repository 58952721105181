import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import Form from './update-call-credits-form';

class UpdateCallCreditsDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string
    }).isRequired
  };

  state = {
    data: null,
    isValid: false
  };

  render() {
    const { onClose, submitting, user } = this.props;
    const { isValid } = this.state;

    return (
      <Modal open size="tiny" onClose={onClose}>
        <Modal.Header>Adjust User Credits</Modal.Header>
        <Modal.Content>
          <Form
            onDataChange={this._onDataChange}
            onValidate={this._onValidate}
            user={user}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} content="Nevermind" disabled={submitting} />
          <Button
            primary
            content="Adjust Users Credits"
            onClick={this._onSubmit}
            disabled={submitting || !isValid}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  _onDataChange = data => {
    this.setState({ data });
  };

  _onValidate = errors => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    onSubmit(data);
  };
}
export default UpdateCallCreditsDialog;
