import './sub-channel-card.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import { Card } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import ArticleLinksBySubChannelIdQuery from '../../../graphql/queries/article-links-by-sub-channel-id.graphql';

@graphql(ArticleLinksBySubChannelIdQuery, {
  name: 'articleLinks',
  options: ({ channel, subChannel }) => {
    const variables = {
      subChannelId: subChannel.id
    };
    return { variables };
  }
})
@withRouter
class SubChannelCard extends Component {
  static propTypes = {
    articleLinks: PropTypes.shape({
      articleLinks: PropTypes.array,
      loading: PropTypes.bool.isRequired
    }).isRequired,
    channel: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).sRequired,
    history: PropTypes.object.isRequired,
    subChannel: PropTypes.shape({
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { channel, history, subChannel } = this.props;
    const { articleLinks, loading } = this.props.articleLinks;

    const url = `${APP_ROOT}/${channel.slug}/resources/sub-channel/${subChannel.id}`;
    const count = loading ? 'All' : articleLinks.length;

    return (
      <Card
        className="subchannel-card"
        style={{ padding: '1em' }}
        onClick={() => {
          history.push(url);
        }}
      >
        <Card.Content>
          <Card.Header>{subChannel.title}</Card.Header>
          <Card.Description>{subChannel.description}</Card.Description>
        </Card.Content>
        <Card.Content extra style={{ textAlign: 'center' }}>
          <Link className="ui" to={url}>
            See {count} Articles
          </Link>
        </Card.Content>
      </Card>
    );
  }
}
export default SubChannelCard;
