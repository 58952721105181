import './tos-privacy-agreement.css';
import './password-registration-form.css';

import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import toPairs from 'lodash/toPairs';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';
import { US_STATES_AND_TERRITORIES } from 'us-regions';

import withUser from '../../hoc/with-user';
import MyForm, { FormField } from '../../ui/form';
import PrivacyPolicy from '../../ui/privacy-policy';
import TermsAndConditions from '../../ui/terms-and-conditions';

const DATE_FORMAT = moment.HTML5_FMT.DATE;

@withUser()
class RegistrationForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    clientInvite: PropTypes.shape({
      user: PropTypes.shape({
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    }),
    onClosePrivacyPolicy: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    showPrivacyPolicy: PropTypes.bool,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    }).isRequired
  };

  state = {
    data: {
      address: '',
      agreedToTermsAndConditions: false,
      city: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      phoneNumber: '',
      state: '',
      zipcode: ''
    },
    isValid: false,
    showTermsAndConditions: false,
    showPrivacyPolicy: false
  };

  constructor(props) {
    super(props);

    const data = this._userData(props);
    if (data) {
      this.state.data = this._mergeUserData(data);
    }
  }

  componentDidUpdate(prevProps) {
    const userChanged =
      (!prevProps.user.User && this.props.user.User) ||
      prevProps.user.User !== this.props.user.User;
    const clientInviteChanged =
      (!prevProps.clientInvite && this.props.clientInvite) ||
      prevProps.clientInvite !== this.props.clientInvite;

    if (!userChanged && !clientInviteChanged) {
      return;
    }

    const data = this._userData(this.props, prevProps);
    if (data) {
      this.setState({
        data: this._mergeUserData(data)
      });
    }
  }

  _userData(props) {
    const { clientInvite } = props;
    if (clientInvite && clientInvite.user) {
      return {
        address: clientInvite.user.address,
        city: clientInvite.user.city,
        ...(clientInvite.isSpouse
          ? { emailAddress: clientInvite.user.emailAddress }
          : null),
        firstName: clientInvite.user.firstName,
        lastName: clientInvite.user.lastName,
        phoneNumber: clientInvite.user.phoneNumber,
        state: clientInvite.user.state,
        zipcode: clientInvite.user.zipcode
      };
    }

    const { User } = props.user;
    if (User) {
      return {
        address: User.address,
        city: User.city,
        firstName: User.firstName,
        lastName: User.lastName,
        emailAddress: User.emailAddress,
        phoneNumber: User.phoneNumber,
        state: User.state,
        zipcode: User.zipcode
      };
    }

    return {};
  }

  _mergeUserData(userData) {
    const { data } = this.state;

    return {
      ...data,
      address: userData.address || data.address,
      city: userData.city || data.city,
      firstName: userData.firstName || data.firstName,
      lastName: userData.lastName || data.lastName,
      emailAddress: userData.emailAddress || data.emailAddress,
      phoneNumber: userData.phoneNumber || data.phoneNumber,
      state: userData.state || data.state,
      zipcode: userData.zipcode || data.zipcode
    };
  }

  _isValidZipCode = zipcode => {
    let zipValidator = new RegExp('^[0-9a-zA-Z ]{5,8}$', 'gimu');

    return zipValidator.test(zipcode.zipcode);
  };

  render() {
    const { children, submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <MyForm
        className="password-registration-form"
        data={data}
        onChange={data => {
          this.setState({ data });
        }}
        onValidate={errors => {
          this.setState({ isValid: isEmpty(errors) });
        }}
      >
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="firstName"
            label="First Name *"
            aria-label="First Name"
            aria-required="true"
            validator={({ firstName }) => {
              if (!firstName) {
                throw new Error('First name is required');
              }
            }}
          />
          <FormField
            component={Input}
            name="lastName"
            label="Last Name *"
            aria-label="Last Name"
            aria-required="true"
            validator={({ lastName }) => {
              if (!lastName) {
                throw new Error('Last name is required');
              }
            }}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="emailAddress"
          label="Personal Email *"
          aria-label="Personal Email"
          aria-required="true"
          type="email"
          validator={({ emailAddress }) => {
            if (!emailAddress) {
              throw new Error('Must enter a personal e-mail address');
            }
          }}
        />
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="password"
            label="Password *"
            type="password"
            aria-label="Password"
            aria-required="true"
            validator={({ password }) => {
              if (!password) {
                throw new Error('Must enter a password');
              }
              if (password.length < 8) {
                throw new Error('Password must have 8 characters or more');
              }
            }}
          />
          <FormField
            component={Input}
            name="passwordConfirmation"
            label="Confirm Password *"
            type="password"
            aria-label="Confirm Password"
            aria-required="true"
            validator={({ password, passwordConfirmation }) => {
              if (!passwordConfirmation) {
                throw new Error('Must confirm password');
              }
              if (password !== passwordConfirmation) {
                throw new Error('Does not match given password');
              }
            }}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="phoneNumber"
          label="Personal Mobile Number *"
          type="tel"
          aria-label="Personal Mobile Number"
          aria-required="true"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Must enter a mobile number');
            }
            let digits = phoneNumber.replace(/\D/g, '');
            if (
              !(
                (digits[0] === '1' && digits.length === 11) ||
                digits.length === 10
              )
            ) {
              throw new Error('Mobile number must have 10 digits');
            }
          }}
        />
        <FormField
          component={Input}
          type="text"
          name="address"
          label="Address"
          aria-placeholder="Address"
        />
        <Form.Group widths="equal" aria-placeholder="Enter your city and state">
          <FormField
            component={Input}
            name="city"
            label="City"
            aria-placeholder="City"
          />
          <FormField
            component={Dropdown}
            name="state"
            label="State"
            aria-placeholder="US States and Terrotories"
            fluid
            selection
            search
            options={toPairs(US_STATES_AND_TERRITORIES).map(
              ([abbreviation, name]) => ({
                text: name,
                value: abbreviation
              })
            )}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="zipcode"
          type="text"
          maxLength="8"
          label="Zipcode"
          aria-placeholder="Zipcode"
          validator={zipcode => {
            if (zipcode.zipcode && !this._isValidZipCode(zipcode)) {
              throw new Error(
                'Zipcode formats currently accepted are USA (00000) and Canada (A1A 1A1).'
              );
            }
          }}
        />
        {children}
        <Form.Group
          className="tos-pp-container"
          validator={({ agreedToTermsAndConditions }) => {
            if (!agreedToTermsAndConditions) {
              throw new Error('Must agree to terms and conditions');
            }
          }}
        >
          <FormField
            className="tos-privacy-agreement-container"
            component={Checkbox}
            name="agreedToTermsAndConditions"
            aria-label="Check to agree to the LifeGuides' Terms and Conditions"
            aria-required="true"
            label={
              <label
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {`I am 18+ and agree to the`}
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      showTermsAndConditions: true
                    });
                  }}
                >
                  {`Terms & Conditions `}
                </a>
                {`and `}
                <a
                  href="#"
                  onClick={() => {
                    this.setState({
                      showPrivacyPolicy: true
                    });
                  }}
                >
                  {`Privacy Policy`}
                </a>
              </label>
            }
            validator={({ agreedToTermsAndConditions }) => {
              if (!agreedToTermsAndConditions) {
                throw new Error('Must agree to terms and conditions');
              }
            }}
          />
        </Form.Group>
        <Form.Button
          primary
          fluid
          size="huge"
          content="Sign Up"
          className="continue-btn"
          loading={submitting}
          disabled={!isValid || submitting}
          onClick={this._onSubmit}
        />
        <PrivacyPolicy
          visible={this.props.showPrivacyPolicy || this.state.showPrivacyPolicy}
          onClose={() => {
            this.setState({
              showPrivacyPolicy: false
            });
            if (isFunction(this.props.onClosePrivacyPolicy)) {
              this.props.onClosePrivacyPolicy();
            }
          }}
        />
        <TermsAndConditions
          visible={this.state.showTermsAndConditions}
          onClose={() => {
            this.setState({
              showTermsAndConditions: false
            });
          }}
        />
      </MyForm>
    );
  }

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const formatted = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    onSubmit(formatted);
  };
}
export default RegistrationForm;
