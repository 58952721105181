import './call-scheduler.css';

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import range from 'lodash/range';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Dropdown, Icon, Loader } from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import { getUserChannelProfile } from '../../../channel-profile';
import { APP_ROOT } from '../../../consts';
import CreateCallRequestMutation from '../../../graphql/mutations/create-call-request.graphql';
import CallRequestsByGuideIdQuery from '../../../graphql/queries/call-requests-by-guide-id.graphql';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import UserAvailabilitiesByUserIdQuery from '../../../graphql/queries/user-availabilities-by-user-id.graphql';
import UserCallCreditsStripeInfoQuery from '../../../graphql/queries/user-call-credits-stripe-info.graphql';
import UserSentCallRequestsQuery from '../../../graphql/queries/user-sent-call-requests.graphql';
import * as tracker from '../../../tracker';
import graphql from '../../hoc/graphql';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';
import { parseQS } from '../guides/params';
import OnboardDialog from '../onboard/dialog';

const moment = extendMoment(Moment);

const CALL_SLOTS_STEP = 15;
const CALL_LENGTH = 30;
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
const SuggestionCount = 3;

function nextWeekDay(d) {
  return (d + 1) % 7;
}

function dayRange(start, end) {
  const days = [];
  let i = start;
  do {
    days.push(i);
    i = nextWeekDay(i);
  } while (i != nextWeekDay(end));
  return days;
}

function mergeDateAndTime(date, time) {
  const d = moment(date);
  const t = moment(time);
  return d.hours(t.hours()).minutes(t.minutes());
}

@withChannel({ loader: null })
@withUser({ loader: null })
@graphql(UserAvailabilitiesByUserIdQuery, {
  name: 'availabilities',
  options: ({ to }) => ({
    variables: {
      userId: to.id
    }
  })
})
@graphql(CallRequestsByGuideIdQuery, {
  name: 'callRequests',
  options: ({ to }) => ({
    variables: {
      guideId: to.id,
      afterDate: moment()
        .subtract(1, 'month')
        .toDate()
    }
  })
})
@graphql(CreateCallRequestMutation, {
  name: 'createCallRequest',
  options: props => {
    const { channel, user } = props;

    if (!user.User) {
      return {};
    }

    let refetchQueries = [
      {
        query: ClientDashboardQuery,
        variables: {
          channelId: channel.channel.id,
          userId: user.User.id
        }
      }
    ];

    return {
      refetchQueries,
      update: (store, { data: { createCallRequest } }) => {
        function updateUserQuery(query, prop) {
          let data = null;
          try {
            data = store.readQuery({ query });
          } catch (e) {
            /* noop */
          }
          if (!data) {
            return;
          }

          const requests = get(data, prop);
          requests.push(createCallRequest);

          store.writeQuery({
            query,
            data
          });
        }
        updateUserQuery(UserSentCallRequestsQuery, 'callRequests');
        updateUserQuery(
          UserCallCreditsStripeInfoQuery,
          'User.sentCallRequests'
        );
      }
    };
  }
})
@withRouter
class CallScheduler extends Component {
  static propTypes = {
    availabilities: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      userAvailabilities: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    callRequests: PropTypes.shape({
      callRequests: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string
      }),
      loading: PropTypes.isRequired
    }).isRequired,
    createCallRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onClickLearnMore: PropTypes.func,
    to: PropTypes.object.isRequired,
    showVideo: PropTypes.bool,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        roles: PropTypes.array,
        timezone: PropTypes.string
      })
    })
  };

  state = {
    availableTimes: [],
    availabilityRanges: [],
    onboarding: false,
    selectedDate: null,
    selectedTime: null,
    submitting: false,
    suggestedTimes: range(SuggestionCount).map(i =>
      moment()
        .startOf('hour')
        .add(i + 1, 'hours')
        .add(1, 'days')
    )
  };

  componentDidUpdate(prevProps) {
    const { userAvailabilities } = this.props.availabilities;
    const { callRequests } = this.props.callRequests;

    if (
      callRequests !== prevProps.callRequests.callRequests ||
      userAvailabilities !== prevProps.availabilities.userAvailabilities
    ) {
      this._updateAvailabilities();
    }
  }

  _createAvailabilityRanges() {
    const { userAvailabilities } = this.props.availabilities;
    const { User } = this.props.user;

    if (!userAvailabilities) {
      return [];
    }

    const timezone = (User && User.timezone) || moment.tz.guess();
    const start = moment()
      .tz(timezone)
      .startOf('day');
    const end = start
      .clone()
      .tz(timezone)
      .add(2, 'weeks');
    const selectionRange = moment.range(start, end);

    return userAvailabilities.reduce((acc, availability) => {
      const endDate = moment(availability.endTime)
        .tz(timezone)
        .startOf('day');
      const endDay = endDate.day();
      const endTime = moment(availability.endTime).tz(timezone);

      const startDate = moment(availability.startTime)
        .tz(timezone)
        .startOf('day');
      const startDay = startDate.day();
      const startTime = moment(availability.startTime).tz(timezone);

      const daysOfWeek = dayRange(startDay, endDay);

      const included = {};

      if (availability.isRecurring) {
        const events = Array.from(selectionRange.by('day')).reduce(
          (acc, day) => {
            const week = day.week();
            const isOnDay = daysOfWeek.includes(day.day());

            const isEffectiveEndDateAfterDay =
              !availability.effectiveEndDate ||
              moment(availability.effectiveEndDate)
                .tz(timezone)
                .endOf('day')
                .isSameOrAfter(day);
            const isEffectiveStartDateBeforeDay =
              !availability.effectiveStartDate ||
              moment(availability.effectiveStartDate)
                .tz(timezone)
                .startOf('day')
                .isSameOrBefore(day);

            if (
              !isOnDay ||
              !isEffectiveEndDateAfterDay ||
              !isEffectiveStartDateBeforeDay ||
              included[week]
            ) {
              return acc;
            }

            included[week] = true;

            const de = endDate.clone();
            const ds = startDate.clone();
            let diff = 0;
            while (
              !de.isBetween(
                day.clone().startOf('week'),
                day.clone().endOf('week'),
                null,
                '[]'
              ) &&
              !ds.isBetween(
                day.clone().startOf('week'),
                day.clone().endOf('week'),
                null,
                '[]'
              ) &&
              diff < 52
            ) {
              diff++;
              de.add(1, 'week');
              ds.add(1, 'week');
            }

            return acc.concat([
              {
                end: mergeDateAndTime(
                  endDate
                    .clone()
                    .add(diff, 'weeks')
                    .format(DATE_FORMAT),
                  endTime
                ),
                start: mergeDateAndTime(
                  startDate
                    .clone()
                    .add(diff, 'weeks')
                    .format(DATE_FORMAT),
                  startTime
                )
              }
            ]);
          },
          []
        );
        return acc.concat(events);
      } else {
        if (selectionRange.contains(startTime)) {
          return acc.concat({
            end: mergeDateAndTime(endDate.format(DATE_FORMAT), endTime),
            start: mergeDateAndTime(startDate.format(DATE_FORMAT), startTime)
          });
        }
        return acc;
      }
    }, []);
  }

  _createAvailableTimes(availabilityRanges) {
    const { callRequests } = this.props.callRequests;
    const { User } = this.props.user;

    if (!callRequests) {
      return [];
    }

    const timezone = (User && User.timezone) || moment.tz.guess();

    return availabilityRanges.reduce((acc, range) => {
      const availabilityRange = moment.range(
        range.start,
        moment(range.end).subtract(CALL_SLOTS_STEP, 'minutes')
      );
      const availabilitySlots = Array.from(
        availabilityRange.by('minutes', {
          excludeEnd: true,
          step: CALL_SLOTS_STEP
        })
      );
      const unallocatedSlots = availabilitySlots.reduce((acc, time) => {
        const timeAllocated = callRequests.some(callRequest => {
          const times = callRequest.call
            ? [callRequest.call.scheduledTime]
            : callRequest.suggestedTimes;
          return times.some(callRequestTime => {
            const callRequestRange = moment.range(
              moment(callRequestTime)
                .tz(timezone)
                .subtract(CALL_LENGTH, 'minutes'),
              moment(callRequestTime)
                .tz(timezone)
                .add(CALL_LENGTH, 'minutes')
            );
            return callRequestRange.contains(time);
          });
        });
        if (!timeAllocated) {
          return acc.concat([time]);
        }
        return acc;
      }, []);
      return acc.concat(unallocatedSlots);
    }, []);
  }

  _updateAvailabilities() {
    const availabilityRanges = this._createAvailabilityRanges();
    const availableTimes = this._createAvailableTimes(availabilityRanges);

    this.setState({ availableTimes, availabilityRanges });
  }

  _getAvailableDates() {
    const { availabilityRanges } = this.state;

    const availableDates = availabilityRanges.reduce((acc, range) => {
      const dates = Array.from(
        moment
          .range(
            range.start.clone().startOf('day'),
            range.end.clone().endOf('day')
          )
          .by('day')
      ).filter(date => !acc.some(d => d.isSame(date, 'day')));
      return acc.concat(dates);
    }, []);

    availableDates.sort((a, b) => {
      return a.isBefore(b) ? -1 : 1;
    });

    return availableDates;
  }

  _getAvailableTimesByDate(dateStr) {
    const { availableTimes } = this.state;

    return availableTimes.filter(time => {
      return time.format(DATE_FORMAT) === dateStr;
    });
  }

  render() {
    const { channel, user, to, showVideo, onClickLearnMore } = this.props;
    const loading = channel.loading || user.loading;
    const { onboarding, selectedDate, selectedTime, submitting } = this.state;

    const isValidDateTime = selectedDate && selectedTime;

    let onLearnMoreHandler = this._toGuideSubroute(this._url('schedule'));

    if (isFunction(onClickLearnMore)) {
      onLearnMoreHandler = onClickLearnMore;
    }

    return (
      <div className="call-scheduler">
        <div className="about-guide-scheduling">
          <div className="talk-to-guide">
            <span className="talk-to-name">Talk to {to.firstName}!</span>
          </div>

          <div className="availability-scheduler-details">
            <div className="user-availability-text">
              <span>
                Select a time that works with your schedule and we’ll follow up
                with a meeting invitation via email.
              </span>
            </div>
          </div>
        </div>

        <div className="guide-suggested-times-container">
          {this._renderAvailablities()}
        </div>

        <div className="scheduler-cta">
          <Button
            size="huge"
            primary
            fluid
            loading={loading || submitting}
            disabled={
              !isValidDateTime ||
              loading ||
              submitting ||
              to.guideStatus === 'DEACTIVATED'
            }
            onClick={this._onSubmit}
            className="call-scheduler-button avenir"
          >
            Book Call
          </Button>
        </div>
        {showVideo && (
          <div className="scheduler-close-video">
            <Button
              size="huge"
              fluid
              onClick={onLearnMoreHandler}
              //onClick={this._toGuideSubroute(this._url('schedule'))}
              className="scheduler-close-video-button"
            >
              <Icon name="chevron left" size="small" />
              Exit Video
            </Button>
          </div>
        )}
        <OnboardDialog open={onboarding} onComplete={this._onOnboardComplete} />
      </div>
    );
  }

  _renderAvailablities() {
    const { loading: availabilitiesLoading } = this.props.availabilities;
    const { loading: callRequestsLoading } = this.props.callRequests;

    if (availabilitiesLoading || callRequestsLoading) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }

    return (
      <>
        {this._renderAvailableDates()}
        <br />
        {this._renderAvailableTimes()}
      </>
    );
  }

  _renderAvailableDates() {
    const availableDates = this._getAvailableDates();

    const options = availableDates.map(date => {
      return {
        text: date.format('ddd, MMM Do'),
        value: date.format(DATE_FORMAT)
      };
    });

    return (
      <Dropdown
        fluid
        placeholder="Select a date"
        button
        scrolling
        options={options}
        onChange={(event, { value }) => {
          this.setState({ selectedDate: value });
        }}
      />
    );
  }

  _renderAvailableTimes() {
    const { selectedDate } = this.state;

    const availableTimes = selectedDate
      ? this._getAvailableTimesByDate(selectedDate)
      : [];

    const options = availableTimes.map(time => {
      return {
        text: time.format('h:mm A'),
        value: time.format(TIME_FORMAT)
      };
    });

    return (
      <Dropdown
        fluid
        disabled={!selectedDate}
        placeholder="Select a time"
        button
        scrolling
        options={options}
        onChange={(event, { value }) => {
          this.setState({ selectedTime: value });
        }}
      />
    );
  }

  _toGuideSubroute = url => e => {
    const { history } = this.props;
    e.stopPropagation();
    history.push(url);
  };

  _url(subroute) {
    const { to, match } = this.props;

    const { page, searchTerm } = parseQS(location.search);

    const newParams = qs.stringify({
      page,
      searchTerm
    });

    const path = match.path.replace(/\/?:id\/.*/, '');

    let _route = `${APP_ROOT}${path}/${to.id}`;
    if (subroute) {
      _route = `${_route}/${subroute}`;
    }
    _route = `${_route}?${newParams}`;
    return _route;
  }

  _onSubmit = () => {
    const { channel } = this.props.channel;
    const { User } = this.props.user;

    getUserChannelProfile(User, channel).then(channelProfile => {
      if (!channelProfile) {
        this.setState({ onboarding: true });
      } else {
        this._createCallRequest();
      }
    });
  };

  _onOnboardComplete = () => {
    this.setState({ onboarding: false });

    this._createCallRequest();
  };

  _createCallRequest() {
    const { createCallRequest, history, to } = this.props;
    const { channel } = this.props.channel;
    const { User } = this.props.user;
    const { selectedDate, selectedTime } = this.state;

    const dateTime = mergeDateAndTime(
      moment(selectedDate, DATE_FORMAT),
      moment(selectedTime, TIME_FORMAT)
    );

    function inPreferedTimezone(d) {
      const m = moment(d);
      const guess = moment.tz.guess();
      if (User && User.timezone !== guess) {
        const localStr = m.format(DATE_TIME_FORMAT);
        return moment.tz(localStr, DATE_TIME_FORMAT, User.timezone);
      }
      return m;
    }

    const scheduledTime = inPreferedTimezone(dateTime)
      .utc()
      .format();

    /*if (!User || !User.roles.length) {
      const data = {
        channelId: channel.id,
        channelSlug: channel.slug,
        toId: to.id,
        scheduledTime
      };
      localStorage.setItem(GUIDE_CALL_REQUEST_KEY, JSON.stringify(data));
      history.push(`${APP_ROOT}/register`);
      return;
    }

    localStorage.removeItem(GUIDE_CALL_REQUEST_KEY);*/

    const variables = {
      channelId: channel.id,
      fromId: User.id,
      toId: to.id,
      scheduledTime
    };

    this.setState({ submitting: true });
    createCallRequest({ variables })
      .then(() => {
        tracker.event('requestCall', 1, { channel: channel.slug });

        this.setState({ submitting: false });
        history.push(`${APP_ROOT}/${channel.slug}/dashboard`);
      })
      .catch(error => {
        tracker.event('requestCall', 0, { channel: channel.slug });

        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'CallScheduler._createCallRequest',
          request: {
            ...variables
          }
        });
      });
  }
}
export default CallScheduler;
