import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../../ui/form';

class UpdateCallCreditsForm extends Component {
  static propTypes = {
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func,
    user: PropTypes.shape({
      callCredits: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      data: {
        callCredits: user.callCredits || 0,
        description: ''
      }
    };
  }

  render() {
    const { onValidate, user } = this.props;
    const { data } = this.state;

    return (
      <MyForm data={data} onChange={this._onDataChange} onValidate={onValidate}>
        <FormField
          component={Input}
          name="callCredits"
          label={`Previous Call Credits : ${user.callCredits}`}
          type="number"
          validator={({ callCredits }) => {
            if (callCredits < 0) {
              throw new Error("Call credits can't be negative");
            }
          }}
        />
        <FormField
          component={Input}
          name="description"
          label="Reason for adjustment"
          placeholder="I adjusted this users call credits because..."
          type="text"
          autoComplete="false"
          validator={({ description }) => {
            if (!description) {
              throw new Error('Must provide a reason');
            }
          }}
        />
      </MyForm>
    );
  }

  _onDataChange = data => {
    const { onDataChange } = this.props;

    const formatted = {
      ...data,
      callCredits: isString(data.callCredits)
        ? parseInt(data.callCredits.replace(/\D/g, ''), 10)
        : data.callCredits
    };

    this.setState({
      data: formatted
    });

    if (onDataChange) {
      onDataChange(formatted);
    }
  };
}
export default UpdateCallCreditsForm;
