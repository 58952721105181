import React, { PureComponent } from 'react';
//import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

//import { APP_ROOT } from '../../../consts';
import GenericContactError from '../../ui/contact-us/generic-contact-error.js';

export default class NotAuthorizedPage extends PureComponent {
  render() {
    let errorMsg = 'You are not authorized to view this resource!';
    return (
      <div>
        <Container fluid text textAlign="center">
          {/* <p>
            <Link to={`${APP_ROOT}/contact`}>Contact us</Link> if this is an
            error!
          </p> */}

          <GenericContactError error={errorMsg} />
        </Container>
      </div>
    );
  }
}
