import './index.css';
import './semantic-overrides.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Divider } from 'semantic-ui-react';

import GuideBookedTimes from '../../../../../graphql/queries/concierge-guides-booked-calls.graphql';
import BookingTimeSelector from '../booking-time-selector';
import CalendarSelector from '../calendar-selector';
import ChannelSelector from '../channel-selector';
import ClientSelector from '../client-selector';
import GuideSelector from '../guide-selector';
import { COMPANY_PROP_TYPE, TIMES_PROP_TYPE } from './proptypes';

class CreateCallRequestForm extends Component {
  state = {
    callRequest: {
      selectedClient: null,
      selectedChannel: null,
      selectedGuide: null,
      selectedDayOf: new Date(),
      selectedDateTime: new Date(),
      selectedTimezone: null
    }
  };

  render() {
    const { callRequest } = this.state;

    const {
      selectedClient,
      selectedChannel,
      selectedGuide,
      selectedDateTime,
      selectedDayOf
    } = callRequest;

    return (
      <div className="concierge-create-call-request-form">
        <div className="create-call-people-selector container-row ">
          <ClientSelector onChange={this.updateCallRequest} />
          <ChannelSelector onChange={this.updateCallRequest} />
          <CalendarSelector
            selectedDateTime={selectedDateTime}
            selectedChannel={selectedChannel}
            selectedClient={selectedClient}
            onChange={this.updateCallRequest}
          />
        </div>
        <div>
          <Divider />
        </div>
        <div className="create-call-time-selector container-row ">
          {selectedChannel && (
            <BookingTimeSelector
              selectedGuide={selectedGuide}
              selectedClient={selectedClient}
              selectedDateTime={selectedDateTime}
              selectedDayOf={selectedDayOf}
              selectedChannel={selectedChannel}
              onSelectGuide={this.updateCallRequest}
              onSelectBooking={this.updateCallRequest}
              onSelectTimezone={this.updateCallRequest}
              onChange={data => {
                //this.setState(data);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  updateCallRequest = data => {
    const { onDataChange } = this.props;
    const { callRequest } = this.state;

    const updatedCallRequest = { ...callRequest, ...data };

    if (_.isFunction(onDataChange)) {
      const {
        selectedClient,
        selectedChannel,
        selectedGuide,
        selectedDateTime,
        selectedTimezone
      } = updatedCallRequest;

      console.log();

      onDataChange({
        channelId: selectedChannel ? selectedChannel.id : null,
        fromId: selectedClient ? selectedClient.id : null,
        toId: selectedGuide ? selectedGuide.id : null,
        selectedClientTimezone: selectedClient ? selectedClient.timezone : null,
        selectedTimezone:
          selectedTimezone || (selectedClient && selectedClient.timezone),
        selectedDateTime
      });
    }

    this.setState({ callRequest: updatedCallRequest });
  };

  static propTypes = {
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func,
    guideBookedTimes: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      callRequests: PropTypes.arrayOf(TIMES_PROP_TYPE)
    })
  };
}
export default CreateCallRequestForm;
