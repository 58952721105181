import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Accordion } from 'semantic-ui-react';

import CallNote from './call-note';

export default class CallNotes extends PureComponent {
  static propTypes = {
    call: PropTypes.object,
    notes: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
  };

  state = { activeIndex: 0 };

  changeTab = (note, index) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  noteToHtml = (note, index) => {
    const { call, user } = this.props;

    const _id = `note_${note.id || index}`;
    const _active = index === this.state.activeIndex || false;

    return (
      <CallNote
        active={_active}
        call={call}
        note={note}
        user={user}
        data-index={index}
        onClick={() => {
          this.changeTab(note, index);
        }}
        key={_id}
      />
    );
  };

  render() {
    const { notes } = this.props;
    const _notes = notes.map(this.noteToHtml);
    return (
      <Accordion styled fluid>
        {_notes}
      </Accordion>
    );
  }
}
