import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Message } from 'semantic-ui-react';

const InitialPrice = 50;

class AccessCodeField extends Component {
  static propTypes = {
    accessCode: PropTypes.shape({
      code: PropTypes.string
    })
  };

  render() {
    return (
      <Message style={{ padding: '.67857143em 1em' }} positive size="large">
        <Icon name="check" color="green" />
        {this._renderDescription()}
        {/*<Icon
            name="close"
            style={{ top: '.67857143em' }}
            onClick={this._clearAccessCode}
          />*/}
      </Message>
    );
  }

  _renderDescription() {
    const { accessCode } = this.props;
    if (accessCode.initialPrice) {
      if (accessCode.callCredits) {
        return (
          <span>
            {accessCode.callCredits} calls with a guide for $
            {accessCode.initialPrice.toFixed(2)}
          </span>
        );
      } else {
        const discount = InitialPrice - accessCode.initialPrice;
        return (
          <span>
            <strong>${discount.toFixed(2)} off</strong> of initial signup price
          </span>
        );
      }
    } else if (accessCode.callCredits) {
      return (
        <span>
          You have <strong>{accessCode.callCredits} free credits</strong> on
          signup
        </span>
      );
    }
  }
}
export default AccessCodeField;
