import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import AdminDashboard from './admin';
import ClientDashboard from './client';
import ConciergeDashboard from './concierge';
import GuideDashboard from './guide';

@withUser({
  authenticated: [ROLES.CLIENT, ROLES.GUIDE, ROLES.CONCIERGE, ROLES.ADMIN]
})
class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  render() {
    const { loading } = this.props.user;
    let content = loading ? this._loader() : this._renderRoleDashboard();
    return <div className="dashboard">{content}</div>;
  }

  _loader = () => {
    return (
      <Segment
        basic
        loading={true}
        className="dashboard-loader"
        size={`massive`}
      />
    );
  };

  _renderRoleDashboard() {
    const { User } = this.props.user;

    if (!User) {
      return null;
    }

    if (User.roles.some(role => role.name === ROLES.GUIDE)) {
      return <GuideDashboard />;
    } else if (User.roles.some(role => role.name === ROLES.CLIENT)) {
      return <ClientDashboard />;
    } else if (User.roles.some(role => role.name === ROLES.CONCIERGE)) {
      return <ConciergeDashboard />;
    } else if (User.roles.some(role => role.name === ROLES.ADMIN)) {
      return <AdminDashboard />;
    }
  }
}
export default Dashboard;
