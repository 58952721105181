function detectService(url) {
  const whiteListed = ['youtube', 'vimeo'];
  let result = whiteListed.find(x => url.includes(x));
  return result;
}

function extractVimeoId(url) {
  let matchTemplate =
    '(?:www\\.|player\\.)?vimeo.com\\/(?:channels\\/(?:\\w+\\/)?|groups\\/(?:[^\\/]*)\\/videos\\/|album\\/(?:\\d+)\\/video\\/|video\\/|)(\\d+)(?:[a-zA-Z0-9_\\-]+)?';

  let id = url.match(matchTemplate);
  return id
    ? `https://player.vimeo.com/video/${encodeURI(id[1])}?byline=0&portrait="0"`
    : null;
}

function extractYoutubeId(url) {
  let matchTemplate = 'v=([a-zA-Z0-9_-]+)&?';

  let id = url.match(matchTemplate);
  return id ? `https://www.youtube.com/embed/${encodeURI(id[1])}` : null;
}

function getVideoInfo(url) {
  const detectedService = detectService(url);
  const services = {
    youtube: {
      service: 'youtube',
      url: extractYoutubeId(url)
    },
    vimeo: {
      service: 'vimeo',
      url: extractVimeoId(url)
    }
  };
  return services[detectedService];
}

export default getVideoInfo;
