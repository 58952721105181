import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Input, Message } from 'semantic-ui-react';

import withUser from '../../hoc/with-user';
import MyForm, { FormField } from '../../ui/form';

const FormUrl = 'https://forms.ontraport.com/v2.4/form_processor.php?';

@withUser()
class SMSOptInForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onError: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string,
        ontraportId: PropTypes.string
      })
    }).isRequired
  };

  state = {
    data: {
      emailAddress: '',
      phoneNumber: ''
    },
    error: null,
    isReadOnly: false,
    isValid: false,
    submitting: false
  };

  componentDidMount() {
    this._checkUser();
  }

  componentDidUpdate(prevProps) {
    this._checkUser(prevProps);
  }

  _checkUser(prevProps) {
    const { User } = this.props.user;

    if (!User) {
      return;
    }

    const hadContact =
      prevProps &&
      prevProps.user.User &&
      prevProps.user.User.emailAddress &&
      prevProps.user.User.phoneNumber;
    const hasContact = User.emailAddress && User.phoneNumber;

    if (!hadContact && hasContact) {
      const data = {
        emailAddress: User.emailAddress || '',
        phoneNumber: User.phoneNumber || ''
      };
      this.setState({ data, isReadOnly: true });
    }
  }

  render() {
    const { data, error, isReadOnly, isValid, submitting } = this.state;

    return (
      <div>
        {error && <Message negative>{error.message || error}</Message>}

        <div style={{ maxWidth: 400, margin: '0 auto 1em' }}>
          <MyForm
            data={data}
            onChange={data => {
              this.setState({ data });
            }}
            onValidate={errors => {
              this.setState({ isValid: isEmpty(errors) });
            }}
          >
            <FormField
              component={Input}
              name="phoneNumber"
              type="tel"
              disabled={isReadOnly}
              label="Personal Mobile Number (SMS)"
              aria-label="Mobile SMS Phone Number"
              aria-required="true"
              validator={({ phoneNumber }) => {
                if (!phoneNumber) {
                  throw new Error('Must enter a phone number');
                }
                let digits = phoneNumber.replace(/\D/g, '');
                if (
                  !(
                    (digits[0] === '1' && digits.length === 11) ||
                    digits.length === 10
                  )
                ) {
                  throw new Error('Phone number must have 10 digits');
                }
              }}
            />
            <FormField
              component={Input}
              name="emailAddress"
              type="email"
              disabled={isReadOnly}
              label="Email"
              aria-label="Email"
              aria-required="true"
              validator={({ emailAddress }) => {
                if (!emailAddress) {
                  throw new Error('Must enter an e-mail address');
                }
              }}
            />
            <div style={{ textAlign: 'center' }}>
              <Button
                size="huge"
                fluid
                primary
                loading={submitting}
                disabled={!isValid || submitting}
                onClick={this._onSubmit}
              >
                Enable SMS
              </Button>
              <div style={{ margin: '1em 0' }}>
                <Button
                  basic
                  loading={submitting}
                  disabled={submitting}
                  onClick={this._onSkip}
                >
                  Skip
                </Button>
              </div>
            </div>
          </MyForm>
        </div>
      </div>
    );
  }

  _onSubmit = () => {
    const { onError, onSubmit } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    const formData = new FormData();
    formData.append('sms_number', data.phoneNumber);
    formData.append('email', data.emailAddress);
    formData.append('contact_id', (User && User.ontraportId) || '');
    formData.append('uid', 'p2c168657f21');
    formData.append(
      'mopsbbk',
      '3E4737A7B23B74FA101D70D1:F7DCBA503ABC80805EC7645B'
    );

    const options = { method: 'POST', mode: 'no-cors', body: formData };

    this.setState({ error: null, submitting: true });
    fetch(FormUrl, options)
      .then(() => {
        this.setState({ submitting: false });

        if (onSubmit) {
          onSubmit();
        }
      })
      .catch(error => {
        this.setState({ error, submitting: false });

        if (onError) {
          onError(error);
        }
      });
  };

  _onSkip = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  };
}
export default SMSOptInForm;
