// import Intercom from 'intercom-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import GenericContactError from './generic-contact-error';

@withRouter
export default class ContactUs extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    return;
    //return <Intercom open appId={process.env.REACT_APP_INTERCOM_APP_ID} />;
  }

  componentDidMount() {
    const { history } = this.props;
    history.length > 0 ? history.goBack() : history.push('/');
  }
}
