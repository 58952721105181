function calculateUserCallCreditsRemaining(stripeUser) {
  let callCreditsRemaining = 0;
  if (stripeUser) {
    const {
      callCredits,
      callCreditsPurchaseRequests,
      sentCallRequests
    } = stripeUser;

    callCreditsRemaining =
      callCredits +
      callCreditsPurchaseRequests.reduce((ac, r) => ac + r.callCredits, 0) -
      sentCallRequests.length;
  }
  return callCreditsRemaining;
}

export { calculateUserCallCreditsRemaining };
