import auth0 from 'auth0-js';
import qs from 'qs';
import React from 'react';

import { AUTH_0_DOMAIN } from '../../consts';

const Auth0 = new auth0.WebAuth({
  clientID: process.env.REACT_APP_AUTH_0_CLIENT_ID,
  domain: AUTH_0_DOMAIN
});

const Connections = {
  facebook: 'Facebook',
  'google-oauth2': 'Google'
};

function connect(connection, path, opts) {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const prefixedPath = path.charAt(0) === '/' ? path : `/${path}`;
  const redirectUri = `${baseUrl}${prefixedPath}`;

  const options = {
    ...opts,
    responseType: 'code token',
    redirectUri
  };

  if (connection) {
    if (!(connection in Connections)) {
      throw new Error(`Unknown connection: ${connection}`);
    }
    options.connection = connection;
  } else {
    options.connect = Object.keys(Connections).concat([
      process.env.REACT_APP_AUTH_0_DB_CONNECTION
    ]);
  }

  Auth0.authorize(options);
}

function logout() {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  Auth0.logout({
    returnTo: `${baseUrl}/logout?complete=true`
  });
}

const { hash } = window.location;
const queryString = qs.parse(hash.substring(1));

function parseHash() {
  return new Promise((resolve, reject) => {
    if (!hash) {
      resolve(null);
      return;
    }

    if (queryString.error) {
      reject(queryString.error_description);
      return;
    }

    Auth0.parseHash({ hash }, (error, response) => {
      if (error) {
        reject(error);
      } else if (response) {
        resolve(response);
      }
    });
  });
}

function getUserInfo() {
  return new Promise((resolve, reject) => {
    parseHash()
      .then(response => {
        if (!response) {
          resolve(null);
          return;
        }
        Auth0.client.userInfo(response.accessToken, (error, user) => {
          if (error) {
            reject(error);
          } else {
            resolve(user);
          }
        });
      })
      .catch(error => {
        reject(error);
      });
  });
}

const userRequest = getUserInfo();

export default function withAuth0() {
  return function componentWrapper(Component) {
    class WithAuth0 extends React.Component {
      static displayName = `withAuth0(${Component.displayName ||
        Component.name})`;

      state = {};

      componentDidMount() {
        userRequest
          .then(user => {
            this.setState({ user });
          })
          .catch(error => {
            this.setState({ error });
          });
      }

      render() {
        const { error, user } = this.state;

        const auth0Props = {
          code: queryString.code,
          connect,
          error,
          logout,
          user
        };

        return <Component auth0={auth0Props} {...this.props} />;
      }
    }

    return WithAuth0;
  };
}

export { Connections };
