import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Message, Modal } from 'semantic-ui-react';

import { propTypes as twilioPropTypes } from '../../hoc/with-twilio';
import Avatar from '../../ui/avatar';
import TwilioConnection from '../twilio-connection';

const isDebug = process.env.NODE_ENV === 'development';
navigator.getUserMedia =
  navigator.getUserMedia ||
  navigator.webkitGetUserMedia ||
  navigator.mozGetUserMedia ||
  navigator.msGetUserMedia;

class CallDialog extends Component {
  static propTypes = {
    call: PropTypes.shape({
      id: PropTypes.string.isReuqired,
      client: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      })
    }).isRequired,
    onClose: PropTypes.func,
    ...twilioPropTypes
  };

  state = {
    initialized: false
  };

  componentDidMount() {
    this._startCall();
  }

  componentDidUpdate(previousProps) {
    this._startCall();
  }

  _startCall() {
    const { call, device, status } = this.props;
    const { initialized } = this.state;

    if (status !== 'ready') {
      return;
    }
    if (device.activeConnection()) {
      return;
    }
    if (initialized) {
      return;
    }

    this.setState({ initialized: true });

    device.connect({
      To: call.client.phoneNumber,
      CallId: call.id
    });
  }

  render() {
    const {
      call,
      clearError,
      connection,
      device,
      error,
      onClose,
      setup,
      staticContext,
      status,
      tokenExpired,
      ...remaining
    } = this.props;

    return (
      <Modal size="mini" {...remaining}>
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center', padding: '1em' }}>
            <Avatar
              user={call.client}
              style={{ width: '4em', height: '4em' }}
            />
            <Header as="h1" style={{ fontWeight: 'normal' }}>
              {call.client.firstName} {call.client.lastName[0]}
            </Header>
            {this._renderConnection()}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  _renderConnection() {
    const { connection, error } = this.props;

    if (error) {
      return this._renderError();
    }

    if (!connection) {
      return null;
    }

    return (
      <TwilioConnection
        key={`twilio-connection-${connection.parameters.CallSid}`}
        close={this._onClose}
        redial={this._redial}
        {...this.props}
      />
    );
  }

  _renderError() {
    const { error, status } = this.props;

    return (
      <div style={{ marginTop: '1em' }}>
        {error.code === 31201 && (
          <Message negative>
            You must allow lifeguides permission to access your microphone to
            make calls. Please choose the Request Access button below and choose
            Allow on the popup for microphone access. This will refresh the
            window and allow you to retry the call!
          </Message>
        )}
        {error.code === 31201 ? (
          <div>
            <Button primary onClick={this._requestMicAccess}>
              Request Access
            </Button>
          </div>
        ) : status === 'ready' ? (
          <div>
            <Button primary onClick={this._redial}>
              Redial
            </Button>
            <Button onClick={this._onClose}>Try Later</Button>
          </div>
        ) : (
          <div>
            <Message negative>{error.message}</Message>
            {status === 'closed' ||
              (status === 'offline' && (
                <Button onClick={this._onClose}>Try Later</Button>
              ))}
          </div>
        )}
      </div>
    );
  }

  _requestMicAccess = () => {
    const constraints = {
      audio: true
    };

    navigator.mediaDevices.getUserMedia(
      constraints
    ); /*.then(
      res => {
        console.log('permission was requested and granted', res);
      },
      err => {
        console.log('errors', err);
      }
    );*/
  };

  _onClose = () => {
    const { clearError, connection, onClose } = this.props;

    if (!onClose) {
      return;
    }
    if (connection && connection.status() !== 'closed') {
      return;
    }

    clearError();

    onClose();
  };

  _redial = () => {
    const { clearError } = this.props;

    clearError();

    this.setState({ initialized: false }, () => {
      this._startCall();
    });
  };
}
export default CallDialog;
