import ApolloClient from './apollo';
import { reconnectSubscription } from './apollo';
import BugsnagClient from './bugsnag';
import { AUTH_TOKEN_NAME } from './consts';
import CreateAnonymousUserMutation from './graphql/mutations/create-anonymous-user.graphql';
import IsEmailAddressRegisteredMutation from './graphql/mutations/is-email-address-registered.graphql';
import UserQuery from './graphql/queries/user.graphql';

let authToken = localStorage.getItem(AUTH_TOKEN_NAME);

function getAuthToken() {
  return authToken;
}

function setAuthToken(token) {
  localStorage.setItem(AUTH_TOKEN_NAME, token);
  authToken = token;
  reconnectSubscription();
  return getUser(true);
}

function getUser(refetch) {
  const options = {
    query: UserQuery,
    fetchPolicy: refetch ? 'network-only' : 'cache-first'
  };
  return ApolloClient.query(options).then(({ data: { User } }) => User);
}

function createUser() {
  const options = {
    mutation: CreateAnonymousUserMutation
  };

  return ApolloClient.mutate(options).then(
    ({ data: { createAnonymousUser } }) => createAnonymousUser
  );
}

function emailExists(emailAddress) {
  const options = {
    mutation: IsEmailAddressRegisteredMutation,
    variables: {
      emailAddress
    }
  };

  return ApolloClient.mutate(options).then(
    ({
      data: {
        isEmailAddressRegistered: { isRegistered }
      }
    }) => isRegistered
  );
}

function ensureUserExists() {
  // Check token again in case local storage cleared for any reason
  const hasToken = localStorage.getItem(AUTH_TOKEN_NAME);
  return getUser(!hasToken).then(user => {
    if (!user) {
      return createUser().then(({ token }) => {
        return setAuthToken(token);
      });
    }
    return user;
  });
}

function logout() {
  localStorage.clear();
  authToken = null;
  BugsnagClient.user = {};
  reconnectSubscription();

  // Receiving "Store reset while query was in flight." errors,
  // currently appears to be no resolution other than waiting for requests to complete
  // https://github.com/apollographql/apollo-client/issues/2919
  // Doing exponential backoff, max 3 tries
  return new Promise((resolve, reject) => {
    const retries = 3;

    function reset(count) {
      ApolloClient.resetStore()
        .then(resolve)
        .catch(error => {
          if (count < retries) {
            const timeout = Math.pow(2, count) * 200;
            window.setTimeout(() => {
              reset(count + 1);
            }, timeout);
          } else {
            reject(error);
          }
        });
    }

    reset(0);
  });
}

export { ensureUserExists, emailExists, getAuthToken, logout, setAuthToken };
