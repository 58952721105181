import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label,
  Segment
} from 'semantic-ui-react';

import BugsnagClient from '../../../../bugsnag';
import { APP_ROOT, ROLES } from '../../../../consts';
import BanUserMutation from '../../../../graphql/mutations/ban-user.graphql';
import CreateUserChannelInviteMutation from '../../../../graphql/mutations/create-user-channel-invite.graphql';
import DeleteUserChannelInviteMutation from '../../../../graphql/mutations/delete-user-channel-invite.graphql';
import DeleteUserChannelProfileMutation from '../../../../graphql/mutations/delete-user-channel-profile.graphql';
import UpdateClientInviteStatusMutation from '../../../../graphql/mutations/update-client-invite-status.graphql';
import UpdateCompanyOnUserStatusMutation from '../../../../graphql/mutations/update-company-on-user-status.graphql';
import UpdateUserCallCredits from '../../../../graphql/mutations/update-user-call-credits.graphql';
import UpdateUserConnectCompanyMutation from '../../../../graphql/mutations/update-user-connect-company.graphql';
import UpdateUserConnectRoleMutation from '../../../../graphql/mutations/update-user-connect-role.graphql';
import UpdateUserDisconnectRoleMutation from '../../../../graphql/mutations/update-user-disconnect-role.graphql';
import UpdateUserFeaturedMutation from '../../../../graphql/mutations/update-user-featured.graphql';
import UpdateUserGuideStatusMutation from '../../../../graphql/mutations/update-user-guide-status.graphql';
import UpdateUserVideoUrlMutation from '../../../../graphql/mutations/update-user-video-link.graphql';
import AllCompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import AllUserRolesQuery from '../../../../graphql/queries/all-user-roles.graphql';
import UserByIdAdminQuery from '../../../../graphql/queries/user-by-id-admin.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import Avatar from '../../../ui/avatar';
import ErrorDialog from '../../../ui/error-dialog';
import Notification from '../../../ui/notification';
import UserBasicInfo from './basic-info';
import UserChannelInfo from './channel-info';
import ConfirmBanDialog from './confirm-ban-dialog';
import ConfirmDeleteChannelInviteDialog from './confirm-delete-channel-invite-dialog';
import ConfirmDeleteChannelProfileDialog from './confirm-delete-channel-profile-dialog';
import ConfirmRevokeClientInviteDialog from './confirm-revoke-client-invite-dialog';
import ConfirmRoleToggleDialog from './confirm-role-toggle-dialog';
import UpdateCallCreditsDialog from './update-call-credits-dialog';
import UpdateVideoURLDialog from './update-video-url-dialog';

const DIALOGS = {
  CONFIRM_BAN: 'CONFIRM_BAN',
  CONFIRM_DELETE_CHANNEL_INVITE: 'CONFIRM_DELETE_CHANNEL_INVITE',
  CONFIRM_DELETE_CHANNEL_PROFILE: 'CONFIRM_DELETE_CHANNEL_PROFILE',
  CONFIRM_REVOKE_CLIENT_INVITE: 'CONFIRM_REVOKE_CLIENT_INVITE',
  CONFIRM_ROLE_TOGGLE: 'CONFIRM_ROLE_TOGGLE',
  UPDATE_CALL_CREDITS: 'UPDATE_CALL_CREDITS',
  UPDATE_VIDEO_URL: 'UPDATE_VIDEO_URL'
};

@withUser({ authenticated: [ROLES.ADMIN] })
@graphql(AllUserRolesQuery, { name: 'roles' })
@graphql(AllCompaniesQuery, { name: 'companies' })
@graphql(BanUserMutation, {
  name: 'banUser',
  options: {
    update: (
      store,
      {
        data: {
          banUser: { id }
        }
      }
    ) => {
      const { userRoles } = store.readQuery({ query: AllUserRolesQuery });

      const role = userRoles.find(r => r.name === ROLES.BANNED);

      const params = { query: UserByIdAdminQuery, variables: { id } };
      const data = store.readQuery(params);
      const updated = {
        ...data,
        user: {
          ...data.user,
          roles: data.user.roles.concat([role])
        }
      };

      store.writeQuery({ ...params, data: updated });
    }
  }
})
@graphql(UpdateUserConnectCompanyMutation, { name: 'connectCompany' })
@graphql(UpdateUserConnectRoleMutation, { name: 'connectRole' })
@graphql(CreateUserChannelInviteMutation, {
  name: 'createChannelInvite',
  options: {
    update: (store, { data: { createUserChannelInvite } }) => {
      const variables = { id: createUserChannelInvite.user.id };
      const data = store.readQuery({ query: UserByIdAdminQuery, variables });

      data.user.channelInvites.push(createUserChannelInvite);

      store.writeQuery({ query: UserByIdAdminQuery, variables, data });
    }
  }
})
@graphql(DeleteUserChannelInviteMutation, { name: 'deleteChannelInvite' })
@graphql(DeleteUserChannelProfileMutation, { name: 'deleteChannelProfile' })
@graphql(UpdateUserDisconnectRoleMutation, { name: 'disconnectRole' })
@graphql(UpdateClientInviteStatusMutation, { name: 'updateClientInviteStatus' })
@graphql(UpdateCompanyOnUserStatusMutation, {
  name: 'updateCompanyOnUserStatus'
})
@graphql(UpdateUserGuideStatusMutation, { name: 'updateGuideStatus' })
@graphql(UpdateUserFeaturedMutation, { name: 'updateIsFeatured' })
@graphql(UpdateUserCallCredits, { name: 'updateCallCredits' })
@graphql(UpdateUserVideoUrlMutation, { name: 'updateVideoUrl' })
@graphql(UserByIdAdminQuery, {
  name: 'userById',
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    variables: { id }
  })
})
@withRouter
class User extends Component {
  static propTypes = {
    banUser: PropTypes.func.isRequired,
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isPublished: PropTypes.bool,
          name: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    connectCompany: PropTypes.func.isRequired,
    connectRole: PropTypes.func.isRequired,
    createChannelInvite: PropTypes.func.isRequired,
    deleteChannelInvite: PropTypes.func.isRequired,
    deleteChannelProfile: PropTypes.func.isRequired,
    disconnectRole: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    roles: PropTypes.shape({
      userRoles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }),
    updateCallCredits: PropTypes.func.isRequired,
    updateClientInviteStatus: PropTypes.func.isRequired,
    updateCompanyOnUserStatus: PropTypes.func.isRequired,
    updateGuideStatus: PropTypes.func.isRequired,
    updateIsFeatured: PropTypes.func.isRequired,
    updateVideoUrl: PropTypes.func.isRequired,
    userById: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string,
        callCredits: PropTypes.number,
        clientInvite: PropTypes.shape({
          id: PropTypes.string,
          companyId: PropTypes.string,
          status: PropTypes.string
        }),
        companyIds: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            company: PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string
            })
          })
        ),
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        isFeatured: PropTypes.bool,
        lastName: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    channelInvite: null,
    channelProfile: null,
    error: null,
    submitting: false,
    success: false,
    successMessage: null
  };

  render() {
    const { history } = this.props;
    const { loading, user } = this.props.userById;
    const { error, success, successMessage } = this.state;

    if (loading) {
      return <Segment basic loading />;
    }

    if (!user) {
      return null;
    }

    return (
      <div className="admin-user">
        <Header size="large">
          <Button
            primary
            icon="angle left"
            size="small"
            className="lineawesome"
            style={{
              width: '2em',
              height: '2em',
              fontSize: '.8em',
              marginRight: '1em'
            }}
            onClick={() => {
              history.push(`${APP_ROOT}/admin/users`);
            }}
          />
          User {user.id}
        </Header>
        <Segment>
          {this._renderControls()}
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <UserBasicInfo
                  user={user}
                  onCallCreditsClick={() =>
                    this.setState({ openDialog: DIALOGS.UPDATE_CALL_CREDITS })
                  }
                  onGuideStatusChange={value => this._updateGuideStatus(value)}
                  onVideoURLClick={() =>
                    this.setState({ openDialog: DIALOGS.UPDATE_VIDEO_URL })
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <UserChannelInfo
                  user={user}
                  onCreateChannelInvite={this._createChannelInvite}
                  onDeleteChannelInvite={channelInvite =>
                    this.setState({
                      channelInvite,
                      openDialog: DIALOGS.CONFIRM_DELETE_CHANNEL_INVITE
                    })
                  }
                  onDeleteChannelProfile={channelProfile =>
                    this.setState({
                      channelProfile,
                      openDialog: DIALOGS.CONFIRM_DELETE_CHANNEL_PROFILE
                    })
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
        <Notification
          open={success}
          onClose={() => {
            this.setState({ success: false });
          }}
        >
          <Icon name="check" color="green" /> {successMessage}
        </Notification>
        {this._renderDialogs()}
      </div>
    );
  }

  _renderDialogs() {
    const { user } = this.props.userById;
    const {
      channelInvite,
      channelProfile,
      openDialog,
      role,
      submitting
    } = this.state;

    const props = {
      onClose: this._onCloseDialog,
      submitting,
      user
    };

    switch (openDialog) {
      case DIALOGS.UPDATE_CALL_CREDITS:
        return (
          <UpdateCallCreditsDialog
            {...props}
            onSubmit={this._updateCallCredits}
          />
        );
      case DIALOGS.CONFIRM_BAN:
        return <ConfirmBanDialog {...props} onSubmit={this._ban} />;
      case DIALOGS.CONFIRM_DELETE_CHANNEL_INVITE:
        return (
          <ConfirmDeleteChannelInviteDialog
            {...props}
            channelInvite={channelInvite}
            onSubmit={this._deleteChannelInvite}
          />
        );
      case DIALOGS.CONFIRM_DELETE_CHANNEL_PROFILE:
        return (
          <ConfirmDeleteChannelProfileDialog
            {...props}
            channelProfile={channelProfile}
            onSubmit={this._deleteChannelProfile}
          />
        );
      case DIALOGS.CONFIRM_REVOKE_CLIENT_INVITE:
        return (
          <ConfirmRevokeClientInviteDialog
            {...props}
            onSubmit={this._revokeClientInvite}
          />
        );
      case DIALOGS.CONFIRM_ROLE_TOGGLE:
        return (
          <ConfirmRoleToggleDialog
            {...props}
            role={role}
            onSubmit={this._toggleRole}
          />
        );
      case DIALOGS.UPDATE_VIDEO_URL:
        return (
          <UpdateVideoURLDialog {...props} onSubmit={this._updateVideoURL} />
        );
      default:
        return null;
    }
  }

  _renderControls() {
    const { user } = this.props.userById;

    const isClient = user.roles.some(r => r.name === ROLES.CLIENT);
    const isGuide = user.roles.some(r => r.name === ROLES.GUIDE);
    const hasPendingClientInvite =
      user.clientInvite && user.clientInvite.status === 'PENDING';

    return (
      <div className="controls">
        <div className="user-name">
          <Avatar user={user} />
          <Header>
            {user.firstName} {user.lastName}
            <Header.Subheader>{user.emailAddress}</Header.Subheader>
          </Header>
        </div>

        {isGuide && (
          <div style={{ paddingRight: '20px' }}>
            <Label
              style={{
                backgroundColor: 'transparent'
              }}
            >
              Featured:{' '}
            </Label>
            <Checkbox
              toggle
              style={{
                verticalAlign: 'middle'
              }}
              checked={user.isFeatured ? user.isFeatured : false}
              onChange={() => this._toggleFeatured()}
            />
          </div>
        )}

        {(isClient || hasPendingClientInvite) && (
          <div className="companies">
            <div>Companies:&nbsp;&nbsp;</div>
            {this._renderCompaniesMenu()}
          </div>
        )}

        <span style={{ margin: '0 .5em' }}>
          Role:{' '}
          {user.roles.length
            ? user.roles.map(role => role.name).join(', ')
            : hasPendingClientInvite
            ? 'INVITED'
            : null}
          &nbsp;
          {this._renderRolesMenu()}
        </span>
      </div>
    );
  }

  _renderCompaniesMenu() {
    const { user } = this.props.userById;
    const { companies, loading } = this.props.companies;
    const { submitting } = this.state;

    let companyIds = [];
    let isInvitedClient = false;
    if (user) {
      if (user.roles.length) {
        companyIds = companyIds.concat(
          user.companyIds
            .filter(companyConnection => companyConnection.status === 'ACTIVE')
            .map(companyConnection => companyConnection.companyId)
        );
      } else if (
        user.clientInvite &&
        user.clientInvite.companyId &&
        user.clientInvite.status === 'PENDING'
      ) {
        isInvitedClient = true;
        companyIds.push(user.clientInvite.companyId);
      }
    }
    const availableCompanies = companies
      ? companies.filter(company => {
          return company.isPublished || companyIds.includes(company.id);
        })
      : [];

    return (
      <Dropdown
        multiple
        selection
        loading={loading}
        disabled={(!user.roles.length && !isInvitedClient) || submitting}
        options={availableCompanies.map(company => ({
          key: company.id,
          text: company.name,
          value: company.id
        }))}
        value={companyIds}
        onChange={
          isInvitedClient
            ? () =>
                this.setState({
                  openDialog: DIALOGS.CONFIRM_REVOKE_CLIENT_INVITE
                })
            : this._onCompaniesChange
        }
      />
    );
  }

  _renderRolesMenu() {
    const { user } = this.props.userById;

    const isBanned = user.roles.some(r => r.name === ROLES.BANNED);
    const isClient = user.roles.some(r => r.name === ROLES.CLIENT);
    const isCorpAdmin = user.roles.some(r => r.name === ROLES.CORP_ADMIN);

    return (
      <Dropdown icon="cog">
        <Dropdown.Menu>
          {isClient && (
            <Dropdown.Item
              text={isCorpAdmin ? 'Remove Corp Admin' : 'Make Corp Admin'}
              onClick={() => {
                this.setState({
                  openDialog: DIALOGS.CONFIRM_ROLE_TOGGLE,
                  role: ROLES.CORP_ADMIN
                });
              }}
            />
          )}
          <Dropdown.Item
            text={isBanned ? 'Un-ban' : 'Ban'}
            icon="ban"
            onClick={() => {
              if (isBanned) {
                this.setState({
                  openDialog: DIALOGS.CONFIRM_ROLE_TOGGLE,
                  role: ROLES.BANNED
                });
              } else {
                this.setState({ openDialog: DIALOGS.CONFIRM_BAN });
              }
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  _onCloseDialog = () => {
    this.setState({ openDialog: null });
  };

  _onCompaniesChange = (event, { value }) => {
    const { user } = this.props.userById;
    const { companies } = this.props.companies;

    const addedCompanies = value
      .filter(
        id =>
          !user.companyIds.find(
            cc => cc.companyId === id && cc.status === 'ACTIVE'
          )
      )
      .map(id => companies.find(c => c.id === id));
    const removedCompanies = user.companyIds
      .filter(cc => !value.includes(cc.companyId) && cc.status === 'ACTIVE')
      .map(cc => companies.find(c => c.id === cc.companyId));
    const allCompanies = [...addedCompanies, ...removedCompanies];

    this.setState({ error: null, submitting: true, success: false });
    const requests = allCompanies.map(company => this._toggleCompany(company));
    return Promise.all(requests)
      .then(() => {
        // Should sucecss message be set here? Only the last successful will show
        // I think only one value will change at a time anyways
        this.setState(
          {
            submitting: false
          },
          () => {
            this.props.userById.refetch();
          }
        );
      })
      .catch(error => {
        this.setState({ error, submitting: false });
      });
  };

  _ban = data => {
    const { banUser } = this.props;
    const { user } = this.props.userById;

    const variables = {
      ...data,
      id: user.id
    };

    this.setState({ error: null, submitting: true, success: false });
    banUser({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `${user.firstName} has been banned`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Users._ban',
          request: {
            ...variables
          }
        });
      });
  };

  _createChannelInvite = channel => {
    const { createChannelInvite } = this.props;
    const { user } = this.props.userById;

    const variables = {
      userId: user.id,
      channelId: channel.id
    };

    this.setState({ error: null, submitting: true, success: false });
    createChannelInvite({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: `${user.firstName} has been authorized to join channel ${channel.shortTitle}`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Users._createChannelInvite',
          request: {
            ...variables
          }
        });
      });
  };

  _deleteChannelInvite = () => {
    const { deleteChannelInvite } = this.props;
    const { user } = this.props.userById;
    const { channelInvite } = this.state;

    const variables = {
      id: channelInvite.id
    };

    this.setState({ error: null, submitting: true, success: false });
    deleteChannelInvite({ variables })
      .then(() => {
        this.setState(
          {
            openDialog: null,
            submitting: false,
            success: true,
            successMessage: `${user.firstName}'s ${channelInvite.channel.shortTitle} invite has been removed`
          },
          () => {
            this.props.userById.refetch();
          }
        );
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Users._deleteChannelInvite',
          request: {
            ...variables
          }
        });
      });
  };

  _deleteChannelProfile = () => {
    const { deleteChannelProfile } = this.props;
    const { user } = this.props.userById;
    const { channelProfile } = this.state;

    const variables = {
      id: channelProfile.id
    };

    this.setState({ error: null, submitting: true, success: false });
    deleteChannelProfile({ variables })
      .then(() => {
        this.setState(
          {
            openDialog: null,
            submitting: false,
            success: true,
            successMessage: `${user.firstName} has been removed from the ${channelProfile.channel.shortTitle} channel`
          },
          () => {
            this.props.userById.refetch();
          }
        );
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Users._deleteChannelProfile',
          request: {
            ...variables
          }
        });
      });
  };

  _revokeClientInvite = () => {
    const { userById } = this.props;
    const { user } = userById;
    const { updateClientInviteStatus } = this.props;

    const variables = {
      id: user.clientInvite.id,
      status: 'REVOKED'
    };

    this.setState({ error: null, submitting: true, success: false });
    updateClientInviteStatus({ variables })
      .then(() => {
        this.setState(
          {
            openDialog: null,
            submitting: false,
            success: true,
            successMessage: `${user.firstName}'s invite has been revoked`
          },
          () => {
            userById.refetch();
          }
        );
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'User._revokeClientInvite',
          request: {
            ...variables
          }
        });
      });
  };

  _toggleCompany(company) {
    const { connectCompany, updateCompanyOnUserStatus } = this.props;
    const { user } = this.props.userById;

    const companyConnection = user.companyIds.find(
      c => c.companyId == company.id
    );

    const mutation = companyConnection
      ? updateCompanyOnUserStatus
      : connectCompany;

    const variables = {
      ...(companyConnection
        ? {
            id: companyConnection.id,
            status:
              companyConnection.status === 'ACTIVE' ? 'TERMINATED' : 'ACTIVE'
          }
        : { id: user.id, companyId: company.id })
    };

    return mutation({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: `${user.firstName} has been ${
            companyConnection && companyConnection.status === 'ACTIVE'
              ? 'removed from'
              : 'assigned to'
          } ${company.name || company.company.name}`
        });
      })
      .catch(error => {
        BugsnagClient.notify(error, {
          context: 'User._toggleCompany',
          request: {
            ...variables
          }
        });
        throw error;
      });
  }

  _toggleFeatured() {
    const { updateIsFeatured } = this.props;
    const { user } = this.props.userById;

    let variables = {
      id: user.id,
      isFeatured: !user.isFeatured
    };
    let message = variables.isFeatured
      ? 'User has been added to featured guides.'
      : 'User has been removed from featured guides.';

    this.setState({ error: null, submitting: true, success: false });
    updateIsFeatured({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: `${message}`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'User._toggleFeatured',
          request: {
            ...variables
          }
        });
      });
  }

  _toggleRole = () => {
    const { connectRole, disconnectRole } = this.props;
    const { user } = this.props.userById;
    const { userRoles } = this.props.roles;
    const { role } = this.state;

    const userRole = userRoles.find(r => r.name === role);
    const hasRole = !!user.roles.find(r => r.name === role);

    const variables = {
      userId: user.id,
      roleId: userRole.id
    };
    const mutation = hasRole ? disconnectRole : connectRole;

    this.setState({ error: null, submitting: true, success: false });
    mutation({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `${userRole.name} has been ${
            hasRole ? 'removed' : 'added'
          } ${hasRole ? 'from' : 'to'} ${user.firstName}`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'User._toggleRole',
          request: {
            ...variables
          }
        });
      });
  };

  _updateCallCredits = data => {
    const { updateCallCredits } = this.props;
    const { user } = this.props.userById;

    const variables = {
      ...data,
      id: user.id,
      callCreditsDiff: data.callCredits - user.callCredits
    };

    this.setState({ error: null, submitting: true, success: false });
    updateCallCredits({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `Call credits set to ${data.callCredits}`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'User._updateCallCredits',
          request: {
            ...variables
          }
        });
      });
  };

  _updateGuideStatus(status) {
    let { updateGuideStatus } = this.props;
    const { user } = this.props.userById;

    const variables = {
      id: user.id,
      status: status || null
    };

    this.setState({ error: null, submitting: true, success: false });
    updateGuideStatus({ variables })
      .then(() => {
        this.setState({
          submitting: false,
          success: true,
          successMessage: status
            ? `Guide status set to ${status.toLowerCase()}`
            : 'Guide status unset'
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'User._updateGuideStatus',
          request: {
            ...variables
          }
        });
      });
  }

  _updateVideoURL = data => {
    const { updateVideoUrl } = this.props;
    const { user } = this.props.userById;

    const variables = {
      id: user.id,
      videoUrl: data.url
    };

    this.setState({ error: null, submitting: true, success: false });
    updateVideoUrl({ variables })
      .then(() => {
        this.setState({
          openDialog: null,
          submitting: false,
          success: true,
          successMessage: `The video link has been added to ${user.firstName}'s profile.`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'User._updateVideoURL',
          request: {
            ...variables
          }
        });
      });
  };
}
export default User;
