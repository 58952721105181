import 'blueimp-canvas-to-blob';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Dimmer, Icon, Loader } from 'semantic-ui-react';

import BugsnagClient from '../../../../bugsnag';
import { DEFAULT_AVATAR_URL } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import withWidth, { isWidthDown } from '../../../hoc/with-width';

@withUser({ authenticated: true })
@withWidth({ className: 'avatar-selector' })
class AvatarSelector extends Component {
  static propTypes = {
    onError: PropTypes.func,
    onFileSelect: PropTypes.func,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      User: PropTypes.shape({
        avatarUrl: PropTypes.string
      })
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  state = {
    file: null,
    filePreview: null
  };

  render() {
    const { submitting } = this.state;

    return (
      <>
        <Dropzone
          ref={ref => {
            this._dropzone = ref;
          }}
          className="avatar-dropzone"
          acceptStyle={{
            borderStyle: 'solid',
            borderColor: '#21ba45'
          }}
          rejectStyle={{
            borderStyle: 'solid',
            borderColor: '#db2828'
          }}
          maxSize={2000000}
          disabled={submitting}
          multiple={false}
          onDrop={this._onDrop}
          onDropRejected={this._onDropRejected}
        >
          {this._renderDropzoneContents}
        </Dropzone>

        <div className="avatar-dropzone-cta-container">
          <Button.Group vertical labeled icon className="avatar-dropzone-cta">
            <Button
              icon="camera"
              content="Select a Photo"
              aria-placeholder="Select a profile photo"
              className="avatar-dropzone-cta upload"
              disabled={submitting}
              onClick={() => {
                this._dropzone.open();
              }}
            />

            <Button
              icon="refresh"
              content="Rotate"
              aria-placeholder="Rotate profile photo 90°"
              className="avatar-dropzone-cta rotate"
              disabled={submitting}
              onClick={this._rotateImage}
            />
          </Button.Group>
        </div>
      </>
    );
  }

  _renderDropzoneContents = ({
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  }) => {
    const { User } = this.props.user;
    const { file, filePreview, uploadingFile, submitting, width } = this.state;

    const avatarUrl =
      filePreview ||
      (file && (file.preview || file.url)) ||
      User.avatarUrl ||
      DEFAULT_AVATAR_URL;

    let overlay = null;

    const isTablet = isWidthDown('computer', width);

    if (isDragActive) {
      overlay = <Icon name="upload" color="green" size="huge" />;
    }
    if (isDragReject) {
      overlay = <Icon name="cancel" color="red" size="huge" />;
    }
    if (uploadingFile) {
      overlay = (
        <Dimmer
          inverted
          active
          style={{
            zIndex: '0',
            display: 'flex',
            height: '175px',
            width: '175px',
            marginTop: '28px',
            marginLeft: '24px',
            background: 'transparent'
          }}
        >
          <Loader />
        </Dimmer>
      );
    }

    let style = {
      display: 'flex',
      height: '175px',
      width: '175px',
      paddingBottom: isTablet ? '20px' : null,
      alignItems: isTablet ? 'flex-end' : 'center',
      justifyContent: 'center',
      backgroundImage: isTablet
        ? `linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,1)), url(${avatarUrl})`
        : `url(${avatarUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain'
    };

    if (submitting) {
      style.backgroundImage = ``;
    }

    return (
      <div {...getRootProps()} style={style}>
        <input {...getInputProps()} />

        {overlay}
        {isTablet && (
          <Icon
            name="camera"
            size="huge"
            inverted
            onClick={e => {
              e.stopPropagation();
              this._dropzone.open();
            }}
          />
        )}

        {isTablet && (
          <Icon
            size="huge"
            name="refresh"
            inverted
            onClick={this._rotateImage}
          />
        )}
      </div>
    );
  };

  _onDrop = ([file]) => {
    const { onFileSelect } = this.props;

    this.setState({ file, filePreview: null });

    if (onFileSelect) {
      onFileSelect(file);
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ filePreview: reader.result });
    };

    // eslint-disable-next-line no-console
    reader.onabort = () => console.log('file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.log('file reading has failed');

    try {
      reader.readAsDataURL(file);
    } catch (err) {
      const variables = { file };
      BugsnagClient.notify(err, {
        context: 'AvatarSelector._onDrop',
        request: {
          ...variables
        }
      });
    }
  };

  _onDropRejected = () => {
    const { onError } = this.props;

    if (onError) {
      onError(
        new Error('The file was too large. Choose a file smaller than 2 MB.')
      );
    }
  };

  _rotateImage = e => {
    e.stopPropagation();

    const { User } = this.props.user;
    const { file, filePreview } = this.state;

    const avatarUrl =
      filePreview ||
      (file && (file.preview || file.url)) ||
      User.avatarUrl ||
      DEFAULT_AVATAR_URL;

    const filename = file ? file.name : avatarUrl.split('.')[2];
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => this._handleRotateCb(img, filename);
    img.src = avatarUrl.replace(/^https:\/\//i, 'http://');
  };

  _handleRotateCb = (img, filename) => {
    const { onFileSelect } = this.props;

    const width = img.width,
      height = img.height,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

    canvas.setAttribute('id', 'photoCanvas');
    canvas.width = width;
    canvas.height = height;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((90 * Math.PI) / 180);
    ctx.drawImage(img, -canvas.width / 2, -canvas.height / 2);
    ctx.save();
    canvas.toBlob(result => {
      const newFile = new File([result], filename);
      newFile.preview = URL.createObjectURL(result);
      this.setState({ file: newFile, filePreview: newFile.preview });

      if (onFileSelect) {
        onFileSelect(newFile);
      }
    });
  };
}
export default AvatarSelector;
