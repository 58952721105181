import './index.css';
import './onboard.css';
import '../register/billboard.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Message, Segment, Step } from 'semantic-ui-react';

import { BeckyOnPhone2 } from '../../../assets';
import { emailExists, ensureUserExists } from '../../../auth';
import BugsnagClient from '../../../bugsnag';
import {
  createOrUpdateIntakeAnswer,
  ensureUserChannelProfileExists,
  updateUserChannelProfileSubChannels
} from '../../../channel-profile';
import {
  ACCESS_CODE_KEY,
  APP_ROOT,
  LAST_CHANNEL_KEY,
  ROLES
} from '../../../consts';
import CreateGuideApplicationMutation from '../../../graphql/mutations/create-guide-application.graphql';
import UserGuideApplicationQuery from '../../../graphql/queries/user-guide-application.graphql';
import graphql from '../../hoc/graphql';
import withAuth0 from '../../hoc/with-auth0';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';
import OnboardAppHeader from '../app-header/onboard';
import SmsOptInForm from '../sms-opt-in-form';
import GuideApplicationForm from './guide-application-form';
import OptionSelect from './option-select';

const SubChannelLimit = 3;

const GraphQLErrorPrefix = 'GraphQL error: ';
const UniqueConstraintEmailAddressError =
  'A unique constraint would be violated on User. Details: Field name = emailAddress';

function friendlyError(message) {
  message = message.replace(GraphQLErrorPrefix, '');
  if (message === UniqueConstraintEmailAddressError) {
    return 'Email already in use';
  }
  return message;
}

@graphql(CreateGuideApplicationMutation, {
  name: 'createGuideApplication',
  options: {
    refetchQueries: [{ query: UserGuideApplicationQuery }]
  }
})
@graphql(UserGuideApplicationQuery, {
  name: 'guideApplication'
})
@withRouter
@withAuth0()
@withChannel({ loader: <Segment basic loading />, required: true })
@withUser({ loader: <Segment basic loading /> })
class Onboard extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        intakeQuestions: PropTypes.arrayOf(PropTypes.shape({})),
        subChannels: PropTypes.arrayOf(PropTypes.shape({}))
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    createGuideApplication: PropTypes.func.isRequired,
    guideApplication: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        guideApplication: PropTypes.shape({
          id: PropTypes.string
        })
      })
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        channelName: PropTypes.string,
        step: PropTypes.string
      })
    }),
    user: PropTypes.shape({
      User: PropTypes.shape({
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            channel: PropTypes.shape({
              id: PropTypes.string
            })
          })
        ),
        guideApplication: PropTypes.shape({
          id: PropTypes.string
        }),
        roles: PropTypes.array
      })
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  state = {
    error: null,
    selectedAnswer: null,
    selectedSubChannels: [],
    submitting: false
  };

  componentDidMount() {
    this._checkGuideApplication();
    this._checkGuideInvite();
    this._checkChannelProfile();
    this._ensureUserExists();
    this._applyAccessCode();
  }

  componentDidUpdate() {
    this._checkGuideApplication();
  }

  _ensureUserExists() {
    if (!this._ensureUserExistsRequest) {
      this._ensureUserExistsRequest = ensureUserExists();
    }

    return this._ensureUserExistsRequest;
  }

  _applyAccessCode() {
    const { location } = this.props;

    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const code = queryString.accessCode;
    if (code) {
      localStorage.setItem(ACCESS_CODE_KEY, code);
    }
  }

  _checkGuideApplication() {
    const { guideApplication, history } = this.props;
    const { step } = this.props.match.params;

    if (step >= 1) {
      return;
    }

    if (!guideApplication || !guideApplication.User) {
      return;
    }

    const { User } = guideApplication;

    if (User.guideApplication && User.guideApplication.status !== 'ACCEPTED') {
      history.push(`${APP_ROOT}/application/${User.guideApplication.id}`);
    }
  }

  _checkGuideInvite() {
    const { history } = this.props;
    const { channel } = this.props.channel;
    const { User } = this.props.user;

    if (!User || !User.roles.length) {
      return;
    }

    const isGuide = User.roles.find(r => r.name === ROLES.GUIDE);
    if (!isGuide) {
      return;
    }

    const invite = User.channelInvites.find(
      invite => invite.channel.id === channel.id
    );
    if (!invite) {
      history.replace(`${APP_ROOT}/dashboard`);
      return;
    }
  }

  _checkChannelProfile() {
    const { history } = this.props;
    const { channel } = this.props.channel;
    const { User } = this.props.user;

    if (User && User.roles.length) {
      const isClient = User.roles.find(r => r.name === ROLES.CLIENT);
      const channelProfile = User.channelProfiles.find(
        p => p.channel.id === channel.id
      );
      if (channelProfile) {
        if (isClient) {
          history.replace(`${APP_ROOT}/${channel.slug}/dashboard`);
        } else {
          history.replace(`${APP_ROOT}/dashboard`);
        }
        return;
      }
      const rolesMatch = !!User.roles.some(
        r => r.name.toLowerCase() === 'guide'
      );
      if (!rolesMatch) {
        history.replace(`${APP_ROOT}/dashboard`);
        return;
      }
    }

    localStorage.setItem(LAST_CHANNEL_KEY, channel.slug);
  }

  _getStep() {
    const { step } = this.props.match.params;

    const int = parseInt(step, 10);
    if (isNaN(int)) {
      return 1;
    }

    return Math.max(1, Math.min(4, int));
  }

  render() {
    const { User } = this.props.user;

    const isRegistered = User && !!User.roles.length;
    const shouldShowAllSteps = !isRegistered;

    const step = this._getStep() - 1;

    return (
      <div className="onboard screen-container">
        <div className="onboard onboard-header">
          <OnboardAppHeader />
        </div>

        <div className="onboard onboard-container">
          <div
            className="img-billboard"
            style={{ backgroundImage: `url(${BeckyOnPhone2})` }}
          />

          <div className="form-container">
            <div className="question-container">
              <Step.Group className="step-container" ordered unstackable>
                <Step aria-label="step 1 of 2" active={step === 0} />
                <Step aria-label="step 2 of 2" active={step === 1} />
                {shouldShowAllSteps && <Step active={step === 2} />}
                {shouldShowAllSteps && <Step active={step === 3} />}
              </Step.Group>
              {this._renderError()}
              <div className="questions">
                {step === 0 && this._renderIntakeQuestions()}
                {step === 1 && this._renderSelectSubChannel()}
                {step === 2 && this._renderGuideApplication()}
                {step === 3 && this._renderSmsOptIn()}
              </div>
            </div>
            <div className="lg-rights-reserved">
              © 2019 LifeGuides. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <Message negative aria-invalid="true">
        <p>{friendlyError(error.message)}</p>
      </Message>
    );
  }

  _renderIntakeQuestions() {
    const { loading, channel } = this.props.channel;
    const { selectedAnswer, submitting } = this.state;

    if (loading) {
      return <Segment basic loading />;
    }

    const [intakeQuestion] = channel.intakeQuestions;
    if (!intakeQuestion) {
      this._goToNextStep();
      return null;
    }

    const title = intakeQuestion[`guideTitle`];
    const subTitle = intakeQuestion[`guideSubTitle`];

    return (
      <div style={{ marginBottom: '0.5rem' }}>
        <Header as="h3" className="onboard-header-txt">
          <div className=""> {title}</div>
          <div className="" style={{ marginTop: '0.5em' }}>
            <Header.Subheader>{subTitle}</Header.Subheader>
          </div>
        </Header>
        <div className="form-questions">
          <OptionSelect
            options={intakeQuestion.options.map(option => ({
              label: option,
              value: option
            }))}
            onChange={({ value }) => {
              this.setState({ selectedAnswer: value });
            }}
          />
          <Button
            loading={submitting}
            disabled={!selectedAnswer || submitting}
            onClick={this._onSubmitAnswer}
            className="continue-btn"
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }

  _renderSelectSubChannel() {
    const { loading, channel } = this.props.channel;
    const { selectedSubChannels } = this.state;

    if (loading) {
      return <Segment basic loading />;
    }

    return (
      <div className="onboard" style={{ marginBottom: '0.5rem' }}>
        <Header as="h3">
          Telling us a bit more about your experience will allow us to better
          qualify you as a LifeGuide
          <br />
          <Header.Subheader>
            Tell us more about your experience so we may better match you with a
            client
          </Header.Subheader>
        </Header>
        <div className="form-questions">
          <OptionSelect
            options={channel.subChannels.map(subChannel => ({
              label: subChannel.title,
              value: subChannel.id
            }))}
            multiple
            limit={SubChannelLimit}
            onChange={options => {
              const selectedSubChannels = channel.subChannels.filter(
                subChannel =>
                  !!options.find(option => option.value === subChannel.id)
              );
              this.setState({ selectedSubChannels });
            }}
          />
          <Header as="h4" style={{ margin: '2em 0' }}>
            {selectedSubChannels.length} of {SubChannelLimit} Selected
          </Header>
          <Button
            className="continue-btn"
            disabled={
              !selectedSubChannels.length ||
              selectedSubChannels.length > SubChannelLimit
            }
            onClick={this._onSubmitSubChannels}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }

  _renderGuideApplication() {
    const { submitting } = this.state;

    return (
      <div>
        <Header as="h1">We’d love to get to know you!</Header>
        <p>
          A representative will be in touch with you in 24 hours to help get you
          set up as a LifeGuide!
        </p>
        <div style={{ maxWidth: 400, margin: '0 auto 5rem' }}>
          <GuideApplicationForm
            submitText="Submit Application"
            submitting={submitting}
            onSubmit={this._onSubmitGuideApplication}
          />
        </div>
      </div>
    );
  }

  _renderSmsOptIn() {
    const { history } = this.props;
    const { User } = this.props.guideApplication;

    const callback = () => {
      history.push(`${APP_ROOT}/application/${User.guideApplication.id}`);
    };

    return (
      <div>
        <Header as="h1">
          Improve your experience by enabling SMS messaging!
        </Header>
        <div style={{ textAlign: 'left' }}>
          <SmsOptInForm
            onCancel={callback}
            onError={error => {
              // eslint-disable-next-line no-console
              console.log('Ontraport form error: ', error);
              callback();
            }}
            onSubmit={callback}
          />
        </div>
      </div>
    );
  }

  _onSubmitAnswer = () => {
    const { channel } = this.props.channel;
    const { selectedAnswer } = this.state;

    const [intakeQuestion] = channel.intakeQuestions;

    this.setState({ error: null, submitting: true });
    ensureUserChannelProfileExists(channel)
      .then(userChannelProfile => {
        return createOrUpdateIntakeAnswer(
          userChannelProfile,
          intakeQuestion,
          selectedAnswer
        ).then(() => {
          this.setState({ submitting: false });
          this._goToNextStep();
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Onboard._onSubmitAnswer'
        });
      });
  };

  _onSubmitSubChannels = () => {
    const { history } = this.props;
    const { channel } = this.props.channel;
    const { User } = this.props.user;
    const { selectedSubChannels } = this.state;

    this.setState({ error: null, submitting: true });
    ensureUserChannelProfileExists(channel)
      .then(userChannelProfile => {
        return updateUserChannelProfileSubChannels(
          userChannelProfile,
          selectedSubChannels
        ).then(() => {
          this.setState({ submitting: false }, () => {
            if (User.roles.length) {
              history.push(`${APP_ROOT}/dashboard`);
            } else {
              this._goToNextStep();
            }
          });
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Onboard._onSubmitSubChannels'
        });
      });
  };

  _onSubmitGuideApplication = data => {
    const { createGuideApplication } = this.props;

    this.setState({ error: null, submitting: true });
    emailExists(data.emailAddress)
      .then(_emailExists => {
        if (_emailExists) {
          this.setState({
            error: {
              message: `An account is already registered with the email ${data.emailAddress}.`
            },
            submitting: false
          });
        } else {
          this._ensureUserExists().then(({ id }) => {
            const variables = {
              userId: id,
              data,
              ...data
            };
            createGuideApplication({ variables })
              .then(({ data: { createGuideApplication: { id } } }) => {
                this._goToNextStep();
              })
              .catch(error => {
                this.setState({ error, submitting: false });
                BugsnagClient.notify(error, {
                  context: 'Onboard._onSubmitGuideApplication',
                  request: {
                    ...variables
                  }
                });
              });
          });
        }
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Onboard._onSubmitGuideApplication',
          request: {
            ...data
          }
        });
      });
  };

  _goToNextStep() {
    const { channelName } = this.props.match.params;
    const { history } = this.props;

    const step = this._getStep();

    history.push(`${APP_ROOT}/${channelName}/onboard/guide/${step + 1}`);
  }
}
export default Onboard;
