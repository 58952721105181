import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextArea } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';

export default class MessageForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onValidate: PropTypes.func
  };

  state = {
    data: {}
  };

  render() {
    const { onChange, onValidate } = this.props;
    const { data } = this.state;

    return (
      <MyForm
        data={data}
        onChange={data => {
          this.setState({ data });
          if (onChange) {
            onChange(data);
          }
        }}
        onValidate={errors => {
          const isValid = isEmpty(errors);
          if (onValidate) {
            onValidate(isValid);
          }
        }}
      >
        <FormField
          component={TextArea}
          name="message"
          placeholder="Message"
          validator={({ message }) => {
            if (!message.length) {
              throw new Error(`Must enter a message to send`);
            }
          }}
        />
      </MyForm>
    );
  }
}
