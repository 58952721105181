import './call-requests.css';

import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Dimmer,
  Dropdown,
  Icon,
  Label,
  Loader,
  Pagination,
  Popup,
  Segment,
  Table
} from 'semantic-ui-react';

import CallRequestsByStatusQuery from '../../../../graphql/queries/call-requests-by-status-pagination.graphql';
import CallRequestsConnectionByStatusQuery from '../../../../graphql/queries/call-requests-connection-by-status.graphql';
import graphql from '../../../hoc/graphql';
import Avatar from '../../../ui/avatar/index';
import SendCallRequest from '../../send-call-request-dialog/send-call-request';
import { PAGE_SIZE, SORT_DIRECTIONS, STATUSES, parseSearch } from './params';

const COLUMNS = [
  {
    name: 'from',
    title: 'Client',
    sortable: false
  },
  {
    name: 'fromTz',
    title: '',
    sortable: false
  },
  {
    name: 'to',
    title: 'Guide',
    sortable: false
  },
  {
    name: 'toTz',
    title: '',
    sortable: false
  },
  {
    name: 'suggestedTimes',
    title: 'Call Time',
    sortable: false
  },
  {
    name: 'status',
    title: 'Status',
    sortable: false
  },
  {
    name: 'createdAt',
    title: 'Created',
    sortable: true
  }
];

function searchToVariables(search) {
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;

  return {
    first,
    skip,
    orderBy,
    status: search.status
  };
}

const USER_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string,
  avatarUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  timezone: PropTypes.string
});

@withRouter
@graphql(CallRequestsByStatusQuery, {
  name: 'callRequests',
  options: props => {
    const { location } = props;

    const search = parseSearch(location.search);
    const variables = searchToVariables(search);

    return { variables };
  }
})
@graphql(CallRequestsConnectionByStatusQuery, {
  name: 'callRequestsConnection',
  options: props => {
    const { location } = props;

    const search = parseSearch(location.search);
    const variables = {
      status: search.status
    };

    return { variables };
  }
})
class CallRequests extends Component {
  static propTypes = {
    callRequests: PropTypes.shape({
      callRequests: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string,
          from: USER_PROP_TYPE,
          status: PropTypes.string,
          timezone: PropTypes.string,
          to: USER_PROP_TYPE
        })
      ),
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired
    }).isRequired,
    callRequestsConnection: PropTypes.shape({
      callRequestsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    loading: false
  };

  componentDidUpdate(prevProps) {
    const { callRequests, location } = this.props;

    const search = parseSearch(location.search);
    const prevSearch = parseSearch(prevProps.location.search);

    if (
      search.page !== prevSearch.page ||
      search.sort !== prevSearch.sort ||
      search.direction !== prevSearch.direction ||
      search.status !== prevSearch.status
    ) {
      this.setState({ loading: true });
      const variables = searchToVariables(search);
      callRequests.refetch({ ...variables }).then(() => {
        this.setState({ loading: false });
      });
    }
  }

  render() {
    const { callRequests, loading: queryLoading } = this.props.callRequests;
    const { loading: stateLoading } = this.state;

    const loading = queryLoading || stateLoading;

    return (
      <Dimmer.Dimmable
        className="concierge-call-requests"
        as={Segment}
        basic
        blurring
        dimmed={loading}
      >
        <Dimmer active={loading} blurring inverted>
          <Loader />
        </Dimmer>
        <Table>
          <Table.Header>
            <Table.Row>{COLUMNS.map(this._renderHeader)}</Table.Row>
          </Table.Header>
          <Table.Body>
            {callRequests && callRequests.map(this._renderCallRequest)}
          </Table.Body>
        </Table>
        {this._renderPagination()}
      </Dimmer.Dimmable>
    );
  }

  _renderHeader = ({ name, sortable, title }) => {
    const { location } = this.props;

    const search = parseSearch(location.search);
    const direction =
      search.direction === SORT_DIRECTIONS.ASC ? 'ascending' : 'descending';

    return (
      <Table.HeaderCell
        key={`column-header-${name}`}
        style={{ cursor: sortable ? 'pointer' : 'auto' }}
        sorted={search.sort === name ? direction : null}
        onClick={() => {
          if (sortable) {
            this._onSort(name);
          }
        }}
      >
        {title}
        {search.sort === name && (
          <Icon name={direction === 'ascending' ? 'sort down' : 'sort up'} />
        )}
        {name === 'status' && (
          <Dropdown icon="filter">
            <Dropdown.Menu>
              {Object.values(STATUSES).map(status => (
                <Dropdown.Item
                  key={status}
                  text={capitalize(status)}
                  value={status}
                  onClick={() => {
                    this._search({ status });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Table.HeaderCell>
    );
  };

  _renderCallRequest = callRequest => {
    const { from, status, suggestedTimes, to, call } = callRequest;

    let scheduledTime = null;
    if (call && call.scheduledTime) {
      scheduledTime = call.scheduledTime;
    }

    const scheduledTimesHtml = suggestedTimes.map((t, i) => {
      let color = 'grey';
      let content =
        'This time was proposed, but not accepted between the client and guide';
      if (t === scheduledTime) {
        color = 'green';
        content = 'This time was the chosen time between client and guide';
      }

      return (
        <Popup
          key={i}
          content={content}
          trigger={
            <Label color={color}>
              {moment.tz(t, from.timezone).format('M/D/YY h:mma')}
            </Label>
          }
        />
      );
    });

    const fromTimezoneLabel = from.timezone && (
      <Table.Cell>
        <Label>{moment.tz(from.timezone).format('z')}</Label>
      </Table.Cell>
    );

    const toTimezoneLabel = to.timezone && (
      <Table.Cell>
        <Popup
          trigger={
            <Label
              basic={from.timezone !== to.timezone}
              color={from.timezone !== to.timezone ? 'red' : null}
            >
              {moment.tz(to.timezone).format('z')}
            </Label>
          }
          content={
            from.timezone !== to.timezone
              ? 'Guide and Client are in the different Timezones.'
              : 'Guide and Client are in the same Timezone.'
          }
        />
      </Table.Cell>
    );

    return (
      <Table.Row key={`call-request-${callRequest.id}`}>
        <Table.Cell>
          <Avatar user={from} /> {from.firstName} {from.lastName[0]}.{' '}
        </Table.Cell>
        {fromTimezoneLabel}
        <Table.Cell>
          <Avatar user={to} /> {to.firstName} {to.lastName[0]}.{' '}
        </Table.Cell>
        {toTimezoneLabel}
        <Table.Cell>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >
            <Label style={{ marginRight: 4 }}>
              {moment.tz(from.timezone).format('z')}
            </Label>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between'
              }}
            >
              {scheduledTimesHtml}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          <Label color="blue">{capitalize(status)}</Label>
        </Table.Cell>
        <Table.Cell>{moment(callRequest.createdAt).fromNow()}</Table.Cell>
      </Table.Row>
    );
  };

  _renderPagination() {
    const { location } = this.props;
    const {
      callRequestsConnection,
      loading
    } = this.props.callRequestsConnection;

    if (loading) {
      return null;
    }

    const search = parseSearch(location.search);

    const count = callRequestsConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    return (
      <Container textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? {
                  content: <Icon name="angle left" className="lineawesome" />,
                  icon: true
                }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? {
                  content: <Icon name="angle right" className="lineawesome" />,
                  icon: true
                }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </Container>
    );
  }

  _onSort = name => {
    const { location } = this.props;

    const search = parseSearch(location.search);

    const direction =
      search.sort === name
        ? search.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
        : SORT_DIRECTIONS.ASC;

    this._search({
      sort: name,
      direction
    });
  };

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _search(params) {
    const { history, location } = this.props;

    const search = parseSearch(location.search);
    const updated = {
      ...search,
      ...params
    };

    const url = `${location.pathname}?${qs.stringify(updated)}`;
    history.push(url);
  }
}
export default CallRequests;
