import '../../../../ui/powerful-cta/index.css';

import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { ROLES } from '../../../../../consts';
import CreateCallRequestMutation from '../../../../../graphql/mutations/create-call-request-by-user.graphql';
import CallRequestsByStatusQuery from '../../../../../graphql/queries/call-requests-by-status-pagination.graphql';
import CallRequestsConnectionByStatusQuery from '../../../../../graphql/queries/call-requests-connection-by-status.graphql';
import graphql from '../../../../hoc/graphql';
import withUser from '../../../../hoc/with-user';
import ErrorDialog from '../../../../ui/error-dialog';
import { PAGE_SIZE, SEARCH_DEFAULTS } from '../params';
import CreateCallRequestForm from './index';

const DATE_FORMAT = moment.HTML5_FMT.DATE;
const TIME_FORMAT = 'HH:mm';

@graphql(CreateCallRequestMutation, {
  name: 'createCallRequest',
  options: {
    refetchQueries: [
      {
        query: CallRequestsByStatusQuery,
        variables: {
          first: PAGE_SIZE,
          skip: 0,
          orderBy: `${SEARCH_DEFAULTS.sort}_${SEARCH_DEFAULTS.direction}`,
          status: 'ACCEPTED'
        }
      },
      {
        query: CallRequestsConnectionByStatusQuery,
        variables: { status: 'ACCEPTED' }
      }
    ]
  }
})
@withUser({ authenticated: [ROLES.CONCIERGE] })
class CreateDialog extends Component {
  static propTypes = {
    createCallRequest: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired
  };

  state = {
    data: null,
    error: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { error, isValid, submitting } = this.state;

    return (
      <Modal
        className="concierge-call-reqest-dialog"
        open
        //size="tiny"
        size="large"
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={this._onClose}
      >
        <Modal.Header>Schedule A New Call</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <CreateCallRequestForm
              onDataChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="powerful"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}
          >
            Schedule Call
          </Button>
          <ErrorDialog
            error={error}
            onClose={() => {
              this.setState({ error: null });
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = data => {
    this.setState({ data, isValid: this._validate(data) });
  };

  //TODO: change this to use the form system
  _validate(data) {
    const { toId, fromId, channelId, selectedDateTime } = data;

    if (!channelId || !toId || !fromId || !selectedDateTime) {
      return false;
    }

    return true;
  }

  _onValidate = errors => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { createCallRequest, onSubmit } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    let scheduledTime = moment
      .tz(data.selectedDateTime, data.selectedTimezone) //start with TZ you're on.
      .tz(data.selectedClientTimezone) //always convert to client TZ.
      .format();

    const variables = {
      ...data,
      createdById: User.id,
      scheduledTime: scheduledTime
    };

    this.setState({ error: null, submitting: true });
    createCallRequest({ variables })
      .then(({ data }) => {
        this.setState({ submitting: false });

        if (_.isFunction(onSubmit)) {
          onSubmit(data.createCallRequest);
        }
      })
      .catch(error => {
        this.setState({ error, submitting: false });
      });
  };
}
export default CreateDialog;
