import './index.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { APP_ROOT } from '../../../consts';
import getVideoInfo from './video-info';

const SEARCH_DEFAULTS = {
  page: 1,
  searchTerm: '',
  itemsPerPage: 6,
  searchSpeed: 500
};

function parseQS(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    searchTerm: search.searchTerm || SEARCH_DEFAULTS.searchTerm,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page
  };
}

//   const services = {
//     youTube : {
//      extractId: this.extractYoutubeId,
//      template: this.templateYoutubeUrl
//    },
//     vimeo:  {
//      extractId: this.extractVimeoeId,
//      template: this.templateYVimeoUrl
//    }
//   }

//   const detectedService = this.detectService(inputUrl)
//   const outputUrl = services[detectedSercvice].template(services[detectedService].extractId(inputUrl));

@withRouter
class GuideDetailsVideo extends Component {
  static propTypes = {
    guide: PropTypes.shape({
      videoUrl: PropTypes.string
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { videoUrl } = this.props.guide;
    if (!videoUrl) {
      this._noVideo();
      return null; //go home
    }
  }

  render() {
    const { videoUrl } = this.props.guide;

    if (!videoUrl) {
      return null; //go home
    }

    let vs = getVideoInfo(videoUrl);
    return (
      <div
        style={{
          height: 'inherit',
          width: '100%',
          backgroundColor: 'black',
          minHeight: 360
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={vs.url}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </div>
    );
  }

  _noVideo = () => {
    const { history, location } = this.props;
    let _route = `${APP_ROOT}${'/guides/schedule'}`;
    let { page, searchTerm } = parseQS(location.search);
    const newParams = qs.stringify({
      page,
      searchTerm
    });
    _route = `${_route}?${newParams}`;
    history.push(_route);
  };
}

export default GuideDetailsVideo;
