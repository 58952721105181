import './index.css';
import '../call/index.css';

import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Prompt, withRouter } from 'react-router-dom';
import {
  Button,
  Confirm,
  Grid,
  Header,
  Icon,
  List,
  Modal,
  Segment
} from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import CancelCallWithReasonMutation from '../../../graphql/mutations/cancel-call-with-reason.graphql';
import TwilioCallQuery from '../../../graphql/queries/twilio-call.graphql';
import TwilioCallSubscription from '../../../graphql/subscriptions/twilio-call.graphql';
import withUser from '../../hoc/with-user';
import Notification from '../../ui/notification';
import CallNotes from '../call-notes';
import CompleteCallDialog from '../call/complete-call-dialog';
import crisisQuestions from '../call/crisis-questions';
import ClientDetails from '../client-details';
import DiscussionTopics from '../discussion-topics';
import DurationTimer from './duration-timer';
import VolumeIndicator from './volume-indicator';

function shouldIgnoreWarning(warning) {
  if (!warning) {
    return true;
  }
  switch (warning) {
    case 'constant-audio-input-level':
      return true;
    default:
      return false;
  }
}

function friendlyWarning(warning) {
  if (!warning) {
    return '';
  }
  switch (warning) {
    case 'high-jitter':
    case 'high-packet-loss':
    case 'low-bytes-received':
      return 'Low connectivity';
    case 'high-rtt':
      return 'High latency';
    case 'ice-connectivity-lost':
      return 'Connectivity lost';
    case 'low-mos':
      return 'Low network conditions';
    default:
      return capitalize(warning.replace('-', ' '));
  }
}

@withUser({ authenticated: true })
@graphql(TwilioCallQuery, {
  name: 'twilioCall',
  options: ({
    connection: {
      parameters: { CallSid }
    }
  }) => ({
    variables: { twilioId: CallSid }
  })
})
@graphql(CancelCallWithReasonMutation, {
  name: 'cancelCall'
})
@withRouter
class TwilioConnection extends Component {
  static propTypes = {
    call: PropTypes.object.isRequired,
    cancelCall: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    connection: PropTypes.shape({
      parameters: PropTypes.shape({
        CallSid: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    device: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    redial: PropTypes.func.isRequired,
    twilioCall: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      twilioCall: PropTypes.object
    }),
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    showCrisisProtocol: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      cancelling: false,
      completing: null,
      disconnected: false,
      inProgress: false,
      muted: false,
      showWarning: false,
      showWelcomeMessage: true,
      startTime: new Date(),
      warnings: [],
      showCrisisProtocol: false
    };
  }

  componentDidMount() {
    this._listenConnection();
    this._subscribeToMore();
  }

  componentDidUpdate(previousProps) {
    this._checkInProgress(previousProps);
  }

  _checkInProgress(previousProps) {
    const { twilioCall } = this.props.twilioCall;

    if (!twilioCall) {
      return;
    }
    if (twilioCall.status !== 'in-progress') {
      return;
    }
    if (get(previousProps.twilioCall, 'twilioCall.status') === 'in-progress') {
      return;
    }

    this._startInProgressTimeout();
  }

  componentWillUnmount() {
    this._stopInProgressTimeout();
  }

  _listenConnection() {
    const { connection } = this.props;

    connection.on('disconnect', this._onDisconnect);
    connection.on('warning', this._onWarning);
    connection.on('warning-cleared', this._onWarningCleared);
  }

  _subscribeToMore() {
    const { twilioCall } = this.props;

    twilioCall.subscribeToMore({
      document: TwilioCallSubscription,
      variables: twilioCall.variables,
      onError: error => {
        // eslint-disable-next-line no-console
        console.log('subscription error', error);
      }
    });
  }

  _startInProgressTimeout() {
    this._timeout = window.setTimeout(() => {
      this.setState({ inProgress: true });
    }, 1000);
  }

  _stopInProgressTimeout() {
    if (this._timeout) {
      window.clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  render() {
    const { twilioCall } = this.props.twilioCall;
    const { inProgress } = this.state;

    if (!twilioCall || !inProgress) {
      return this._renderStatus();
    }
    if (twilioCall.status === 'in-progress') {
      return this._renderInProgress();
    }
    if (twilioCall.status === 'completed') {
      return this._renderCallCompleted();
    }

    return null;
  }

  _renderDuration() {
    const { startTime } = this.state;

    return <DurationTimer startTime={startTime} />;
  }

  _renderStatus() {
    const { close, connection, redial } = this.props;
    const { twilioCall } = this.props.twilioCall;

    const status = (twilioCall && twilioCall.status) || 'initiated';
    const text = {
      initiated: 'Connecting...',
      ringing: 'Ringing...',
      'in-progress': (
        <span>
          <Icon name="check" color="green" />
          Connected
        </span>
      ),
      completed: 'Call Completed',
      'no-answer': 'No Answer'
    };

    let content = null;
    if (connection.status() === 'open') {
      content = (
        <div className="call-length-and-cancel">
          {this._renderDuration()}
          <br />
          <Button className="cancel" onClick={() => this._disconnect(true)}>
            Cancel
          </Button>
        </div>
      );
    } else if (status === 'no-answer') {
      content = (
        <div className="redial-and-try-later">
          <br />
          <Button className="redial" primary onClick={redial}>
            Redial
          </Button>
          <Button className="try-later" onClick={close}>
            Try Later
          </Button>
          <Button
            className="cancel"
            onClick={this.setState({ cancelling: true })}
          >
            Cancel Call
          </Button>
        </div>
      );
    } else {
      content = (
        <div>
          <br />
          <Button onClick={close}>Close</Button>
        </div>
      );
    }

    return (
      <div className="status-and-content">
        <span className="text-status" style={{ fontSize: '1.2rem' }}>
          {text[status]}
        </span>
        <div className="content">{content}</div>
      </div>
    );
  }

  _renderInProgress() {
    const { call } = this.props;

    return ReactDOM.createPortal(
      <div className="twilio-connection">
        <Segment
          basic
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            zIndex: 11111,
            margin: 0,
            backgroundColor: '#FFF',
            overflowY: 'scroll'
          }}
        >
          <Grid container columns={2}>
            <Grid.Row>
              <Grid.Column width={5}>
                <ClientDetails channel={call.channel} client={call.client} />
              </Grid.Column>
              <Grid.Column width={7}>
                {this._renderVolume()}
                {this._renderTwilioUi()}
                <Header as="h3" style={{ margin: '.5em 0 1.5em 0' }}>
                  {this._renderDuration()}
                </Header>
                <CallNotes call={call} />
                {this._renderWelcomeMessage()}
                <DiscussionTopics call={call} />
              </Grid.Column>
              <Grid.Column width={4} className="call-topics">
                {this._renderCrisisButton()}
                {this._renderCrisisProtocol()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {this._renderWarning()}
        <Prompt message="Leaving this page will end the call, are you sure you want to do this?" />
      </div>,
      document.body
    );
  }

  _renderWarning() {
    const { showWarning, warnings } = this.state;

    const [warning] = warnings.reverse();

    return (
      <Notification
        open={showWarning && !!warning}
        onClose={() => {
          this.setState({ showWarning: false });
        }}
      >
        <Icon name="wifi" color="red" /> {friendlyWarning(warning)}
      </Notification>
    );
  }

  _renderCrisisButton() {
    return (
      <Button
        className="crisis-btn powerful-btn"
        onClick={() => {
          this.setState({ showCrisisProtocol: !this.state.showCrisisProtocol });
        }}
      >
        Crisis
      </Button>
    );
  }

  _renderCrisisProtocol() {
    const { showCrisisProtocol } = this.state;
    let crisisProtocolHeight = showCrisisProtocol ? 'auto' : 0;
    let crisisProtocolVisibility = showCrisisProtocol ? 1 : 0;

    const animation = showCrisisProtocol
      ? `crisisFadeIn ${125}ms ease-in-out`
      : `crisisFadeOut ${125}ms ease-in-out`;

    let listItems = crisisQuestions.map(q => {
      return (
        <List.Item key={q.id}>
          <List.Header as="">
            {q.id} {q.title}
          </List.Header>
          <List.List>
            {q.questions.map(qI => {
              return <List.Item key={qI}>○ {qI}</List.Item>;
            })}
          </List.List>
        </List.Item>
      );
    });

    return (
      <Segment
        className={`crisis`}
        style={{
          animation: animation,
          height: crisisProtocolHeight,
          opacity: crisisProtocolVisibility
        }}
      >
        <div className="call-client-header">Crisis Protocol</div>

        <List className="crisis-prototcol-text" relaxed>
          {listItems}
        </List>
      </Segment>
    );
  }

  _renderWelcomeMessage() {
    const { showWelcomeMessage } = this.state;

    if (!showWelcomeMessage) {
      return null;
    }

    return (
      <Segment color="blue" inverted>
        <Header size="small">
          First Call Script
          {this.state.disconnected ? (
            <Icon
              name="close"
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => {
                this.setState({ showWelcomeMessage: false });
              }}
            />
          ) : null}
        </Header>
        <p>
          Hello, may I speak with (name of client). Hi (Name) I&apos;m (YOUR
          NAME) your LifeGuide and I am so glad you scheduled a call with me
          today. As you know, I am experienced in (LIFE CHALLENGE) and am here
          to listen and offer my personal experience during a time that others
          may not quite understand.
          <br />
          <br />
          This call is not recorded and is totally confidential. So my intent is
          just to be here for you and over time, become a trusted advisor you
          can reach out to anytime.
          <br />
          <br />
          I&apos;ve been looking forward to meeting you and learning more about
          how I may help you. I see that you are interested in talking about
          (see subchannels they chose). Tell me more about your concerns in
          these areas.
          <br />
          <br />
          With 5 minutes left: I see that our time is almost up, is there
          anything more you want to talk about before we conclude our call
          today? Thank you again for sharing some of these difficult personal
          moments with me. Not only from my experience, but it is known that
          this journey can be very difficult. Please know that I am here to walk
          beside you. Would you like to schedule a next session? Great- I look
          forward to talking with you then. Thank you for being part of
          Lifeguides (NAME), I hope our time together was valuable for you.
          <br />
          <br />
          [They can schedule on their dashboard account or call the Well-Being
          Concierge at 877.532.3472 or the Guide can contact the WBC on their
          behalf to schedule the next call (or series of calls) for them.]
        </p>
      </Segment>
    );
  }

  _renderVolume() {
    const { connection } = this.props;

    return <VolumeIndicator connection={connection} />;
  }

  _renderTwilioUi() {
    const { connection } = this.props;

    if (connection.status() !== 'open') {
      return null;
    }

    return (
      <div className="twilio-connection">
        <div style={{ float: 'right' }}>
          {this._renderMute()}
          <Button negative onClick={() => this._disconnect()}>
            <Icon name="phone" />
            End Call
          </Button>
        </div>
      </div>
    );
  }

  _renderMute() {
    const { muted } = this.state;

    return (
      <Button onClick={this._toggleMute}>
        <Icon name={muted ? 'microphone' : 'microphone slash'} />
        {muted ? 'Unmute Me' : 'Mute Me'}
      </Button>
    );
  }

  _renderCallCutOffDialog() {
    const { close, redial } = this.props;

    return (
      <div className="twilio-connection">
        <Modal open size="tiny">
          <Modal.Content>
            <Header size="medium">Would you like to redial?</Header>
          </Modal.Content>
          <Modal.Actions className="redial-and-try-later">
            <Button
              className="cancel"
              onClick={() => this.setState({ cancelling: true })}
            >
              Cancel Call
            </Button>
            <Button className="try-later" onClick={close}>
              Try Later
            </Button>
            <Button className="redial" primary onClick={redial}>
              Redial
            </Button>
          </Modal.Actions>
        </Modal>
        {this._renderCancelDialog()}
      </div>
    );
  }

  _renderCancelDialog() {
    const { cancelling } = this.state;

    // Refactor to use the communications/cancelcall-modal
    // Will need to add reason there later
    // Currently this mutation uses a reason of 'Missed call'

    return (
      <Confirm
        open={cancelling}
        content="Are you sure you want to cancel this call?"
        cancelButton={<Button>Nevermind</Button>}
        confirmButton={<Button>Yes, please cancel</Button>}
        onCancel={() => this.setState({ cancelling: false })}
        onConfirm={this._cancelCall}
      />
    );
  }

  _renderShouldCompleteCallDialog() {
    return (
      <div className="twilio-connection">
        <Modal open size="tiny">
          <Modal.Content>
            <Header size="medium">
              Would you like to finish up this call or were you disconnected?
            </Header>
          </Modal.Content>
          <Modal.Actions className="redial-and-try-later">
            <Button
              onClick={() => {
                this.setState({ completing: false });
              }}
            >
              I was disconnected
            </Button>
            <Button
              primary
              onClick={() => {
                this.setState({ completing: true });
              }}
            >
              Finish call
            </Button>
          </Modal.Actions>
        </Modal>
        {this._renderCancelDialog()}
      </div>
    );
  }

  _renderCallCompleted() {
    const { call, close } = this.props;
    const { twilioCall } = this.props.twilioCall;
    const { completing } = this.state;

    if (completing === null) {
      return this._renderShouldCompleteCallDialog();
    }

    if (!completing) {
      return this._renderCallCutOffDialog();
    }

    return (
      <CompleteCallDialog
        open
        onCancel={close}
        call={call}
        twilioCall={twilioCall}
      />
    );
  }

  _onDisconnect = () => {
    this.setState({ disconnected: true });
  };

  _onWarning = warning => {
    const { inProgress, warnings } = this.state;

    // eslint-disable-next-line no-console
    console.log('warning', warning);

    if (!inProgress) {
      return;
    }

    if (shouldIgnoreWarning(warning)) {
      return;
    }

    if (warnings.includes(warning)) {
      return;
    }

    warnings.push(warning);
    this.setState({ showWarning: true, warnings });
  };

  _onWarningCleared = warning => {
    const { warnings } = this.state;

    // eslint-disable-next-line no-console
    console.log('warning-cleared', warning);

    const index = warnings.indexOf(warning);
    if (index < 0) {
      return;
    }

    warnings.splice(index, 1);
    this.setState({ warnings });
  };

  _toggleMute = () => {
    const { connection } = this.props;
    const { muted } = this.state;

    if (connection.status() === 'open') {
      this.setState({ muted: !muted });
      connection.mute(!muted);
    }
  };

  _disconnect = shouldClose => {
    const { close, connection } = this.props;

    if (connection.status() === 'open') {
      connection.disconnect();
    }

    if (shouldClose) {
      close();
    }
  };

  _cancelCall = () => {
    const { call, cancelCall, history } = this.props;

    const variables = {
      id: call.id,
      reason: 'Missed call'
    };

    cancelCall({ variables }).then(() => {
      history.push(`${APP_ROOT}/${call.channel.slug}/dashboard`);
    });
  };
}
export default TwilioConnection;
