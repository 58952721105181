import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Header, Modal } from 'semantic-ui-react';

import Avatar from '../avatar';
import CallNotesList from '../call-notes-list';

export default class CallNotesDialog extends Component {
  static propTypes = {
    call: PropTypes.object,
    callNotes: PropTypes.arrayOf(PropTypes.object),
    client: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { call, callNotes, client, onClose, open, user } = this.props;

    return (
      <Modal
        size="large"
        className="call-notes-dialog"
        open={open}
        onClose={onClose}
      >
        <Modal.Content>
          <Header size="medium">Notes</Header>
          <Header size="small">
            {client.firstName} {client.lastName[0]}
          </Header>
          <Avatar user={client} />
        </Modal.Content>
        <Modal.Content scrolling>
          <CallNotesList call={call} notes={callNotes} user={user} />
        </Modal.Content>
      </Modal>
    );
  }
}
