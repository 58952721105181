import { isEmpty, sampleSize } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Button, Dropdown, Form, Input, Modal } from 'semantic-ui-react';

import AllCompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import MyForm, { FormField } from '../../../ui/form';

function generateCode() {
  const chars =
    'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890';
  return sampleSize(chars, 8).join('');
}

@graphql(AllCompaniesQuery, { name: 'companies' })
class EditDialog extends Component {
  static propTypes = {
    accessCode: PropTypes.shape({
      id: PropTypes.string,
      callCredits: PropTypes.number,
      code: PropTypes.string,
      endsAt: PropTypes.string,
      initialPrice: PropTypes.number,
      name: PropTypes.string,
      startsAt: PropTypes.string
    }).isRequired,
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.array
    }).isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const { accessCode } = props;

    const data = {
      callCredits: accessCode.callCredits || '',
      code: accessCode.code || generateCode(),
      endsAt: accessCode.endsAt
        ? moment.utc(accessCode.endsAt).format('YYYY-MM-DD')
        : '',
      initialPrice: accessCode.initialPrice || '',
      name: accessCode.name || '',
      startsAt: accessCode.startsAt
        ? moment.utc(accessCode.startsAt).format('YYYY-MM-DD')
        : '',
      companyId: accessCode.company ? accessCode.company.id : null
    };

    this.state = {
      data,
      isValid: false
    };
  }

  render() {
    const { accessCode, submitting } = this.props;
    const { loading, companies } = this.props.companies;
    const { data, isValid } = this.state;

    const isNew = !accessCode.id;

    const publishedCompanies = companies
      ? companies
          .filter(company => company.isPublished)
          .map(company => ({
            text: company.name,
            value: company.id
          }))
      : [];

    return (
      <Modal open size="tiny" onClose={this._onClose}>
        <Modal.Content>
          <Modal.Description>
            <MyForm
              data={data}
              onChange={this._onDataChange}
              onValidate={errors => {
                this.setState({ isValid: isEmpty(errors) });
              }}
            >
              <FormField
                component={Input}
                name="code"
                label="Code"
                autoComplete="false"
                action={{
                  labelPosition: 'right',
                  icon: 'gear',
                  content: 'Generate',
                  onClick: () => {
                    const { data } = this.state;
                    const updatedData = {
                      ...data,
                      code: generateCode()
                    };
                    this.setState({
                      data: updatedData
                    });
                  }
                }}
                validator={({ code }) => {
                  if (!code) {
                    throw new Error('Code is required');
                  }
                }}
              />
              <FormField
                component={Input}
                name="name"
                label="Name"
                autoComplete="false"
                validator={({ name }) => {
                  if (!name) {
                    throw new Error('Name is required');
                  }
                }}
              />
              <FormField
                component={Dropdown}
                name="companyId"
                label="Companies"
                fluid
                selection
                loading={loading}
                options={[{ text: '', value: null }].concat(publishedCompanies)}
              />
              <FormField
                component={Input}
                name="callCredits"
                label="Signup Credits"
                type="number"
                autoComplete="false"
                validator={({ callCredits, initialPrice }) => {
                  if (!callCredits) {
                    if (!initialPrice) {
                      throw new Error(
                        'Must specify either free call credits or an initial purchase price'
                      );
                    }
                    return;
                  }
                  const intValue = parseInt(callCredits, 10);
                  if (intValue <= 0) {
                    throw new Error('Credits must be greater than 0');
                  }
                }}
              />
              <FormField
                component={Input}
                name="initialPrice"
                label="Initial Purchase Price"
                type="number"
                autoComplete="false"
                validator={({ callCredits, initialPrice }) => {
                  if (!initialPrice) {
                    if (!callCredits) {
                      throw new Error(
                        'Must specify either free call credits or an initial purchase price'
                      );
                    }
                    return;
                  }
                  const floatValue = parseFloat(initialPrice);
                  if (floatValue <= 0) {
                    throw new Error(
                      'Initial purchase price must be greater than 0'
                    );
                  }
                }}
              />
              <Form.Group widths="equal">
                <FormField
                  component={Input}
                  name="startsAt"
                  label="Starts"
                  type="date"
                  autoComplete="false"
                  validator={({ startsAt }) => {
                    if (startsAt && !moment(startsAt).isValid()) {
                      throw new Error('Start must be a valid date');
                    }
                  }}
                />
                <FormField
                  component={Input}
                  name="endsAt"
                  label="Ends"
                  type="date"
                  autoComplete="false"
                  validator={({ endsAt }) => {
                    if (endsAt && !moment(endsAt).isValid()) {
                      throw new Error('End must be a valid date');
                    }
                  }}
                />
              </Form.Group>
            </MyForm>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={this._onClose}>
            Cancel
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}
          >
            {isNew ? 'Create' : 'Update'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = data => {
    const { initialPrice } = data;

    let formattedInitialPrice = parseFloat(initialPrice);
    if ((formattedInitialPrice * 100) % 1 != 0) {
      formattedInitialPrice = formattedInitialPrice.toFixed(2);
    }

    const formatted = {
      ...data,
      initialPrice: initialPrice ? formattedInitialPrice : ''
    };

    this.setState({ data: formatted });
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const parsed = {
      callCredits: data.callCredits ? parseInt(data.callCredits, 10) : null,
      code: data.code,
      endsAt: data.endsAt ? moment(data.endsAt).toISOString() : null,
      initialPrice: data.initialPrice ? parseFloat(data.initialPrice) : null,
      name: data.name,
      startsAt: data.startsAt ? moment(data.startsAt).toISOString() : null,
      companyId: data.companyId
    };

    onSubmit(parsed);
  };
}
export default EditDialog;
