import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { ACCESS_CODE_KEY, APP_ROOT } from '../../../consts';

const BIOCLINICA_ACCESS_CODE = 'TEST';

@withRouter
export default class BioclinicaLanding extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  render() {
    const { history } = this.props;

    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          primary
          size="huge"
          onClick={() => {
            localStorage.setItem(ACCESS_CODE_KEY, BIOCLINICA_ACCESS_CODE);
            history.push(`${APP_ROOT}/onboard/client`);
          }}
        >
          Do the thing
        </Button>
      </div>
    );
  }
}
