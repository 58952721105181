import './index.css';
import '../create-call-request-form/call-select-form-layout.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { Dropdown, Icon, Label, Popup } from 'semantic-ui-react';

import MyForm, { FormField } from '../../../../ui/form';

const DEFAULT_USER_IMAGE =
  'https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png';
class GuideSelector extends Component {
  render() {
    const {
      selectedGuide,
      selectedChannel,
      guides,
      loading,
      onChange
    } = this.props;

    let url = DEFAULT_USER_IMAGE;

    if (selectedGuide) {
      url = selectedGuide.avatarUrl; //wherever the
    }

    return (
      <MyForm
        className="call-request-form-guide-selector my-form-container"
        data={{}}
        onChange={this._onDataChange}
        onValidate={this._onValidate}
      >
        <div className="guide-selector-avatar  field-container ">
          <div
            className={`field-cell circular-avi`}
            style={{ backgroundImage: `url(${url})` }}
          />
        </div>

        <div className="guide-selector-user field-container">
          <FormField
            loading={loading}
            disabled={loading}
            deburr
            search={!loading}
            className={`field-cell fluid`}
            component={Dropdown}
            name={`selectedGuide`}
            label={`Select Guide`}
            fluid
            selection
            options={this.guidesToGuideOptionsByChannel()}
          />
        </div>
        <div className="guide-selector-info field-container">
          <div className="info-box">{this._renderInfo()}</div>
        </div>
      </MyForm>
    );
  }

  _renderInfo = () => {
    const { selectedGuide } = this.props;

    if (!selectedGuide) {
      return <div className="info-box empty" />;
    }
    return (
      <div className="info-box">
        <Popup
          trigger={
            <Label basic>
              <Icon name="clipboard list" />
              {`5`}
            </Label>
          }
          header="Guide Availablility"
          content={`${selectedGuide.firstName} is {not} available to be booked. They have {X} calls scheduled this week.`}
        />
      </div>
    );
  };

  guidesToGuideOptionsByChannel = () => {
    const { guides, selectedChannel } = this.props;

    let _guides = guides || [];

    if (!selectedChannel) {
      return _.chain(_guides)
        .map(this._guideToOption)
        .value();
    }

    return _.chain(_guides)
      .filter(this._guideByChannel(selectedChannel))
      .map(this._guideToOption)
      .value();
  };

  _guideByChannel = selectedChannel => (guide, index) => {
    const channelMatches = _.filter(guide.channelProfiles, profiles => {
      return profiles.channel.id === selectedChannel.id;
    });
    return channelMatches.length > 0;
  };

  _guideToOption = guide => {
    return {
      text: `${guide.firstName} ${guide.lastName}`,
      value: guide,
      key: guide.id,
      image: { avatar: true, src: guide.avatarUrl || DEFAULT_USER_IMAGE }
    };
  };

  _onValidate = () => {};
  _onDataChange = data => {
    const { onChange } = this.props;
    if (_.isFunction(onChange)) {
      onChange(data);
    }
  };
}

export default GuideSelector;
