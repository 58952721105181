import './faceplate.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Avatar from '../../ui/avatar';

export default class ClientFaceplate extends Component {
  static propTypes = {
    client: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { client } = this.props;

    return (
      <div className="client-faceplate">
        <div className="client-name-avatar">
          <Avatar
            className="client-avatar"
            user={client}
            style={{ width: '4rem', height: '4rem', margin: 0 }}
          />
          <div className="client-name">
            {client.firstName} {client.lastName[0]}.
          </div>
        </div>
      </div>
    );
  }
}
