import React, { Component } from 'react';
import { Container, List, Segment } from 'semantic-ui-react';

import crisisQuestions from '../../call/crisis-questions';

class ConciergeCrisisProtocol extends Component {
  render() {
    return <Container>{this._renderCrisisProtocol()}</Container>;
  }

  _renderCrisisProtocol() {
    let listItems = crisisQuestions.map(q => {
      return (
        <List.Item key={q.id}>
          <List.Header as="">
            {q.id} {q.title}
          </List.Header>
          <List.List>
            {q.questions.map(qI => {
              return <List.Item key={qI}>○ {qI}</List.Item>;
            })}
          </List.List>
        </List.Item>
      );
    });

    return (
      <Segment className={`crisis`}>
        <div className="call-client-header">Crisis Protocol</div>

        <List className="crisis-prototcol-text" relaxed>
          {listItems}
        </List>
      </Segment>
    );
  }
}
export default ConciergeCrisisProtocol;
