import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

import { BUGSNAG_API_KEY } from './consts';

function beforeSend(report) {
  if (!process.env.REACT_APP_LOG_BUGS) {
    // eslint-disable-next-line
    console.log('Bugsnag error report', report);
    report.ignore();
  }
}

const bugsnagClient = bugsnag({
  apiKey: BUGSNAG_API_KEY,
  beforeSend
});

if (process.env.REACT_APP_HOST_ENV) {
  bugsnagClient.app.releaseStage = process.env.REACT_APP_HOST_ENV;
}

bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

export default bugsnagClient;
export { bugsnagClient, ErrorBoundary };
