import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import {
  Button,
  Divider,
  Header,
  Input,
  List,
  Segment
} from 'semantic-ui-react';

import UpdateUserChannelProfileDataMutation from '../../../graphql/mutations/update-user-channel-profile-data.graphql';

@graphql(UpdateUserChannelProfileDataMutation, {
  name: 'updateChannelProfileData'
})
class ClientDetails extends Component {
  static propTypes = {
    channel: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    updateChannelProfileData: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { channel, client } = this.props;

    const channelProfile = client.channelProfiles.find(
      p => p.channel.id === channel.id
    );

    this.state = {
      data: (channelProfile && channelProfile.data) || {},
      submitting: false
    };
  }

  render() {
    return (
      <div className="client-details">
        {this._renderClient()}
        {this._renderIntakeProfieldFields()}
      </div>
    );
  }

  _renderClient() {
    const { channel, client } = this.props;
    const { submitting } = this.state;

    const location = client.state
      ? client.city
        ? `${client.city}, ${client.state}`
        : client.state
      : '';

    const profileFields = channel.profileFields.filter(
      pf => !pf.intakeQuestion
    );

    return (
      <Segment className="profile-segment">
        <Header>Client Profile</Header>
        <Divider />
        <List className="profile-fields">
          {this._renderListItem('Name', client.firstName || '')}
          {this._renderListItem('Age', client.age || '')}
          {this._renderListItem('Location', location)}
          {profileFields.map(profileField =>
            this._renderListItem(profileField.title, '', profileField)
          )}
        </List>
        <div className="actions">
          <Button
            className="save"
            primary
            loading={submitting}
            disabled={submitting}
            onClick={this._save}
          >
            Save Profile
          </Button>
        </div>
      </Segment>
    );
  }

  _renderIntakeProfieldFields() {
    const { channel, client } = this.props;
    const { submitting } = this.state;

    const channelProfile = client.channelProfiles.find(
      p => p.channel.id === channel.id
    );
    if (!channelProfile) {
      return null;
    }

    const groups = channel.profileFields
      .filter(pf => !!pf.intakeQuestion)
      .reduce((acc, pf) => {
        const { intakeQuestion } = pf;
        if (!acc[intakeQuestion.id]) {
          const intakeAnswer =
            channelProfile &&
            channelProfile.intakeAnswers.find(
              ia =>
                ia.intakeQuestion && ia.intakeQuestion.id === intakeQuestion.id
            );
          acc[intakeQuestion.id] = {
            intakeAnswer,
            intakeQuestion,
            profileFields: [pf]
          };
        } else {
          acc[intakeQuestion.id].profileFields.push(pf);
        }
        return acc;
      }, {});

    return Object.values(groups).map(group => {
      return (
        <Segment
          key={`profile-fields-${group.intakeQuestion.id}`}
          className="profile-segment"
        >
          <Header>
            {group.intakeQuestion.title}
            {group.intakeAnswer && `(${group.intakeAnswer.answer})`}
          </Header>
          <Divider />
          <List className="profile-fields">
            {this._renderListItem(
              'Answer',
              (group.intakeAnswer && group.intakeAnswer.answer) || ''
            )}
            {group.profileFields.map(profileField =>
              this._renderListItem(profileField.title, '', profileField)
            )}
          </List>
          <div className="actions">
            <Button
              className="save"
              primary
              loading={submitting}
              disabled={submitting}
              onClick={this._save}
            >
              Save Profile
            </Button>
          </div>
        </Segment>
      );
    });
  }

  _renderListItem(title, value, profileField) {
    const { data } = this.state;

    return (
      <List.Item key={`list-item-${title}`} className="profile-field">
        <List.Header>{title}</List.Header>
        <List.Description>
          <Input
            icon={profileField ? null : 'lock'}
            fluid
            disabled={!profileField}
            value={(profileField && data[profileField.id]) || value}
            onChange={(event, { value }) => {
              this._setValue(profileField, value);
            }}
          />
        </List.Description>
      </List.Item>
    );
  }

  _setValue(profileField, value) {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [profileField.id]: value
      }
    });
  }

  _save = () => {
    const { channel, client, updateChannelProfileData } = this.props;
    const { data } = this.state;

    const channelProfile = client.channelProfiles.find(
      p => p.channel.id === channel.id
    );

    const variables = {
      id: channelProfile.id,
      data
    };

    this.setState({ error: null, submitting: true });
    updateChannelProfileData({ variables })
      .then(() => {
        this.setState({
          submitting: false
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
      });
  };
}
export default ClientDetails;
