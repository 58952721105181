import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Container,
  Dropdown,
  Icon,
  Input,
  Modal,
  Pagination,
  Segment
} from 'semantic-ui-react';

import { APP_ROOT, ROLES } from '../../../../consts';
import UpdateClientInviteStatusMutation from '../../../../graphql/mutations/update-client-invite-status.graphql';
import UpdateCompanyOnUserStatusMutation from '../../../../graphql/mutations/update-company-on-user-status.graphql';
import AllUsersCorpAdminPaginationQuery from '../../../../graphql/queries/all-users-corp-admin-pagination.graphql';
import ClientInviteUsersCorpAdminPaginationQuery from '../../../../graphql/queries/client-invite-users-corp-admin-pagination.graphql';
import ClientUsersCorpAdminPaginationQuery from '../../../../graphql/queries/client-users-corp-admin-pagination.graphql';
import ClientUsersInactiveCorpAdminPaginationQuery from '../../../../graphql/queries/client-users-inactive-corp-admin-pagination.graphql';
import graphql from '../../../hoc/graphql';
import withCompanies from '../../../hoc/with-companies';
import withUser from '../../../hoc/with-user';
import Avatar from '../../../ui/avatar';
import DataGrid from '../../../ui/data-grid';
import ErrorDialog from '../../../ui/error-dialog';
import Notification from '../../../ui/notification';

const ASSOCIATION_ACTIONS = {
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
  REINVITE: 'REINVITE',
  REVOKE_INVITE: 'REVOKE_INVITE'
};

const PAGE_SIZE = 10;
const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};
const SEARCH_DEFAULTS = {
  page: 1,
  sort: 'createdAt',
  direction: SORT_DIRECTIONS.DESC,
  status: 'all'
};

function parseSearch(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page,
    sort: search.sort || SEARCH_DEFAULTS.sort,
    direction: search.direction || SEARCH_DEFAULTS.direction,
    status: search.status || SEARCH_DEFAULTS.status
  };
}

function paginationVariables(props) {
  const { location } = props;
  const { companies } = props.companies;
  const { User } = props.user;
  const [company] = companies;

  const search = parseSearch(location.search);
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;
  const variables = {
    first,
    skip,
    orderBy,
    companyId: company.id,
    searchTerm: search.filter,
    userId: User.id
  };

  return { fetchPolicy: 'network-only', variables };
}

function skipUnlessStatus(status) {
  return ({ location }) => {
    const search = parseSearch(location.search);
    return search.status !== status;
  };
}

@withCompanies({ loader: <Segment basic loading /> })
@withRouter
@withUser()
@graphql(AllUsersCorpAdminPaginationQuery, {
  name: 'users',
  options: paginationVariables,
  skip: skipUnlessStatus('all')
})
@graphql(ClientInviteUsersCorpAdminPaginationQuery, {
  name: 'users',
  options: paginationVariables,
  skip: skipUnlessStatus('invited')
})
@graphql(ClientUsersCorpAdminPaginationQuery, {
  name: 'users',
  options: paginationVariables,
  skip: skipUnlessStatus('registered')
})
@graphql(ClientUsersInactiveCorpAdminPaginationQuery, {
  name: 'users',
  options: paginationVariables,
  skip: skipUnlessStatus('inactive')
})
@graphql(UpdateClientInviteStatusMutation, { name: 'updateClientInviteStatus' })
@graphql(UpdateCompanyOnUserStatusMutation, {
  name: 'updateCompanyOnUserStatus'
})
class UsersList extends Component {
  static propTypes = {
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ).isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    updateClientInviteStatus: PropTypes.func.isRequired,
    updateCompanyOnUserStatus: PropTypes.func.isRequired,
    users: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string,
          clientInvite: PropTypes.shape({
            id: PropTypes.string
          }),
          corporateEmailAddress: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string
            })
          )
        })
      ),
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  state = {
    confirmAction: null,
    confirmUser: null,
    error: null,
    rejectInvite: null,
    submitting: false,
    success: false,
    succesMessage: null
  };

  render() {
    const { location } = this.props;
    const { success, successMessage } = this.state;
    const search = parseSearch(location.search);

    return (
      <div>
        <Input
          icon={{ name: 'search', circular: true }}
          iconPosition="left"
          placeholder="Search..."
          fluid
          value={search.filter || ''}
          onChange={this._onSearchChange}
          action
        >
          <input />
          <Dropdown
            button
            basic
            floating
            options={[
              { text: 'All Associated', value: 'all' },
              { text: 'Registered', value: 'registered' },
              { text: 'Invited', value: 'invited' },
              { text: 'Inactive', value: 'inactive' }
            ]}
            defaultValue={search.status || SEARCH_DEFAULTS.status}
            onChange={this._onStatusFilterChange}
          />
        </Input>
        {this._renderUsers()}
        {this._renderConfirmActionDialog()}

        <Notification
          open={success}
          onClose={() => {
            this.setState({ success: false });
          }}
        >
          <Icon name="check" color="green" /> {successMessage}
        </Notification>
      </div>
    );
  }

  _renderUsers() {
    const { location } = this.props;
    const { users, loading } = this.props.users;

    const columns = [
      {
        formatter: (_, user) => <Avatar user={user} />
      },
      {
        prop: 'firstName',
        label: 'First Name',
        sortable: true
      },
      {
        prop: 'lastName',
        label: 'Last Name',
        sortable: true
      },
      {
        prop: 'corporateEmailAddress',
        label: 'Company E-Mail Address',
        sortable: true
      },
      {
        prop: 'status',
        label: 'Status',
        formatter: (_, user) => {
          const { companies } = this.props.companies;

          const [company] = companies;
          const companyConnection = user.companyIds.find(
            c => c.companyId === company.id
          );
          const isRegistered = user.roles.some(
            role => role.name === ROLES.CLIENT
          );
          const isTerminated =
            companyConnection && companyConnection.status === 'TERMINATED';

          if (isTerminated) {
            const terminatedAt = moment(
              companyConnection.terminatedAt
            ).fromNow();
            return `Terminated (${terminatedAt})`;
          }
          if (isRegistered) {
            return 'Registered';
          }
          if (user.clientInvite) {
            if (user.clientInvite.status === 'PENDING') {
              return 'Invited';
            }
            if (user.clientInvite.status === 'REVOKED') {
              return 'Invite Revoked';
            }
          }

          return '';
        },
        sortable: false
      },
      {
        prop: 'createdAt',
        label: 'Created',
        sortable: true,
        formatter: 'dateTimeFromNow'
      },
      {
        formatter: (_, user) => {
          const { companies } = this.props.companies;

          const [company] = companies;

          function getAction() {
            const companyConnection = user.companyIds.find(
              c => c.companyId === company.id
            );
            const isActive =
              companyConnection && companyConnection.status === 'ACTIVE';
            const isClient = user.roles.some(
              role => role.name === ROLES.CLIENT
            );
            const isCorpAdmin = user.roles.some(
              role => role.name === ROLES.CORP_ADMIN
            );

            if (isCorpAdmin) {
              return null;
            }

            if (isClient) {
              if (isActive) {
                return ASSOCIATION_ACTIONS.DISCONNECT;
              } else {
                return ASSOCIATION_ACTIONS.CONNECT;
              }
            } else if (user.clientInvite) {
              if (user.clientInvite.status === 'PENDING') {
                return ASSOCIATION_ACTIONS.REVOKE_INVITE;
              } else if (user.clientInvite.status === 'REVOKED') {
                return ASSOCIATION_ACTIONS.REINVITE;
              }
            }
          }

          const action = getAction();
          const actionText = {
            [ASSOCIATION_ACTIONS.CONNECT]: `Add back to ${company.name}`,
            [ASSOCIATION_ACTIONS.DISCONNECT]: `Remove from ${company.name}`,
            [ASSOCIATION_ACTIONS.REINVITE]: `Reinvite to ${company.name}`,
            [ASSOCIATION_ACTIONS.REVOKE_INVITE]: `Revoke invite to ${company.name}`
          };

          if (!action) {
            return null;
          }

          return (
            <Dropdown icon="cog" direction="left">
              <Dropdown.Menu>
                <Dropdown.Item
                  text={actionText[action]}
                  onClick={() =>
                    this.setState({ confirmAction: action, confirmUser: user })
                  }
                />
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      }
    ];

    const search = parseSearch(location.search);
    const direction =
      search.direction === SORT_DIRECTIONS.ASC ? 'ascending' : 'descending';

    return (
      <div style={{ paddingTop: '1em' }}>
        <DataGrid
          columns={columns}
          data={users}
          loading={loading}
          onSort={this._onSort}
          sortColumn={search.sort}
          sortDirection={direction}
        />
        {this._renderPagination()}
      </div>
    );
  }

  _renderPagination() {
    const { location } = this.props;
    const { usersConnection } = this.props.users;

    if (!usersConnection) {
      return null;
    }

    const search = parseSearch(location.search);

    const count = usersConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    if (!count) {
      return null;
    }

    return (
      <Container textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: (
              <Icon name="ellipsis horizontal" className="lineawesome" />
            ),
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? {
                  content: <Icon name="angle left" className="lineawesome" />,
                  icon: true
                }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? {
                  content: <Icon name="angle right" className="lineawesome" />,
                  icon: true
                }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </Container>
    );
  }

  _renderConfirmActionDialog() {
    const { companies } = this.props.companies;
    const { confirmAction, confirmUser, error, submitting } = this.state;

    const [company] = companies;

    if (!confirmAction || !confirmUser) {
      return null;
    }

    const descriptionText = {
      [ASSOCIATION_ACTIONS.CONNECT]: `add ${confirmUser.firstName} back to ${company.name}?`,
      [ASSOCIATION_ACTIONS.DISCONNECT]: `remove ${confirmUser.firstName} from ${company.name}?`,
      [ASSOCIATION_ACTIONS.REINVITE]: `reinvite ${confirmUser.firstName} to ${company.name}?`,
      [ASSOCIATION_ACTIONS.REVOKE_INVITE]: `revoke ${confirmUser.firstName}'s invite to ${company.name}?`
    };
    const actionText = {
      [ASSOCIATION_ACTIONS.CONNECT]: `Add ${confirmUser.firstName}`,
      [ASSOCIATION_ACTIONS.DISCONNECT]: `Remove ${confirmUser.firstName}`,
      [ASSOCIATION_ACTIONS.REINVITE]: `Reinvite ${confirmUser.firstName}`,
      [ASSOCIATION_ACTIONS.REVOKE_INVITE]: `Revoke ${confirmUser.firstName}'s invite`
    };

    return (
      <>
        <Modal
          open
          size="tiny"
          closeIcon={true}
          onClose={() => {
            this.setState({ confirmAction: null, confirmUser: null });
          }}
        >
          <Modal.Header>Are you sure?</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Are you sure you want to {descriptionText[confirmAction]}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={submitting}
              onClick={() =>
                this.setState({ confirmAction: null, confirmUser: null })
              }
            >
              Cancel
            </Button>
            <Button
              primary
              loading={submitting}
              disabled={submitting}
              onClick={this._performAction}
            >
              {actionText[confirmAction]}
            </Button>
          </Modal.Actions>
        </Modal>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </>
    );
  }

  _onStatusFilterChange = (event, { value }) => {
    this._search({
      status: value,
      page: 1
    });
  };

  _onSearchChange = debounce((event, { value }) => {
    this._search({
      filter: value,
      page: 1
    });
  });

  _onSort = name => {
    const { location } = this.props;

    const search = parseSearch(location.search);

    const direction =
      search.sort === name
        ? search.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
        : SORT_DIRECTIONS.ASC;

    this._search({
      sort: name,
      direction
    });
  };

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _search(params) {
    const { history, location } = this.props;

    const search = parseSearch(location.search);
    const updated = {
      ...search,
      ...params
    };

    const url = `${APP_ROOT}/corp-admin/users?${qs.stringify(updated)}`;
    history.push(url);
  }

  _performAction = () => {
    const { companies } = this.props.companies;
    const { confirmAction, confirmUser } = this.state;

    const [company] = companies;

    switch (confirmAction) {
      case ASSOCIATION_ACTIONS.CONNECT:
        return this._updateCompanyOnUserStatus(
          'ACTIVE',
          `${confirmUser.firstName} added back to ${company.name}`
        );
      case ASSOCIATION_ACTIONS.DISCONNECT:
        return this._updateCompanyOnUserStatus(
          'TERMINATED',
          `${confirmUser.firstName} remove from ${company.name}`
        );
      case ASSOCIATION_ACTIONS.REINVITE:
        return this._updateClientInviteStatus(
          'PENDING',
          `${confirmUser.firstName} invited back to ${company.name}`
        );
      case ASSOCIATION_ACTIONS.REVOKE_INVITE:
        return this._updateClientInviteStatus(
          'REVOKED',
          `Revoked ${confirmUser.firstName}'s invite to ${company.name}`
        );
    }
  };

  _updateCompanyOnUserStatus(status, successMessage) {
    const { updateCompanyOnUserStatus } = this.props;
    const { companies } = this.props.companies;
    const { confirmUser } = this.state;

    const [company] = companies;

    const companyOnUser = confirmUser.companyIds.find(
      c => c.companyId === company.id
    );
    const variables = {
      id: companyOnUser.id,
      status
    };

    this.setState({ error: null, submitting: true });
    updateCompanyOnUserStatus({ variables })
      .then(() => {
        this.setState(
          {
            confirmAction: null,
            confirmUser: null,
            submitting: false,
            success: true,
            successMessage
          },
          () => {
            this.props.users.refetch();
          }
        );
      })
      .catch(error => {
        this.setState({ error, submitting: false });
      });
  }

  _updateClientInviteStatus(status, successMessage) {
    const { updateClientInviteStatus } = this.props;
    const { confirmUser } = this.state;

    const variables = {
      id: confirmUser.clientInvite.id,
      status
    };

    updateClientInviteStatus({ variables }).then(() => {
      this.setState(
        {
          confirmAction: null,
          confirmUser: null,
          submitting: false,
          success: true,
          successMessage
        },
        () => {
          this.props.users.refetch();
        }
      );
    });
  }
}
export default UsersList;
