import '../../../ui/powerful-cta/index.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';

import ApolloClient from '../../../../apollo';
import { ROLES } from '../../../../consts';
import CreateClientMutation from '../../../../graphql/mutations/create-client.graphql';
import ClientInvitesByEmailAddressQuery from '../../../../graphql/queries/client-invites-by-email-address.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import ErrorDialog from '../../../ui/error-dialog';
import CreateClientForm from './create-client-form';

const STEPS = {
  CHECK_CLIENT_INVITE: 'CHECK_CLIENT_INVITE',
  CREATE_CLIENT: 'CREATE_CLIENT'
};

@graphql(CreateClientMutation, {
  name: 'createClient'
})
@withUser({ authenticated: [ROLES.CONCIERGE] })
class CreateClientDialog extends Component {
  static propTypes = {
    createClient: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
  };

  state = {
    clientInvite: null,
    data: null,
    emailAddress: '',
    error: null,
    isValid: false,
    step: STEPS.CHECK_CLIENT_INVITE,
    submitting: false
  };

  render() {
    const { error } = this.state;

    return (
      <Modal
        className="concierge-create-user-dialog"
        open
        size="tiny"
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={this._onClose}
      >
        <Modal.Header>New Client</Modal.Header>
        <Modal.Content>{this._renderContent()}</Modal.Content>
        <Modal.Actions>{this._renderActions()}</Modal.Actions>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </Modal>
    );
  }

  _renderContent() {
    const { clientInvite, emailAddress, step } = this.state;

    switch (step) {
      case STEPS.CHECK_CLIENT_INVITE:
        return (
          <Modal.Description>
            <p>First check for an existing invite</p>
            <Form>
              <Form.Field>
                <label>E-mail Address</label>
                <Input
                  value={emailAddress}
                  onChange={(event, { value }) => {
                    this.setState({ emailAddress: value });
                  }}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        );
      case STEPS.CREATE_CLIENT:
        return (
          <Modal.Description>
            {this._renderClientInvite()}
            <CreateClientForm
              clientInvite={clientInvite}
              emailAddress={emailAddress}
              onDataChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </Modal.Description>
        );
    }
  }

  _renderActions() {
    const { emailAddress, isValid, step, submitting } = this.state;

    switch (step) {
      case STEPS.CHECK_CLIENT_INVITE:
        return (
          <Button
            className="powerful"
            loading={submitting}
            disabled={!emailAddress || submitting}
            onClick={this._onCheckClientInvite}
          >
            Check for Invite
          </Button>
        );
      case STEPS.CREATE_CLIENT:
        return (
          <Button
            className="powerful"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onCreateClient}
          >
            Create Client
          </Button>
        );
    }
  }

  _renderClientInvite() {
    const { clientInvite, emailAddress } = this.state;

    if (clientInvite) {
      const isCorporateEmail =
        clientInvite.user.corporateEmailAddress === emailAddress;
      return (
        <Message>
          <p>
            A {clientInvite.status.toLowerCase()}{' '}
            {clientInvite.isSpouse ? 'spouse' : ''} invite exists for{' '}
            {isCorporateEmail ? 'corporate' : 'personal'} e-mail address.
          </p>
        </Message>
      );
    }

    return (
      <Message>
        <p>No invite found for {emailAddress}</p>
      </Message>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onCheckClientInvite = () => {
    const { emailAddress } = this.state;

    const variables = {
      emailAddress
    };

    this.setState({ error: null, submitting: true });
    ApolloClient.query({
      query: ClientInvitesByEmailAddressQuery,
      variables
    }).then(({ data: { clientInvites } }) => {
      const [clientInvite] = clientInvites;
      this.setState({
        clientInvite,
        step: STEPS.CREATE_CLIENT,
        submitting: false
      });
    });
  };

  _onDataChange = data => {
    this.setState({ data });
  };

  _onValidate = errors => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onCreateClient = () => {
    const { createClient, onSubmit } = this.props;
    const { data } = this.state;

    const variables = {
      ...data
    };

    this.setState({ error: null, submitting: true });
    createClient({ variables })
      .then(() => {
        this.setState({ submitting: false });

        if (onSubmit) {
          onSubmit(data);
        }
      })
      .catch(error => {
        this.setState({ error, submitting: false });
      });
  };
}
export default CreateClientDialog;
