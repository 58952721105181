import './nocredits.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';

@withRouter
class NoCredits extends Component {
  static propTypes = {
    firstName: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { firstName } = this.props;

    return (
      <div className="nocredits">
        <Header as="h1" style={{ fontWeight: 'normal' }}>
          You have no remaining credits
        </Header>
        <p>
          You will need to purchase additional credits <br />
          before you can schedule a call with {firstName}.
        </p>
        <Button primary onClick={this._navToPaymentOptions}>
          Purchase More Credits
        </Button>
      </div>
    );
  }

  _navToPaymentOptions = () => {
    const { history } = this.props;
    history.push(`${APP_ROOT}/settings/payment-options`);
  };
}

export default NoCredits;
