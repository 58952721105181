import './sendmessage-modal.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import { ROLES } from '../../../consts';
import CreateMessageWithCallRequestMutation from '../../../graphql/mutations/create-message-with-call-request.graphql';
import CreateMessageWithCallMutation from '../../../graphql/mutations/create-message-with-call.graphql';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import MessageForm from './sendmessage-form';

@withUser({ authenticated: true })
@graphql(CreateMessageWithCallMutation, { name: 'createMessageWithCall' })
@graphql(CreateMessageWithCallRequestMutation, {
  name: 'createMessageWithCallRequest'
})
@withRouter
class SendMessage extends Component {
  static propTypes = {
    call: PropTypes.shape({
      callNotes: PropTypes.array,
      client: PropTypes.object,
      duration: PropTypes.number,
      guide: PropTypes.object,
      rating: PropTypes.number,
      scheduledTime: PropTypes.date,
      startedAt: PropTypes.string
    }),
    callRequest: PropTypes.shape({
      from: PropTypes.object,
      to: PropTypes.object
    }),

    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,

    //withUser
    user: PropTypes.object.isRequired,

    //graphQL
    createMessageWithCall: PropTypes.func.isRequired,
    createMessageWithCallRequest: PropTypes.func.isRequired
  };

  state = {
    error: null,
    isMessageValid: false,
    message: '',
    submitting: false,
    success: false,
    successMessage: null
  };

  getOtherUser() {
    const { call, callRequest } = this.props;
    const { User } = this.props.user;

    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);
    if (call) {
      return isGuide ? call.client : call.guide;
    } else if (callRequest) {
      return isGuide ? callRequest.from : callRequest.to;
    }
  }

  render() {
    const { open, onClose } = this.props;
    const { isMessageValid, submitting } = this.state;

    if (!open) {
      return null;
    }

    const otherUser = this.getOtherUser();

    return (
      <Modal
        open
        size="small"
        className="send-message-modal"
        onClose={() => {
          this.setState({ messaging: false });
        }}
      >
        <Modal.Content>
          <div className="header-container">
            <Avatar user={otherUser} />
            <Header as="h3">
              Send a message to {otherUser.firstName} {otherUser.lastName[0]}
            </Header>
          </div>
          <MessageForm
            onChange={({ message }) => {
              this.setState({ message });
            }}
            onValidate={isValid => {
              this.setState({ isMessageValid: isValid });
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            disabled={!isMessageValid || submitting}
            loading={submitting}
            onClick={this._onSendMessageClick}
          >
            <Icon size="large" name="paper plane" />
            Send Message
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onSendMessageClick = () => {
    const {
      call,
      callRequest,
      createMessageWithCall,
      createMessageWithCallRequest,
      onClose
    } = this.props;
    const { User } = this.props.user;
    const { message } = this.state;

    const otherUser = this.getOtherUser();

    const variables = {
      ...(call && { callId: call.id }),
      ...(callRequest && { callRequestId: callRequest.id }),
      fromId: User.id,
      text: message,
      toId: otherUser.id
    };
    const mutation = call
      ? createMessageWithCall
      : createMessageWithCallRequest;

    this.setState({ error: null, submitting: true, success: false });
    mutation({ variables })
      .then(() => {
        this.setState({
          messaging: false,
          submitting: false,
          success: true,
          successMessage: 'Message Sent'
        });

        onClose();
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'ScheduledCallEntry._onSendMessageClick',
          request: {
            ...variables
          }
        });
      });
  };
}

export default SendMessage;
