import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CallRequestEntry from './call-request';
import CancelledCallEntry from './call/cancelled';
import CompletedCallEntry from './call/completed';
import ScheduledCallEntry from './call/scheduled';
import StartedCallEntry from './call/started';

const CallComponents = {
  CANCELLED: CancelledCallEntry,
  COMPLETED: CompletedCallEntry,
  SCHEDULED: ScheduledCallEntry,
  STARTED: StartedCallEntry
};

export default class CallEntry extends PureComponent {
  static propTypes = {
    call: PropTypes.shape({
      status: PropTypes.string
    }),
    callRequest: PropTypes.object
  };

  render() {
    const { call, callRequest } = this.props;

    const Component = call
      ? CallComponents[call.status]
      : callRequest
      ? CallRequestEntry
      : null;

    return <Component {...this.props} />;
  }
}
