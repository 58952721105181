import './controlpanel.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { List } from 'semantic-ui-react';

import { APP_ROOT, ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import CancelCallModal from './cancelcall-modal';
import CancelCallRequestModal from './cancelCallRequest-modal';
import SendMessageModal from './sendmessage-modal';

const CANCEL_CALL = 'cancelcall';
const SEND_MESSAGE = 'sendmessage';

@withUser({ authenticated: true })
@withRouter
class CallCtaControlPanel extends Component {
  static propTypes = {
    call: PropTypes.shape({
      client: PropTypes.shape({
        firstName: PropTypes.string
      })
    }),
    callRequest: PropTypes.shape({
      to: PropTypes.shape({
        firstName: PropTypes.string
      })
    }),
    history: PropTypes.object.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    route: ''
  };

  _closeModals = () => {
    this.setState({
      route: ''
    });
  };

  renderCallActions() {
    const { route } = this.state;
    const { call, history } = this.props;
    const { User } = this.props.user;

    const isGuide = User && User.roles.find(x => x.name === ROLES.GUIDE);

    return (
      <div className="communcations-controlpanel">
        <List horizontal className="communcations-controlpanel-list">
          {isGuide && call.status === 'STARTED' && (
            <List.Item
              className="complete-call"
              onClick={() => {
                history.push(`${APP_ROOT}/call/${call.id}?complete=true`);
              }}
            >
              Complete Call
            </List.Item>
          )}
          {(call.status === 'SCHEDULED' || call.status === 'STARTED') && (
            <List.Item
              className="cancel-call"
              onClick={() => {
                this.setState({
                  route: CANCEL_CALL
                });
              }}
            >
              Cancel Call
            </List.Item>
          )}
          <List.Item
            className="send-message"
            onClick={() => {
              this.setState({
                route: SEND_MESSAGE
              });
            }}
          >
            Send Message
          </List.Item>
        </List>
        <CancelCallModal
          call={call}
          onClose={this._closeModals}
          open={route === CANCEL_CALL}
        />
        <SendMessageModal
          call={call}
          onClose={this._closeModals}
          open={route === SEND_MESSAGE}
        />
      </div>
    );
  }

  renderCallRequestActions() {
    const { route } = this.state;
    const { callRequest } = this.props;
    const { User } = this.props.user;

    const isGuide = User && User.roles.find(x => x.name === ROLES.GUIDE);

    return (
      <div className="communcations-controlpanel">
        <List horizontal className="communcations-controlpanel-list">
          {!isGuide && (
            <List.Item
              className="cancel-call"
              onClick={() => {
                this.setState({
                  route: CANCEL_CALL
                });
              }}
            >
              Cancel Request
            </List.Item>
          )}
          <List.Item
            className="send-message"
            onClick={() => {
              this.setState({
                route: SEND_MESSAGE
              });
            }}
          >
            Send Message
          </List.Item>
        </List>
        <CancelCallRequestModal
          callRequest={callRequest}
          onClose={this._closeModals}
          open={route === CANCEL_CALL}
        />
        <SendMessageModal
          callRequest={callRequest}
          onClose={this._closeModals}
          open={route === SEND_MESSAGE}
        />
      </div>
    );
  }

  render() {
    const { call, callRequest } = this.props;
    if (!call && !callRequest) {
      throw new Error('Call or Call Request must be included.');
    }
    return call ? this.renderCallActions() : this.renderCallRequestActions();
  }
}
export default CallCtaControlPanel;
