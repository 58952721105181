import './index.css';
import './channel-groups.css';
import './above-fold.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon, List } from 'semantic-ui-react';

import { BeckyOnPhone1 } from '../../../assets';
import { APP_ROOT, ROLES } from '../../../consts';
import ChannelGroupsQuery from '../../../graphql/queries/all-channel-groups.graphql';
import PortalAppHeader from '../../container/app-header/portal';
import graphql from '../../hoc/graphql';
import withCompanies from '../../hoc/with-companies';
import withUser from '../../hoc/with-user';
import FullscreenLoader from '../../ui/fullscreen-loader';
import oldPeople from './old-people.jpg';
import ExplainerVideo from './video';

@withCompanies({
  loader: <FullscreenLoader />
})
@graphql(ChannelGroupsQuery, {
  name: 'channelGroups'
})
@withRouter
@withUser()
class Portal extends Component {
  state = {
    chatNow: false,
    ctaFocus: '',
    videoOpen: false
  };

  static propTypes = {
    channelGroups: PropTypes.shape({
      channelGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          description: PropTypes.string,
          channels: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              iconColor: PropTypes.string,
              slug: PropTypes.string,
              title: PropTypes.string
            })
          ),
          title: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired,
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          logoUrl: PropTypes.string,
          name: PropTypes.string
        })
      )
    })
  };

  _filterChannelGroups() {
    const { channelGroups } = this.props.channelGroups;
    const companies = _.get(this.props, 'companies.companies', []);
    let _channelGroups = _.sortBy(channelGroups, cg => cg.order)
      //.reverse()
      .reduce((acc, group) => {
        let channels = group.channels.filter(c => c.isPublished);
        let newGroupChannels = Object.assign({}, group, { channels });
        return channels.length > 0 ? [...acc, newGroupChannels] : acc;
      }, []);

    _channelGroups = _.chain(_channelGroups)
      .filter(g => g.channels.length > 0)
      .value();

    if (!companies.length) {
      return _channelGroups;
    }

    return _.chain(_channelGroups)
      .filter(channelGroup =>
        companies.some(company =>
          channelGroup.channels.some(channel => {
            return company.channels.some(c => c.id === channel.id);
          })
        )
      )
      .value();
  }

  _filterChannels(channelGroup) {
    const { channels } = channelGroup;
    const companies = _.get(this.props, 'companies.companies', []);

    if (!companies.length) {
      return channels.filter(c => c.isPublished);
    }

    return _.chain(channels)
      .filter(channel => {
        return (
          channel.isPublished &&
          companies.some(company =>
            company.channels.some(c => c.id === channel.id)
          )
        );
      })
      .value();
  }

  _flatChannelsFromGroups = groups => {
    return groups.reduce((acc, group) => {
      return acc.concat(this._filterChannels(group));
    }, []);
  };

  render() {
    const { history } = this.props;
    const { loading: channelsLoading } = this.props.channelGroups;
    const { loading: userLoading, User } = this.props.user;

    if (channelsLoading || userLoading) {
      return <FullscreenLoader />;
    }
    const filteredGroups = this._filterChannelGroups();
    const filteredChannels = this._flatChannelsFromGroups(filteredGroups);

    //TODO: pull into handle-redirect fn
    if (User && User.roles.length) {
      const isClient = User.roles.find(role => role.name === ROLES.CLIENT);
      if (!isClient) {
        history.push(`${APP_ROOT}/dashboard`);
      }
      if (filteredChannels.length === 1) {
        history.push(`${APP_ROOT}/${filteredChannels[0].slug}/dashboard`);
      }
    } else {
      if (filteredChannels.length === 1) {
        history.push(`${APP_ROOT}/${filteredChannels[0].slug}/onboard/client`);
      }
    }

    return (
      <div className="portal channel-portal">
        <PortalAppHeader />
        {this._renderAboveFold()}
        <div className="portal-body-divider" />
        <div className="portal-body">{this._renderChannelGroups()}</div>
      </div>
    );
  }

  _renderAboveFold = () => {
    const { videoOpen, ctaFocus } = this.state;
    let content = null;
    if (videoOpen) {
      content = <>{this._renderVideoPlayer()}</>;
    }

    let ctaFocusClass = ctaFocus ? `focusing-${ctaFocus}` : '';

    return (
      <div className={`portal-header ${ctaFocusClass}`}>
        <div className="portal-header-circles">
          <div className="portal-header-circle-1" />
          <div className="portal-header-circle-2" />
        </div>
        <div className="portal-header-content">
          <div className="portal-header-info">
            <span className="portal-header-info-header">
              Welcome to Lifeguides!
            </span>
            <span className="portal-header-info-content">
              Our Wellbeing Concierge Team<sup className="TM">™</sup> is
              available now to help you get matched to the right guides and
              resources to help with over 200 Life Challenges
              <sup className="TM">™</sup>.
            </span>
            <span className="portal-header-info-callout">
              Get the help you need today!
            </span>
          </div>
          <div className="portal-header-video">
            <div
              className="portal-header-video-splash image-billboard"
              style={{
                backgroundImage: `url(${BeckyOnPhone1})`
              }}
              onMouseEnter={this._setCtaFocus('video')}
              onMouseLeave={this._clearCtaFocus}
              onClick={this._playVideo}
            >
              <div className="portal-header-video-splash-playbutton">
                <Icon name="play" />
              </div>
              {content}
            </div>
          </div>
        </div>
      </div>
    );
  };

  _setCtaFocus = item => () => {
    if (this.state.ctaFocus) {
      return;
    }

    this.setState({
      ctaFocus: `${item}`
    });
  };

  _clearCtaFocus = () => {
    this.setState({
      ctaFocus: ''
    });
  };

  // _chatNow = () => {
  //   this.setState({
  //     chatNow: true
  //   });
  // };

  _playVideo = () => {
    this.setState({
      videoOpen: true
    });
  };

  _stopVideo = () => {
    this.setState({
      videoOpen: false
    });
  };

  _renderVideo = () => {
    const { videoOpen } = this.state;

    let content = this._renderVideoPlaceholder();

    if (videoOpen) {
      content = <>{this._renderVideoPlayer()}</>;
    }

    return <div className="portal-video">{content}</div>;
  };

  _renderVideoPlaceholder = () => {
    return (
      <div
        className="portal-video-placeholder"
        style={{ backgroundImage: `url(${oldPeople})` }}
      >
        <div className="portal-video-info">
          <div className="portal-video-title">
            <Header>
              <span>Welcome to LifeGuides</span>
            </Header>
          </div>
          <div className="portal-video-subtitle">
            <Header.Subheader>
              Kristy took on the battle of her struggling father with
              alzheimers. Learn more about how you can overcome your Life
              Challenges.
            </Header.Subheader>
          </div>
          <div className="portal-video-cta">
            <Button icon labelPosition="left" onClick={this._playVideo}>
              <Icon name="video play" />
              See how It works
            </Button>
          </div>
        </div>
      </div>
    );
  };

  _renderVideoPlayer = () => {
    return (
      <ExplainerVideo
        videoOpen={this.state.videoOpen}
        onClose={this._stopVideo}
      />
    );
  };

  _renderChannelGroups = () => {
    const filteredGroups = this._filterChannelGroups();

    let lengthClass = '';

    if (filteredGroups.length < 3) {
      lengthClass = `is-short`;
    }

    return (
      <>
        <Header className="channel-groups-header">
          Explore Common Life Challenges<sup className="TM">™</sup>
        </Header>
        <div className={`channel-groups ${lengthClass}`}>
          {filteredGroups.map(this._renderChannelGroup)}
        </div>
      </>
    );
  };

  _renderChannelGroup = (channelGroup, index) => {
    const filteredChannels = this._filterChannels(channelGroup);

    const billboard = _.get(channelGroup, 'featuredImage.file.url', oldPeople);
    const description = channelGroup.description.trim() || channelGroup.title;

    return (
      <div
        key={`channel-group-${channelGroup.id}`}
        className="channel-group"
        title={`${description}`}
      >
        <div
          className="image-billboard"
          style={{ backgroundImage: `url(${billboard})` }}
        />
        <Header className="channel-group-title">{channelGroup.title}</Header>
        <List className="channel-group-list">
          {filteredChannels.map(this._renderChannel)}
        </List>
      </div>
    );
  };

  _renderChannel = channel => {
    return (
      <List.Item
        key={`channel-${channel.id}`}
        className="channel-group-list-item"
        onClick={() => this._onChannelClick(channel)}
        as="a"
      >
        <span>{channel.title}</span>
      </List.Item>
    );
  };

  _onChannelClick = channel => {
    const { history } = this.props;
    const { User } = this.props.user;

    if (User && User.roles.length) {
      history.push(`${APP_ROOT}/${channel.slug}/dashboard`);
    } else {
      history.push(`${APP_ROOT}/${channel.slug}/onboard/client`);
    }
  };
}
export default Portal;
