import './index.css';

import { capitalize, flatten, kebabCase, startCase, uniq } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Message, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import UserImportByIdQuery from '../../../../graphql/queries/user-import-by-id.graphql';
import UserImportLogCreatedSubscription from '../../../../graphql/subscriptions/user-import-log-created.graphql';
import UserImportUpdatedSubscription from '../../../../graphql/subscriptions/user-import-updated.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import DataGrid from '../../../ui/data-grid';
import ResourceHeader from '../../../ui/resource-header';

@withRouter
@graphql(UserImportByIdQuery, {
  name: 'userImport',
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    variables: { id }
  })
})
@withUser({ authenticated: [ROLES.ADMIN] })
class UserImport extends Component {
  static propTypes = {
    userImport: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      subscribeToMore: PropTypes.func.isRequired,
      userImport: PropTypes.shape({
        id: PropTypes.string,
        logs: PropTypes.Object
      })
    }).isRequired
  };

  state = {};

  componentDidMount() {
    this._initializeSubscriptions();
  }

  componentDidUpdate() {
    this._initializeSubscriptions();
  }

  componentWillUnmount() {
    if (this._unsubscribeUserImportUpdated) {
      this._unsubscribeUserImportUpdated();
      delete this._unsubscribeUserImportUpdated;
    }
    if (this._unsubscribeUserImportLogCreated) {
      this._unsubscribeUserImportLogCreated();
      delete this._unsubscribeUserImportLogCreated;
    }
  }

  _initializeSubscriptions() {
    const { userImport } = this.props.userImport;

    if (!userImport) {
      return;
    }
    if (!this._unsubscribeUserImportUpdated) {
      this._unsubscribeUserImportUpdated = this.props.userImport.subscribeToMore(
        {
          document: UserImportUpdatedSubscription,
          variables: {
            id: userImport.id
          }
        }
      );
    }

    if (!this._unsubscribeUserImportLogCreated) {
      this._unsubscribeUserImportLogCreated = this.props.userImport.subscribeToMore(
        {
          document: UserImportLogCreatedSubscription,
          variables: {
            importId: userImport.id
          }
        }
      );
    }
  }

  render() {
    const { loading, userImport } = this.props.userImport;

    if (loading) {
      return <Segment basic loading />;
    }

    const { id, updatedAt, error, progress, status } = userImport;

    return (
      <div className="admin-user-import">
        <ResourceHeader
          title={`Bulk Import ${id}`}
          parentPath={`/admin/users/imports`}
        />
        <Header as="h2">
          {capitalize(status)}{' '}
          {status === 'STARTED' && `(${Math.round(progress * 100)}%)`}
          <Header.Subheader>{moment(updatedAt).fromNow()}</Header.Subheader>
        </Header>
        {userImport.company && (
          <Header as="h3">Company: {userImport.company.name}</Header>
        )}
        {error && <Message negative>{error}</Message>}
        {this._renderImportDataToTable()}
      </div>
    );
  }

  _baseColums = () => {
    return [
      {
        id: '01',
        label: 'User',
        prop: 'user',
        formatter: 'userToIcon'
        //,sortable: true
      },
      {
        id: '02',
        label: 'Action',
        prop: 'mutationType',
        formatter: 'capitalizedText',
        sortable: true
      },
      {
        id: '03',
        label: 'Status',
        prop: 'status',
        formatter: 'capitalizedText',
        sortable: true
      },
      {
        id: '04',
        label: 'Alert',
        prop: 'error',
        formatter: 'readableErrorStatus',
        sortable: true
      }
    ];
  };

  _metaDataColumns = () => {
    const { logs } = this.props.userImport.userImport;
    const keyList = logs.map(log => {
      return Object.keys(log.data);
    });

    const uniqKeys = uniq(flatten(keyList));

    const makeKey = uniqKeys.map(key => {
      return {
        id: `${kebabCase(key)}`,
        label: `${startCase(key)}`,
        prop: `data.${key}`
      };
    });

    return makeKey;
  };

  _allCols = () => {
    return [...this._baseColums(), ...this._metaDataColumns()];
  };

  _renderImportDataToTable() {
    const { logs } = this.props.userImport.userImport;
    const cols = this._allCols();

    return <DataGrid columns={cols} data={logs} />;
  }
}

export default UserImport;
