import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

const Timezones = [
  {
    name: 'America/Adak',
    description: 'Hawaii-Aleutian Time (Adak)'
  },
  {
    name: 'America/Anchorage',
    description: 'Alaska Time'
  },
  {
    name: 'America/Chicago',
    description: 'Central Time'
  },
  {
    name: 'America/Denver',
    description: 'Mountain Time (Denver)'
  },
  {
    name: 'America/Los_Angeles',
    description: 'Pacific Time'
  },
  {
    name: 'America/New_York',
    description: 'Eastern Time'
  },
  {
    name: 'America/Phoenix',
    description: 'Mountain Time (Phoenix)'
  },
  {
    name: 'Pacific/Honolulu',
    description: 'Hawaii-Aleutian Time (Honolulu)'
  }
];

export default class TimezoneSelector extends Component {
  static propTypes = {
    value: PropTypes.string
  };

  render() {
    const { value } = this.props;

    const guess = moment.tz.guess();

    const timezones = Timezones.slice(0);

    if (!timezones.find(zone => zone.name === guess)) {
      const abbr = moment.tz(guess).zoneAbbr();
      timezones.push({
        name: guess,
        description: `${guess} (${abbr})`
      });
    }

    timezones.sort((a, b) => {
      const ma = moment.tz(a.name);
      const mb = moment.tz(b.name);
      return ma.utcOffset() - mb.utcOffset();
    });

    const options = timezones.map(zone => {
      const m = moment.tz(zone.name);
      return {
        text: `(GMT ${m.format('Z')}) ${zone.description}`,
        value: zone.name
      };
    });

    return (
      <Dropdown {...this.props} value={value || guess} options={options} />
    );
  }
}
