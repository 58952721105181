import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Button, Icon, List } from 'semantic-ui-react';

import { APP_ROOT, LAST_CHANNEL_KEY, ROLES } from '../../../../consts';
import UpdateCallRatingMutation from '../../../../graphql/mutations/update-call-rating.graphql';
import withUser from '../../../hoc/with-user';
import CallEntryBase from '../../../ui/call-entry';
import CallNotesDialog from '../../../ui/call-notes-dialog';
import GuideCallActions from '../../communications/controlpanel';

@graphql(UpdateCallRatingMutation, { name: 'updateCallRating' })
@withUser({ authenticated: true })
@withRouter
class CompletedCallEntry extends Component {
  static propTypes = {
    call: PropTypes.shape({
      callNote: PropTypes.shape({
        text: PropTypes.string
      }),
      channel: PropTypes.shape({
        slug: PropTypes.string
      }),
      client: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      }),
      duration: PropTypes.number,
      guide: PropTypes.object,
      rating: PropTypes.number,
      scheduledTime: PropTypes.date,
      startedAt: PropTypes.string
    }).isRequired,
    history: PropTypes.object.isRequired,
    isBanner: PropTypes.bool,
    isTablet: PropTypes.bool,
    updateCallRating: PropTypes.func.isRequired,
    bookAgain: PropTypes.bool,
    user: PropTypes.shape({
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    showingNote: false
  };

  render() {
    const { call, isBanner, isTablet, bookAgain } = this.props;
    const { scheduledTime } = call;
    const { User } = this.props.user;
    const { showingNote } = this.state;

    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);

    const start = moment(scheduledTime).tz(User.timezone || moment.tz.guess());
    const end = moment(scheduledTime)
      .tz(User.timezone || moment.tz.guess())
      .add(1, 'hours');
    const timeFormat = 'h:mma';
    const timeStart = `${start.format(timeFormat)}`;
    const tzabbr = ` ${moment
      .tz(User.timezone || moment.tz.guess())
      .zoneAbbr()}`;
    const date = `${start.format('MM/DD')} `;

    const subheader = (
      <div>
        <span>
          {date}
          {timeStart}
          {tzabbr}
        </span>
        <div>
          <GuideCallActions call={call} />
        </div>
      </div>
    );

    return (
      <CallEntryBase
        channel={call.channel}
        isTablet={isTablet}
        guide={call.guide}
        user={isGuide ? call.client : call.guide}
        date={call.scheduledTime}
        scheduled={false}
        className="completed-call-entry"
        subheader={subheader}
        isBanner={isBanner}
        bookAgain={bookAgain}
        userRole={isGuide ? 'GUIDE' : 'CLIENT'}
        isCompleted={true}
      >
        <List.Content
          floated={isTablet ? null : 'right'}
          style={
            isTablet
              ? {
                  position: 'static',
                  top: '0.3em',
                  right: '0'
                }
              : null
          }
        >
          {!isGuide && this._renderClientContent(call)}

          {/* <Rating
            icon="star"
            disabled={isGuide}
            defaultRating={call.rating}
            maxRating={5}
            onRate={this._onRateCall}
            style={{ paddingTop: '0.6em' }}
          /> */}

          {isGuide && call.callNote && (
            <CallNotesDialog
              call={call}
              callNotes={[call.callNote]}
              client={call.client}
              user={User}
              open={showingNote}
              onClose={() => {
                this.setState({ showingNote: false });
              }}
            />
          )}
          {isGuide && call.callNote && (
            <List.Content
              className="notes-icon-container"
              onClick={() => {
                this.setState({ showingNote: true });
              }}
            >
              <Icon name="comment" size="big" />
              Notes
            </List.Content>
          )}
        </List.Content>
      </CallEntryBase>
    );
  }

  _renderClientContent(call) {
    return (
      <div className="client-completed-cta">
        <Button
          className="book-again-cta primary"
          onClick={() => this._goToGuideModal(call.guide.id)}
        >
          Book Again
        </Button>
      </div>
    );
  }

  _goToGuideModal = id => {
    const { history } = this.props;
    const { channel } = this.props.call;
    let slug = localStorage.getItem(LAST_CHANNEL_KEY);
    if (channel && channel.slug) {
      slug = channel.slug;
    }
    history.push(`${APP_ROOT}/${slug}/guides/${id}/schedule`);
  };

  _onRateCall = (e, { rating }) => {
    const { call, updateCallRating } = this.props;

    const variables = {
      id: call.id,
      rating
    };
    updateCallRating({ variables }).then(() => {});
  };
}
export default CompletedCallEntry;
