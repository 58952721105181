import './index.css';

import debounce from 'lodash/debounce';
import intersectionBy from 'lodash/intersectionBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import ApolloClient from '../../../apollo';
import { APP_ROOT, ROLES } from '../../../consts';
import GuidesByNameQuery from '../../../graphql/queries/guides-by-name.graphql';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import AvailabilityCalendar from './availability-calendar';

const SEARCH_SPEED = 500;

@withRouter
@withUser({ authenticated: [ROLES.ADMIN, ROLES.CONCIERGE] })
class GuideAvailability extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    guides: [],
    loading: false,
    searchQuery: ''
  };

  _query(searchQuery) {
    if (!searchQuery.replace(/\s+/)) {
      this.setState({ guides: [] });
      return;
    }

    const parts = searchQuery.split(/\s+/);

    this.setState({ loading: true, searchQuery });
    const requests = parts.map(name => {
      const variables = { name };
      return ApolloClient.query({ query: GuidesByNameQuery, variables }).then(
        ({ data: { users } }) => users
      );
    });
    Promise.all(requests).then(results => {
      const [first, ...remaining] = results;
      const guides = remaining.reduce(
        (acc, cs) => intersectionBy(acc, cs, c => c.id),
        first
      );
      this.setState({ guides, loading: false });
    });
  }

  render() {
    const { match } = this.props;
    const { guides, loading, searchQuery } = this.state;

    return (
      <div className="guide-availability">
        <div className="container">
          <label>Select a guide:</label>
          <Dropdown
            className="guide-select"
            deburr
            defaultValue={match.params.id}
            fluid
            label="Select Guide"
            loading={loading}
            noResultsMessage={
              loading
                ? 'Searching'
                : searchQuery
                ? 'No results found'
                : 'Type a name to search'
            }
            search
            selection
            options={guides.map(guide => ({
              image: <Avatar user={guide} />,
              text: `${guide.firstName} ${guide.lastName}`,
              value: guide.id
            }))}
            onChange={this._onGuideChange}
            onSearchChange={this._onSearchChange}
          />
          <br />
          <Switch>
            <Route
              path={`${match.url}/:id`}
              exact
              component={AvailabilityCalendar}
            />
          </Switch>
        </div>
      </div>
    );
  }

  _onGuideChange = (event, { value }) => {
    const { history } = this.props;

    history.push(`${APP_ROOT}/guide-availability/${value}`);
  };

  _onSearchChange = debounce((event, { searchQuery }) => {
    this._query(searchQuery);
  }, SEARCH_SPEED);
}
export default GuideAvailability;
