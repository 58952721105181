import './styles.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactRTE from 'react-rte';

const ToolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS'
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Strike Through', style: 'STRIKETHROUGH' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' }
  ]
};

export default class RichTextEditor extends Component {
  static propTypes = {
    format: PropTypes.oneOf(['html', 'markdown']),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  };

  state = {
    value: ReactRTE.createEmptyValue()
  };

  componentDidMount() {
    this._setValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this._setValue();
    }
  }

  _setValue() {
    const { format, value } = this.props;

    const formatted = this.state.value.toString(format);

    if (value !== formatted) {
      this.setState({
        value: this.state.value.setContentFromString(value, format)
      });
    }
  }

  render() {
    const { value } = this.state;

    return (
      <ReactRTE
        className="react-rte"
        toolbarConfig={ToolbarConfig}
        value={value}
        onChange={this._onChange}
      />
    );
  }

  _onChange = value => {
    const { format, onChange } = this.props;

    this.setState({ value }, () => {
      onChange(value.toString(format));
    });
  };
}
