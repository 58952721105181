import './accessibility.css';
import './theme.css';
import './theme-mobile.css';
import './App.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { Responsive } from 'semantic-ui-react';

import { APP_ROOT } from '../consts';
import AppHeader from './container/app-header';
import AppNav from './container/app-nav';
import AppWorkspace from './container/app-workspace';

// import LiveChat from './container/live-chat';

@withRouter
class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  state = {
    menuopen: false
  };

  toggleMenu = () => {
    let _state = !this.state.menuopen;

    this.setState({
      menuopen: _state
    });
  };

  _isRoot() {
    const { location } = this.props;

    const rootRegex = new RegExp(`^${APP_ROOT}\\/?$`);
    return !!location.pathname.match(rootRegex);
  }

  _isOnboard() {
    const { location } = this.props;
    const onboardRegex = new RegExp(`^${APP_ROOT}\\/[\\s\\S]*\\/onboard`);
    return !!location.pathname.match(onboardRegex);
  }

  _isRegister() {
    const { location } = this.props;
    const registerRegex = new RegExp(`^${APP_ROOT}\\/register`);
    return !!location.pathname.match(registerRegex);
  }

  _hasHeader() {
    return !this._isRoot() && !this._isOnboard() && !this._isRegister();
  }

  render() {
    return (
      <div className="app-container">
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <div className="app-nav-workspace-container">
            {this._hasHeader() && (
              <AppNav
                isMobile={false}
                toggleMenu={this.toggleMenu}
                menuOpen={this.state.menuopen}
              />
            )}
            <div className="header-and-workspace-container">
              {this._hasHeader() && (
                <AppHeader
                  toggleMenu={this.toggleMenu}
                  menuOpen={this.state.menuopen}
                  isMobile={false}
                />
              )}
              <AppWorkspace />
            </div>
          </div>
          {/* <LiveChat /> */}
        </Responsive>

        <Responsive
          maxWidth={Responsive.onlyMobile.maxWidth}
          className="mobile-sidebar-container"
        >
          <Sidebar
            sidebar={
              <AppNav
                isMobile={true}
                toggleMenu={this.toggleMenu}
                menuOpen={this.state.menuopen}
                className="appnav-mobile"
              />
            }
            open={this.state.menuopen}
            onSetOpen={this.toggleMenu}
            className="mobile-sidebar"
          >
            <div className="app-nav-workspace-container">
              <div className="header-and-workspace-container">
                {this._hasHeader() && (
                  <AppHeader
                    toggleMenu={this.toggleMenu}
                    menuOpen={this.state.menuopen}
                    isMobile={true}
                  />
                )}
                <AppWorkspace />
              </div>
            </div>
          </Sidebar>
        </Responsive>
      </div>
    );
  }
}
export default App;
