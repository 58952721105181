import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Menu, Tab } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import GuideApplications from './guide-applications';

import './admin-applications.css';

@withRouter
@withUser({ authenticated: [ROLES.ADMIN] })
export default class AdminDashboard extends Component {
  state = {
    confirmApplicant: null
  };

  render() {
    //PENDING
    //ACCEPTED
    //REJECTED

    const panes = [
      {
        menuItem: <Menu.Item key="PENDING">Pending</Menu.Item>,
        render: () => (
          <Tab.Pane attached={false}>
            <GuideApplications status="PENDING" />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item key="ACCEPTED" color="green">
            Accepted
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <GuideApplications status="ACCEPTED" />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item key="REJECTED" color="red">
            Rejected
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <GuideApplications status="REJECTED" />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div className="admin-applications">
        <Header as="h2" icon textAlign="center">
          <Header.Content>Applications awaiting approval</Header.Content>
        </Header>
        <Tab
          fluid
          menu={{
            color: 'blue',
            secondary: true,
            pointing: true
          }}
          panes={panes}
        />
      </div>
    );
  }
}
