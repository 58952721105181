import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, List, Modal } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import { APP_ROOT } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import CallEntryBase from '../../../ui/call-entry';
import GuideCallActions from '../../communications/controlpanel';

@withUser({ authenticated: true })
@withRouter
class StartedCallEntry extends Component {
  static propTypes = {
    call: PropTypes.shape({
      client: PropTypes.object,
      duration: PropTypes.number,
      guide: PropTypes.object,
      rating: PropTypes.number,
      scheduledTime: PropTypes.date,
      startedAt: PropTypes.string
    }).isRequired,
    isBanner: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    useDesktop: false
  };

  render() {
    const { call, history, isBanner, isTablet } = this.props;
    const { User } = this.props.user;

    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);

    const { client, guide, scheduledTime, startedAt } = call;
    const started = moment.utc(startedAt).fromNow();

    const subheader = (
      <div>
        <span>Started {started}</span>
        <div>
          <GuideCallActions call={call} />
        </div>
      </div>
    );

    return (
      <CallEntryBase
        channel={call.channel}
        user={isGuide ? client : guide}
        guide={guide}
        date={scheduledTime}
        subheader={subheader}
        isTablet={isTablet}
        isBanner={isBanner}
        className="started-call-entry"
        userRole={isGuide ? 'GUIDE' : 'CLIENT'}
      >
        {isGuide && (
          <List.Content
            floated={isTablet ? null : 'right'}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Button
              primary
              fluid={isTablet}
              onClick={() => {
                if (isTablet) {
                  this.setState({ useDesktop: true });
                } else {
                  history.push(`${APP_ROOT}/call/${call.id}`);
                }
              }}
              style={
                !isBanner
                  ? { display: 'flex', alignItems: 'center' }
                  : {
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '0.7em 1.5em',

                      justifyContent: 'center'
                    }
                /* lineHeight: '1em', */
              }
            >
              <span>Get Started</span>
            </Button>
          </List.Content>
        )}
        {this._renderUseDesktopModal()}
      </CallEntryBase>
    );
  }

  _renderUseDesktopModal() {
    const { useDesktop } = this.state;

    if (!useDesktop) {
      return null;
    }

    return (
      <Modal
        open
        size="small"
        onClose={() => {
          this.setState({ useDesktop: false });
        }}
      >
        <Modal.Content>
          <p>
            To start your call, please use a desktop computer, not your mobile
            device!
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ useDesktop: false });
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default StartedCallEntry;
