import toPairs from 'lodash/toPairs';
import { format } from 'phone-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, Form, Header, Input, TextArea } from 'semantic-ui-react';
import { US_STATES_AND_TERRITORIES } from 'us-regions';

import { EDUCATION_LEVELS_TEXT, ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import withWidth, { isWidthDown } from '../../../hoc/with-width';
import MyForm, { FormField } from '../../../ui/form';
import TimezoneSelector from '../../../ui/timezone-selector';

const MAXLENGTH_FIELD = 75;
const MAXLENGTH_TEXTAREA = 2000;

function getUserData(user) {
  if (!user) {
    return null;
  }
  return {
    address: user.address || '',
    age: user.age || '',
    avatarUrl: user.avatarUrl || '',
    bio: user.bio || '',
    city: user.city || '',
    educationLevel: user.educationLevel || null,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber || '',
    shortIntro: user.shortIntro || '',
    state: user.state || '',
    timezone: user.timezone || '',
    zipcode: user.zipcode || ''
  };
}

function formatData(data) {
  return {
    ...data,
    phoneNumber: format('(NNN) NNN-NNNN', data.phoneNumber.replace(/\D/g, ''))
  };
}

@withUser({ authenticated: true })
@withWidth()
class BasicInfoForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        age: PropTypes.number,
        address: PropTypes.string,
        avatarUrl: PropTypes.string,
        bio: PropTypes.string,
        educationLevel: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        phoneNumber: PropTypes.string,
        timezone: PropTypes.string,
        zipcode: PropTypes.string
      })
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    const { onChange } = this.props;
    const { User } = props.user;

    const data = formatData({
      address: '',
      age: '',
      avatarUrl: '',
      bio: '',
      city: '',
      educationLevel: null,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      shortIntro: '',
      state: '',
      timezone: '',
      zipcode: '',
      ...getUserData(User)
    });

    this.state = {
      data
    };

    if (onChange) {
      onChange(data);
    }
  }

  componentDidUpdate(prevProps) {
    const userChanged =
      (!prevProps.user.User && this.props.user.User) ||
      prevProps.user.User !== this.props.user.User;
    if (!userChanged) {
      return;
    }

    const { User } = this.props.user;
    const { data } = this.state;

    this._onChange({
      ...data,
      ...getUserData(User)
    });
  }

  _isValidZipCode = zipcode => {
    let zipValidator = new RegExp('^[0-9a-zA-Z ]{5,8}$', 'gimu');
    return zipValidator.test(zipcode.zipcode);
  };

  render() {
    const { onValidate, width } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    const isTablet = isWidthDown('computer', width);
    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);

    return (
      <MyForm
        className="profile-form"
        data={data}
        onChange={this._onChange}
        onValidate={onValidate}
      >
        <Form.Group widths="equal" grouped={isTablet}>
          <FormField
            component={Input}
            name="firstName"
            label="First Name"
            aria-placeholder="First Name"
            validator={({ firstName }) => {
              if (!firstName) {
                throw new Error('First name is required');
              }
              if (firstName.length > MAXLENGTH_FIELD) {
                throw new Error(
                  `First name cannot be longer than ${MAXLENGTH_FIELD} letters`
                );
              }
            }}
          />
          <FormField
            component={Input}
            name="lastName"
            label="Last Name"
            aria-placeholder="Last Name"
            validator={({ lastName }) => {
              if (!lastName) {
                throw new Error('Last name is required');
              }
              if (lastName.length > MAXLENGTH_FIELD) {
                throw new Error(
                  `Last name cannot be longer than ${MAXLENGTH_FIELD} letters`
                );
              }
            }}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="age"
          type="number"
          label="Age"
          aria-placeholder="Age"
          validator={({ age }) => {
            if ((age && age < 1) || age > 125) {
              throw new Error('Age needs to be between 1 and 125');
            }
          }}
        />
        <FormField
          component={Input}
          type="text"
          name="address"
          label="Address"
          aria-placeholder="Address"
        />
        <Form.Group widths="equal" aria-placeholder="Enter your city and state">
          <FormField
            component={Input}
            name="city"
            label="City"
            aria-placeholder="City"
          />
          <FormField
            component={Dropdown}
            name="state"
            label="State"
            aria-placeholder="US States and Terrotories"
            fluid
            selection
            search
            options={toPairs(US_STATES_AND_TERRITORIES).map(
              ([abbreviation, name]) => ({
                text: name,
                value: abbreviation
              })
            )}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="zipcode"
          type="text"
          maxLength="8"
          label="Zipcode"
          aria-placeholder="Zipcode"
          validator={zipcode => {
            if (zipcode.zipcode && !this._isValidZipCode(zipcode)) {
              throw new Error(
                'Zipcode formats currently accepted are USA (00000) and Canada (000 000).'
              );
            }
          }}
        />
        <FormField
          component={Input}
          name="phoneNumber"
          type="tel"
          label="Personal Mobile Number"
          content="Personal Mobile Number"
          aria-placeholder="Personal Mobile Number"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Must enter a mobile number');
            }
            let digits = phoneNumber.replace(/\D/g, '');
            if (
              !(
                (digits[0] === '1' && digits.length === 11) ||
                digits.length === 10
              )
            ) {
              throw new Error('Mobile number must have 10 digits');
            }
          }}
        />
        <FormField
          component={TimezoneSelector}
          name="timezone"
          label="Time Zone"
          aria-placeholder="timezone"
          fluid
          selection
        />
        <Header>
          {isGuide
            ? `Tell potential clients about yourself`
            : `Tell us about yourself`}
        </Header>
        <FormField
          component={TextArea}
          name="bio"
          label="Bio"
          aria-placeholder="Bio"
          validator={({ bio }) => {
            if (bio.length > MAXLENGTH_TEXTAREA) {
              throw new Error(
                `Bio needs to be under ${MAXLENGTH_TEXTAREA} characters in length`
              );
            }
          }}
        />

        {isGuide && (
          <div style={{ marginBottom: '20px' }}>
            <Header>Provide a short, two sentence introduction</Header>
            <FormField
              component={TextArea}
              maxlength={80}
              name="shortIntro"
              placeholder="I have .... years of experience in ..."
              aria-placeholder="Short Intro"
              validator={({ shortIntro }) => {
                if (shortIntro.length > 80) {
                  throw new Error(
                    `Short Introduction needs to be under ${80} characters in length`
                  );
                }
              }}
            />
          </div>
        )}
        {isGuide && <Header>What is your level of education?</Header>}
        {isGuide && (
          <FormField
            component={Dropdown}
            name="educationLevel"
            placeholder="Education Level"
            aria-placeholder="Education Level"
            fluid
            upward
            selection
            options={[{ text: '', value: null }].concat(
              toPairs(EDUCATION_LEVELS_TEXT).map(([value, text]) => ({
                text,
                value
              }))
            )}
          />
        )}
      </MyForm>
    );
  }

  _onChange = data => {
    const { onChange } = this.props;

    const formatted = formatData(data);

    this.setState({ data: formatted });

    if (onChange) {
      onChange(formatted);
    }
  };
}
export default BasicInfoForm;
