import './index.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Header, Label, List } from 'semantic-ui-react';

import { APP_ROOT, LAST_CHANNEL_KEY, ROLES } from '../../../consts';
import Avatar from '../avatar';
import FormattedName from '../formatted-name';
import Countdown from './countdown';

@withRouter
class CallEntry extends PureComponent {
  static propTypes = {
    cancelled: PropTypes.bool,
    scheduled: PropTypes.bool,
    channel: PropTypes.shape({
      shortTitle: PropTypes.string,
      slug: PropTypes.string
    }),
    completed: PropTypes.bool,
    children: PropTypes.node,
    notifications: PropTypes.node,
    date: PropTypes.string,
    history: PropTypes.object.isRequired,
    isCompleted: PropTypes.bool,
    isTablet: PropTypes.bool,
    timezone: PropTypes.string,
    showDateYear: PropTypes.bool,
    subheader: PropTypes.node,
    bookAgain: PropTypes.bool,
    curUser: PropTypes.object,
    className: PropTypes.string,
    isBanner: PropTypes.bool,
    user: PropTypes.shape({
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
      timezone: PropTypes.string,
      companyIds: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          company: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        })
      )
    }).isRequired,
    userRole: PropTypes.string
  };

  _guideUrl() {
    const { channel, user } = this.props;
    let slug = localStorage.getItem(LAST_CHANNEL_KEY);
    if (channel && channel.slug) {
      slug = channel.slug;
    }
    return `${APP_ROOT}/${slug}/guides/${user.id}`;
  }

  render() {
    const {
      children,
      curUser,
      date,
      timezone,
      className,
      isBanner,
      scheduled,
      notifications
    } = this.props;

    let bannerClass = isBanner ? 'is-banner' : '';
    let _className = className || ''; //passed in from parent
    let classes = `${bannerClass} ${_className} callentry callentry-list-item`;

    let borderClass = `${isBanner ? '' : 'bottom-border'} list-wrapper`;
    const checkedtz = (curUser && curUser.timezone) || moment.tz.guess();
    const bef = moment.tz(date, timezone);
    const m = bef.clone().tz(checkedtz);
    const morning = moment().startOf('day');
    const midnight = moment().endOf('day');
    const isToday = m.isBetween(morning, midnight);

    const countdown =
      scheduled && isToday ? (
        <div className="callentry-countdown-container">
          <Countdown date={m} />
        </div>
      ) : null;

    const _notifications = notifications || null;

    return (
      <div className={classes}>
        <div className={borderClass}>
          <div className="list-item-container">
            {/* {this._renderDate()} */}
            {this._renderHeader()}
            <div className="callentry-children">
              {children}
              {countdown}
            </div>
          </div>
        </div>
        {_notifications}
      </div>
    );
  }

  _renderHeader() {
    const {
      channel,
      user,
      userRole,
      isBanner,
      curUser,
      date,
      timezone,
      bookAgain,
      isCompleted
    } = this.props;

    const [companyConnections] = user.companyIds || [];

    const checkedtz = (curUser && curUser.timezone) || moment.tz.guess();
    const bef = moment.tz(date, timezone);
    const m = bef.clone().tz(checkedtz);

    const morning = moment().startOf('day');
    const midnight = moment().endOf('day');
    const isToday = m.isBetween(morning, midnight);
    const fullName = (
      <FormattedName firstName={user.firstName} lastName={user.lastName} />
    );

    let headerStatement = '';
    if (isBanner && isToday && !isCompleted) {
      headerStatement = ` Your call with ${user.firstName} starts soon!`;
    }
    if ((isBanner && !isToday) || isCompleted) {
      headerStatement = fullName;
    }
    if (!isBanner) {
      headerStatement = fullName;
    }

    return (
      <div className="callentry-header">
        <Avatar
          key="avatar"
          user={user}
          className="callentry-header-avatar"
          onClick={userRole == ROLES.CLIENT ? this._handleAvatarClick : null}
        />
        <List.Content
          key="header"
          className={bookAgain ? 'list-content book-again' : 'list-content'}
        >
          <List.Header className="list-header">
            {userRole === ROLES.CLIENT ? (
              <div>
                <Link to={this._guideUrl()}>
                  <div className="header-and-tags">
                    <div className="header">{fullName}</div>
                    <div className="channel-and-company">
                      {channel && (
                        <Label color={channel.iconColor}>
                          {channel.shortTitle}
                        </Label>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="header-and-tags">
                <div className="header">{headerStatement} </div>
                <div className="channel-and-company">
                  {channel && (
                    <Label color={channel.iconColor}>
                      {channel.shortTitle}
                    </Label>
                  )}{' '}
                  {companyConnections && (
                    <Label>{companyConnections.company.name}</Label>
                  )}
                </div>
              </div>
            )}
            {this._renderSubheader()}
          </List.Header>
        </List.Content>
      </div>
    );
  }

  _renderDate() {
    const { cancelled, curUser, date, showDateYear, timezone } = this.props;

    if (!date) {
      return null;
    }

    const checkedtz = (curUser && curUser.timezone) || moment.tz.guess();
    const bef = moment.tz(date, timezone);
    const m = bef.clone().tz(checkedtz);
    const dateFormat = showDateYear ? 'MMM D, Y' : 'MMM D';

    return (
      <List.Content className="callentry-date">
        <Header
          as="h4"
          style={{
            textDecoration: cancelled ? 'line-through' : 'none'
          }}
          className="callentry-date-header"
        >
          {m.format(dateFormat)}
          <Header.Subheader className="callentry-date-subheader">
            {m.format('ddd')}
          </Header.Subheader>
        </Header>
      </List.Content>
    );
  }

  _handleAvatarClick = () => {
    const { history } = this.props;
    history.push(this._guideUrl());
  };

  _renderSubheader() {
    const { subheader, bookAgain } = this.props;

    return (
      <Header.Subheader className="callentry-subheader">
        {!bookAgain ? (
          <span className="subheader"> {subheader} </span> || (
            <span className="no-content" />
          )
        ) : (
          <span className="book-again-txt">
            Book another call with this guide!
          </span>
        )}
      </Header.Subheader>
    );
  }
}
export default CallEntry;
