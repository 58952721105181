import './index.css';
import './onboard.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { LGSVG } from '../../../assets';
import { APP_ROOT } from '../../../consts';
import withAuth0 from '../../hoc/with-auth0';
import withCompanies from '../../hoc/with-companies';
import withUser from '../../hoc/with-user';

@withAuth0()
@withUser()
@withCompanies()
class OnboardAppHeader extends Component {
  state = {
    chatNow: false
  };

  static propTypes = {
    auth0: PropTypes.object.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired,
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    })
  };

  state = {
    busy: false
  };

  render() {
    const { auth0, companies } = this.props;
    const { busy } = this.state;

    let company = null;
    if (companies && companies.companies) {
      [company] = companies.companies;
    }

    const divider = company ? (
      <div className="appheader-logo-login-divider" />
    ) : null;
    const companyContent = company ? (
      <span className="appheader-company">{company.name}</span>
    ) : null;

    return (
      <div className={`app-header onboard`}>
        <div className="appheader-logo-signin-container">
          <Link to={APP_ROOT} className="appheader-logo-link">
            <img src={LGSVG} alt="Lifeguides Logo" className="appheader-logo" />
          </Link>

          {divider}
          {companyContent}
          <div className="appheader-user-component-container" />
        </div>
        <div className="appheader-call-chat-cta-container">
          <div className="appheader-call-cta">
            <span className="onboard-header-label">
              {`Already have an account?`}
            </span>
          </div>
          <div className="appheader-chat-cta">
            <Button
              className="hollow-button inverted"
              loading={busy}
              onClick={() => {
                this.setState({
                  busy: true
                });
                auth0.connect(null, '/login');
              }}
            >
              Sign In
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default OnboardAppHeader;
