import './index.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon, List, Segment } from 'semantic-ui-react';

import { APP_ROOT, ROLES } from '../../../consts';
import CallQuery from '../../../graphql/queries/call.graphql';
import graphql from '../../hoc/graphql';
import withTwilio, {
  propTypes as twilioPropTypes
} from '../../hoc/with-twilio';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import CallDialog from '../call-dialog';
import CallNotes from '../call-notes';
import ClientDetails from '../client-details';
import ClientFaceplate from '../client-details/faceplate';
import GuideCallActions from '../communications/controlpanel';
import DiscussionTopics from '../discussion-topics';
import CompleteCallDialog from './complete-call-dialog';
import crisisQuestions from './crisis-questions';

@withRouter
@graphql(CallQuery, {
  name: 'call',
  options: ({
    match: {
      params: { id }
    }
  }) => ({
    variables: { id }
  })
})
@withUser({ authenticated: [ROLES.GUIDE] })
@withWidth()
@withTwilio
class Call extends Component {
  static propTypes = {
    call: PropTypes.shape({
      call: PropTypes.shape({
        client: PropTypes.object,
        guide: PropTypes.object,
        scheduledTime: PropTypes.string,
        status: PropTypes.string
      }),
      loading: PropTypes.bool.isRequired
    }),
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showCrisisProtocol: PropTypes.bool,
    width: PropTypes.number.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        timezone: PropTypes.string
      }).isRequired
    }).isRequired,
    ...twilioPropTypes
  };

  state = {
    open: false,
    showCrisisProtocol: false
  };

  render() {
    const { width } = this.props;
    const { loading, call } = this.props.call;

    if (loading) {
      <Segment basic loading />;
    }
    if (!call) {
      return null;
    }

    const isTablet = isWidthDown('computer', width);

    return (
      <div>
        <div className="call-container">
          <div className="call-container-row">
            <div className="call-client">
              <div className="client-header-container">
                <div className="client-faceplate-guide-actions">
                  <ClientFaceplate client={call.client} />
                  <GuideCallActions call={call} />
                </div>
                <div className="call-button-start">
                  {this._renderStartButton()}
                </div>
              </div>
              <Segment className="call-notes-container">
                <Header className="call-notes-text">Call Notes</Header>
                <CallNotes className="call-notes" call={call} />
              </Segment>
              <ClientDetails
                className="client-details"
                channel={call.channel}
                client={call.client}
              />
            </div>
            {!isTablet && (
              <div className="call-topics">
                {this._renderCrisisButton()}
                {this._renderCrisisProtocol()}
                <DiscussionTopics className="call-topics" call={call} />
              </div>
            )}
          </div>
        </div>
        {this._renderCallDialog()}
        {this._renderCompleteDialog()}
      </div>
    );
  }

  _renderStartButton() {
    const { call } = this.props.call;

    if (!['SCHEDULED', 'STARTED'].includes(call.status)) {
      return null;
    }

    return (
      <div className="start-call-container">
        <Button className="start-call powerful-btn" onClick={this._start}>
          <Icon className="phone lineawesome" size="large" />
          <div className="start-call-button-text">Start Call</div>
        </Button>
      </div>
    );
  }

  _renderCrisisButton() {
    return (
      <Button
        className="crisis-btn powerful-btn"
        onClick={() => {
          this.setState({ showCrisisProtocol: !this.state.showCrisisProtocol });
        }}
      >
        Crisis
      </Button>
    );
  }

  _renderCrisisProtocol() {
    const { showCrisisProtocol } = this.state;
    let crisisProtocolHeight = showCrisisProtocol ? 'auto' : 0;
    let crisisProtocolVisibility = showCrisisProtocol ? 1 : 0;

    const animation = showCrisisProtocol
      ? `crisisFadeIn ${125}ms ease-in-out`
      : `crisisFadeOut ${125}ms ease-in-out`;

    let listItems = crisisQuestions.map(q => {
      return (
        <List.Item key={q.id}>
          <List.Header as="">
            {q.id} {q.title}
          </List.Header>
          <List.List>
            {q.questions.map(qI => {
              return <List.Item key={qI}>○ {qI}</List.Item>;
            })}
          </List.List>
        </List.Item>
      );
    });

    return (
      <Segment
        className={`crisis`}
        style={{
          animation: animation,
          height: crisisProtocolHeight,
          opacity: crisisProtocolVisibility
        }}
      >
        <div className="call-client-header">Crisis Protocol</div>

        <List className="crisis-prototcol-text" relaxed>
          {listItems}
        </List>
      </Segment>
    );
  }

  _renderCallDialog() {
    const { call } = this.props.call;
    const { open } = this.state;

    if (!call || call.status === 'COMPLETED') {
      return null;
    }
    if (!open) {
      return null;
    }

    return (
      <CallDialog
        {...this.props}
        call={call}
        open
        onClose={() => {
          this.setState({ open: false });
        }}
      />
    );
  }

  _renderCompleteDialog() {
    const { history, location } = this.props;
    const { call } = this.props.call;

    if (call.status !== 'STARTED') {
      return null;
    }

    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    if (!params.complete) {
      return null;
    }

    return (
      <CompleteCallDialog
        open
        onCancel={() => {
          history.push(`${APP_ROOT}/call/${call.id}`);
        }}
        call={call}
      />
    );
  }

  _start = () => {
    const { setup } = this.props;

    setup();
    this.setState({ open: true });
  };
}
export default Call;
