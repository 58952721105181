import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Header, Progress } from 'semantic-ui-react';

import ProfileStep from './profile-step';

class ProfileStepsProgress extends Component {
  static propTypes = {
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired,
    profileSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  };

  render() {
    const { profileSteps } = this.props;
    const { User } = this.props.user;

    const incompleteSteps = profileSteps.filter(step => {
      return step.condition(User);
    });

    if (!incompleteSteps.length) {
      return null;
    }

    return this._renderIncompleteProfileSteps(incompleteSteps);
  }

  _renderIncompleteProfileSteps(incompleteSteps) {
    const { profileSteps } = this.props;

    let totalStepsCompleted = profileSteps.length - incompleteSteps.length;
    let percent = parseInt((totalStepsCompleted / profileSteps.length) * 100);

    return (
      <div className="profile-steps-progress">
        <Header size="large">Your Profile</Header>
        <Progress percent={percent} />
        <p className="progress-label">
          <span className="bold">{totalStepsCompleted}</span> of{' '}
          <span className="bold">{profileSteps.length}</span> steps complete
        </p>
        {incompleteSteps.map(this._renderIncompleteProfileStep)}
      </div>
    );
  }

  _renderIncompleteProfileStep = (step, i) => {
    return <ProfileStep step={step} index={i} />;
  };
}

export default ProfileStepsProgress;
