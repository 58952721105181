import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TimezoneSelector from '../../../../ui/timezone-selector';

class BookingTimezoneSelector extends Component {
  render() {
    const { selectedTimezone, onChange } = this.props;
    return (
      <div className="controls field tz-input">
        <label>Timezone</label>
        <TimezoneSelector
          selection
          value={selectedTimezone}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default BookingTimezoneSelector;
