import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Dropdown, Icon, List, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';

class UserBasicInfo extends Component {
  static propTypes = {
    onCallCreditsClick: PropTypes.func.isRequired,
    onGuideStatusChange: PropTypes.func.isRequired,
    onVideoURLClick: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      bio: PropTypes.string,
      callCredits: PropTypes.number,
      city: PropTypes.string,
      educationLevel: PropTypes.string,
      guideStatus: PropTypes.string,
      phoneNumber: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      state: PropTypes.string,
      videoUrl: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      onCallCreditsClick,
      onGuideStatusChange,
      onVideoURLClick,
      user
    } = this.props;
    const isClient = user.roles.some(role => role.name === ROLES.CLIENT);
    const isGuide = user.roles.some(role => role.name === ROLES.GUIDE);

    const statusOptions = [
      {
        key: '',
        text: '',
        value: ''
      },
      {
        key: 'TRIAL',
        text: 'Trial',
        value: 'TRIAL'
      },
      {
        key: 'DEACTIVATED',
        text: 'Deactivated',
        value: 'DEACTIVATED'
      }
    ];

    return (
      <Segment basic>
        <List>
          {isGuide && (
            <List.Item>
              <List.Header>Status</List.Header>
              <Dropdown
                selection
                disabled={!user.roles.length && !isGuide}
                options={statusOptions}
                value={user.guideStatus}
                onChange={(event, { value }) => onGuideStatusChange(value)}
              />
            </List.Item>
          )}
          <List.Item>
            <List.Header>Location</List.Header>
            {user.state
              ? user.city
                ? `${user.city}, ${user.state}`
                : user.state
              : 'N/A'}
          </List.Item>
          <List.Item>
            <List.Header>Mobile Number</List.Header>
            {user.phoneNumber || 'N/A'}
          </List.Item>
          {isGuide && (
            <List.Item>
              <List.Header>Bio</List.Header>
              {user.bio || 'N/A'}
            </List.Item>
          )}
          {isGuide && (
            <List.Item>
              <List.Header>Education</List.Header>
              {user.educationLevel || 'N/A'}
            </List.Item>
          )}
          {isGuide && (
            <List.Item>
              <List.Header>Video URL</List.Header>
              {user.videoUrl && (
                <span>
                  <a
                    href={user.videoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {user.videoUrl}
                  </a>
                  &nbsp;
                </span>
              )}
              <Button compact size="mini" onClick={onVideoURLClick}>
                <Icon name="edit" />
                {user.videoUrl ? 'Edit' : 'Add URL'}
              </Button>
            </List.Item>
          )}
          {isClient && (
            <List.Item>
              <List.Header>
                Total Call Credits (requested + available)
              </List.Header>
              {user.callCredits}
            </List.Item>
          )}
          {isClient && (
            <List.Item>
              <Button primary onClick={onCallCreditsClick}>
                Adjust User Credits
              </Button>
            </List.Item>
          )}
        </List>
      </Segment>
    );
  }
}
export default UserBasicInfo;
