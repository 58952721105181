import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';

import ResourceHeader from '../../ui/resource-header';
import SubChannelCard from './sub-channel-card';

class SubChannelsList extends Component {
  static propTypes = {
    isTablet: PropTypes.bool.isRequired,
    channel: PropTypes.shape({
      subChannels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ).isRequired
    }).isRequired
  };

  render() {
    const { channel, isTablet } = this.props;

    const columns = isTablet ? 2 : 3;

    return (
      <div className="resource-subchannels resource-container">
        <ResourceHeader title="Select a topic to see relevant articles, videos and more!" />
        <Card.Group itemsPerRow={columns} stackable>
          {channel.subChannels.map(this._subchannelToHtml(channel))}
        </Card.Group>
      </div>
    );
  }

  _subchannelToHtml = channel => subChannel => {
    return (
      <SubChannelCard
        key={`subChannel-${subChannel.id}`}
        channel={channel}
        subChannel={subChannel}
      />
    );
  };
}
export default SubChannelsList;
