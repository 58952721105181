import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { APP_ROOT } from '../../../consts';
import GuideDetailsDialog from '../guide-details-dialog';

@withRouter
class DashboardFeaturedGuideRoutes extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        channelName: PropTypes.string.isRequired
      }).isRequired,
      url: PropTypes.string.isRequired
    })
  };

  _onCloseModal = () => {
    const { match, history } = this.props;
    const { channelName } = match.params;
    const toDash = `${APP_ROOT}/${channelName}/dashboard`;
    history.push(toDash);
  };

  _onClickVideo = () => {
    const { match, history } = this.props;
    const { channelName } = match.params;
    const toVideo = `${APP_ROOT}/${channelName}/dashboard/video`;
    history.push(toVideo);
  };

  _onClickLearnMore = () => {
    const { match, history } = this.props;
    const { channelName } = match.params;
    const toSchedule = `${APP_ROOT}/${channelName}/dashboard/schedule`;
    history.push(toSchedule);
  };

  render() {
    const { match } = this.props;
    const rootProps = this.props;
    const { channelName } = match.params;

    return (
      <div className="dashboard-featured-guide-routes-container">
        <Switch>
          <Route
            path={`${match.url}/schedule`}
            render={props => {
              return (
                <GuideDetailsDialog
                  {...rootProps}
                  channelName={channelName}
                  guideId={rootProps.guide.id}
                  showScheduler={true}
                  showVideo={false}
                  onCloseModal={this._onCloseModal}
                  onClickVideo={this._onClickVideo}
                />
              );
            }}
          />
          <Route
            path={`${match.url}/video`}
            render={props => {
              return (
                <GuideDetailsDialog
                  {...rootProps}
                  channelName={channelName}
                  guideId={rootProps.guide.id}
                  showScheduler={true}
                  showVideo={true}
                  onCloseModal={this._onCloseModal}
                  onClickLearnMore={this._onClickLearnMore}
                />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}
export default DashboardFeaturedGuideRoutes;
