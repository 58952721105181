export const APP_ROOT = "";
export const WEBSITE_URL = "https://lifeguides.com";
export const CONCIERGE_PHONE_NUMBER = "1-877-532-3472";
export const ACCESS_CODE_KEY = "access_code";

export const AUTH_0_DOMAIN = "lifeguides.auth0.com";
export const AUTH_TOKEN_NAME = "lifeguides-auth";

export const BUGSNAG_API_KEY = "a798d4b9c26e5461ded1df76ddddeaef";

export const CLIENT_INVITE_KEY = "invite_id";

export const GUIDE_CALL_REQUEST_KEY = "guide_call_request_data";
export const GUIDE_CALL_REQUEST_CREATED_KEY = "guide_call_request_created";

export const DEFAULT_CHANNEL_SLUG = "alzheimers";
export const LAST_CHANNEL_KEY = "channel";

export const STRIPE_API_TEST_KEY = "pk_test_m1NZK1vyWfbate6mFZDj0a53";

export const ROLES = {
  ADMIN: "ADMIN",
  BANNED: "BANNED",
  CLIENT: "CLIENT",
  CONCIERGE: "CONCIERGE",
  CORP_ADMIN: "CORP_ADMIN",
  GUEST: "GUEST",
  GUIDE: "GUIDE"
};

export const EDUCATION_LEVELS = {
  ASSOCIATE_DEGREE: "ASSOCIATE_DEGREE",
  BACHELORS_DEGREE: "BACHELORS_DEGREE",
  GRADUATE_DEGREE: "GRADUATE_DEGREE",
  PROFESSIONAL_DEGREE: "PROFESSIONAL_DEGREE",
  TEACHER_CERTIFICATION: "TEACHER_CERTIFICATION"
};

export const EDUCATION_LEVELS_TEXT = {
  ASSOCIATE_DEGREE: "Associate Degree",
  BACHELORS_DEGREE: "Bachelor's Degree",
  GRADUATE_DEGREE: "Graduate Degree",
  PROFESSIONAL_DEGREE: "Professional Degree",
  TEACHER_CERTIFICATION: "Teacher Certification"
};

export const DEFAULT_AVATAR_URL =
  "https://lh3.googleusercontent.com/-bGCI8xZmQEM/AAAAAAAAAAI/AAAAAAAACms/EQfrUSx4aG4/s640/photo.jpg";

/*Social Media*/
export const TWITTER_ACCOUNT_URL = "https://twitter.com/LifeGuidesNow";
export const FACEBOOK_ACCOUNT_URL = "https://www.facebook.com/LifeGuidesNow/";

/*Trackers*/
export const GOOGLE_TAG_MANAGER_ID = "GTM-K5TQF4T";
