import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class VolumeIndicator extends Component {
  static propTypes = {
    connection: PropTypes.object.isRequired
  };

  state = {
    volume: null
  };

  componentDidMount() {
    const { connection } = this.props;

    connection.volume(this._onVolumeChange);
  }

  _onVolumeChange = (inputVolume, outputVolume) => {
    this.setState({ volume: outputVolume });
  };

  render() {
    const { volume } = this.state;

    if (volume === null) {
      return null;
    }

    const scaled = Math.round(volume * 100);

    return (
      <div
        style={{
          float: 'left',
          width: 100,
          borderWidth: 1,
          borderColor: '#eeeeee',
          borderStyle: 'solid',
          margin: '1rem'
        }}
      >
        <div
          style={{
            height: '1rem',
            width: `${scaled}%`,
            backgroundColor: '#00ff00'
          }}
        />
      </div>
    );
  }
}
