import React, { PureComponent } from 'react';
import { Container } from 'semantic-ui-react';

import GenericContactError from '../../ui/contact-us/generic-contact-error.js';

export default class NotFoundPage extends PureComponent {
  render() {
    let errorMsg = 'That page could not be found!';
    return (
      <div>
        <Container fluid text textAlign="center">
          <GenericContactError error={errorMsg} />
        </Container>
      </div>
    );
  }
}
