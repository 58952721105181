import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Icon, Message, Segment } from 'semantic-ui-react';

import { setAuthToken } from '../../../auth';
import BugsnagClient from '../../../bugsnag';
import { APP_ROOT } from '../../../consts';
import AuthenticateAuth0Mutation from '../../../graphql/mutations/authenticate-auth0.graphql';
import withAuth0 from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';

const GraphQLErrorPrefix = 'GraphQL error: ';
const FunctionExecutionErrorPrefix = 'function execution error: ';

function friendlyError(message) {
  return message
    .replace(GraphQLErrorPrefix, '')
    .replace(FunctionExecutionErrorPrefix, '');
}

@graphql(AuthenticateAuth0Mutation, { name: 'authenticate' })
@withAuth0()
@withRouter
@withUser({ authenticated: false })
class Login extends Component {
  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    auth0: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    error: null,
    submitting: false
  };

  componentDidMount() {
    const { auth0 } = this.props;
    if (auth0.code) {
      this._authenticate(auth0.code);
    } else if (auth0.error) {
      this.setState({ error: auth0.error });
    } else {
      auth0.connect(null, '/login', {
        prompt: 'login'
      });
    }
  }

  _authenticate(code) {
    const { authenticate, history } = this.props;

    const variables = { code };

    this.setState({ error: null, submitting: true });
    authenticate({ variables })
      .then(({ data: { authenticateAuth0: { authToken } } }) => {
        this.setState({ submitting: false });

        setAuthToken(authToken).then(() => {
          history.push(`${APP_ROOT}/`);
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Login._authenticate',
          request: {
            ...variables
          }
        });
      });
  }

  render() {
    const { submitting } = this.state;

    return (
      <Segment basic loading={submitting}>
        {this._renderError()}
      </Segment>
    );
  }

  _renderError() {
    const { auth0 } = this.props;
    const { error } = this.state;

    if (!error) {
      return null;
    }

    const message = friendlyError(error.message || error);
    if (message.startsWith('BANNED_USER: ')) {
      return this._renderBannedUserError(message.replace('BANNED_USER: ', ''));
    }
    if (message === 'UNREGISTERED_USER') {
      return this._renderUnregisteredUserError();
    }

    return (
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        <Message negative>{message}</Message>
        <Button
          primary
          size="large"
          fluid
          onClick={() => {
            auth0.connect(null, '/login', {
              prompt: 'login'
            });
          }}
        >
          <Icon name="refresh" />
          Click here to try logging in again
        </Button>
      </div>
    );
  }

  _renderBannedUserError(reason) {
    return (
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        <Message negative>
          <p>You&apos;ve been banned from LifeGuides.</p>
          <dl>
            <dt>Reason:</dt>
            <dd>{reason}</dd>
          </dl>
        </Message>
      </div>
    );
  }

  _renderUnregisteredUserError() {
    const { auth0, history } = this.props;

    return (
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        <Message negative>
          You&apos;ve not yet registered with this account.
        </Message>
        <Button
          primary
          size="huge"
          fluid
          onClick={() => {
            history.push(`${APP_ROOT}/register`);
          }}
        >
          Sign Up
        </Button>
        <Divider horizontal>or</Divider>
        <Button
          size="huge"
          fluid
          onClick={() => {
            auth0.connect(null, '/login', {
              prompt: 'login'
            });
          }}
        >
          Sign In with a Different Account
        </Button>
      </div>
    );
  }
}
export default Login;
