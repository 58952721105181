import './index.css';
import '../register/social-buttons.css';

// import Intercom from 'intercom-react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Divider,
  Header,
  Icon,
  Message,
  Segment
} from 'semantic-ui-react';

import { FBPNG, GOOGSVG } from '../../../assets';
import { setAuthToken } from '../../../auth';
import BugsnagClient from '../../../bugsnag';
import {
  APP_ROOT,
  FACEBOOK_ACCOUNT_URL,
  TWITTER_ACCOUNT_URL
} from '../../../consts';
import RegisterGuideAuth0Mutation from '../../../graphql/mutations/register-guide-auth0.graphql';
import RegisterGuideMutation from '../../../graphql/mutations/register-guide.graphql';
import GuideApplicationQuery from '../../../graphql/queries/guide-application.graphql';
import graphql from '../../hoc/graphql';
import withAuth0, { Connections } from '../../hoc/with-auth0';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import RegisterForm from './register-form';

const LocalStorageKey = 'guide-application-id';

const GraphQLErrorPrefix = 'GraphQL error: ';
const FunctionExecutionErrorPrefix = 'function execution error: ';

function friendlyError(message) {
  return message
    .replace(GraphQLErrorPrefix, '')
    .replace(FunctionExecutionErrorPrefix, '');
}

@graphql(GuideApplicationQuery, {
  name: 'guideApplication',
  options: ({ match }) => {
    const id = localStorage.getItem(LocalStorageKey) || match.params.id;
    return { variables: { id } };
  }
})
@graphql(RegisterGuideMutation, {
  name: 'registerGuide'
})
@graphql(RegisterGuideAuth0Mutation, {
  name: 'registerGuideAuth0'
})
@withAuth0()
@withWidth()
@withRouter
class GuideApplication extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    guideApplication: PropTypes.shape({
      guideApplication: PropTypes.object,
      loading: PropTypes.bool.isRequired
    }),
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    registerGuide: PropTypes.func.isRequired,
    registerGuideAuth0: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired
  };

  state = {
    connecting: false,
    error: null,
    //showIntercom: false,
    step: 0,
    submitting: false
  };

  UNSAFE_componentWillMount() {
    const { auth0, history, match, registerGuideAuth0 } = this.props;

    if (auth0.code) {
      this.setState({ connecting: true });

      const id = localStorage.getItem(LocalStorageKey) || match.params.id;

      const variables = {
        applicationId: id,
        timezone: moment.tz.guess(),
        code: auth0.code
      };

      this.setState({ submitting: true });
      registerGuideAuth0({
        variables
      })
        .then(({ data: { registerGuideAuth0: { authToken } } }) => {
          this.setState({ submitting: false });

          setAuthToken(authToken).then(() => {
            history.push(`${APP_ROOT}/dashboard`);
          });
        })
        .catch(error => {
          this.setState({
            connecting: false,
            error,
            step: 1,
            submitting: false
          });
          history.push(`${APP_ROOT}/application/${id}`);
        });
    }
  }

  render() {
    const { connecting } = this.state;

    return connecting
      ? this._renderConnecting()
      : this._renderGuideApplication();
  }

  _renderConnecting() {
    const { auth0 } = this.props;

    if (auth0.error) {
      return (
        <Message negative>
          <p>Authentication error: {auth0.error.errorDescription}</p>
        </Message>
      );
    }

    if (!auth0.user) {
      return null;
    }

    const connectionName = Connections[auth0.user.identities[0].connection];

    return (
      <Segment basic loading>
        {`Connecting to ${connectionName}...`}
      </Segment>
    );
  }

  // _openIntercom = () => {
  //   this.setState({ showIntercom: true });
  // };

  _renderGuideApplication() {
    const { guideApplication, loading } = this.props.guideApplication;

    if (loading) {
      return <Segment basic loading={loading} />;
    }

    if (!guideApplication) {
      return null;
    }

    switch (guideApplication.status) {
      case 'PENDING':
        return this._renderPending();
      case 'ACCEPTED':
        return this._renderAccepted();
      default:
        return null;
    }
  }

  opennewtab(url) {
    window.open(url, '_blank');
  }

  _renderPending() {
    //const { showIntercom } = this.state;
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);

    return (
      <div style={{ textAlign: 'center' }}>
        <Icon name="check circle" size="massive" color="green" />
        <br />
        <Header as="h1">You&apos;re almost a LifeGuide!</Header>
        <p>A representative will contact you in less than</p>
        <Header size="huge" style={{ margin: '0 0 2em' }}>
          24 hours
        </Header>
        <p>IN THE MEANTIME</p>
        <Button
          primary
          size="massive"
          onClick={() => {
            this.opennewtab(`https://www.lifeguides.com/`);
          }}
        >
          Learn More about LifeGuides
        </Button>
        <br />
        <br />
        {/* {!isTablet && (
          <Button basic onClick={this._openIntercom}>
            Contact Us
          </Button>
        )}
        {showIntercom && (
          <Intercom open appId={process.env.REACT_APP_INTERCOM_APP_ID} />
        )} */}
      </div>
    );
  }

  _renderAccepted() {
    const { step } = this.state;

    return (
      <div>
        {step === 0 && this._renderCongratulations()}
        {step === 1 && this._renderRegistrationForm()}
      </div>
    );
  }

  _renderCongratulations() {
    // const { showIntercom } = this.state;
    return (
      <div style={{ textAlign: 'center' }}>
        <Header as="h1">Congratulations, You&apos;re a LifeGuide!</Header>
        <p>You soon will be helping alleviate suffering world-wide.</p>
        <p>Thank you for joining us on this mission!</p>
        <br />
        <Icon name="hand peace outline" size="massive" color="black" />
        <br />
        <br />
        <p>There are just a few more steps before getting your first call.</p>
        <Button
          primary
          size="massive"
          onClick={() => {
            this.setState({ step: 1 });
          }}
        >
          Finish Your Profile
        </Button>
        <br />
        <br />
        {/* <div>
          <p>
            <b>Need Help?</b>
          </p>
          <p>
            Please send any feedback or bug reports{' '}
            <a onClick={this._openIntercom}>Here</a>
          </p>
        </div> */}
        <br />
        <br />
        <hr style={{ width: '50%' }} />
        <p>© LifeGuides, Inc</p>
        <br />
        <h3>Stay Up to Date !</h3>
        <div>
          <a
            href={FACEBOOK_ACCOUNT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              name="facebook f"
              size="big"
              color="grey"
              className="circular inverted"
            />
          </a>
          <a
            href={TWITTER_ACCOUNT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              name="twitter"
              size="big"
              color="grey"
              className="circular inverted"
            />
          </a>
        </div>
        {/* {showIntercom && (
          <Intercom open appId={process.env.REACT_APP_INTERCOM_APP_ID} />
        )} */}
      </div>
    );
  }

  _renderRegistrationForm() {
    const { guideApplication } = this.props.guideApplication;

    return (
      <div style={{ textAlign: 'center' }}>
        <Header as="h1">Finish Setting Up Your Account</Header>
        <p>Welcome, LifeGuide! We are happy to have you.</p>
        <div style={{ maxWidth: 480, margin: '0 auto' }}>
          <Divider />
          <Header size="medium">
            {guideApplication.user.firstName} {guideApplication.user.lastName}
            <Header.Subheader>
              {guideApplication.user.emailAddress} -{' '}
              {guideApplication.user.city}, {guideApplication.user.state}
            </Header.Subheader>
          </Header>
          <Divider />
          {this._renderError()}
          {this._renderSocialMediaLogIns()}
          <Divider horizontal>or</Divider>
          <RegisterForm onSubmit={this._onSubmitRegistration} />
        </div>
      </div>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <Message negative>
        <p>{friendlyError(error.message)}</p>
      </Message>
    );
  }

  _renderSocialMediaLogIns() {
    return (
      <div className="social-button-container">
        <div className="google">
          <Button
            basic
            className="hollow-button"
            onClick={() => this._connect('google-oauth2')}
          >
            <img src={GOOGSVG} className="google-logo" /> Sign up with Google
          </Button>
        </div>
        <div className="facebook">
          <Button
            basic
            className="hollow-button"
            onClick={() => this._connect('facebook')}
          >
            <img src={FBPNG} className="facebook-logo" />
            Sign up with Facebook
          </Button>
        </div>
      </div>
    );
  }

  _connect(connection) {
    const { auth0 } = this.props;
    const { guideApplication } = this.props.guideApplication;

    localStorage.setItem(LocalStorageKey, guideApplication.id);

    auth0.connect(connection, '/application');
  }

  _onSubmitRegistration = data => {
    const { history, registerGuide } = this.props;
    const { guideApplication } = this.props.guideApplication;

    const variables = {
      applicationId: guideApplication.id,
      timezone: moment.tz.guess(),
      ...data
    };

    this.setState({ error: null, submitting: true });
    registerGuide({ variables })
      .then(({ data: { registerGuide: { authToken } } }) => {
        this.setState({ submitting: false });

        setAuthToken(authToken).then(() => {
          history.push(`${APP_ROOT}/dashboard`);
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'GuideApplication._onSubmitRegistration',
          request: {
            ...variables
          }
        });
      });
  };
}
export default GuideApplication;
