import './index.css';
import '../create-call-request-form/call-select-form-layout.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

import ChannelsQuery from '../../../../../graphql/queries/all-channels.graphql';
import graphql from '../../../../hoc/graphql';
import MyForm, { FormField } from '../../../../ui/form';
import ChannelInfo from './info';

const DEFAULT_CHANNEL_IMAGE =
  'https://react.semantic-ui.com/images/wireframe/image.png';

@graphql(ChannelsQuery, { name: 'channels' })
class ChannelSelector extends Component {
  static propTypes = {
    channels: PropTypes.shape({
      channels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired
  };

  state = {
    selectedChannel: null
  };

  render() {
    const { channels, loading } = this.props.channels;
    const { selectedChannel } = this.state;

    let url = DEFAULT_CHANNEL_IMAGE;

    if (selectedChannel && selectedChannel.featuredImage) {
      url = selectedChannel.featuredImage.file.url;
    }

    return (
      <MyForm
        className="call-request-form-channel-selector my-form-container"
        data={{ selectedChannel }}
        onChange={this._onDataChange}
      >
        <div className="channel-selector-avatar  field-container ">
          <div
            className={`field-cell circular-avi`}
            style={{ backgroundImage: `url(${url})` }}
          />
        </div>
        <div className="guide-selector-channel field-container">
          <FormField
            loading={loading}
            disabled={loading}
            deburr
            search={!loading}
            className={`field-cell fluid`}
            component={Dropdown}
            name={`selectedChannel`}
            label={`Channel`}
            fluid
            selection
            options={this.guideChannelToGuideChannelOptions()}
          />
        </div>
        <div className="channel-selector-info field-container">
          <ChannelInfo channel={selectedChannel} />
        </div>
      </MyForm>
    );
  }

  guideChannelToGuideChannelOptions = () => {
    const { channels } = this.props.channels;

    if (!channels) {
      return [];
    }

    const publishedChannels = channels
      ? channels.filter(channel => channel.isPublished)
      : [];

    return _.map(publishedChannels, channel => {
      return {
        text: channel.title,
        value: channel,
        key: channel.id,
        image: {
          avatar: true,
          src:
            channel && channel.featuredImage
              ? channel.featuredImage.file.url
              : DEFAULT_CHANNEL_IMAGE
        }
      };
    });
  };

  _onDataChange = data => {
    const { onChange } = this.props;

    this.setState(data);

    if (_.isFunction(onChange)) {
      onChange(data);
    }
  };
}

export default ChannelSelector;
