import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

class ConfirmDeleteChannelInviteDialog extends Component {
  static propTypes = {
    channelInvite: PropTypes.shape({
      id: PropTypes.string,
      channel: PropTypes.shape({
        id: PropTypes.string,
        shortTitle: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { channelInvite, onClose, onSubmit, user } = this.props;

    return (
      <Modal open size="tiny" onClose={onClose}>
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to delete {user.firstName}&apos;s invite to
            the {channelInvite.channel.shortTitle}?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} content="Nevermind" />
          <Button primary content="Delete Channel Invite" onClick={onSubmit} />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default ConfirmDeleteChannelInviteDialog;
