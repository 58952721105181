import './dashboard-layout.css';
import './guide.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, List, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import CallEntry from '../call-entry';
import ProfileStepsProgress from './profile-steps';
import profileSteps from './profile-steps/guide-steps';

@withUser({ authenticated: true, roles: [ROLES.GUIDE] })
@graphql(GuideDashboardQuery, {
  options: props => {
    const { User } = props.user;
    return {
      fetchPolicy: 'network-only',
      variables: {
        userId: User.id
      }
    };
  }
})
@withRouter
@withWidth()
class GuideDashboard extends Component {
  static propTypes = {
    data: PropTypes.shape({
      calls: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      callRequests: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      callRequestsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      }).isRequired
    }).isRequired,
    width: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    return data.loading ? this._loader() : this._dashboard();
  }

  _loader = () => {
    return (
      <Segment
        basic
        loading={true}
        className="dashboard-loader"
        size={`massive`}
      />
    );
  };

  _dashboard = () => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);

    const classes = `guide-dashboard dashboard-layout ${
      isTablet ? 'tablet' : ''
    } dash-avenir`;

    return (
      <div className={classes}>
        <div className="above-fold">{this._renderUpcoming()}</div>

        <div className="below-fold">
          {/* {this._renderPending()} */}
          <ProfileStepsProgress
            profileSteps={profileSteps}
            user={this.props.user}
          />
        </div>
      </div>
    );
  };

  _renderPending() {
    const { callRequests, callRequestsConnection } = this.props.data;

    const callsList = callRequests || [];
    const callsAmt = callRequestsConnection.aggregate.count || 0;

    let header = (
      <span className="guide-details-header">
        Requested Calls <span className="call-count">{` (${callsAmt}) `}</span>
      </span>
    );

    let callsListHtml = (
      <div className={`calls-placeholder`}>
        <span>You Currently Have No Requested Calls</span>
      </div>
    );

    let seeMore = null;

    if (callsList && callsAmt) {
      callsListHtml = (
        <List celled className="requested-calls-wrapper" verticalAlign="middle">
          {callsList.slice(0, 2).map(this._pendingCallToHtml)}
        </List>
      );

      seeMore = callsAmt > 2 && (
        <div className="seemore">
          <Button
            primary
            onClick={this._sendToCallRequests}
            className="see-more-button"
          >
            See More
          </Button>
        </div>
      );
    }

    return (
      <div className="requested-calls">
        {header}
        {callsListHtml}
        {seeMore}
      </div>
    );
  }

  _pendingCallToHtml = callRequest => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);
    return (
      <CallEntry
        key={`call-request-${callRequest.id}`}
        callRequest={callRequest}
        isTablet={isTablet}
        scheduled={false}
      />
    );
  };

  _renderPendingOld() {
    const { width } = this.props;
    const { callRequests, callRequestsConnection } = this.props.data;

    const isTablet = isWidthDown('computer', width);

    return (
      <div className="pending-calls-container">
        {callRequests && (
          <div className="pending-calls">
            <Header as="h3" size="large">
              Call Requests{' '}
              <span className="call-count">
                ({callRequestsConnection.aggregate.count})
              </span>
            </Header>

            <List
              celled
              className="requested-calls-wrapper"
              verticalAlign="middle"
            >
              {callRequests.map(callRequest => (
                <CallEntry
                  key={`call-request-${callRequest.id}`}
                  callRequest={callRequest}
                  isTablet={isTablet}
                  onAcceptCallRequestClick={() => {
                    this.setState({ callRequest });
                  }}
                />
              ))}
            </List>
            {callRequestsConnection.aggregate.count > 2 && (
              <div
                style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
              >
                <Button
                  primary
                  onClick={this._sendToCallRequests}
                  className="see-more-button"
                >
                  See More
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  _renderUpcoming() {
    const { width } = this.props;
    const { calls } = this.props.data;

    const isTablet = isWidthDown('computer', width);
    if (!calls) {
      return null;
    }

    let callsAmt = calls.length || 0;

    let header = (
      <span className="guide-details-header">
        Upcoming Calls <span className="call-count">{` (${callsAmt}) `}</span>
      </span>
    );

    let callList = (
      <div className={`calls-placeholder`}>
        <span>You Currently Have No Upcoming Calls</span>
      </div>
    );

    let seeMore = null;

    if (callsAmt) {
      callList = (
        <List celled verticalAlign="middle">
          {calls.slice(0, 2).map(call => (
            <CallEntry
              key={`scheduled-call-${call.id}`}
              call={call}
              isTablet={isTablet}
              isBanner={true}
            />
          ))}
        </List>
      );
    }

    if (callsAmt > 2) {
      seeMore = (
        <div className="seemore">
          <Button
            primary
            onClick={this._sendToScheduledCalls}
            className="see-more-button"
          >
            See More
          </Button>
        </div>
      );
    }

    return (
      <div className="upcoming-calls">
        {header}
        {callList}
        {seeMore}
      </div>
    );
  }

  _sendToScheduledCalls = () => {
    const { history } = this.props;
    history.push('/calls#scheduled');
  };

  _sendToCallRequests = () => {
    const { history } = this.props;
    history.push('/calls#requests');
  };
}
export default GuideDashboard;
