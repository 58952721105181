import './index.css';
import './register-form.css';
import './question-container.css';
import './backboard.css';
import './billboard.css';
import './social-buttons.css';

// import Intercom from 'intercom-react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Divider,
  Header,
  Icon,
  Message,
  Step
} from 'semantic-ui-react';

import ApolloClient from '../../../apollo';
import { FBPNG, GOOGSVG } from '../../../assets';
import {
  BeckyOnPhone2,
  FemaleHandsHoldingPhone,
  HoldingHandsAtSunset3x,
  WomanSmilingPhone
} from '../../../assets';
import { setAuthToken } from '../../../auth';
import BugsnagClient from '../../../bugsnag';
import {
  ACCESS_CODE_KEY,
  APP_ROOT,
  CLIENT_INVITE_KEY,
  CONCIERGE_PHONE_NUMBER
} from '../../../consts';
import InviteClientMutation from '../../../graphql/mutations/invite-client.graphql';
import RegisterClientAuth0Mutation from '../../../graphql/mutations/register-client-auth0.graphql';
import RegisterClientMutation from '../../../graphql/mutations/register-client.graphql';
import AccessCodeByCodeQuery from '../../../graphql/queries/access-code-by-code.graphql';
import ClientInviteByIdQuery from '../../../graphql/queries/client-invite-by-id.graphql';
import ClientInvitesByEmailAddressQuery from '../../../graphql/queries/client-invites-by-email-address.graphql';
import UserQuery from '../../../graphql/queries/user.graphql';
import UserOntraportIdSubscription from '../../../graphql/subscriptions/user-ontraport-id.graphql';
import * as tracker from '../../../tracker';
import withAuth0, { Connections } from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import ErrorDialog, { friendlyError } from '../../ui/error-dialog';
import FullScreenLoader from '../../ui/fullscreen-loader';
import OnboardAppHeader from '../app-header/onboard';
import ExplainerVideo from '../home/video';
import SmsOptInForm from '../sms-opt-in-form';
import AccessCodeField from './access-code-field';
import Auth0RegistrationForm from './auth0-registration-form';
import CorporateEmailForm from './corporate-email-form';
import PasswordRegistrationForm from './password-registration-form';
import SpouseInviteForm from './spouse-invite-form';

@graphql(InviteClientMutation, {
  name: 'inviteClient'
})
@graphql(RegisterClientMutation, {
  name: 'registerClient',
  options: {
    refetchQueries: [{ query: UserQuery }],
    update: (
      store,
      {
        data: {
          registerClient: { authToken }
        }
      }
    ) => {
      if (authToken) {
        setAuthToken(authToken);
      }
    }
  }
})
@graphql(RegisterClientAuth0Mutation, {
  name: 'registerClientAuth0',
  options: {
    refetchQueries: [{ query: UserQuery }],
    update: (
      store,
      {
        data: {
          registerClientAuth0: { authToken }
        }
      }
    ) => {
      if (authToken) {
        setAuthToken(authToken);
      }
    }
  }
})
@withAuth0()
@withUser()
@withRouter
class Register extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    inviteClient: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    registerClient: PropTypes.func.isRequired,
    registerClientAuth0: PropTypes.func.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      subscribeToMore: PropTypes.func.isRequired,
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    accessCode: null,
    chatNow: false,
    clientInvite: null,
    clientInviteRevoked: false,
    corporateEmailAddress: null,
    error: null,
    errorConfirm: null,
    fetching: true,
    ontraportSuccess: false,
    registerSuccess: false,
    registering: false,
    smsSuccess: false,
    step: 0,
    submitting: false,
    userCompletedSteps: true,
    showPrivacyPolicy: false,
    circleload: false,
    videoOpen: false
  };

  _steps = [
    {
      name: 'contact-concierge',
      render: this._renderContactConcierge,
      shouldShow: () =>
        !(
          this._hasAccessCodeWithCompany() || this._hasClientInviteWithCompany()
        ),
      billboardImage: BeckyOnPhone2,
      billboardCta: null
    },
    {
      name: 'corporate-email',
      render: this._renderCorporateEmailForm,
      shouldShow: () =>
        this._hasAccessCodeWithCompany() && !this._hasClientInviteWithCompany(),
      billboardImage: BeckyOnPhone2,
      billboardCta: null
    },
    {
      name: 'registration',
      render: this._renderRegistrationForm,
      shouldShow: () =>
        this._hasAccessCodeWithCompany() || this._hasClientInviteWithCompany(),
      billboardImage: WomanSmilingPhone,
      billboardCta: 'video'
    },
    {
      name: 'sms-opt-in',
      render: this._renderSmsOptIn,
      shouldShow: () =>
        this._hasAccessCodeWithCompany() || this._hasClientInviteWithCompany(),
      billboardImage: FemaleHandsHoldingPhone,
      billboardCta: null
    },
    {
      name: 'spouse-invite',
      render: this._renderSpouseInviteForm,
      shouldShow: () =>
        (this._hasAccessCodeWithCompany() ||
          this._hasClientInviteWithCompany()) &&
        !this._isInvitedSpouse(),
      billboardImage: HoldingHandsAtSunset3x,
      billboardCta: null
    }
  ];

  constructor(props) {
    super(props);
    this._checkAuthenticated(props);
  }

  componentDidMount() {
    this._initializeSubscriptions();

    const accessCodeRequest = this._checkAccessCode();
    const clientInviteRequest = this._checkClientInvite();
    Promise.all([accessCodeRequest, clientInviteRequest])
      .then(() => {
        this.setState({ fetching: false });
      })
      .catch(error => {
        this.setState({ fetching: false, error });
      });

    setTimeout(() => {
      this.setState({
        circleload: true
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { history } = this.props;
    const { step } = this.state;

    if (prevState.step !== step) {
      // Only neccessary for Google Analytics tracking, doesn't affect state
      history.replace(`${APP_ROOT}/register/${step}`);
    }

    this._checkAuthenticated(this.props);
    this._initializeSubscriptions(this.props);
  }

  componentWillUnmount() {
    if (this._unsubscribeUserOntraportId) {
      this._unsubscribeUserOntraportId();
    }
  }

  _hasAccessCodeWithCompany() {
    const { accessCode } = this.state;
    return !!(accessCode && accessCode.company);
  }

  _hasClientInviteWithCompany() {
    const { clientInvite } = this.state;
    return !!(clientInvite && clientInvite.company);
  }

  _isInvitedSpouse() {
    const { clientInvite } = this.state;
    return !!(clientInvite && clientInvite.isSpouse);
  }

  _getAvailableSteps() {
    return this._steps.filter(step =>
      step.shouldShow ? step.shouldShow() : true
    );
  }

  _checkAuthenticated(props) {
    const { history } = this.props;
    const { User } = props.user;
    const { registering } = this.state;

    if (registering) {
      // Prevent redirect while still on steps
      return;
    }

    if (User && User.roles.length) {
      history.push(`${APP_ROOT}/`);
    }
  }

  _checkAccessCode() {
    const { location } = this.props;

    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const code =
      queryString.accessCode || localStorage.getItem(ACCESS_CODE_KEY);

    if (!code) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this._getAccessCode(code)
        .then(accessCode => {
          localStorage.setItem(ACCESS_CODE_KEY, code);
          this.setState({ accessCode }, resolve);
        })
        .catch(error => reject(error));
    });
  }

  _getAccessCode(code) {
    const variables = { code };
    return ApolloClient.query({
      query: AccessCodeByCodeQuery,
      variables
    }).then(({ data: { accessCode } }) => accessCode);
  }

  _checkClientInvite() {
    const { location } = this.props;

    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const inviteId =
      queryString.inviteId || localStorage.getItem(CLIENT_INVITE_KEY);

    if (!inviteId) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this._getClientInvite(inviteId)
        .then(clientInvite => {
          if (!clientInvite) {
            reject(new Error('Invalid invitation code'));
          } else if (clientInvite.status === 'REVOKED') {
            reject(
              new Error(
                'The person who invited you appears to have cancelled your invite.'
              )
            );
          } else if (clientInvite.status !== 'PENDING') {
            reject(new Error('Invalid invite, invitation may have expired.'));
          } else {
            localStorage.setItem(CLIENT_INVITE_KEY, inviteId);
            this.setState({ clientInvite }, resolve);
          }
        })
        .catch(error => reject(error));
    });
  }

  _getClientInvite(id) {
    const variables = { id };
    return ApolloClient.query({
      query: ClientInviteByIdQuery,
      variables
    }).then(({ data: { clientInvite } }) => clientInvite);
  }

  _getClientInvitesByEmailAddress(emailAddress) {
    const variables = { emailAddress };
    return ApolloClient.query({
      query: ClientInvitesByEmailAddressQuery,
      variables
    }).then(({ data: { clientInvites } }) => clientInvites);
  }

  _connect(connection) {
    const { auth0 } = this.props;
    const { accessCode } = this.state;

    if (accessCode) {
      // Put the code in storage before redirecting
      localStorage.setItem(ACCESS_CODE_KEY, accessCode.code);
    }

    auth0.connect(connection, '/register');
  }

  _initializeSubscriptions() {
    const { User } = this.props.user;

    if (!User) {
      return;
    }

    if (!this._unsubscribeUserOntraportId) {
      this._ontraportRequest = new Promise((resolve, reject) => {
        const callback = () => {
          resolve();
          this._onUserUpdatedOntraportId();
        };

        // If the user already has an ontraport ID they likely came through the ontraport endpoint
        // Don't wait for subscription
        if (User.ontraportId) {
          callback();
        }

        this._unsubscribeUserOntraportId = this.props.user.subscribeToMore({
          document: UserOntraportIdSubscription,
          variables: { userId: User.id },
          updateQuery: (prev, { subscriptionData }) => {
            const { User } = prev;
            const { node } = subscriptionData.data.user;

            callback();

            return {
              ...prev,
              User: {
                ...User,
                ontraportId: node.ontraportId
              }
            };
          }
        });
      });
    }
  }

  _playVideo = () => {
    this.setState({
      videoOpen: true
    });
  };

  _stopVideo = () => {
    this.setState({
      videoOpen: false
    });
  };

  _renderVideoPlayer = () => {
    return (
      <ExplainerVideo
        videoOpen={this.state.videoOpen}
        onClose={this._stopVideo}
      />
    );
  };

  _renderBillboardCTA = ctaName => {
    let noop = () => null;

    if (!ctaName) {
      return noop;
    }

    const ctas = {
      video: () => {
        let content = <Icon name="play" />;
        if (this.state.videoOpen) {
          content = <>{this._renderVideoPlayer()}</>;
        }
        return (
          <div onClick={this._playVideo} className={`billboard-cta ${ctaName}`}>
            <div className="register-video-splash-playbutton">{content}</div>
          </div>
        );
      }
    };

    return ctas[ctaName] || noop;
  };

  render() {
    const { chatNow } = this.state;

    return (
      <div className="registration screen-container">
        {this._renderContents()}
        {/* <Intercom
          open={chatNow}
          appId={process.env.REACT_APP_INTERCOM_APP_ID}
          onClose={() => {
            this.setState({
              chatNow: false
            });
          }}
        /> */}
      </div>
    );
  }

  _renderContents() {
    const { fetching } = this.state;

    if (fetching) {
      return this._renderLoading();
    }

    const steps = this._getAvailableSteps();
    if (steps.length) {
      return this._renderSteps();
    }
  }

  _renderLoading() {
    return <FullScreenLoader />;
  }

  _renderSteps() {
    const { step } = this.state;
    const steps = this._getAvailableSteps();

    const completed = step >= steps.length;
    const activeStep = completed ? steps[steps.length - 1] : steps[step];

    const billboardCTA = this._renderBillboardCTA(activeStep.billboardCta)();

    const billboard = activeStep.billboardImage ? (
      <div
        className="img-billboard"
        style={{ backgroundImage: `url(${activeStep.billboardImage})` }}
      >
        <div className="img-billboard-tint">{billboardCTA}</div>
      </div>
    ) : null;

    const questions = (
      <div className="question-container">
        {steps.length > 1 && (
          <Step.Group className="step-container" ordered unstackable>
            {steps.map((s, i) => (
              <Step
                key={`step-${s.name}`}
                aria-label={`step ${i + 1} of ${steps.length}`}
                active={step === i}
              />
            ))}
          </Step.Group>
        )}
        {this._renderAuth0Error()}
        {this._renderError()}
        {completed ? (
          <FullScreenLoader />
        ) : (
          steps.map((s, i) =>
            i === step ? (
              <React.Fragment key={s.name}>
                {s.render.apply(this)}
              </React.Fragment>
            ) : null
          )
        )}
      </div>
    );

    const trademark = (
      <div className="lg-rights-reserved">
        © 2019 LifeGuides. All Rights Reserved.
      </div>
    );

    return (
      <>
        {this._renderBackboard()}
        <div className="registration-wrapper">
          <div className="registration-header">
            <OnboardAppHeader />
          </div>
          <div className="registration-container">
            {billboard}
            <div className="form-container">
              {questions}
              {trademark}
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderBackboard = () => {
    const { circleload } = this.state;
    const loadClass = circleload ? 'loaded' : '';
    return (
      <div className="registration-backboard backboard-circles">
        <div className={`backboard-circle-1 ${loadClass}`} />
        <div className={`backboard-circle-2 ${loadClass}`} />
      </div>
    );
  };

  _renderAuth0Error() {
    const { auth0 } = this.props;

    if (!auth0.error) {
      return null;
    }

    return (
      <Message negative>
        <p>Authentication error: {auth0.error.errorDescription}</p>
      </Message>
    );
  }

  _renderError() {
    const { error, errorConfirm } = this.state;

    if (!error) {
      return null;
    }

    if (errorConfirm) {
      return (
        <ErrorDialog
          error={error}
          onClose={() => {
            errorConfirm();
          }}
        />
      );
    }

    return (
      <Message negative>
        <p>{friendlyError(error.message)}</p>
      </Message>
    );
  }

  _renderConciergeInfo() {
    return (
      <div className="concierge-info">
        Call toll free at {CONCIERGE_PHONE_NUMBER}!
      </div>
    );
  }

  _renderContactConcierge() {
    return (
      <div className="registration-step contact-concierge">
        <img
          src="/logo-color.png"
          alt="LifeGuides logo"
          style={{ width: '200px' }}
        />
        <Message>
          <span>Connect with our Wellbeing Concierge Team™ to </span>
          <span>help you set up an account.</span>
          <br />
          {this._renderConciergeInfo()}
        </Message>
      </div>
    );
  }

  _renderCorporateEmailForm() {
    const { accessCode, submitting } = this.state;

    return (
      <div className="registration-step corporate-email-step">
        {accessCode.company.logo && (
          <img
            src={accessCode.company.logo.file.url}
            alt={accessCode.company.name}
            className="company-logo"
          />
        )}
        <CorporateEmailForm
          submitting={submitting}
          onSubmit={this._onSubmitCorporateEmail}
        />
      </div>
    );
  }

  _renderRegistrationForm() {
    const { auth0 } = this.props;

    return auth0.user
      ? this._renderAuth0Registration()
      : this._renderPasswordRegistration();
  }

  _renderAuth0Registration() {
    const { user } = this.props.auth0;
    const { clientInvite, submitting } = this.state;

    if (!user) {
      return null;
    }

    const connectionName = Connections[user.identities[0].connection];

    return (
      <div
        className="registration-step credentials auth0"
        style={{ textAlign: 'left' }}
      >
        {this._renderSignupHeader()}
        <Divider />
        <Header as="h2" style={{ margin: '1em 0' }}>
          <Avatar
            src={user.picture}
            floated="left"
            style={{ width: '2em', height: '2em', marginRight: '1em' }}
          />
          {user.given_name} {user.family_name}
          <Header.Subheader>
            <Icon name="check" />
            {connectionName} Connected
          </Header.Subheader>
        </Header>
        <Divider />
        <Auth0RegistrationForm
          clientInvite={clientInvite}
          submitting={submitting}
          onSubmit={this._onSubmitAuth0Registration}
        >
          {this._renderClientInvite()}
          {this._renderAccessCodeInput()}
        </Auth0RegistrationForm>
      </div>
    );
  }

  _renderPasswordRegistration() {
    const { clientInvite, submitting, showPrivacyPolicy } = this.state;

    return (
      <div className="registration-step credentials email-password">
        {this._renderSignupHeader()}
        {this._renderSignupCopy()}
        {this._renderSocialMediaLogIns()}

        <Divider horizontal>or</Divider>

        <PasswordRegistrationForm
          clientInvite={clientInvite}
          submitting={submitting}
          onSubmit={this._onSubmitRegistration}
          showPrivacyPolicy={showPrivacyPolicy}
          onClosePrivacyPolicy={() => {
            this.setState({
              showPrivacyPolicy: false
            });
          }}
        >
          {this._renderClientInvite()}
          {this._renderAccessCodeInput()}
        </PasswordRegistrationForm>
      </div>
    );
  }

  _renderSignupHeader() {
    return (
      <div>
        <Header as="h1" className="onboard-header-txt">
          Create an Account
        </Header>
      </div>
    );
  }

  _renderSignupCopy = () => {
    return (
      <p>
        Keep your information confidential by using your personal email account.{' '}
        <a
          href="#"
          onClick={() => {
            this.setState({
              showPrivacyPolicy: true
            });
          }}
        >
          Learn more.
        </a>
      </p>
    );
  };

  _renderSocialMediaLogIns() {
    return (
      <div className="social-button-container equal width fields">
        <div className="google field">
          <Button
            basic
            className="hollow-button"
            onClick={() => this._connect('google-oauth2')}
          >
            <img src={GOOGSVG} className="google-logo" /> Sign up with Google
          </Button>
        </div>
        <div className="facebook field">
          <Button
            basic
            className="hollow-button"
            onClick={() => this._connect('facebook')}
          >
            <img src={FBPNG} className="facebook-logo" />
            Sign up with Facebook
          </Button>
        </div>
      </div>
    );
  }

  _renderClientInvite() {
    const { clientInvite } = this.state;

    if (!clientInvite) {
      return null;
    }

    const { company } = clientInvite;
    if (!company) {
      return null;
    }

    const callCredits = clientInvite.isSpouse
      ? company.spouseCallCredits
      : company.employeeCallCredits;

    if (!callCredits) {
      return null;
    }

    return (
      <Message style={{ padding: '.67857143em 1em' }} positive size="large">
        <Icon name="check" color="green" />
        {callCredits} free call credits with sign up to {company.name}
      </Message>
    );
  }

  _renderAccessCodeInput() {
    const { accessCode, accessCodeError, clientInvite } = this.state;

    if (!accessCode) {
      return null;
    }
    if (clientInvite) {
      return null;
    }

    return (
      <AccessCodeField
        accessCode={accessCode}
        error={accessCodeError}
        onSubmit={this._onAccessCodeChange}
        onTextChange={this._onAccessCodeTextChange}
      />
    );
  }

  _renderSmsOptIn() {
    return (
      <div className="registration-step sms-opt-in form-frame">
        <Header as="h1">
          Improve your experience by enabling SMS messaging!
        </Header>
        <Divider />
        <div style={{ textAlign: 'left' }}>
          <SmsOptInForm
            onCancel={this._onSmsSuccess}
            onError={error => {
              // eslint-disable-next-line no-console
              console.log('Ontraport form error: ', error);
              this._onSmsSuccess();
            }}
            onSubmit={this._onSmsSuccess}
          />
        </div>
      </div>
    );
  }

  _renderSpouseInviteForm() {
    const { submitting } = this.state;

    return (
      <div className="registration-step spouse-invite form-frame">
        <Header as="h1">Invite Your Spouse</Header>
        <p className="spouse-invite-form-info">
          Fill out your Life Partner’s information and they will unlock
          immediate access to help today!
        </p>
        <SpouseInviteForm
          submitting={submitting}
          onCancel={this._nextStep}
          onSubmit={this._onSubmitSpouseInvite}
        />
      </div>
    );
  }

  _register(data, mutation) {
    const { accessCode, clientInvite, corporateEmailAddress } = this.state;

    const variables = {
      accessCode: accessCode ? accessCode.code : null,
      clientInviteId: clientInvite ? clientInvite.id : null,
      corporateEmailAddress,
      timezone: moment.tz.guess(),
      ...data
    };

    mutation({ variables })
      .then(this._onRegistrationSuccess)
      .catch(this._onRegistrationError.bind(this, variables));
  }

  _inviteClient(variables) {
    const { inviteClient } = this.props;

    this.setState({ error: null, submitting: true });
    inviteClient({ variables })
      .then(() => {
        this.setState({ submitting: false }, this._nextStep);
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Register._inviteClient',
          request: {
            ...variables
          }
        });
      });
  }

  _onSubmitCorporateEmail = data => {
    this.setState({ error: null, submitting: true });
    this._getClientInvitesByEmailAddress(data.corporateEmailAddress).then(
      clientInvites => {
        if (clientInvites.length) {
          const [clientInvite] = clientInvites.sort((a, b) =>
            a.status === b.status ? 0 : a.status === 'PENDING' ? -1 : 1
          );
          this.setState({ clientInvite, submitting: false });
        } else {
          this.setState(
            {
              corporateEmailAddress: data.corporateEmailAddress,
              submitting: false
            },
            this._nextStep
          );
        }
      }
    );
  };

  _onSubmitAuth0Registration = data => {
    const { auth0, registerClientAuth0 } = this.props;

    this._register(
      {
        ...data,
        code: auth0.code
      },
      registerClientAuth0
    );
  };

  _onSubmitRegistration = data => {
    const { registerClient } = this.props;

    this._register(data, registerClient);
  };

  _onSubmitSpouseInvite = data => {
    const { User } = this.props.user;
    const { accessCode, clientInvite } = this.state;

    let companyId = null;
    if (accessCode && accessCode.company) {
      companyId = accessCode.company.id;
    }
    if (clientInvite && clientInvite.company) {
      companyId = clientInvite.company.id;
    }

    const variables = {
      ...data,
      companyId,
      createdById: User.id,
      isSpouse: true
    };

    this._inviteClient(variables);
  };

  _onRegistrationError(variables, error) {
    tracker.event('signUp', 0);

    this.setState({ error, submitting: false });
    BugsnagClient.notify(error, {
      context: 'Register._onRegistrationError',
      request: {
        ...variables
      }
    });
  }

  _onRegistrationSuccess = () => {
    tracker.event('signUp', 1);

    this.setState(
      {
        error: null,
        registerSucess: true,
        submitting: false
      },
      this._nextStep
    );
  };

  _onUserUpdatedOntraportId = () => {
    const { ontraportSuccess } = this.state;

    if (ontraportSuccess) {
      return;
    }

    this.setState({ ontraportSuccess: true });
  };

  _onSmsSuccess = () => {
    this.setState(
      { error: null, smsSuccess: true, submitting: false },
      this._nextStep
    );
  };

  _nextStep = () => {
    const { step } = this.state;

    const steps = this._getAvailableSteps();
    const nextIndex = step + 1;
    const userCompletedSteps = nextIndex >= steps.length;

    this.setState(
      { registering: true, step: nextIndex, userCompletedSteps },
      () => {
        if (userCompletedSteps) {
          this._completeSteps();
        }
      }
    );
  };

  _completeSteps = () => {
    const { history } = this.props;
    const { userCompletedSteps } = this.state;

    if (!userCompletedSteps) {
      return;
    }

    history.push(`${APP_ROOT}/`);
  };
}
export default Register;
