import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  Header,
  Icon,
  List,
  Message,
  Segment
} from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import AllChannelsQuery from '../../../../graphql/queries/all-channels.graphql';
import graphql from '../../../hoc/graphql';

@graphql(AllChannelsQuery, { name: 'channels' })
class UserChannelInfo extends Component {
  static propTypes = {
    channels: PropTypes.shape({
      channels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          shortTitle: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    onCreateChannelInvite: PropTypes.func.isRequired,
    onDeleteChannelInvite: PropTypes.func.isRequired,
    onDeleteChannelProfile: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      channelInvites: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          channel: PropTypes.shape({
            id: PropTypes.string,
            shortTitle: PropTypes.string
          })
        })
      ),
      channelProfiles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          channel: PropTypes.shape({
            id: PropTypes.string,
            shortTitle: PropTypes.string
          }),
          intakeAnswer: PropTypes.shape({
            id: PropTypes.string,
            answer: PropTypes.string,
            intakeQuestion: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string
            })
          }),
          subChannels: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string
            })
          )
        })
      ),
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    }).isRequired
  };

  render() {
    return (
      <>
        <Header>
          Channels
          {this._renderChannelInviteMenu()}
        </Header>
        {this._renderChannelInvites()}
        {this._renderChannelProfiles()}
      </>
    );
  }

  _renderChannelInviteMenu() {
    const { channels, loading } = this.props.channels;
    const { onCreateChannelInvite, user } = this.props;

    const isGuide = user.roles.some(role => role.name === ROLES.GUIDE);

    if (!isGuide) {
      return null;
    }

    const availableChannels = channels
      ? channels
          .filter(channel => {
            const inviteExists = !!user.channelInvites.find(
              i => i.channel.id === channel.id
            );
            const profileExists = !!user.channelProfiles.find(
              p => p.channel.id === channel.id
            );
            return !inviteExists && !profileExists && channel.isPublished;
          })
          .sort((a, b) => a.shortTitle.localeCompare(b.shortTitle))
      : [];

    return (
      <Button.Group compact size="mini" floated="right">
        <Dropdown item button pointing text="Authorize" loading={loading}>
          <Dropdown.Menu>
            {availableChannels.map(channel => (
              <Dropdown.Item
                key={`channel-${channel.id}`}
                text={channel.shortTitle}
                onClick={() => onCreateChannelInvite(channel)}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    );
  }

  _renderChannelInvites() {
    const { user } = this.props;
    return user.channelInvites.map(this._renderChannelInvite);
  }

  _renderChannelInvite = channelInvite => {
    const { onDeleteChannelInvite } = this.props;

    return (
      <Message
        info
        floating
        onDismiss={() => onDeleteChannelInvite(channelInvite)}
      >
        Authorized to join <b>{channelInvite.channel.shortTitle}</b>
      </Message>
    );
  };

  _renderChannelProfiles() {
    const { user } = this.props;
    return user.channelProfiles.map(this._renderChannelProfile);
  }

  _renderChannelProfile = channelProfile => {
    const { onDeleteChannelProfile, user } = this.props;

    const isGuide = user.roles.some(role => role.name === ROLES.GUIDE);

    return (
      <Segment basic key={`channel-profile-${channelProfile.id}`}>
        <Divider horizontal>
          {channelProfile.channel.shortTitle}
          {isGuide && (
            <>
              &nbsp;
              <Icon
                name="close"
                circular
                link
                onClick={() => onDeleteChannelProfile(channelProfile)}
              />
            </>
          )}
        </Divider>
        <Header size="medium">Intake Questions</Header>
        <List>
          {channelProfile.intakeAnswers.map(intakeAnswer => (
            <List.Item key={`intake-answer-${intakeAnswer.id}`}>
              <List.Header>
                {intakeAnswer.intakeQuestion &&
                  intakeAnswer.intakeQuestion.title}
              </List.Header>
              {intakeAnswer.answer}
            </List.Item>
          ))}
        </List>
        <Header size="medium">Sub Channels</Header>
        <List>
          {channelProfile.subChannels.map(subChannel => (
            <List.Item key={`sub-channel-${subChannel.id}`}>
              {subChannel.title}
            </List.Item>
          ))}
        </List>
      </Segment>
    );
  };
}
export default UserChannelInfo;
