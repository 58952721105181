import './dashboard-layout.css';
import '../register/billboard.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Header, Icon, List, Modal, Segment } from 'semantic-ui-react';

import { GuideVideoSplash } from '../../../assets';
import {
  APP_ROOT,
  CONCIERGE_PHONE_NUMBER,
  GUIDE_CALL_REQUEST_CREATED_KEY,
  ROLES
} from '../../../consts';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import DashboardPromotedGuideQuery from '../../../graphql/queries/dashboard-promoted-guide.graphql';
import graphql from '../../hoc/graphql';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import Avatar from '../../ui/avatar';
import CallEntry from '../call-entry/index';
import GuideDetailsVideo from '../guide-video';
import DashboardFeaturedGuideRoutes from './dashboard-featured-guide-routes';

/*Featured guide video stuff: lets extract this. */
const VWIDTH = 1024;
const VHEIGHT = 576;
const VRATIO = VHEIGHT / VWIDTH;
const VGUTTER = 40;
const HGUTTER = 80;

@withChannel()
@withUser({ authenticated: true, roles: [ROLES.CLIENT] })
@graphql(ClientDashboardQuery, {
  skip: ({ channel }) => !channel || !channel.channel,
  options: ({ channel, user }) => ({
    fetchPolicy: 'network-only',
    variables: {
      channelId: channel.channel.id,
      userId: user.User.id
    }
  })
})
@graphql(DashboardPromotedGuideQuery, {
  name: 'promotedGuides',
  skip: ({ channel }) => !channel || !channel.channel,
  options: props => {
    const { channel } = props.channel;

    return {
      variables: {
        channelId: channel ? channel.id : null
      }
    };
  }
})
@withRouter
@withWidth()
class ClientDashboard extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    data: PropTypes.shape({
      calls: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          status: PropTypes.string
        })
      ),
      callRequests: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          status: PropTypes.string
        })
      ),
      callRequestsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        id: PropTypes.string,
        callCredits: PropTypes.number
      })
    }),
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            channel: PropTypes.shape({
              name: PropTypes.string
            })
          })
        ),
        id: PropTypes.string
      }).isRequired
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    const state = {
      callRequestCreated: null,
      videoOpen: false,
      vWidth: 1024,
      vHeight: 576
    };
    const data = localStorage.getItem(GUIDE_CALL_REQUEST_CREATED_KEY);
    if (data) {
      try {
        const callRequest = JSON.parse(data);
        state.callRequestCreated = callRequest;
      } catch (error) {
        /*noop*/
      }
    }
    this.state = state;
  }

  componentDidMount() {
    this._checkChannel();
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate() {
    this._checkChannel();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  _checkChannel() {
    const { history, match } = this.props;
    const { User } = this.props.user;

    if (!match.params.channelName) {
      const [channelProfile] = User.channelProfiles;
      if (channelProfile) {
        history.replace(`${APP_ROOT}/${channelProfile.channel.slug}/dashboard`);
      } else {
        history.replace(`${APP_ROOT}/`);
      }
    }
  }

  render() {
    const { data } = this.props;

    let content = this._loader();

    if (data && !data.loading) {
      content = this._hasNoCalls()
        ? this._exploratoryDashboard()
        : this._dashboard();
    }

    return content;
  }

  _dashboard = () => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);

    const classes = `client-dashboard dashboard-layout ${
      isTablet ? 'tablet' : ''
    } dash-avenir`;

    return (
      <div className={classes}>
        <div className="above-fold">{this._renderUpcoming()}</div>
        <div className="divide-fold" />
        <div className="below-fold">{this._renderRecentlyCompleted()}</div>
        {this._renderCallRequestCreated()}
      </div>
    );
  };

  _exploratoryDashboard = () => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);

    const classes = `client-dashboard dashboard-layout ${
      isTablet ? 'tablet' : ''
    } dash-avenir`;

    return (
      <div className={classes}>
        <div className="above-fold">{this._renderGuideMatchCTA()}</div>
        <div className="divide-fold" />
        <div className="below-fold">{this._renderFeaturedGuideDetails()}</div>
      </div>
    );
  };

  _loader = () => {
    return (
      <Segment
        basic
        loading={true}
        className="dashboard-loader"
        size={`massive`}
      />
    );
  };

  _renderGuideBadges = theGuide => {
    const allBadges = this._getGuideBadges(theGuide);
    return (
      <div className="summary-badges">
        {allBadges.map(badge => {
          const { label, icon, value } = badge;
          let key = _.kebabCase(label);
          return (
            <div className="summary-badge avenir" key={`badge-${key}`}>
              <i aria-hidden="true" className={`icon ${icon}`} />
              <div className="summary-badge-info">
                <span className="summary-badge-info-label">{label}</span>
                <span className="summary-badge-info-value">{value}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  _getGuideBadges = theGuide => {
    const { channelProfiles, city, state } = theGuide;

    const toCombinedAnswers = (acc, channelProfile) => {
      return acc.concat(
        channelProfile.intakeAnswers.map(intakeAnswer => {
          return {
            icon: intakeAnswer.intakeQuestion.iconName || null,
            label: intakeAnswer.intakeQuestion.title || null,
            value: intakeAnswer.answer || null
          };
        })
      );
    };

    const location = {
      icon: 'map marker lineawesome',
      label: 'Location',
      value: state ? (city ? `${city}, ${state}` : state) : 'N/A'
    };
    return [...channelProfiles.reduce(toCombinedAnswers, []), location];
  };

  _renderGuideName = theGuide => {
    let NAME_TRUNCATION = 10;
    let firstName = theGuide.firstName.slice(0, NAME_TRUNCATION);
    let ellipsis = theGuide.firstName.length >= NAME_TRUNCATION ? '...' : '';
    let lastName = theGuide.lastName.charAt(0);
    return (
      <span className="client-dashboard-cta-guide-name">{`${firstName}${ellipsis} ${lastName}.`}</span>
    );
  };

  _getPromotedGuide = () => {
    const { promotedGuides } = this.props;

    if (promotedGuides.loading) {
      return null;
    }

    return [
      ...promotedGuides.featuredUser,
      ...promotedGuides.unfeaturedUser
    ][0];
  };

  _renderNoMatch = () => {
    return (
      <>
        <span className="client-dashboard-cta-guide-nomatch">
          {`Call the concierge to schedule your meeting:`}
          <a
            className="client-dashboard-cta-guide-nomatch-number"
            href={`tel:${CONCIERGE_PHONE_NUMBER}`}
          >
            {CONCIERGE_PHONE_NUMBER}
          </a>
        </span>
      </>
    );
  };

  _scheduleNowButton = theGuide => {
    const { channel } = this.props;
    const to = `${APP_ROOT}/${channel.channel.slug}/dashboard/schedule`;
    return (
      <>
        {' '}
        <Link to={to}>
          <Button className="client-call-schedule-button powerful">
            <span>Schedule a Call</span>
          </Button>
        </Link>
      </>
    );
  };

  _renderGuideMatchCTA = () => {
    const { promotedGuides } = this.props;

    let yourGuide = this._loader();

    if (!promotedGuides.loading) {
      let theGuide = this._getPromotedGuide();

      if (theGuide) {
        yourGuide = (
          <>
            <Avatar
              user={theGuide}
              className="client-dashboard-cta-guide-avatar"
              style={{
                height: 52,
                width: 52
              }}
            />
            {this._renderGuideName(theGuide)}
            {this._renderGuideBadges(theGuide)}
            {this._scheduleNowButton(theGuide)}
            <DashboardFeaturedGuideRoutes guide={theGuide} />
          </>
        );
      } else {
        yourGuide = this._renderNoMatch();
      }
    }

    return (
      <div className="client-dashboard-cta">
        <div className="client-dashboard-cta-message">
          <span className="client-dashboard-cta-message-title">
            We found a match for you!
          </span>
          <span className="client-dashboard-cta-message-subtitle">
            Schedule a meeting to get the support you need with your Life
            Challenge<sup>™</sup>
          </span>
        </div>
        <div className="client-dashboard-cta-guide">{yourGuide}</div>
      </div>
    );
  };

  /* ↓ VIDEO IN UNSCHEDULED STATE ↓ */
  _playVideo = () => {
    this.setState({
      videoOpen: true
    });
  };

  _stopVideo = () => {
    this.setState({
      videoOpen: false
    });
  };
  _renderVideoPlayer = () => {
    const { vWidth, vHeight } = this.state;
    const theGuide = this._getPromotedGuide();
    return (
      <Modal
        open={this.state.videoOpen}
        onClose={this._stopVideo}
        className="video-modal"
        style={{
          width: vWidth,
          height: vHeight
        }}
        closeIcon={this.state.isMobile}
      >
        <GuideDetailsVideo guide={theGuide} />
      </Modal>
    );
  };
  _renderVideoBillboardCTA = () => {
    let content = <Icon name="play" />;
    if (this.state.videoOpen) {
      content = <>{this._renderVideoPlayer()}</>;
    }
    return (
      <div onClick={this._playVideo} className={`billboard-cta guide-details`}>
        <div className="register-video-splash-playbutton">{content}</div>
      </div>
    );
  };

  _renderTheFeaturedVideo = theGuide => {
    if (!theGuide || !theGuide.videoUrl) {
      return null;
    }

    const billboard = (
      <div
        className="img-billboard"
        style={{ backgroundImage: `url(${GuideVideoSplash})` }}
      >
        {this._renderVideoBillboardCTA()}
      </div>
    );

    return (
      <div className="guide-details-video">
        <span className="guide-details-header">Watch My Story</span>
        <div className="guide-details-video-frame">{billboard}</div>
      </div>
    );
  };
  /* ↑ VIDEO IN UNSCHEDULED STATE ↑ */

  /* ↓ ABOUT SECTION IN UNSCHEDULED STATE ↓ */
  _renderAbout = theGuide => {
    if (!theGuide || !theGuide.bio) {
      return null;
    }
    return (
      <div className="guide-details-about">
        <span className="guide-details-header">{`About ${theGuide.firstName}`}</span>
        {this._renderExperience(theGuide)}
        <div className="guide-details-about-bio">{theGuide.bio}</div>
      </div>
    );
  };

  _renderSubject = subject => {
    return (
      <span
        className="expert-subject-li"
        key={`subject-${_.kebabCase(subject.title)}`}
      >
        <span className="expert-subject-li-title">{subject.title}</span>
      </span>
    );
  };

  _renderExperience = theGuide => {
    if (!theGuide || !theGuide) {
      return null;
    }
    const { channelProfiles } = theGuide;
    const toCombinedSubjects = (acc, channelProfile) =>
      acc.concat(channelProfile.subChannels);
    return (
      <div className="guide-subjects">
        <div className="subjects-title">Experience in: </div>
        <div className="expert-subjects">
          {channelProfiles
            .reduce(toCombinedSubjects, [])
            .slice(0, 3)
            .map(this._renderSubject)}
        </div>
      </div>
    );
  };
  /* ↑ ABOUT SECTION IN UNSCHEDULED STATE ↑ */

  _renderFeaturedGuideDetails() {
    const theGuide = this._getPromotedGuide();
    return (
      <div className="client-dashboard-guide-details">
        {this._renderTheFeaturedVideo(theGuide)}

        {this._renderAbout(theGuide)}
      </div>
    );
  }

  _renderRecentlyCompleted() {
    const { calls } = this.props.data;

    const callsList = calls.filter(call => call.status === 'COMPLETED');
    const callsAmt = (callsList && callsList.length) || 0;

    let header = null;
    let callsListHtml = null;
    let seeMore = null;
    let content = null;

    if (callsList && callsAmt) {
      header = (
        <Header as="h3" size="large">
          Completed Calls
          <span className="call-count"> ({callsAmt})</span>
        </Header>
      );

      callsListHtml = (
        <List celled className="requested-calls-wrapper" verticalAlign="middle">
          {callsList.slice(0, 2).map(this._completedCallToHtml)}
        </List>
      );

      seeMore = callsAmt > 2 && (
        <div className="seemore">
          <Button
            primary
            onClick={this._sendToCompletedCalls}
            className="see-more-button"
          >
            See More
          </Button>
        </div>
      );

      content = (
        <div className="completed-calls">
          {header}
          {callsListHtml}
          {seeMore}
        </div>
      );
    }

    return content;
  }

  _completedCallToHtml = call => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);
    return (
      <CallEntry
        key={`call-${call.id}`}
        call={call}
        isTablet={isTablet}
        cancelled={true}
        scheduled={false}
      />
    );
  };

  _renderUpcoming() {
    const { calls, callRequests } = this.props.data;
    if (!calls && !callRequests) {
      return null;
    }

    const scheduledCalls = calls.filter(call =>
      ['SCHEDULED', 'STARTED'].includes(call.status)
    );

    if (!scheduledCalls.length && !callRequests.length) {
      return this._renderBookAgain();
    }

    return this._renderUpcomingCalls();
  }

  _renderBookAgain = () => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);
    const { calls, callRequests } = this.props.data;
    const [completedCall] = calls.filter(call => call.status === 'COMPLETED');

    if (!calls && !callRequests) {
      return null;
    }

    if (!calls.length && !callRequests.length) {
      return null;
    }

    let header = (
      <Header as="h3" size="large">
        Book Again
      </Header>
    );

    let bookAgainCall = (
      <div className={`calls-placeholder`}>
        <span>There is nothing to book again</span>
      </div>
    );

    if (completedCall) {
      bookAgainCall = (
        <List celled verticalAlign="middle">
          <CallEntry
            call={completedCall}
            callRequest={completedCall.callRequest}
            isTablet={isTablet}
            isBanner={true}
          />
        </List>
      );
    }

    return (
      <div className="upcoming-calls">
        {header}
        {bookAgainCall}
      </div>
    );
  };

  _hasNoCalls = () => {
    const { calls, callRequests } = this.props.data;
    //console.log(calls, callRequests);
    return (!calls && !callRequests) || (!calls.length && !callRequests.length);
  };

  _renderUpcomingCalls = () => {
    const { width } = this.props;
    const isTablet = isWidthDown('computer', width);
    const { calls, callRequests, callRequestsConnection } = this.props.data;

    if (this._hasNoCalls()) {
      return null;
    }

    const scheduledCalls = calls.filter(call =>
      ['SCHEDULED', 'STARTED'].includes(call.status)
    );

    const callsAmt =
      scheduledCalls.length + callRequestsConnection.aggregate.count || 0;
    const allUpcomingCalls = [...scheduledCalls, ...callRequests].slice(0, 2);

    let header = (
      <span className="guide-details-header">
        Upcoming Calls <span className="call-count">{` (${callsAmt}) `}</span>
      </span>
    );

    let callList = (
      <div className={`calls-placeholder`}>
        <span>You Currently Have No Upcoming Calls</span>
      </div>
    );

    let seeMore = null;

    if (callsAmt) {
      callList = (
        <List celled verticalAlign="middle">
          {allUpcomingCalls.map(call => (
            <CallEntry
              key={`upcoming-call-${call.id}`}
              call={call.status != 'PENDING' ? call : null}
              callRequest={call.status == 'PENDING' ? call : null}
              isTablet={isTablet}
              isBanner={true}
              scheduled={call.status == 'SCHEDULED'}
            />
          ))}
        </List>
      );
    }

    if (callsAmt > 2) {
      seeMore = (
        <div className="seemore">
          <Button
            primary
            onClick={this._sendToScheduledCalls}
            className="see-more-button"
          >
            See More
          </Button>
        </div>
      );
    }

    return (
      <div className="upcoming-calls">
        {header}
        {callList}
        {seeMore}
      </div>
    );
  };

  _sendToScheduledCalls = () => {
    const { history } = this.props;
    const { channel } = this.props.channel;
    history.push(`${APP_ROOT}/${channel.slug}/calls#scheduled`);
  };

  _sendToCompletedCalls = () => {
    const { history } = this.props;
    const { channel } = this.props.channel;
    history.push(`${APP_ROOT}/${channel.slug}/calls#history`);
  };

  _renderCallRequestCreated() {
    const { callRequestCreated } = this.state;

    if (!callRequestCreated) {
      return null;
    }

    const onClose = () => {
      this.setState({ callRequestCreated: null });
      localStorage.removeItem(GUIDE_CALL_REQUEST_CREATED_KEY);
    };

    return (
      <Modal open size="tiny" onClose={onClose}>
        <Modal.Content style={{ textAlign: 'center' }}>
          <Modal.Description>
            <Header size="huge">Your call has been requested!</Header>
            <Avatar user={callRequestCreated.to} style={{ margin: '0 .5em' }} />
            <p style={{ fontSize: '1.2em' }}>
              <b>{callRequestCreated.to.firstName}</b> will select one of the
              times you requested that works for them soon.
            </p>
            <Button primary fluid size="huge" onClick={onClose}>
              Sounds Great!
            </Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  /*Video Size update*/
  updateDimensions = () => {
    const { innerWidth, innerHeight } = window;
    let maxWidth = Math.round(VWIDTH - VGUTTER);

    if (innerWidth > maxWidth) {
      if (this.state.vWidth != maxWidth) {
        this.setState({
          vWidth: maxWidth,
          vHeight: Math.floor(maxWidth * VRATIO),
          isMobile: false
        });
      }
    } else {
      let smallerWidth = Math.round(innerWidth - VGUTTER);
      let hGutter = 0;
      if (innerWidth > innerHeight) {
        hGutter = HGUTTER;
        smallerWidth = smallerWidth - hGutter;
      }
      if (this.state.vWidth != smallerWidth) {
        this.setState({
          vWidth: smallerWidth,
          vHeight: Math.floor(smallerWidth * VRATIO),
          isMobile: true
        });
      }
    }
  };
}
export default ClientDashboard;
