import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { APP_ROOT } from '../../../consts';

@withRouter
class ConciergeDashboard extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { history } = this.props;
    history.push(`${APP_ROOT}/concierge/call-scheduling`);
  }

  render() {
    return <div />;
  }
}
export default ConciergeDashboard;
