import isEmpty from 'lodash/isEmpty';
import toPairs from 'lodash/toPairs';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, Dropdown, Form, Header, Input } from 'semantic-ui-react';
import { US_STATES_AND_TERRITORIES } from 'us-regions';

import withAuth0 from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';
import MyForm, { FormField } from '../../ui/form';
import PrivacyPolicy from '../../ui/privacy-policy';
import TermsAndConditions from '../../ui/terms-and-conditions';

const DATE_FORMAT = moment.HTML5_FMT.DATE;

@withAuth0()
@withUser()
class PhoneForm extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    children: PropTypes.node,
    clientInvite: PropTypes.shape({
      user: PropTypes.shape({
        phoneNumber: PropTypes.string
      })
    }),
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        emailAddress: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    }).isRequired
  };

  state = {
    data: {
      address: '',
      agreedToTermsAndConditions: false,
      city: '',
      emailAddress: '',
      phoneNumber: '',
      state: '',
      zipcode: ''
    },
    isValid: false,
    showPrivacyPolicy: false,
    showTermsAndConditions: false
  };

  constructor(props) {
    super(props);

    const data = this._userData(props);
    if (data) {
      this.state.data = this._mergeUserData(data);
    }
  }

  componentDidUpdate(prevProps) {
    const userChanged =
      (!prevProps.user.User && this.props.user.User) ||
      prevProps.user.User !== this.props.user.User;
    if (!userChanged) {
      return;
    }

    const data = this._userData(this.props, prevProps);
    if (data) {
      this.setState({
        data: this._mergeUserData(data)
      });
    }
  }

  _userData(props) {
    const { clientInvite } = props;
    if (clientInvite && clientInvite.user) {
      return {
        address: clientInvite.user.address,
        city: clientInvite.user.city,
        phoneNumber: clientInvite.user.phoneNumber,
        state: clientInvite.user.state,
        zipcode: clientInvite.user.zipcode
      };
    }

    const { User } = props.user;
    if (User) {
      return {
        address: clientInvite.user.address,
        city: clientInvite.user.city,
        emailAddress: User.emailAddress,
        phoneNumber: User.phoneNumber,
        state: clientInvite.user.state,
        zipcode: clientInvite.user.zipcode
      };
    }

    return {};
  }

  _mergeUserData(userData) {
    const { data } = this.state;

    return {
      ...data,
      address: userData.address || data.address,
      city: userData.city || data.city,
      emailAddress: userData.emailAddress || data.emailAddress,
      phoneNumber: userData.phoneNumber || data.phoneNumber,
      state: userData.state || data.state,
      zipcode: userData.zipcode || data.zipcode
    };
  }

  _isValidZipCode = zipcode => {
    let zipValidator = new RegExp('^[0-9a-zA-Z ]{5,8}$', 'gimu');

    return zipValidator.test(zipcode.zipcode);
  };

  render() {
    const { auth0, children, submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <MyForm
        data={data}
        onChange={data => {
          this.setState({ data });
        }}
        onValidate={errors => {
          this.setState({ isValid: isEmpty(errors) });
        }}
      >
        {auth0.user &&
          (!auth0.user.email && (
            <div>
              <Header>What is your e-mail address?</Header>
              <FormField
                component={Input}
                name="emailAddress"
                aria-label="Email"
                aria-required="true"
                type="email"
                label="Personal Email *"
                validator={({ emailAddress }) => {
                  if (!emailAddress) {
                    throw new Error('Must enter an e-mail address');
                  }
                }}
              />
            </div>
          ))}
        <Header>What is the best phone number to reach you at?</Header>
        <FormField
          component={Input}
          name="phoneNumber"
          type="tel"
          label="Personal Mobile Number *"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Must enter a mobile number');
            }
            let digits = phoneNumber.replace(/\D/g, '');
            if (
              !(
                (digits[0] === '1' && digits.length === 11) ||
                digits.length === 10
              )
            ) {
              throw new Error('Mobile number must have 10 digits');
            }
          }}
        />
        <FormField
          component={Input}
          type="text"
          name="address"
          label="Address"
          aria-placeholder="Address"
        />
        <Form.Group widths="equal" aria-placeholder="Enter your city and state">
          <FormField
            component={Input}
            name="city"
            label="City"
            aria-placeholder="City"
          />
          <FormField
            component={Dropdown}
            name="state"
            label="State"
            aria-placeholder="US States and Terrotories"
            fluid
            selection
            search
            options={toPairs(US_STATES_AND_TERRITORIES).map(
              ([abbreviation, name]) => ({
                text: name,
                value: abbreviation
              })
            )}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="zipcode"
          type="text"
          maxLength="8"
          label="Zipcode"
          aria-placeholder="Zipcode"
          validator={zipcode => {
            if (zipcode.zipcode && !this._isValidZipCode(zipcode)) {
              throw new Error(
                'Zipcode formats currently accepted are USA (00000) and Canada (000 000).'
              );
            }
          }}
        />
        {children}
        <FormField
          component={Checkbox}
          name="agreedToTermsAndConditions"
          style={{ paddingTop: '20px' }}
          label={
            <label>
              I am 18 (or older) and agree to the{' '}
              <a
                href="#"
                onClick={() => {
                  this.setState({
                    showTermsAndConditions: true
                  });
                }}
              >
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href="#"
                onClick={() => {
                  this.setState({
                    showPrivacyPolicy: true
                  });
                }}
              >
                Privacy Policy
              </a>
            </label>
          }
          validator={({ agreedToTermsAndConditions }) => {
            if (!agreedToTermsAndConditions) {
              throw new Error('Must agree to terms and conditions');
            }
          }}
        />
        <Form.Button
          primary
          fluid
          size="huge"
          loading={submitting}
          disabled={!isValid || submitting}
          onClick={this._onSubmit}
        >
          Sign Up
        </Form.Button>
        <PrivacyPolicy
          visible={this.state.showPrivacyPolicy}
          onClose={() => {
            this.setState({
              showPrivacyPolicy: false
            });
          }}
        />
        <TermsAndConditions
          visible={this.state.showTermsAndConditions}
          onClose={() => {
            this.setState({
              showTermsAndConditions: false
            });
          }}
        />
      </MyForm>
    );
  }

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const formatted = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    onSubmit(formatted);
  };
}
export default PhoneForm;
