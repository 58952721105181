import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router-dom';

import { APP_ROOT } from '../../consts';
import AccessCodes from '../container/admin/access-codes';
import GuideApplications from '../container/admin/guide-applications';
import AdminReporting from '../container/admin/reporting';
import AdminUser from '../container/admin/user';
import UserImport from '../container/admin/user-import';
import UserImports from '../container/admin/user-imports';
import UserImportUpload from '../container/admin/user-imports/upload';
import AdminUsers from '../container/admin/users';
import BioclinicaLanding from '../container/bioclinica-landing';
import Call from '../container/call';
import CallTabs from '../container/call-tabs';
import ConciergeCalls from '../container/concierge/calls';
import ConciergeCrisisProtocol from '../container/concierge/crisis-protocol';
import CorpAdminUsers from '../container/corp-admin/users';
import Dashboard from '../container/dashboard';
import GuideApplication from '../container/guide-application';
import GuideAvailability from '../container/guide-availability';
import Guides from '../container/guides';
import Home from '../container/home';
import Login from '../container/login';
import Logout from '../container/logout';
import Onboard from '../container/onboard';
import Register from '../container/register';
import Resources from '../container/resources';
import Settings from '../container/settings';
import PaymentOptions from '../container/settings/payment-options';
import Profile from '../container/settings/profile';
import ContactUs from '../ui/contact-us/index';
import NotAuthorizedPage from '../ui/error-pages/401';
import NotFoundPage from '../ui/error-pages/404';
import ServerErrorPage from '../ui/error-pages/500';
import BetaPage from '../ui/error-pages/beta';

@withRouter
class Routes extends Component {
  render() {
    return (
      <div className={`routes ${this._getClassNames()}`}>
        <Switch>
          <Route path={`${APP_ROOT}/`} exact component={Home} />
          {/* Unauthenticated routes */}
          <Route
            path={`${APP_ROOT}/application`}
            exact
            component={GuideApplication}
          />
          <Route
            path={`${APP_ROOT}/application/:id`}
            exact
            component={GuideApplication}
          />
          <Route
            path={`${APP_ROOT}/bioclinica`}
            component={BioclinicaLanding}
          />
          {/* <Route path={`${APP_ROOT}/contact`} component={ContactUs} /> */}
          <Route path={`${APP_ROOT}/login`} component={Login} />
          <Route path={`${APP_ROOT}/register`} component={Register} />
          <Route path={`${APP_ROOT}/:channelName/guides`} component={Guides} />
          <Route
            path={`${APP_ROOT}/:channelName/onboard/client`}
            render={({ location }) => (
              <Redirect to={`${APP_ROOT}/register${location.search}`} />
            )}
          />
          <Route
            path={`${APP_ROOT}/:channelName/onboard/guide`}
            exact
            component={Onboard}
          />
          <Route
            path={`${APP_ROOT}/:channelName/onboard/guide/:step`}
            exact
            component={Onboard}
          />
          {/* Concierge routes */}
          <Route
            path={`${APP_ROOT}/concierge/call-scheduling`}
            component={ConciergeCalls}
          />
          <Route
            path={`${APP_ROOT}/concierge/crisis-protocol`}
            component={ConciergeCrisisProtocol}
          />
          {/* Authenticated routes */}
          <Route
            path={`${APP_ROOT}/guide-availability`}
            component={GuideAvailability}
          />
          <Route path={`${APP_ROOT}/dashboard`} component={Dashboard} />
          <Route path={`${APP_ROOT}/calls`} component={CallTabs} />
          <Route path={`${APP_ROOT}/call/:id`} component={Call} />
          <Route path={`${APP_ROOT}/logout`} component={Logout} />
          <Route path={`${APP_ROOT}/settings`} exact component={Settings} />
          <Route
            path={`${APP_ROOT}/settings/payment-options`}
            exact
            component={PaymentOptions}
          />
          <Route
            path={`${APP_ROOT}/settings/profile`}
            exact
            component={Profile}
          />
          <Route path={`${APP_ROOT}/:channelName/calls`} component={CallTabs} />
          <Route
            path={`${APP_ROOT}/:channelName/dashboard`}
            component={Dashboard}
          />
          <Route
            path={`${APP_ROOT}/:channelName/resources`}
            component={Resources}
          />
          {/* Admin routes */}
          <Route
            path={`${APP_ROOT}/admin/reporting`}
            component={AdminReporting}
          />
          <Route
            path={`${APP_ROOT}/admin/access-codes`}
            component={AccessCodes}
          />
          <Route
            path={`${APP_ROOT}/admin/guide-applications`}
            component={GuideApplications}
          />
          <Route
            path={`${APP_ROOT}/admin/users`}
            exact
            component={AdminUsers}
          />
          <Route path={`${APP_ROOT}/admin/user/:id`} component={AdminUser} />
          <Route
            path={`${APP_ROOT}/admin/users/imports`}
            exact
            component={UserImports}
          />
          <Route
            path={`${APP_ROOT}/admin/users/imports/upload`}
            exact
            component={UserImportUpload}
          />
          <Route
            path={`${APP_ROOT}/admin/users/import/:id`}
            component={UserImport}
          />
          {/* Corp admin routes */}
          <Route
            path={`${APP_ROOT}/corp-admin/users`}
            exact
            component={CorpAdminUsers}
          />
          {/* Beta features */}
          <Route
            path={`${APP_ROOT}/bank`}
            render={() => (
              <BetaPage
                title={'Earnings Reports'}
                icon="usd lineawesome"
                content={
                  'We look forward to allowing users to use this feature in the near future!'
                }
              />
            )}
          />
          <Route
            path={`${APP_ROOT}/training`}
            render={() => (
              <BetaPage
                title={'Guide Training'}
                icon="graduation cap lineawesome"
                content={
                  <>
                    Access the guide
                    <a href={`https://lg-guides.slack.com`}> slack channel </a>
                    for help.
                  </>
                }
              />
            )}
          />
          {/* Error Routes */}
          <Route path={`${APP_ROOT}/401`} component={NotAuthorizedPage} />
          <Route path={`${APP_ROOT}/500`} component={ServerErrorPage} />
          <Route path={`${APP_ROOT}/404`} component={NotFoundPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }

  _getClassNames = () => {
    if (location.pathname === '/') {
      return 'portal';
    }

    let kebabed = _.kebabCase(location.pathname);
    let broken = location.pathname.split('/');
    let names = _.uniq([kebabed, ...broken])
      .join(' ')
      .trim();

    return names;
  };
}
export default Routes;
