import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Button, Confirm } from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import { ROLES } from '../../../consts';
import UpdateCallRequestStatusMutation from '../../../graphql/mutations/update-call-request-status.graphql';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import UserSentCallRequestsQuery from '../../../graphql/queries/user-sent-call-requests.graphql';
import * as tracker from '../../../tracker';
import withUser from '../../hoc/with-user';

@withUser({ authenticated: true })
@graphql(UpdateCallRequestStatusMutation, {
  name: 'updateCallRequestStatus',
  options: props => {
    const { callRequest } = props;
    const { User } = props.user;

    const isClient = User.roles.some(role => role.name === ROLES.CLIENT);

    const variables = { channelId: callRequest.channel.id, userId: User.id };

    const refetchQueries = [];
    if (isClient) {
      refetchQueries.push({
        query: ClientDashboardQuery,
        variables
      });
    }

    return {
      refetchQueries,
      update: (
        store,
        {
          data: {
            updateCallRequest: { id }
          }
        }
      ) => {
        let data = null;
        try {
          data = store.readQuery({
            query: UserSentCallRequestsQuery,
            variables
          });
        } catch (error) {
          /* no-op */
        }

        if (!data) {
          return;
        }

        const { callRequests } = data;
        const index = callRequests.findIndex(
          callRequest => callRequest.id === id
        );
        if (index >= 0) {
          callRequests.splice(index, 1);
        }

        store.writeQuery({
          query: UserSentCallRequestsQuery,
          variables,
          data
        });
      }
    };
  }
})
class CancelCallRequestAction extends Component {
  static propTypes = {
    //args
    callRequest: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string
      }),
      from: PropTypes.shape({
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            intakeAnswers: PropTypes.arrayOf(
              PropTypes.shape({
                answer: PropTypes.string,
                intakeQuestion: PropTypes.shape({
                  iconName: PropTypes.string,
                  title: PropTypes.string
                })
              })
            ),
            subChannels: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string
              })
            )
          })
        )
      })
    }).isRequired,

    //graphql
    updateCallRequestStatus: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,

    //withUser
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    error: null,
    submitting: false,
    success: false,
    successMessage: null
  };

  _renderCancelPendingCall() {
    const { open, onClose } = this.props;
    const { submitting } = this.state;

    if (!open) {
      return null;
    }

    let message = `Are you sure you want to cancel this scheduled call?`;

    let cancelCta = <Button disabled={submitting}>Nevermind</Button>;
    let confirmCta = (
      <Button disabled={submitting} loading={submitting}>
        Yes, please cancel
      </Button>
    );

    if (this.state.error) {
      message = `Cancellation Error: ${this.state.error.toString()}`;
      confirmCta = null;
      cancelCta = <Button disabled={submitting}>OK</Button>;
    }

    return (
      <Confirm
        open
        content={message}
        cancelButton={cancelCta}
        confirmButton={confirmCta}
        onCancel={onClose}
        onConfirm={this._onConfirmCancelRequestedCall}
      />
    );
  }

  _onConfirmCancelRequestedCall = () => {
    const { callRequest, updateCallRequestStatus } = this.props;

    const variables = {
      id: callRequest.id,
      status: 'CANCELLED'
    };

    this.setState({ error: null, submitting: true });
    updateCallRequestStatus({ variables }, { partialRefetch: true })
      .then(() => {
        tracker.event('cancelCall', 1);

        this.setState({ submitting: false });
      })
      .catch(error => {
        tracker.event('cancelCall', 0);

        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'CancelCallRequestAction._onConfirmCancelRequestedCall',
          request: {
            ...variables
          }
        });
      });
  };

  render() {
    return (
      <div className="cancelcall-modal">{this._renderCancelPendingCall()}</div>
    );
  }
}
export default CancelCallRequestAction;
