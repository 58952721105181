import './option-select.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox, List, Radio } from 'semantic-ui-react';

export default class OptionSelect extends Component {
  static propTypes = {
    options: PropTypes.array,
    limit: PropTypes.number,
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    const { multiple } = props;

    this.state = {
      selected: multiple ? [] : null
    };
  }

  render() {
    const { options } = this.props;

    return (
      <div className="option-select">
        <List className="options-list">{options.map(this._renderOption)}</List>
      </div>
    );
  }

  _renderOption = option => {
    const { label, value } = option;
    const { multiple, onChange } = this.props;
    const { selected } = this.state;

    const isSelected = multiple
      ? selected && !!selected.find(s => s.value === option.value)
      : selected
      ? selected.value === option.value
      : false;
    const Component = multiple ? Checkbox : Radio;

    return (
      <List.Item className="option" key={`record-${value}`}>
        <Component
          checked={isSelected}
          label={label}
          aria-label={label}
          id={value}
          onChange={() => {
            const selected = multiple ? this._toggleSelected(option) : option;
            this.setState({
              selected
            });
            onChange(selected);
          }}
        />
      </List.Item>
    );
  };

  _toggleSelected(record) {
    const { limit } = this.props;
    const { selected } = this.state;

    const index = selected.findIndex(r => r.value === record.value);
    if (index >= 0) {
      selected.splice(index, 1);
    } else {
      if (limit && selected.length === limit) {
        selected.shift();
      }
      selected.push(record);
    }
    return selected;
  }
}
