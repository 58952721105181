import './summary.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import Avatar from '../../ui/avatar';
import FormattedName from '../../ui/formatted-name';

const SEARCH_DEFAULTS = {
  page: 1,
  searchTerm: '',
  itemsPerPage: 6,
  searchSpeed: 500
};

function parseQS(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    searchTerm: search.searchTerm || SEARCH_DEFAULTS.searchTerm,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page
  };
}

class GuideAboutSummaryBadge extends Component {
  static propTypes = {
    icon: PropTypes.string,
    label: PropTypes.node,
    value: PropTypes.node
  };

  render() {
    const { icon, label, value } = this.props;

    return (
      <div className="summary-badge avenir">
        <Icon
          name={icon.replace('lineawesome', '').trim()}
          className="lineawesome"
        />
        <div className="summary-badge-info">
          <span className="summary-badge-info-label">{label}</span>
          <span className="summary-badge-info-value">{value}</span>
        </div>
      </div>
    );
  }
}
@withRouter
class GuideAboutSummary extends Component {
  static propTypes = {
    guide: PropTypes.object.isRequired
  };

  render() {
    const { guide, compressed } = this.props;

    let compressedClass = '';
    if (compressed) {
      compressedClass = 'is-compressed';
    }

    const { firstName, lastName } = guide;

    return (
      <div className={`guide-summary ${compressedClass}`}>
        <Avatar
          user={guide}
          className="summary-avatar"
          isFeatured={guide.isFeatured}
        />

        <div className="summary-information">
          <div className="summary-readout">
            <div className="summary-main">
              <div className="summary-guidename avenir bold">
                <FormattedName firstName={firstName} lastName={lastName} />
              </div>
            </div>

            <div className="summary-secondary">{this._renderCtas()}</div>
          </div>

          <div className="summary-badges">{this._renderBadges()}</div>
        </div>
      </div>
    );
  }

  _getBadges = () => {
    const { channelProfiles, city, state } = this.props.guide;

    return channelProfiles
      .reduce((acc, channelProfile) => {
        return acc.concat(
          channelProfile.intakeAnswers.map(intakeAnswer => {
            return {
              icon: intakeAnswer.intakeQuestion.iconName || null,
              label: intakeAnswer.intakeQuestion.title || null,
              value: intakeAnswer.answer || null
            };
          })
        );
      }, [])
      .concat([
        {
          icon: 'map marker',
          label: 'Location',
          value: state ? (city ? `${city}, ${state}` : state) : 'N/A'
        }
      ]);
  };

  _renderBadges = () => {
    const badges = this._getBadges().map(badge => {
      return (
        <GuideAboutSummaryBadge
          key={`guide-about-summary-badge-${badge.label}`}
          {...badge}
        />
      );
    });

    return badges;
  };

  _renderCtas = () => {
    const { guide, compressed, onClickVideo } = this.props;

    let onClick = this._navTo('video', guide.id);

    if (_.isFunction(onClickVideo)) {
      onClick = onClickVideo; //overrid handler if a better one is supplied
    }

    let video = guide.videoUrl ? (
      <Button onClick={onClick}>
        <Icon
          className="summary-ctas-button-icon lineawesome"
          name="play circle outline"
        />
        <span className="summary-ctas-button-text">Watch their story</span>
      </Button>
    ) : null;

    let compressedCTA = <div className="summary-ctas avenir">{video}</div>;
    let expandedCTA = (
      <div className="summary-ctas avenir">
        {video}
        <Button primary onClick={this._navTo('schedule', guide.id)}>
          Schedule Call
        </Button>
      </div>
    );

    let ctas = compressed ? compressedCTA : expandedCTA;

    return ctas;
  };

  /* Nav */
  _navTo = (subroute, guideId) => () => {
    const { history, location, match } = this.props;

    let _route = match.path.replace(/\/?:id\/.*/, '');
    let { page, searchTerm } = parseQS(location.search);
    const newParams = qs.stringify({
      page,
      searchTerm
    });

    if (subroute && guideId) {
      _route = `${_route}/${guideId}/${subroute}`;
    }

    _route = `${_route}?${newParams}`;
    history.push(_route);
  };
}

export default GuideAboutSummary;
