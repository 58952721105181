import 'react-big-scheduler/lib/css/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import '../create-call-request-form/call-select-form-layout.css';

import _ from 'lodash';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler';

import CallRequestsByGuideIdsQuery from '../../../../../graphql/queries/call-requests-by-guide-ids.graphql';
import GuidesByChannelIdQuery from '../../../../../graphql/queries/guides-by-channel-id.graphql';
import graphql from '../../../../hoc/graphql';
import withDndContext from '../../../../hoc/with-dnd-context';
import BookingResourceItem from './booking-resource-item';
import BookingTimezoneSelector from './booking-timezone-selector';
import statusToColor from './status-color-map';

const moment = extendMoment(Moment);

const DATE_FORMAT = moment.HTML5_FMT.DATE;
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const ROW_HEIGHT = 50;
const CALL_DURATION_IN_MINUTES = 45;
const CALL_SLOTS_STEP = 15;

function nextWeekDay(d) {
  return (d + 1) % 7;
}

function dayRange(start, end) {
  const days = [];
  let i = start;
  do {
    days.push(i);
    i = nextWeekDay(i);
  } while (i != nextWeekDay(end));
  return days;
}

function mergeDateAndTime(date, time) {
  const d = moment(date);
  const t = moment(time);
  return d
    .hours(t.hours())
    .minutes(t.minutes())
    .toDate();
}

@graphql(GuidesByChannelIdQuery, {
  name: 'guides',
  options: ({ selectedChannel }) => ({
    variables: { channelId: selectedChannel.id }
  })
})
//make this NOT re-query when the id's are exactly the same as last time?
@graphql(CallRequestsByGuideIdsQuery, {
  name: 'callRequests',
  skip: ({ guides }) => !guides.users,
  options: ({ guides }) => {
    const ids = guides.users ? guides.users.map(g => g.id) : [];
    return {
      variables: {
        guideIds: ids,
        //prevent totally unbounded set
        lowerTimeBound: moment(new Date())
          .subtract(1, 'month')
          .format()
      }
    };
  }
})
class BookingTimeSelector extends Component {
  static propTypes = {
    guides: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired
  };

  _slotAvailabilities = {};

  constructor(props) {
    super(props);

    const { selectedClient, selectedDayOf } = this.props;

    const config = {
      views: [],
      eventItemLineHeight: ROW_HEIGHT,
      eventItemHeight: ROW_HEIGHT / 2,
      resourceName: 'Guides',
      schedulerMaxHeight: 400
    };

    const behaviors = {
      isNonWorkingTimeFunc: this._isNonWorkingTime,
      getNonAgendaViewBodyCellBgColorFunc: this._colorCellByAvailabilty
    };

    const schedulerData = new SchedulerData(
      selectedDayOf,
      ViewTypes.Day,
      false,
      false,
      config,
      behaviors
    );
    schedulerData.localeMoment.locale('en');
    schedulerData.setMinuteStep(CALL_SLOTS_STEP);

    this.state = {
      schedulerData,
      selectedDateTime: new Date(),
      selectedTimezone:
        (selectedClient && selectedClient.timezone) || moment.tz.guess(),
      currentBooking: null
    };

    const resources = this._generateResources();
    const events = this._getChannelBookings();
    if (resources.length) {
      this._slotAvailabilities = this._getAvailabilities();
      schedulerData.setResources(resources);
    }
    if (events.length) {
      schedulerData.setEvents(events);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedClient, selectedDayOf } = this.props;
    const { callRequests } = this.props.callRequests;
    const { users } = this.props.guides;
    const { currentBooking, schedulerData, selectedTimezone } = this.state;

    const selectedClientChanged =
      selectedClient && selectedClient !== prevProps.selectedClient;
    const selectedDayChanged = selectedDayOf !== prevProps.selectedDayOf;
    const selectedGuidesChanged = users && users !== prevProps.guides.users;
    const selectedTimezoneChanged =
      selectedTimezone !== prevState.selectedTimezone;
    const currentBookingChanged =
      currentBooking && currentBooking !== prevState.currentBooking;
    const callRequestsChanged =
      callRequests && callRequests !== prevProps.callRequests.callRequests;

    const resources = this._generateResources();
    const events = this._getChannelBookings();

    if (selectedClientChanged) {
      this.setState({ selectedTimezone: selectedClient.timezone });
    }
    if (
      selectedGuidesChanged ||
      selectedTimezoneChanged ||
      selectedDayChanged
    ) {
      this._slotAvailabilities = this._getAvailabilities();
    }
    if (selectedDayChanged) {
      schedulerData.setDate(selectedDayOf);
    }
    if (selectedGuidesChanged) {
      schedulerData.setResources(resources);
    }
    if (
      currentBookingChanged ||
      callRequestsChanged ||
      selectedDayChanged ||
      selectedTimezoneChanged
    ) {
      schedulerData.setEvents(events);
    }
  }

  _generateResources = () => {
    const { users } = this.props.guides;

    if (!users) {
      return [];
    }

    return users.map(guide => {
      const { id, firstName, lastName } = guide;
      return {
        id: id,
        name: `${firstName} ${lastName}`,
        guide
      };
    });
  };

  render() {
    return (
      <div className="call-request-form-bookingtime-selector ui form">
        <BookingTimezoneSelector
          selectedTimezone={this.state.selectedTimezone}
          onChange={this._onTimezoneChange}
        />
        {this._renderScheduler()}
      </div>
    );
  }

  _renderScheduler() {
    const { schedulerData } = this.state;

    if (!this.props.guides || !this.props.callRequests) {
      return null;
    }

    return (
      <div className="bookingtime-selector-timeline field-container">
        <Scheduler
          className="scheduler"
          schedulerData={schedulerData}
          newEvent={this._newEvent}
          slotItemTemplateResolver={this._renderResource}
          calendarPopoverEnabled="false"
          conflictOccurred={this._conflictOccurred}
          movable="true"
          eventItemTemplateResolver={this._eventItemTemplateResolver}
          moveEvent={this.moveEvent}
          //onViewChange={this.onViewChange}
          //eventItemClick={this.eventClicked}
        />
      </div>
    );
  }

  _availableColor = `#ffffff`;
  _unavailableColor = `#e9e9e9`;

  _colorCellByAvailabilty = (schedulerData, slotId, header) => {
    const guide = schedulerData.getResourceById(slotId).guide;
    const available = !!(
      this._slotAvailabilities[guide.id] &&
      this._slotAvailabilities[guide.id][header.time]
    );

    return available ? this._availableColor : this._unavailableColor;
  };

  _renderResource = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const { onSelectGuide, selectedClient, selectedGuide } = this.props;
    const resource = schedulerData.getResourceById(slot.slotId);
    const { guide } = resource;

    return (
      <BookingResourceItem
        key={`booking-resource-item-${guide.id}`}
        onSelectGuide={onSelectGuide}
        selectedClient={selectedClient}
        selectedGuide={selectedGuide}
        guide={guide}
        rowHeight={ROW_HEIGHT}
      />
    );
  };

  _newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    const {
      selectedClient,
      onSelectGuide,
      onSelectBooking,
      currentBooking
    } = this.props;

    const { selectedTimezone } = this.state;

    if (!selectedClient) {
      alert('You need to select a call participant before a call time.');
      return;
    }

    const resource = schedulerData.getResourceById(slotId);
    const available = !!(
      this._slotAvailabilities[resource.guide.id] &&
      this._slotAvailabilities[resource.guide.id][start]
    );

    if (!available) {
      return;
    }

    let _color = statusToColor('NEW');
    let _start = moment.tz(start, selectedTimezone);

    let newEvent = {
      id: `temp-id-${Math.random()
        .toString(36)
        .substr(2, 9)}`,
      title: `Schedule a call between ${selectedClient.firstName} and ${resource.guide.firstName}`,
      participants: {
        client: selectedClient,
        guide: resource.guide
      },
      start: _start.format(),
      end: _start
        .clone()
        .add(CALL_DURATION_IN_MINUTES, 'minutes')
        .format(),
      resourceId: slotId,
      borderColor: _color.border,
      bgColor: _color.background
    };

    this.setState({
      currentBooking: newEvent
    });

    if (_.isFunction(onSelectGuide)) {
      onSelectGuide({
        selectedGuide: resource.guide
      });
    }

    if (_.isFunction(onSelectBooking)) {
      onSelectBooking({
        selectedDateTime: _start
      });
    }
  };

  _conflictOccurred = (
    schedulerData,
    action,
    event,
    type,
    slotId,
    slotName,
    start,
    end
  ) => {
    console.log('conflict occured');
  };

  _getChannelBookings = () => {
    if (!this.props.callRequests) {
      return [];
    }

    const { selectedDayOf } = this.props;
    const { callRequests } = this.props.callRequests;
    const { currentBooking, schedulerData, selectedTimezone } = this.state;

    if (!callRequests) {
      return [];
    }

    let bookings = callRequests.reduce((acc, cR) => {
      let newBookings = [];

      const { from, to } = cR;
      const resource = schedulerData.getResourceById(to.id);
      const guideName =
        resource &&
        resource.guide &&
        `${resource.guide.firstName} ${resource.guide.lastName[0]}.`;
      const clientName = `${from.firstName} ${from.lastName[0]}.`;

      if (cR.call) {
        const { scheduledTime, id, status } = cR.call;
        let onDay = moment(scheduledTime).isSame(selectedDayOf, 'day');
        if (!onDay) {
          //switching to eastern time they fall in here.
          return acc;
        }
        let start = moment(scheduledTime)
          .tz(selectedTimezone)
          .format(DATE_TIME_FORMAT);
        //TODO: add duration if you have it.
        let end = moment(scheduledTime)
          .add(CALL_DURATION_IN_MINUTES, 'minutes')
          .tz(selectedTimezone)
          .format(DATE_TIME_FORMAT);
        let resourceId = to.id;
        let title = `${_.capitalize(
          status
        )} call between ${clientName} and ${guideName}`;
        let _color = statusToColor(status);
        newBookings = [
          {
            id,
            start,
            end,
            resourceId,
            participants: {
              client: from,
              guide: to
            },
            title,
            borderColor: _color.border,
            bgColor: _color.background
          }
        ];
      } else {
        let suggestedTimes = _.uniq(cR.suggestedTimes);
        let onDay = suggestedTimes.some(suggestedTime =>
          moment(suggestedTime).isSame(selectedDayOf, 'day')
        );
        if (!onDay) {
          return acc;
        }
        const { id } = cR;
        newBookings = suggestedTimes.map((sT, i) => {
          let start = moment(sT)
            .tz(selectedTimezone)
            .format(DATE_TIME_FORMAT);
          let end = moment(sT)
            .add(CALL_DURATION_IN_MINUTES, 'minutes')
            .tz(selectedTimezone)
            .format(DATE_TIME_FORMAT);

          let resourceId = to.id;
          let title = `${_.capitalize(
            status
          )} call between ${clientName} and ${guideName} (${cR.status})`;

          let _color = statusToColor(cR.status);

          return {
            id: `${id}-${i}`,
            start,
            end,
            resourceId,
            participants: {
              client: from,
              guide: to
            },
            title,
            borderColor: _color.border,
            bgColor: _color.background
          };
        });
      }
      return [...acc, ...newBookings];
    }, []);

    if (currentBooking) {
      let _cb = {
        ...currentBooking,
        ...{
          start: moment(currentBooking.start)
            .tz(selectedTimezone)
            .format(DATE_TIME_FORMAT),
          end: moment(currentBooking.end)
            .tz(selectedTimezone)
            .format(DATE_TIME_FORMAT)
        }
      };

      return [...bookings, _cb];
    }

    return bookings;
  };

  _getAvailabilities() {
    const { users } = this.props.guides;

    return users.reduce((acc, guide) => {
      const availabilities = this._getGuideAvailabilities(guide);

      const slots = availabilities.reduce((acc, availability) => {
        const range = moment.range(
          availability.start,
          moment(availability.end).subtract(CALL_SLOTS_STEP, 'minutes')
        );
        const slots = Array.from(
          range.by('minute', { step: CALL_SLOTS_STEP })
        ).reduce((acc, slot) => {
          return {
            ...acc,
            [slot.format(DATE_TIME_FORMAT)]: true
          };
        }, {});
        return {
          ...acc,
          ...slots
        };
      }, {});

      return {
        ...acc,
        [guide.id]: slots
      };
    }, {});
  }

  _getGuideAvailabilities(guide) {
    const { selectedDayOf } = this.props;
    const { selectedTimezone } = this.state;

    const day = moment(selectedDayOf);

    return guide.availability.reduce((acc, availability) => {
      const endDate = moment(availability.endTime)
        .tz(selectedTimezone)
        .endOf('day');
      const endDay = endDate.day();
      const endTime = moment(availability.endTime).tz(selectedTimezone);

      const startDate = moment(availability.startTime)
        .tz(selectedTimezone)
        .startOf('day');
      const startDay = startDate.day();
      const startTime = moment(availability.startTime).tz(selectedTimezone);

      const daysOfWeek = dayRange(startDay, endDay);

      if (availability.isRecurring) {
        const isOnDay = daysOfWeek.includes(day.day());

        const isEffectiveEndDateAfterDay =
          !availability.effectiveEndDate ||
          moment(availability.effectiveEndDate)
            .tz(selectedTimezone)
            .endOf('day')
            .isSameOrAfter(day);
        const isEffectiveStartDateBeforeDay =
          !availability.effectiveStartDate ||
          moment(availability.effectiveStartDate)
            .tz(selectedTimezone)
            .startOf('day')
            .isSameOrBefore(day);

        if (
          !isOnDay ||
          !isEffectiveEndDateAfterDay ||
          !isEffectiveStartDateBeforeDay
        ) {
          return acc;
        }

        const de = endDate.clone();
        const ds = startDate.clone();
        let diff = 0;
        while (
          !de.isBetween(
            day.clone().startOf('week'),
            day.clone().endOf('week'),
            null,
            '[]'
          ) &&
          !ds.isBetween(
            day.clone().startOf('week'),
            day.clone().endOf('week'),
            null,
            '[]'
          ) &&
          diff < 52
        ) {
          diff++;
          de.add(1, 'week');
          ds.add(1, 'week');
        }

        return acc.concat([
          {
            end: mergeDateAndTime(
              endDate
                .clone()
                .add(diff, 'weeks')
                .format(DATE_FORMAT),
              endTime
            ),
            start: mergeDateAndTime(
              startDate
                .clone()
                .add(diff, 'weeks')
                .format(DATE_FORMAT),
              startTime
            )
          }
        ]);
      } else {
        if (!moment.range(startDate, endDate).contains(day)) {
          return acc;
        }

        return acc.concat([
          {
            end: mergeDateAndTime(endDate.format(DATE_FORMAT), endTime),
            start: mergeDateAndTime(startDate.format(DATE_FORMAT), startTime)
          }
        ]);
      }
    }, []);
  }

  _onTimezoneChange = (event, { value }) => {
    const { onSelectTimezone } = this.props;
    const { schedulerData } = this.state;

    this.setState({ selectedTimezone: value }, () => {
      schedulerData.setEvents(this._getChannelBookings());
      this.forceUpdate();
    });

    onSelectTimezone({ selectedTimezone: value });
  };

  _eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    const resource = this.state.schedulerData.getResourceById(event.resourceId);
    const { guide } = resource;

    let rightBorderWidth = isEnd ? 25 : 0;
    let leftBorderWidth = isStart ? 2 : 0;
    let borderColor = event.borderColor || event.bgColor;
    let backgroundColor = event.bgColor;

    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );

    if (event.participants && event.participants.client) {
      let nm = `${event.participants.client.lastName[0]}.`;
      let fn = event.participants.client.firstName;
      titleText = `${fn} ${nm}`;
    }

    let divStyle = {
      borderLeft: leftBorderWidth + 'px solid ' + borderColor,
      borderRight: rightBorderWidth + 'px solid rgba(50, 50, 50, 0.4)',
      backgroundColor: backgroundColor,
      height: mustBeHeight
    };

    if (agendaMaxEventWidth) {
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    }
    divStyle = { ...divStyle, textOverflow: 'clip' };

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>
          {titleText}
        </span>
      </div>
    );
  };

  _isNonWorkingTime = (schedulerData, time) => false;

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    //{eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}
    if (confirm(`At this time, the event cannot be moved.`)) {
      // schedulerData.moveEvent(event, slotId, slotName, start, end);
      // this.forceUpdate()
      //TODO: call server and make change there.
    }
  };
}

export default withDndContext(BookingTimeSelector);
