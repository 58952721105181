import isError from 'lodash/isError';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';

const GraphQLErrorPrefix = 'GraphQL error: ';
const FunctionExecutionErrorPrefix = 'function execution error: ';

function getErrorMessage(error) {
  if (isString(error)) {
    return error;
  }
  if (isError(error)) {
    if (error.result && error.result.errors && error.result.errors.length) {
      return error.result.errors.map(getErrorMessage).join(', ');
    }
    if (error.graphQLErrors && error.graphQLErrors.length) {
      return error.graphQLErrors.map(getErrorMessage).join(', ');
    }
    if (error.networkError) {
      return getErrorMessage(error.networkError);
    }
    return error.message;
  }
  if (isObject(error)) {
    return error.message;
  }
}

export function friendlyError(message) {
  return message
    .replace(GraphQLErrorPrefix, '')
    .replace(FunctionExecutionErrorPrefix, '');
}

export default class ErrorDialog extends PureComponent {
  static propTypes = {
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        message: PropTypes.string
      })
    ]),
    onClose: PropTypes.func
  };

  render() {
    const { error, onClose } = this.props;

    if (!error) {
      return null;
    }

    const message = getErrorMessage(error);

    return (
      <Modal open size="mini" onClose={onClose}>
        <Modal.Header>
          <Icon name="exclamation" color="red" />
          Error
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Message error>{friendlyError(message)}</Message>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>OK</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
