import ApolloClient from './apollo';
import { ensureUserExists } from './auth';
import CreateOrUpdateIntakeAnswerMutation from './graphql/mutations/create-or-update-intake-answer.graphql';
import CreateUserChannelProfileWInviteMutation from './graphql/mutations/create-user-channel-profile-w-invite.graphql';
import CreateUserChannelProfileMutation from './graphql/mutations/create-user-channel-profile.graphql';
import UpdateUserChannelProflileSubChannelsMutation from './graphql/mutations/update-user-channel-profile-subchannels.graphql';
import UserChannelProfilesQuery from './graphql/queries/user-channel-profiles-by-channel-id.graphql';
import UserQuery from './graphql/queries/user.graphql';

// Functions to query and mutate user channel profile data and maintain cache

export function getUserChannelProfile(user, channel) {
  if (!user || !channel) {
    return Promise.resolve(null);
  }
  const variables = {
    channelId: channel.id,
    userId: user.id
  };
  const options = {
    query: UserChannelProfilesQuery,
    variables
  };
  return ApolloClient.query(options).then(
    ({ data: { userChannelProfiles } }) => userChannelProfiles[0]
  );
}

export function createUserChannelProfile(user, channel) {
  const invite = user.channelInvites.find(i => i.channel.id === channel.id);

  const variables = {
    channelId: channel.id,
    ...(invite && { inviteId: invite.id }),
    userId: user.id
  };
  const options = {
    mutation: invite
      ? CreateUserChannelProfileWInviteMutation
      : CreateUserChannelProfileMutation,
    variables,
    update: (store, { data: { createUserChannelProfile } }) => {
      const variables = {
        channelId: channel.id,
        userId: user.id
      };

      let data = { userChannelProfiles: [] };
      try {
        data = store.readQuery({ query: UserChannelProfilesQuery, variables });
      } catch (e) {
        /* noop */
      }

      data.userChannelProfiles.push(createUserChannelProfile);

      store.writeQuery({
        query: UserChannelProfilesQuery,
        variables,
        data
      });

      try {
        data = store.readQuery({ query: UserQuery });
        data.User.channelProfiles.push({
          ...createUserChannelProfile,
          channel
        });
        store.writeQuery({ query: UserQuery, data });
      } catch (e) {
        /* noop */
      }
    }
  };
  return ApolloClient.mutate(options).then(
    ({ data: { createUserChannelProfile } }) => createUserChannelProfile
  );
}

export function ensureUserChannelProfileExists(channel) {
  return ensureUserExists().then(user => {
    return getUserChannelProfile(user, channel).then(channelProfile => {
      if (channelProfile) {
        return channelProfile;
      }
      return createUserChannelProfile(user, channel);
    });
  });
}

export function createOrUpdateIntakeAnswer(
  channelProfile,
  intakeQuestion,
  answer
) {
  const intakeAnswer = channelProfile.intakeAnswers.find(
    ia => ia.intakeQuestionId === intakeQuestion.id
  );
  const variables = {
    id: intakeAnswer ? intakeAnswer.id : '',
    answer,
    intakeQuestionId: intakeQuestion.id,
    userChannelProfileId: channelProfile.id
  };
  const options = {
    mutation: CreateOrUpdateIntakeAnswerMutation,
    variables,
    update: (store, { data: { upsertIntakeAnswer } }) => {
      const variables = {
        channelId: channelProfile.channelId,
        userId: upsertIntakeAnswer.userChannelProfile.user.id
      };
      let data = null;
      try {
        data = store.readQuery({
          query: UserChannelProfilesQuery,
          variables
        });
      } catch (error) {
        /* no-op */
      }
      if (!data) {
        return;
      }

      const cachedChannelProfle = data.userChannelProfiles.find(
        p => p.channelId === channelProfile.channelId
      );
      const index = cachedChannelProfle.intakeAnswers.findIndex(
        ia => ia.intakeQuestionId === upsertIntakeAnswer.id
      );
      if (index >= 0) {
        cachedChannelProfle.intakeAnswers.splice(index, 1, upsertIntakeAnswer);
      } else {
        cachedChannelProfle.intakeAnswers.push(upsertIntakeAnswer);
      }

      store.writeQuery({
        query: UserChannelProfilesQuery,
        variables,
        data
      });
    }
  };
  return ApolloClient.mutate(options).then(
    ({ data: { upsertIntakeAnswer } }) => upsertIntakeAnswer
  );
}

export function updateUserChannelProfileSubChannels(
  channelProfile,
  subChannels
) {
  const subChannelIds = subChannels.map(s => s.id);
  const variables = {
    id: channelProfile.id,
    subChannelIds
  };
  const options = {
    mutation: UpdateUserChannelProflileSubChannelsMutation,
    variables,
    update: (store, { data: { updateUserChannelProfile } }) => {
      const variables = {
        channelId: channelProfile.channelId,
        userId: updateUserChannelProfile.user.id
      };
      let data = null;
      try {
        data = store.readQuery({
          query: UserChannelProfilesQuery,
          variables
        });
      } catch (error) {
        /* no-op */
      }
      if (!data) {
        return;
      }

      const cachedChannelProfile = data.userChannelProfiles.find(
        p => p.id === updateUserChannelProfile.id
      );
      cachedChannelProfile.subChannelIds =
        updateUserChannelProfile.subChannelIds;

      store.writeQuery({
        query: UserChannelProfilesQuery,
        variables,
        data
      });
    }
  };
  return ApolloClient.mutate(options).then(
    ({ data: { updateUserChannelProfile } }) => updateUserChannelProfile
  );
}
