import './payment-options.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Elements } from 'react-stripe-elements';
import {
  Button,
  Confirm,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Segment,
  Table
} from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import AddCallCreditsMutation from '../../../graphql/mutations/add-call-credits.graphql';
import CreateStripeCardMutation from '../../../graphql/mutations/create-stripe-card.graphql';
import UpdateStripeCardIsDeactivatedMutation from '../../../graphql/mutations/update-stripe-card-is-deactivated.graphql';
import UpdateUserDefaultStripeSourceMutation from '../../../graphql/mutations/update-user-default-stripe-source.graphql';
import UserCallCreditsStripeInfo from '../../../graphql/queries/user-call-credits-stripe-info.graphql';
import CallCreditsPurchaseRequestsSubscription from '../../../graphql/subscriptions/call-credits-purchase-requests.graphql';
import UserStripeCardsSubscription from '../../../graphql/subscriptions/user-stripe-cards.graphql';
import * as tracker from '../../../tracker';
import graphql from '../../hoc/graphql';
import withUser from '../../hoc/with-user';
import ErrorDialog from '../../ui/error-dialog';
import Notification from '../../ui/notification';
import { calculateUserCallCreditsRemaining } from '../call/call-credits-calculations';
import StripeCardForm from '../stripe-card-form';

const CreditRate = 12.99;

function getCardIconName(brand) {
  switch (brand) {
    case 'American Express':
      return 'cc amex card big';
    case 'Diners Club':
    case 'Discover':
    case 'JCB':
    case 'MasterCard':
    case 'Visa':
      return `cc ${brand.toLowerCase()} card big`;
    default:
      return 'credit card';
  }
}

@graphql(AddCallCreditsMutation, {
  name: 'addCallCredits'
})
@graphql(CreateStripeCardMutation, {
  name: 'createStripeCard',
  options: {
    refetchQueries: [{ query: UserCallCreditsStripeInfo }]
  }
})
@graphql(UpdateStripeCardIsDeactivatedMutation, {
  name: 'updateStripeCardIsDeactivated',
  options: {
    refetchQueries: [{ query: UserCallCreditsStripeInfo }]
  }
})
@graphql(UpdateUserDefaultStripeSourceMutation, {
  name: 'updateUserDefaultStripeSource',
  options: {
    refetchQueries: [{ query: UserCallCreditsStripeInfo }]
  }
})
@graphql(UserCallCreditsStripeInfo, {
  name: 'userCallCreditsStripeInfo',
  options: { fetchPolicy: 'network-only' }
})
@withUser({ authenticated: true })
class PaymentOptions extends Component {
  static propTypes = {
    addCallCredits: PropTypes.func.isRequired,
    createStripeCard: PropTypes.func.isRequired,
    updateStripeCardIsDeactivated: PropTypes.func.isRequired,
    updateUserDefaultStripeSource: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    userCallCreditsStripeInfo: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      subscribeToMore: PropTypes.func.isRequired,
      User: PropTypes.shape({
        stripeCards: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string
          })
        ),
        stripeCharges: PropTypes.arrayOf(
          PropTypes.shape({
            data: PropTypes.object
          })
        )
      })
    })
  };

  state = {
    callCredits: 2,
    isAddCreditsDialogOpen: false,
    isAddCreditCardDialogOpen: false,
    stripeCardForDefault: null,
    stripeCardForDelete: null,
    submitting: false,
    successMessage: null
  };

  componentDidMount() {
    this._subscribe();
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  _subscribe() {
    const { User } = this.props.user;

    const variables = { userId: User.id };

    if (!this._unsubscribeCallCreditsPurchaseRequests) {
      this._unsubscribeCallCreditsPurchaseRequests = this.props.userCallCreditsStripeInfo.subscribeToMore(
        {
          document: CallCreditsPurchaseRequestsSubscription,
          variables,
          updateQuery: this._onCallCreditsPurchaseRequestsSubscription
        }
      );
    }

    if (!this._unsubscribeStripeCards) {
      this._unsubscribeStripeCards = this.props.userCallCreditsStripeInfo.subscribeToMore(
        {
          document: UserStripeCardsSubscription,
          variables,
          updateQuery: this._onStripeCardsSubscription
        }
      );
    }
  }

  _unsubscribe() {
    if (this._unsubscribeCallCreditsPurchaseRequests) {
      this._unsubscribeCallCreditsPurchaseRequests();
      delete this._unsubscribeCallCreditsPurchaseRequests;
    }
    if (this._unsubscribeStripeCards) {
      this._unsubscribeStripeCards();
      delete this._unsubscribeStripeCards;
    }
  }

  _onCallCreditsPurchaseRequestsSubscription = (prev, { subscriptionData }) => {
    if (!subscriptionData) {
      return prev;
    }

    const { mutation, node } = subscriptionData.data.callCreditsPurchaseRequest;
    if (mutation === 'UPDATED') {
      if (node.status === 'COMPLETED') {
        const { User } = prev;

        const stripeCharges = User.stripeCharges.slice(0);
        stripeCharges.unshift(node.stripeCharge);

        const updatedUser = {
          ...User,
          callCredits: User.callCredits + node.callCredits,
          stripeCharges
        };

        tracker.event('creditCardCharge', node.stripeCharge.data.amount, {
          callCredits: node.callCredits
        });

        this.setState({
          isAddCreditCardDialogOpen: false,
          isAddCreditsDialogOpen: false,
          submitting: false,
          successMessage: `${node.callCredits} credits have been added to your account`
        });

        return {
          ...prev,
          User: updatedUser
        };
      }
      if (node.status === 'FAILED') {
        tracker.event('creditCardCharge', 0);

        this.setState({ error: node.error });
      }
    }

    return prev;
  };

  _onStripeCardsSubscription = (prev, { subscriptionData }) => {
    if (!subscriptionData) {
      return prev;
    }

    const { User } = prev;
    const stripeCards = User.stripeCards.slice(0);
    const updatedUser = {
      ...User,
      stripeCards
    };

    const { mutation, node, updatedFields } = subscriptionData.data.stripeCard;
    if (mutation === 'CREATED') {
      stripeCards.push(node);
    }
    if (mutation === 'UPDATED') {
      const index = stripeCards.findIndex(card => card.id === node.id);
      if (index >= 0) {
        stripeCards.splice(index, 1, node);
      }
      if (updatedFields.includes('stripeCardId')) {
        const hasOtherCards = stripeCards.length > 1;
        if (hasOtherCards) {
          this.setState({
            isAddCreditCardDialogOpen: false,
            submitting: false,
            successMessage: `${node.brand} ending in ${node.last4} has been added to your account`
          });
        } else {
          updatedUser.defaultStripeSource = node.stripeCardId;
        }
      }
    }

    return {
      ...prev,
      User: updatedUser
    };
  };

  render() {
    const { loading, User } = this.props.userCallCreditsStripeInfo;

    if (loading) {
      return <Segment loading />;
    }

    if (!User) {
      return null;
    }

    const { error, successMessage } = this.state;

    const hasCards = !!User.stripeCards.length;
    const hasCharges = !!User.stripeCharges.length;
    const hasCallCreditsLogs = !!User.callCreditsLogs.length;

    const callCredits = calculateUserCallCreditsRemaining(User);

    return (
      <div>
        <Header>
          {hasCards && (
            <Button
              floated="right"
              primary
              onClick={() => {
                this.setState({ isAddCreditsDialogOpen: true });
              }}
            >
              <Icon name="plus" />
              Get more credits
            </Button>
          )}
          Call Credits
        </Header>
        <p>You have {callCredits} call credits remaining.</p>
        {!hasCards && (
          <Message
            warning
            icon="credit card"
            content={
              <div>
                <p>
                  You&apos;ll need to add a credit card on file{' '}
                  {callCredits > 0
                    ? 'to purchase more call credits.'
                    : 'and purchase credits to schedule calls with our guides.'}
                </p>
                {!hasCallCreditsLogs && (
                  <p>
                    Initial purchase includes 2 calls and access to all
                    resources.
                  </p>
                )}
                <Button
                  primary
                  onClick={() => {
                    this.setState({ isAddCreditCardDialogOpen: true });
                  }}
                >
                  <Icon name="plus" size="large" />
                  {hasCallCreditsLogs
                    ? 'Add a new card'
                    : 'Add a card and checkout for $50.00'}
                </Button>
              </div>
            }
          />
        )}
        {hasCards && (
          <div style={{ marginBottom: '1em' }}>
            <Header>
              <Button
                floated="right"
                size="tiny"
                primary
                compact
                onClick={() => {
                  this.setState({ isAddCreditCardDialogOpen: true });
                }}
              >
                <Icon name="plus" />
                Add a new card
              </Button>
              Your credit cards
            </Header>
            {this._renderStripeCards()}
          </div>
        )}
        {hasCharges && (
          <div style={{ marginBottom: '1em' }}>
            <Header>Payment History</Header>
            {this._renderStripeCharges()}
          </div>
        )}

        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
        <Notification
          open={!!successMessage}
          onClose={() => {
            this.setState({ successMessage: null });
          }}
        >
          <Icon name="check" color="green" /> {successMessage}
        </Notification>
        {this._renderAddCreditsDialog()}
        {this._renderAddCreditCardDialog()}
        {this._renderMakeDefaultStripeCardConfirm()}
        {this._renderDeleteStripeCardConfirm()}
      </div>
    );
  }

  _renderStripeCards() {
    const { User } = this.props.userCallCreditsStripeInfo;

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Card</Table.HeaderCell>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{User.stripeCards.map(this._renderStripeCard)}</Table.Body>
      </Table>
    );
  }

  _renderStripeCard = stripeCard => {
    const { User } = this.props.userCallCreditsStripeInfo;

    const isDefault =
      User.defaultStripeSource &&
      User.defaultStripeSource === stripeCard.stripeCardId;
    const hasMultiple = User.stripeCards.length > 1;
    const expiration = moment({
      year: stripeCard.expYear,
      month: stripeCard.expMonth
    });

    return (
      <Table.Row key={`stripe-card-${stripeCard.id}`}>
        <Table.Cell>
          {isDefault && <Label ribbon>Default</Label>}
          <Icon name={getCardIconName(stripeCard.brand)} /> {stripeCard.brand}{' '}
          ending in {stripeCard.last4}
        </Table.Cell>
        <Table.Cell>
          {expiration.isBefore() ? 'Expired ' : ''}
          {expiration.format('MM/YY')}
        </Table.Cell>
        <Table.Cell textAlign="right">
          {!isDefault && (
            <Button
              onClick={() => {
                this.setState({ stripeCardForDefault: stripeCard });
              }}
            >
              Make Default
            </Button>
          )}
          {hasMultiple && (
            <Button
              onClick={() => {
                this.setState({ stripeCardForDelete: stripeCard });
              }}
            >
              Delete
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  _renderStripeCharges() {
    const { User } = this.props.userCallCreditsStripeInfo;

    return (
      <Table className="payment-table" striped unstackable fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Card</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {User.stripeCharges.map(this._renderStripeCharge)}
        </Table.Body>
      </Table>
    );
  }

  _renderStripeCharge = stripeCharge => {
    const { data } = stripeCharge;

    return (
      <Table.Row key={stripeCharge.id}>
        <Table.Cell>
          {moment.unix(data.created).format('MMM. D, YYYY')}
        </Table.Cell>
        <Table.Cell>
          <Icon name={getCardIconName(data.source.brand)} /> {data.source.brand}{' '}
          ending in {data.source.last4}
        </Table.Cell>
        <Table.Cell>{data.description}</Table.Cell>
        <Table.Cell>${data.amount * 0.01}</Table.Cell>
      </Table.Row>
    );
  };

  _renderAddCreditsDialog() {
    const { callCredits, isAddCreditsDialogOpen, submitting } = this.state;

    const isValid = callCredits > 0;

    return (
      <Modal
        size="tiny"
        open={isAddCreditsDialogOpen}
        onClose={() => {
          if (!submitting) {
            this.setState({ isAddCreditsDialogOpen: false });
          }
        }}
      >
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center' }}>
            <Header>Add Additional Call Credits</Header>
            <Form>
              <Form.Field>
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Input
                        value={callCredits}
                        style={{ textAlign: 'center' }}
                        onChange={(event, { value }) => {
                          const intValue = parseInt(
                            value.replace(/\D/g, ''),
                            10
                          );
                          if (isNaN(intValue)) {
                            this.setState({ callCredits: '' });
                          } else {
                            this.setState({ callCredits: intValue });
                          }
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>x</Grid.Column>
                    <Grid.Column>$12.99</Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Button
                fluid
                primary
                size="large"
                loading={submitting}
                disabled={!isValid || submitting}
                onClick={this._onSubmitAddCredits}
              >
                Add Credits
                {isValid && ` for $${(callCredits * CreditRate).toFixed(2)}`}
              </Form.Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  _renderAddCreditCardDialog() {
    const { User } = this.props.userCallCreditsStripeInfo;
    const { isAddCreditCardDialogOpen, submitting } = this.state;

    const hasCallCreditsLogs = !!User.callCreditsLogs.length;

    return (
      <Modal
        size="tiny"
        open={isAddCreditCardDialogOpen}
        onClose={() => {
          if (!submitting) {
            this.setState({ isAddCreditCardDialogOpen: false });
          }
        }}
      >
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center', padding: '1em' }}>
            <Header>Enter Credit Card Details</Header>
            <Elements>
              <StripeCardForm
                submitButtonText={
                  hasCallCreditsLogs
                    ? 'Save Credit Card'
                    : 'Checkout for $50.00'
                }
                submitting={submitting}
                onSubmit={this._onSubmitStripeCard}
              />
            </Elements>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }

  _renderMakeDefaultStripeCardConfirm() {
    const { stripeCardForDefault } = this.state;

    if (!stripeCardForDefault) {
      return null;
    }

    return (
      <Confirm
        open
        content={`Are you sure you want to make the ${stripeCardForDefault.brand} card ending in ${stripeCardForDefault.last4} your new default payment method?`}
        cancelButton="Nevermind"
        confirmButton="Yes, make this card my default"
        onCancel={() => {
          this.setState({ stripeCardForDefault: null });
        }}
        onConfirm={this._onConfirmMakeDefault}
      />
    );
  }

  _renderDeleteStripeCardConfirm() {
    const { stripeCardForDelete } = this.state;

    if (!stripeCardForDelete) {
      return null;
    }

    return (
      <Confirm
        open
        content={`Are you sure you want to delete the ${stripeCardForDelete.brand} card ending in ${stripeCardForDelete.last4}?`}
        cancelButton="Nevermind"
        confirmButton="Yes, delete this card"
        onCancel={() => {
          this.setState({ stripeCardForDelete: null });
        }}
        onConfirm={this._onConfirmDelete}
      />
    );
  }

  _onConfirmMakeDefault = () => {
    const { updateUserDefaultStripeSource } = this.props;
    const { User } = this.props.userCallCreditsStripeInfo;
    const { stripeCardForDefault } = this.state;

    const variables = {
      id: User.id,
      defaultStripeSource: stripeCardForDefault.stripeCardId
    };

    this.setState({ submitting: true });
    updateUserDefaultStripeSource({ variables })
      .then(() => {
        this.setState({
          stripeCardForDefault: null,
          submitting: false,
          successMessage: `${stripeCardForDefault.brand} ending in ${stripeCardForDefault.last4} is now your default`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'PaymentOptions._onConfirmMakeDefault',
          request: {
            ...variables
          }
        });
      });
  };

  _onConfirmDelete = () => {
    const { updateStripeCardIsDeactivated } = this.props;
    const { stripeCardForDelete } = this.state;

    const variables = {
      id: stripeCardForDelete.id,
      isDeactivated: true
    };

    this.setState({ submitting: true });
    updateStripeCardIsDeactivated({ variables })
      .then(() => {
        this.setState({
          stripeCardForDelete: null,
          submitting: false,
          successMessage: `${stripeCardForDelete.brand} ending in ${stripeCardForDelete.last4} has been deleted`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'PaymentOptions._onConfirmDelete',
          request: {
            ...variables
          }
        });
      });
  };

  _onSubmitAddCredits = () => {
    const { callCredits } = this.state;

    const variables = {
      callCredits
    };

    this.setState({ submitting: true });
    this.props.addCallCredits({ variables }).catch(error => {
      this.setState({ error, submitting: false });
      BugsnagClient.notify(error, {
        context: 'PaymentOptions._onSubmitAddCredits',
        request: {
          ...variables
        }
      });
    });
  };

  _onSubmitStripeCard = token => {
    const { createStripeCard } = this.props;
    const { User } = this.props.userCallCreditsStripeInfo;

    const hasCallCreditsLogs = !!User.callCreditsLogs.length;

    const variables = {
      token: token.id,
      brand: token.card.brand,
      expMonth: token.card.exp_month,
      expYear: token.card.exp_year,
      last4: token.card.last4,
      stripeCardId: token.card.id,
      userId: User.id
    };

    this.setState({ submitting: true });
    createStripeCard({ variables })
      .then(() => {
        if (hasCallCreditsLogs) {
          // Don't need to wait for charge
          this.setState({
            isAddCreditCardDialogOpen: false,
            submitting: false,
            successMessage: `${token.card.brand} ending in ${token.card.last4} has been added to your account`
          });
        }
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'PaymentOptions._onSubmitStripeCard',
          request: {
            ...variables
          }
        });
      });
  };
}
export default PaymentOptions;
