import './index.css';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Header, Modal } from 'semantic-ui-react';

class UserDialog extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    user: PropTypes.shape({
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { children, title, subtitle, user, ...remaining } = this.props;

    return (
      <Modal
        size="tiny"
        //closeIcon={isTablet}
        closeIcon={{ name: 'close lineawesome ' }}
        {...remaining}
        className="modal call schedule user-dialog"
      >
        <Modal.Content className="call schedule">
          <Modal.Description style={{ textAlign: 'center' }}>
            <Header as="h1" style={{ fontWeight: 'normal' }}>
              {title}
            </Header>
            {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
            <div>{children}</div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default UserDialog;
