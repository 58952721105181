export default [
  {
    id: 1,
    title: 'Is this a crisis?',
    questions: [
      'Thinking about, or planning to hurt themselves',
      'Thinking about, or planning to hurt someone else',
      'Believe someone in their life intends to hurt them'
    ]
  },
  {
    id: 2,
    title: 'If yes, then clarify and confirm:',
    questions: [
      '“I thought I heard you say __________. Did I hear that right?”'
    ]
  },
  {
    id: 3,
    title: 'Tell them:',
    questions: [
      'You need to end the call',
      'LifeGuides will send them a list of professional crisis services',
      'National Suicide Prevention Lifeline: 1-800-273-TALK (8255)',
      'Crisis Text Line: Text "START" TO 741-741',
      'Encourage them to use the crisis services right away',
      'Reassure them they can call you back once they are no longer in crisis'
    ]
  },
  {
    id: 4,
    title: 'Contact Dave McNeish ASAP, or within one hour at most',
    questions: [
      'dave@lifeguides.com',
      'direct message through Slack',
      '603-809-7076'
    ]
  },
  {
    id: 5,
    title: 'Send Dave this info:',
    questions: [
      'Your name and contact info',
      'Client’s name',
      'Date and time of the call',
      'Quote the threatening statement'
    ]
  }
];
