import './index.css';
import './requested-time.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Button } from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import CreateCallMutation from '../../../graphql/mutations/create-call.graphql';
import UpdateCallRequestStatusMutation from '../../../graphql/mutations/update-call-request-status.graphql';
import GuideCallCountsQuery from '../../../graphql/queries/guide-call-counts.graphql';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import UserClientCallsQuery from '../../../graphql/queries/user-client-calls.graphql';
import UserReceivedCallRequestsQuery from '../../../graphql/queries/user-received-call-requests.graphql';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import UserDialog from '../../ui/user-dialog';

@withUser({ authenticated: true })
@graphql(CreateCallMutation, {
  name: 'createCall',
  options: props => ({
    update: (store, { data: { createCall } }) => {
      const { User } = props.user;

      const variables = { statuses: ['SCHEDULED'], userId: User.id };

      let data = null;
      try {
        data = store.readQuery({
          query: UserClientCallsQuery,
          variables
        });
      } catch (e) {
        /* noop */
      }
      if (!data) {
        return;
      }

      const { calls } = data.user;
      calls.push(createCall);

      store.writeQuery({
        query: UserClientCallsQuery,
        variables,
        data
      });
    }
  })
})
@graphql(UpdateCallRequestStatusMutation, {
  name: 'updateCallRequestStatus',
  options: props => {
    const { User } = props.user;

    const variables = { userId: User.id };

    return {
      refetchQueries: [
        { query: GuideCallCountsQuery, variables },
        { query: GuideDashboardQuery, variables }
      ],
      update: (
        store,
        {
          data: {
            updateCallRequest: { id }
          }
        }
      ) => {
        // Accepted or rejected, remove from received call requests
        let data = null;
        try {
          store.readQuery({ query: UserReceivedCallRequestsQuery, variables });
        } catch (e) {
          /* noop */
        }
        if (!data) {
          return;
        }

        const { callRequests } = data.user;
        const index = callRequests.findIndex(
          callRequest => callRequest.id === id
        );
        if (index >= 0) {
          callRequests.splice(index, 1);
        }

        store.writeQuery({
          query: UserReceivedCallRequestsQuery,
          variables,
          data
        });
      }
    };
  }
})
class ReceiveCallRequestDialog extends Component {
  static propTypes = {
    callRequest: PropTypes.shape({
      id: PropTypes.string.isRequired,
      channel: PropTypes.shape({
        id: PropTypes.string,
        shortTitle: PropTypes.string
      }),
      from: PropTypes.shape({
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            intakeAnswers: PropTypes.arrayOf(
              PropTypes.shape({
                answer: PropTypes.string,
                intakeQuestion: PropTypes.shape({
                  title: PropTypes.string
                })
              })
            )
          })
        )
      }).isRequired,
      suggestedTimes: PropTypes.arrayOf(PropTypes.string),
      timezone: PropTypes.string
    }).isRequired,
    createCall: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool,
    updateCallRequestStatus: PropTypes.func.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        id: PropTypes.string,
        timezone: PropTypes.string
      })
    }).isRequired
  };

  state = {
    error: null,
    showFull: false,
    submitting: false
  };

  render() {
    const { callRequest, onClose, open } = this.props;
    const { loading } = this.props.user;
    const { submitting } = this.state;

    return (
      <UserDialog
        className="call-request"
        style={{
          width: 'auto',
          height: 'auto'
        }}
        user={callRequest.from}
        title="Pick a time that works"
        subtitle={
          <div style={{ display: 'flex' }} className="call-request-from-client">
            <div className="call-request-client-avatar-container">
              <Avatar
                user={callRequest.from}
                className="call-request-client-avatar"
              />
            </div>
            <div className="call-request-client-details">
              <div className="call-request-client-name-and-channel">
                <div className="call-request-client-name">
                  <span>
                    {callRequest.from.firstName} {callRequest.from.lastName[0]}
                    {'. '}
                  </span>
                </div>
                <div className="call-request-client-channel-container">
                  <span className="call-request-client-channel">
                    {callRequest.channel.shortTitle}
                  </span>
                </div>
              </div>
              <div className="call-request-client-topics">
                {this._renderSubChannel()}
              </div>
            </div>
          </div>
        }
        open={open}
        onClose={onClose}
      >
        {this._renderRequestedTimes()}
        <div className="requested-time-reject-call">
          <Button
            basic
            loading={loading || submitting}
            disabled={loading || submitting}
            onClick={this._reject}
            className="reject"
          >
            Reject Request
          </Button>
        </div>
      </UserDialog>
    );
  }

  _renderSubChannel() {
    const { callRequest } = this.props;
    const { from } = callRequest;

    if (!from || !from.channelProfiles) {
      return null;
    }
    const channelProfile = from.channelProfiles.find(
      p => p.channel.id === callRequest.channel.id
    );
    if (!channelProfile) {
      return null;
    }
    const subChannels = channelProfile.subChannels.map(sb => sb.title);
    return (
      <span style={{ fontFamily: 'Avenir Next', fontSize: '1rem' }}>
        {subChannels.join(', ')}
      </span>
    );
  }

  _renderRequestedTimes() {
    const { callRequest } = this.props;

    return (
      <div className="requested-time">
        {callRequest.suggestedTimes.map(this._renderRequestedTime)}
      </div>
    );
  }

  _renderRequestedTime = time => {
    const { loading, User } = this.props.user;
    const { submitting } = this.state;

    const m = moment(time).tz(User.timezone || moment.tz.guess());

    return (
      <div key={`requested-time-${time}`} className="requested-time-list-item">
        <div className="requested-time-list-item-content">
          <div className="requested-time-list-item-header">
            {m.format('ddd, MMM D')}
          </div>
        </div>
        <div className="requested-time-list-item-time">{m.format('h:mma')}</div>
        <div className="requested-time-list-item-accept-call">
          <Button
            primary
            className="requested-time-list-item-accept-call-button"
            loading={loading || submitting}
            disabled={loading || submitting}
            onClick={() => {
              this._acceptTime(time);
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    );
  };

  _acceptTime(time) {
    const {
      callRequest,
      createCall,
      onSubmit,
      updateCallRequestStatus
    } = this.props;
    const { User } = this.props.user;

    const variables = {
      channelId: callRequest.channel.id,
      requestId: callRequest.id,
      guideId: User.id,
      clientId: callRequest.from.id,
      scheduledTime: time
    };

    this.setState({ submitting: true });
    createCall({ variables })
      .then(() => {
        const variables = {
          id: callRequest.id,
          status: 'ACCEPTED'
        };
        return updateCallRequestStatus({ variables }).then(() => {
          this.setState({ submitting: false });
          onSubmit();
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'ReceiveCallRequestDialog._acceptTime',
          request: {
            ...variables
          }
        });
      });
  }

  _reject = () => {
    const { callRequest, onSubmit, updateCallRequestStatus } = this.props;

    const variables = {
      id: callRequest.id,
      status: 'REJECTED'
    };

    this.setState({ submitting: true });
    return updateCallRequestStatus({ variables })
      .then(() => {
        this.setState({ submitting: false });
        onSubmit();
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'ReceiveCallRequestDialog._reject',
          request: {
            ...variables
          }
        });
      });
  };
}
export default ReceiveCallRequestDialog;
