import 'semantic-ui-css/semantic.min.css';
import 'babel-polyfill';
import 'date-input-polyfill-react';
import 'whatwg-fetch';

import './assets/line-awesome/css/line-awesome-semantic-font-awesome.css';
import './assets/fonts/fonts.css';
import './tracker';
import './index.css';

import createHistory from 'history/createBrowserHistory';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import ApolloClient from './apollo';
import { ErrorBoundary } from './bugsnag';
import App from './components/App';
import ScrollToTop from './components/routes/scroll-to-top';
import { STRIPE_API_TEST_KEY } from './consts';
import * as serviceWorker from './serviceWorker';

const StripeApiKey =
  process.env.REACT_APP_STRIPE_API_KEY || STRIPE_API_TEST_KEY;

const history = createHistory();

const application = (
  <ApolloProvider client={ApolloClient}>
    <StripeProvider apiKey={StripeApiKey}>
      <Router history={history}>
        <ErrorBoundary>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ErrorBoundary>
      </Router>
    </StripeProvider>
  </ApolloProvider>
);

ReactDOM.render(application, document.getElementById('root'));
serviceWorker.register();
