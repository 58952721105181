import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { RetryLink } from "apollo-link-retry";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getFragmentDefinitions, getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";

import BugsnagClient from "./bugsnag";
import { AUTH_TOKEN_NAME } from "./consts";
import UserFragment from "./graphql/fragments/user.graphql";
import * as tracker from "./tracker";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, { query }) => {
      const { operation } = getMainDefinition(query);
      return operation === "query";
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      BugsnagClient.notify(`GraphQL error: ${message}`, {
        metaData: {
          locations,
          path
        },
        request: {
          body: operation.query.loc.source.body,
          variables: operation.variables
        }
      });
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`Network error: ${networkError}`);
  }
});

const authLink = setContext((_, context) => {
  const headers = { ...context.headers };

  const token = localStorage.getItem(AUTH_TOKEN_NAME);
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return {
    ...context,
    headers
  };
});

function isUserQuery({ query }) {
  const [userFragment] = getFragmentDefinitions(UserFragment);
  const [queryFragment] = getFragmentDefinitions(query);
  if (!queryFragment) {
    return false;
  }

  return userFragment.selectionSet.selections.every(selection => {
    return queryFragment.selectionSet.selections.some(
      s => s.name.value === selection.name.value
    );
  });
}

function setBugsnagUser(user) {
  if (user) {
    BugsnagClient.user = {
      id: user.id,
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles.map(role => role.name)
    };
  }
}

function setTrackerUser(user) {
  const data = {
    userId: null
  };

  if (user) {
    data.userId = user.id;
  }

  tracker.dataLayer(data);
}

const userLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (isUserQuery(operation)) {
      setBugsnagUser(response.data.user);
      setTrackerUser(response.data.user);
    }
    return response;
  });
});

const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL,
  {
    connectionParams: () => {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);
      return { authorization: token ? `Bearer ${token}` : null };
    },
    inactivityTimeout: 0,
    reconnect: true,
    timeout: 30000
  }
);
const wsLink = new WebSocketLink(subscriptionClient);

export function reconnectSubscription() {
  subscriptionClient.close();
  subscriptionClient.connect();
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  ApolloLink.from([authLink, userLink, errorLink, retryLink, httpLink])
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache()
});
