import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

import withWidth, { isWidthDown } from '../../hoc/with-width';
import GuideRoutes from './guide-routes';
import GuidesList from './guides-list';
import GuidesPager from './guides-pager';

@withWidth()
class Guides extends Component {
  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string.isRequired,
    onChangePage: PropTypes.func.isRequired,
    guides: PropTypes.array.isRequired,
    guidesCount: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  };

  render() {
    const { fetching, onChangePage, guides, guidesCount, width } = this.props;

    const isTablet = isWidthDown('computer', width);
    const isMobile = isWidthDown('tablet', width);

    let guidesList = <Segment basic loading={fetching} />;
    let pager = null;
    let guideRoutes = null;

    if (!fetching) {
      guidesList = (
        <GuidesList
          guides={guides}
          guidesCount={guidesCount}
          isTablet={isTablet}
          isMobile={isMobile}
        />
      );
      guideRoutes = <GuideRoutes />;
      pager = <GuidesPager count={guidesCount} onChangePage={onChangePage} />;
    }

    const content = (
      <div className="guides guides-workspace">
        {guidesList}
        {pager}
        {guideRoutes}
      </div>
    );

    return content;
  }
}
export default Guides;
