import './parser.css';

import isEmail from 'is-email';
import camelCase from 'lodash/camelCase';
import neatCSV from 'neat-csv';
import { isValid as isPhone } from 'phone-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Pagination,
  Segment,
  Table
} from 'semantic-ui-react';

import MyForm, { FormField } from '../../../ui/form';
import Scrollarea from './scrollarea';

class Parser extends Component {
  static propTypes = {
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    onChange: PropTypes.func,
    text: PropTypes.string
  };

  state = {
    columns: {},
    customColumnKey: null,
    customColumnName: null,
    customFields: [],
    data: null,
    dirty: false,
    page: 1,
    pageSize: 20,
    parseOptions: {
      strict: true
    }
  };

  componentDidMount() {
    this._parse();
  }

  _parse = () => {
    const { text } = this.props;
    const { columns, dirty, parseOptions } = this.state;

    this.setState({ parsing: true });

    try {
      neatCSV(text, {
        mapHeaders: ({ header }) => camelCase(header),
        ...parseOptions
      })
        .then(data => {
          const guessedColumns = this._guessColumns(data);
          this.setState(
            {
              columns: dirty ? columns : guessedColumns,
              data,
              parsing: false
            },
            this._onChange
          );
        })
        .catch(error => {
          this.setState({ error, parsing: false });
        });
    } catch (error) {
      this.setState({ error, parsing: false });
    }
  };

  _guessColumns(data) {
    const { parseOptions } = this.state;

    const [, row] = data;
    if (!row) {
      return {};
    }

    return Object.keys(row).reduce((acc, key) => {
      const guess =
        parseOptions.headers === false
          ? this._guessFieldByValue(row[key])
          : this._guessFieldByKey(key);
      if (guess) {
        acc[key] = guess;
      }
      return acc;
    }, {});
  }

  _guessFieldByValue(value) {
    if (isEmail(value)) {
      return 'emailAddress';
    }
    if (isPhone(value)) {
      return 'phoneNumber';
    }
  }

  _guessFieldByKey(key) {
    const lower = key.toLowerCase();

    if (lower.includes('email') || lower.includes('e-mail')) {
      return 'emailAddress';
    }
    if (lower.includes('name')) {
      if (lower.includes('first')) {
        return 'firstName';
      }
      if (lower.includes('last')) {
        return 'lastName';
      }
    }
    if (lower.includes('phone')) {
      return 'phoneNumber';
    }
    if (lower.includes('city')) {
      return 'city';
    }
    if (lower.includes('state')) {
      return 'state';
    }
    if (lower.includes('zip')) {
      return 'zip';
    }
    if (lower.includes('dob') || lower.includes('birth')) {
      return 'dateOfBirth';
    }
  }

  render() {
    const { error, parsing } = this.state;

    if (error) {
      return <Message negative>{error.message}</Message>;
    }

    return (
      <Dimmer.Dimmable
        as={Segment}
        className="admin-user-import-parser"
        blurring
        dimmed={parsing}
      >
        <Dimmer inverted active={parsing}>
          <Loader />
        </Dimmer>
        {this._renderOptions()}
        <Scrollarea className="scrollarea">
          <Table>
            {this._renderHeaders()}
            {this._renderData()}
          </Table>
        </Scrollarea>
        {this._renderPagination()}
        {this._renderCustomColumnDialog()}
      </Dimmer.Dimmable>
    );
  }

  _renderOptions() {
    const { parseOptions } = this.state;

    const data = {
      headers: !('headers' in parseOptions)
    };

    return (
      <MyForm data={data} onChange={this._onParseOptionsChange}>
        <p>Parsing options:</p>
        <FormField
          component={Checkbox}
          name="headers"
          label="Headers in First Row"
          aria-label="Headers in First Row"
          toggle
          checked={data.headers}
        />
      </MyForm>
    );
  }

  _renderHeaders() {
    const { data } = this.state;

    if (!data) {
      return null;
    }

    const [row] = data;
    if (!row) {
      return null;
    }

    const keys = Object.keys(row);

    return (
      <Table.Header>
        <Table.Row>{keys.map(this._renderHeader)}</Table.Row>
      </Table.Header>
    );
  }

  _renderHeader = key => {
    const { fields } = this.props;
    const { columns, customFields } = this.state;

    const column = columns[key] || '';

    const availableFields = fields
      .filter(
        field =>
          column === field.name || !Object.values(columns).includes(field.name)
      )
      .concat(
        customFields.filter(field =>
          Object.values(columns).includes(field.name)
        )
      );

    return (
      <Table.HeaderCell key={`data-header-${key}`}>
        <Dropdown
          scrolling
          value={column}
          options={[
            { text: '', value: '' },
            { text: 'Custom', value: 'custom' }
          ].concat(
            availableFields.map(field => ({
              text: field.label,
              value: field.name
            }))
          )}
          onChange={(event, { value }) => this._onColumnSelect(key, value)}
        />
      </Table.HeaderCell>
    );
  };

  _renderData() {
    const { data, page, pageSize } = this.state;

    if (!data) {
      return null;
    }

    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    const pageData = data.slice(start, end);

    return <Table.Body>{pageData.map(this._renderDataRow)}</Table.Body>;
  }

  _renderDataRow = (data, i) => {
    const keys = Object.keys(data);

    return (
      <Table.Row key={`data-row-${i}`}>
        {keys.map(key => (
          <Table.Cell key={`data-cell-${key}`}>{data[key]}</Table.Cell>
        ))}
      </Table.Row>
    );
  };

  _renderPagination() {
    const { data, page, pageSize } = this.state;

    if (!data) {
      return null;
    }

    const count = data.length;
    const totalPages = Math.ceil(count / pageSize);

    return (
      <div style={{ textAlign: 'center' }}>
        <Pagination
          activePage={page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > pageSize
              ? { content: <Icon name="angle left lineawesome" />, icon: true }
              : null
          }
          nextItem={
            count > pageSize
              ? { content: <Icon name="angle right lineawesome" />, icon: true }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </div>
    );
  }

  _renderCustomColumnDialog() {
    const {
      columns,
      customColumnKey,
      customColumnName,
      customFields
    } = this.state;

    if (!customColumnKey) {
      return null;
    }

    return (
      <Modal
        open
        size="tiny"
        onClose={() => {
          this.setState({ customColumnKey: null });
        }}
      >
        <Modal.Content>
          <Modal.Description>
            <MyForm
              data={{ columnName: customColumnName }}
              onChange={data =>
                this.setState({ customColumnName: data.columnName })
              }
            >
              <FormField
                component={Input}
                name="columnName"
                label="Column Name"
                type="text"
                autoComplete="false"
                validator={({ columnName }) => {
                  if (!columnName) {
                    throw new Error('Must specify column name');
                  }
                }}
              />
            </MyForm>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ customColumnKey: false });
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            disabled={!customColumnName}
            onClick={() => {
              const name = camelCase(customColumnName);
              this.setState(
                {
                  columns: {
                    ...columns,
                    [customColumnKey]: name
                  },
                  customColumnKey: null,
                  customFields: customFields.concat([
                    {
                      name,
                      label: customColumnName
                    }
                  ])
                },
                this._onChange
              );
            }}
          >
            Set Custom Column Name
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onColumnSelect = (key, value) => {
    const { columns } = this.state;

    if (value === 'custom') {
      this.setState({ customColumnKey: key });
    } else {
      this.setState(
        {
          columns: {
            ...columns,
            [key]: value
          },
          dirty: true
        },
        this._onChange
      );
    }
  };

  _onChange = () => {
    const { onChange } = this.props;
    const { columns, data, parseOptions } = this.state;

    if (onChange) {
      onChange({
        columns,
        data,
        parseOptions
      });
    }
  };

  _onParseOptionsChange = data => {
    const parseOptions = {
      ...(data.headers ? null : { headers: false }),
      strict: true
    };

    this.setState({ parseOptions }, this._parse);
  };

  _onPageChange = (e, { activePage }) => {
    this.setState({
      page: activePage
    });
  };
}
export default Parser;
