import './article-link.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, Dimmer, Image, Loader, Segment } from 'semantic-ui-react';

import * as tracker from '../../../tracker';

const EmbedRequests = {};

function getUrl(str) {
  if (/$https?:\/\//.test(str)) {
    return str;
  }
  const match = str.match(/>Embedded content: ([^<]+)/);
  if (match) {
    const [, url] = match;
    return url;
  }
}

function getEmbedContent(url, id) {
  if (EmbedRequests[url]) {
    return EmbedRequests[url];
  }

  const request = fetch(
    `${process.env.REACT_APP_EMBED_API_URL}?url=${encodeURIComponent(
      url
    )}&id=${id}`
  ).then(response => response.json());

  EmbedRequests[url] = request;

  return request;
}

class ArticleLink extends Component {
  static propTypes = {
    articleLink: PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      description: PropTypes.string,
      featuredImage: PropTypes.shape({
        id: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string
        })
      }),
      isFeatured: PropTypes.bool,
      title: PropTypes.string
    }).isRequired,
    channel: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired
  };

  state = {
    data: null,
    error: null,
    loading: true,
    iconIndex: 0,
    thumbnailIndex: 0
  };

  componentDidMount() {
    const { articleLink } = this.props;

    const url = getUrl(articleLink.body);
    if (!url) {
      this.setState({
        error: new Error('No embedable content in article link'),
        loading: false
      });
      return;
    }

    getEmbedContent(url, articleLink.id)
      .then(data => {
        if (data.error) {
          this.setState({ error: data.error, loading: false });
        } else {
          this.setState({ data, loading: false });
        }
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  render() {
    const { articleLink } = this.props;
    const { data, error, loading, iconIndex, thumbnailIndex } = this.state;

    const urlStr = getUrl(articleLink.body);
    let url = null;
    try {
      url = new URL(urlStr);
    } catch (error) {
      /* noop */
    }

    const icon = data && data.links.icon && data.links.icon[iconIndex];
    const thumbnail = articleLink.featuredImage
      ? { href: articleLink.featuredImage.file.url }
      : data && data.links.thumbnail && data.links.thumbnail[thumbnailIndex];
    const ratio =
      thumbnail && thumbnail.media
        ? thumbnail.media.width / thumbnail.media.height
        : 1.25;

    const link = (
      <a
        href={urlStr}
        onClick={this._onLinkClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read the article{url && ` on ${url.hostname}`}&nbsp;&gt;
      </a>
    );

    return (
      <Card className="article-link">
        {loading && (
          <Card.Content className="placeholder">
            <Segment basic>
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
              <div className="short-paragraph" />
              <Image src="/wireframe/image.png" />
              <div className="paragraph" />
            </Segment>
          </Card.Content>
        )}
        {thumbnail && (
          <div
            className={`thumbnail ${ratio >= 1.25 ? 'landscape' : 'portrait'}`}
            style={{
              backgroundImage: `url(${thumbnail.href})`
            }}
          >
            <img
              src={thumbnail.href}
              onError={() => {
                this.setState({ thumbnailIndex: thumbnailIndex + 1 });
              }}
              style={{ display: 'none' }}
            />
          </div>
        )}
        {data && (
          <Card.Content>
            <Card.Meta className="site">
              {icon && (
                <img
                  className="icon"
                  src={icon.href}
                  onError={() => {
                    this.setState({ iconIndex: iconIndex + 1 });
                  }}
                />
              )}
              <span className="name">
                {data.meta.site || (url && url.hostname)}
              </span>
              {data.meta.author && data.meta.author !== data.meta.site && (
                <span className="author">{data.meta.author}</span>
              )}
            </Card.Meta>
            <Card.Header>{articleLink.title || data.meta.title}</Card.Header>
            <Card.Description>
              {articleLink.description || data.meta.description}
            </Card.Description>
            {link}
          </Card.Content>
        )}
        {error && (
          <Card.Content className="placeholder">
            <div className="short-paragraph" />
            <Image src="/wireframe/image.png" />
            <Card.Description>Preview Unavailable</Card.Description>
            {link}
          </Card.Content>
        )}
        <Card.Content extra>
          {articleLink.tags.map(tag => `${tag}`).join('; ')}
        </Card.Content>
      </Card>
    );
  }

  _onLinkClick = () => {
    const { articleLink, channel } = this.props;

    tracker.event('articleLinkClick', 1, {
      channel: channel.slug,
      id: articleLink.id,
      href: event.target.href
    });
  };
}
export default ArticleLink;
