import { APP_ROOT, ROLES } from '../../../consts';

export default [
  /* DASHBOARD */
  {
    name: 'dashboard',
    label: 'Overview',
    to: `${APP_ROOT}/dashboard`,
    icon: 'star outline lineawesome',
    description: 'Overview',
    roles: [ROLES.ADMIN, ROLES.GUIDE]
  },
  {
    name: 'channel-dashboard',
    label: 'Overview',
    to: `${APP_ROOT}/:channelName/dashboard`,
    icon: 'calendar outline',
    description: 'Overview',
    roles: [ROLES.CLIENT]
  },
  {
    name: 'portal',
    label: 'Portal',
    to: APP_ROOT,
    icon: 'home lineawesome',
    isSubmenuItem: true,
    description: 'Return to the portal page',
    roles: [ROLES.CLIENT]
  },
  /* ADMIN */
  {
    name: 'reporting',
    label: 'Reporting',
    to: `${APP_ROOT}/admin/reporting`,
    icon: 'pie chart',
    description: 'View admin reports',
    roles: [ROLES.ADMIN]
  },
  {
    name: 'guide-applications',
    label: 'Guide Applications',
    to: `${APP_ROOT}/admin/guide-applications`,
    icon: 'clipboard',
    description: 'View and approve guide applications',
    roles: [ROLES.ADMIN]
  },
  {
    name: 'guide-availability',
    label: 'Guide Availability',
    to: `${APP_ROOT}/guide-availability`,
    icon: 'calendar',
    description: 'View and manage guide availability times',
    roles: [ROLES.ADMIN, ROLES.CONCIERGE]
  },
  {
    name: 'call-script',
    label: 'WBC Call Script',
    //to: `${APP_ROOT}/concierge/call-script`,
    to:
      'https://docs.google.com/document/d/1tfmZECHtfwkUY1ZEdD_aNjLVEic6OwcLXFOuNRvQYKo/view',
    icon: 'microphone',
    description: 'Learn the WBC script to manage calls effectively',
    roles: [ROLES.CONCIERGE]
  },
  {
    name: 'crisis-protocol',
    label: 'Crisis Protocol',
    to: `${APP_ROOT}/concierge/crisis-protocol`,
    icon: 'exclamation',
    description: 'Follow these steps when participants are in crisis',
    roles: [ROLES.CONCIERGE]
  },
  {
    name: 'admin-users',
    label: 'Users',
    to: `${APP_ROOT}/admin/users`,
    icon: 'users lineawesome',
    description: 'View and manage registered users',
    roles: [ROLES.ADMIN]
  },
  {
    name: 'access-codes',
    label: 'Access Codes',
    to: `${APP_ROOT}/admin/access-codes`,
    icon: 'barcode',
    description: 'View and manage access codes',
    roles: [ROLES.ADMIN]
  },
  /* CLIENT */
  // {
  //   name: 'guides',
  //   label: 'Guides',
  //   to: `${APP_ROOT}/:channelName/guides`,
  //   icon: 'headphones lineawesome',
  //   description: 'Guides',
  //   roles: [ROLES.CLIENT]
  // },
  {
    name: 'resources',
    label: 'Resources',
    to: `${APP_ROOT}/:channelName/resources`,
    icon: 'book lineawesome',
    description: 'Resources',
    roles: [ROLES.CLIENT]
  },
  {
    name: 'calls',
    label: 'Call History',
    to: `${APP_ROOT}/:channelName/calls#history`,
    icon: 'phone lineawesome',
    description: 'Call History',
    roles: [ROLES.CLIENT, ROLES.GUIDE]
  },
  /* CONCIERGE */
  {
    name: 'concierge-calls',
    label: 'Calls',
    to: `${APP_ROOT}/concierge/call-scheduling`,
    icon: 'phone',
    description: 'View calls or place call requests for users',
    roles: [ROLES.CONCIERGE]
  },
  /* CORP ADMIN */
  {
    name: 'corp-admin-users',
    label: 'Manage Users',
    to: `${APP_ROOT}/corp-admin/users`,
    icon: 'users lineawesome',
    description: 'View and manage users within company',
    roles: [ROLES.CORP_ADMIN]
  },
  /* GUIDE */
  {
    name: 'training',
    label: 'Resources',
    to: `${APP_ROOT}/training`,
    icon: 'list lineawesome',
    description: 'Guide Training',
    roles: [ROLES.GUIDE]
  },
  {
    name: 'bank',
    label: 'Earnings',
    to: `${APP_ROOT}/bank`,
    icon: 'usd lineawesome',
    description: 'Bank',
    roles: [ROLES.GUIDE]
  },
  /* ACCOUNT/PROFILE */
  {
    name: 'profile',
    label: 'Your Profile',
    to: `${APP_ROOT}/settings/profile`,
    icon: 'user lineawesome',
    description: 'Edit your information',
    isSubmenuItem: true,
    roles: [ROLES.CLIENT, ROLES.GUIDE]
  },
  {
    name: 'account',
    label: 'Your Account',
    to: `${APP_ROOT}/settings/payment-options`,
    icon: 'money lineawesome',
    description: 'Access and refill your account credits',
    isSubmenuItem: true,
    roles: [ROLES.CLIENT]
  },
  /* HELP links */
  {
    name: 'help',
    label: 'Help',
    to: `http://help.lifeguides.com/client-knowledge-center`,
    icon: 'question lineawesome',
    description: 'Help',
    roles: [ROLES.CLIENT]
  },
  {
    name: 'logout',
    label: 'Log Out',
    to: `${APP_ROOT}/logout`,
    icon: 'sign-out lineawesome',
    description: 'Log Out',
    isSubmenuItem: true,
    roles: [ROLES.ADMIN, ROLES.CLIENT, ROLES.CONCIERGE, ROLES.GUIDE]
  }
];
