import { capitalize } from 'lodash';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';

const email = cell => {
  return <a href={`mailto:${cell}`}>{cell}</a>;
};

const status = cell => {
  let statusStyle = cell === 'FAILED' ? 'negative-style' : 'positive-style';
  return <span className={statusStyle}>{capitalize(cell)}</span>;
};

const capitalizedText = cell => {
  return <span>{capitalize(cell)}</span>;
};

const userToIcon = (cell, row, colModel) => {
  if (!cell) {
    return <Icon name="user" color="red" />;
  }
  const { id, firstName, lastName } = cell;
  const valToIcon = (
    <Link to={`${APP_ROOT}/admin/user/${id}`}>
      <Icon link name={`user`} />
    </Link>
  );

  const cta = `Go view ${firstName} ${lastName}'s user record.`;
  const popup = <Popup content={cta} trigger={valToIcon} />;
  return <span>{popup}</span>;
};

const readableErrorStatus = (cell, row, colModel) => {
  if (!cell) {
    return '';
  }

  let errorOutput = '';
  try {
    let parsed = JSON.parse(cell);
    let errors = parsed.errors || [
      { message: `Missing .errors on: ${parsed.toString()}` }
    ];
    errorOutput = _.map(errors, 'message').join('');
  } catch (err) {
    errorOutput = cell;
  }

  return errorOutput;
};

const dateTimeFromNow = cell => {
  if (!cell) {
    return null;
  }
  // cell looks like this:
  // 2019-05-21T18:50:44.966Z

  let dateTimeFromNow;

  try {
    dateTimeFromNow = moment(cell).fromNow();
  } catch (err) {
    dateTimeFromNow = err.toString();
  }

  return dateTimeFromNow;
};

const defaultFormatter = cell => {
  if (_.isNull(cell) || _.isUndefined(cell)) {
    return '';
  }
  if (_.isObject(cell)) {
    return JSON.stringify(cell);
  }
  return cell;
};

const _formatters = {
  email,
  status,
  capitalizedText,
  userToIcon,
  defaultFormatter,
  readableErrorStatus,
  dateTimeFromNow
};

export const getCellFormatter = format => {
  if (_.isFunction(format)) {
    return format;
  }

  return _formatters[format] || defaultFormatter;
};
