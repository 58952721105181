import './roadmaps.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Card, Header, Icon, Segment } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import RoadmapsBySubChannelIdsQuery from '../../../graphql/queries/roadmaps-by-sub-channel-ids.graphql';
import ResourceHeader from '../../ui/resource-header';
import RoadmapsItem from './roadmaps-item';

@graphql(RoadmapsBySubChannelIdsQuery, {
  name: 'roadmaps',
  options: ({ channel }) => {
    return {
      variables: {
        subChannelIds: channel.subChannels.map(subChannel => subChannel.id)
      }
    };
  }
})
class Roadmaps extends Component {
  static propTypes = {
    isTablet: PropTypes.bool.isRequired,
    channel: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      subChannels: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,

    match: PropTypes.object.isRequired,
    roadmaps: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      roadmaps: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired
  };

  state = {
    hovering: false
  };

  constructor(props) {
    super(props);

    const hideWelcome = localStorage.getItem('hideRoadmapsWelcome');

    this.state = { hideWelcome };
  }

  render() {
    const { loading } = this.props.roadmaps;

    if (loading) {
      return (
        <div className="resource-roadmaps resource-container">
          <Segment
            basic
            loading
            size={`massive`}
            className="resources-loader"
          />
        </div>
      );
    }

    return (
      <div className="resource-roadmaps resource-container">
        <ResourceHeader title="Resource Roadmap" />
        {this._renderWelcome()}
        {this._renderRoadmaps()}
      </div>
    );
  }

  _renderWelcome() {
    const { hideWelcome } = this.state;

    if (hideWelcome) {
      return null;
    }
    return (
      <Segment color="blue" inverted>
        <Header size="small">
          Customized for you!
          <Icon
            name="close"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              this.setState({ hideWelcome: true }, () => {
                localStorage.setItem('hideRoadmapsWelcome', 'true');
              });
            }}
          />
        </Header>
        <p>
          You’ve taken an important step by reaching out for support and
          guidance. I hope you are feeling better about managing your situation.
          Caring for a loved one is challenging, and even when things are going
          reasonably well, some days can be really tough. That’s why we consider
          it important to have someone to talk with and to help you navigate the
          road ahead.
          <br />
          <br />
          Your Roadmap was developed based on the areas you identified as most
          important to you at this time.
        </p>
      </Segment>
    );
  }

  _renderRoadmaps() {
    const { isTablet } = this.props;

    const columns = isTablet ? 2 : 3;
    const { roadmaps } = this.props.roadmaps;

    if (!roadmaps) {
      return <Segment>There are currently no roadmaps available.</Segment>;
    }

    return (
      <Card.Group itemsPerRow={columns} stackable>
        {roadmaps.map(this._roadmapToCard)}
      </Card.Group>
    );
  }

  _roadmapToCard = roadmap => {
    const { channel } = this.props;
    const url = `${APP_ROOT}/${channel.slug}/resources/roadmaps/${roadmap.id}`;
    return <RoadmapsItem roadmap={roadmap} url={url} />;
  };
}
export default Roadmaps;
