import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Segment, Select, Tab } from 'semantic-ui-react';

import withChannel from '../../hoc/with-channel';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import NotFoundPage from '../../ui/error-pages/404';
import Roadmap from './roadmap';
import Roadmaps from './roadmaps';
import SubChannel from './sub-channel';
import SubChannelsList from './sub-channels-list';

const Tabs = [
  {
    title: 'All',
    path: '/'
  },
  {
    title: 'Roadmap',
    path: '/roadmaps'
  }
];
const selectOptions = [
  {
    text: 'All',
    value: '/'
  },
  {
    text: 'Roadmap',
    value: '/roadmaps'
  }
];

@withChannel({ required: true })
@withRouter
@withWidth()
class Resources extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        slug: PropTypes.string,
        subChannels: PropTypes.array
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired
  };

  state = {
    activeIndex: 0
  };

  componentDidUpdate(previousProps) {
    const { channel } = this.props.channel;
    if (channel && !previousProps.channel.channel) {
      this._setActiveTab();
    }
  }

  _setActiveTab() {
    const { location } = this.props;

    const active = Tabs.slice()
      .sort((a, b) => b.path.length - a.path.length)
      .find(tab => {
        const path = this._childPath(tab.path);
        return location.pathname.startsWith(path);
      });
    const activeIndex = active ? Tabs.indexOf(active) : 0;

    this.setState({ activeIndex });
  }

  _childPath(path) {
    const { match } = this.props;
    const { channel } = this.props.channel;
    const root = match.path.replace(':channelName', channel.slug);
    return `${root}${path}`;
  }

  render() {
    return (
      <div className="resources">
        {this._renderTabs()}
        {this._renderContent()}
      </div>
    );
  }

  _renderTabs() {
    const { width } = this.props;
    const { loading } = this.props.channel;
    const { activeIndex } = this.state;

    const isTablet = isWidthDown('computer', width);

    let content = null;

    if (!loading) {
      content = (
        <Tab
          className="tab-navigation"
          menu={{ color: 'blue', secondary: true, pointing: true }}
          panes={Tabs.map(tab => ({
            menuItem: tab.title,
            render: () => null
          }))}
          activeIndex={activeIndex}
          onTabChange={this._onTabChange}
          fluid
        />
      );

      if (isTablet) {
        content = (
          <Select
            className="select-navigation"
            placeholder="All"
            options={selectOptions}
            onChange={this._onSelectChange}
            fluid
          />
        );
      }
    }

    return <div className="resource-menu">{content}</div>;
  }

  _renderContent() {
    const { width } = this.props;
    const { channel, loading } = this.props.channel;

    let content = (
      <Segment basic loading size={`massive`} className="resources-loader" />
    );

    const isTablet = isWidthDown('computer', width);

    if (!loading) {
      content = (
        <Switch>
          <Route
            path={this._childPath('/')}
            exact
            render={() => (
              <SubChannelsList channel={channel} isTablet={isTablet} />
            )}
          />
          <Route
            path={this._childPath('/roadmaps')}
            exact
            render={() => <Roadmaps channel={channel} isTablet={isTablet} />}
          />
          <Route
            path={this._childPath('/roadmaps/:id')}
            exact
            render={() => <Roadmap channel={channel} />}
          />
          <Route
            path={this._childPath('/sub-channel/:id')}
            exact
            render={() => <SubChannel channel={channel} isTablet={isTablet} />}
          />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      );
    }

    return <div className="resources-content">{content}</div>;
  }

  _onSelectChange = (event, data) => {
    const { history } = this.props;

    const activeIndex = selectOptions.findIndex(
      option => option.value === data.value
    );
    const path = this._childPath(data.value);
    history.push(path);

    this.setState({ activeIndex });
  };

  _onTabChange = (event, { activeIndex }) => {
    const { history } = this.props;

    const tab = Tabs[activeIndex];
    const path = this._childPath(tab.path);
    history.push(path);

    this.setState({ activeIndex });
  };
}
export default Resources;
