function statusToColor(status) {
  switch (status) {
    case 'CANCELLED':
      return {
        background: 'rgba(255, 0, 0, 0.3)',
        border: 'rgba(200,0,200,1)'
      };
    case 'COMPLETED':
      return {
        background: 'rgba(200,200,200,0.6)',
        border: 'rgba(200,200,200,1)'
      };
    case 'NEW':
      return {
        background: 'rgba(100,0,200,0.3)',
        border: 'rgba(100,0,200,0.8)'
      };
    default:
      return {
        background: 'rgba(200,0,200,0.3)',
        border: 'rgba(200,0,200,0.8)'
      };
  }
}

export default statusToColor;
