import './spouse-invite-form.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import BaseForm from '../../ui/spouse-invite-form';

class SpouseInviteForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
  };

  state = {
    data: null,
    isValid: false
  };

  render() {
    const { submitting } = this.props;
    const { isValid } = this.state;

    return (
      <div className="spouse-invite-form">
        <BaseForm
          onChange={data => {
            this.setState({ data });
          }}
          onValidate={errors => {
            this.setState({ isValid: isEmpty(errors) });
          }}
        >
          <Form.Button
            className="continue-btn"
            primary
            fluid
            size="huge"
            content="Send Invitation"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}
          />
          <br />
          <Form.Button
            basic
            fluid
            content="Skip For Now"
            loading={submitting}
            disabled={submitting}
            onClick={this._onSkip}
          />
        </BaseForm>
      </div>
    );
  }

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    if (onSubmit) {
      onSubmit(data);
    }
  };

  _onSkip = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  };
}
export default SpouseInviteForm;
