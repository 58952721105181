import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import UpdateOrCreateAccessCodeWithCompanyMutation from '../../../../graphql/mutations/update-or-create-access-code-with-company.graphql';
import UpdateOrCreateAccessCodeMutation from '../../../../graphql/mutations/update-or-create-access-code.graphql';
import AllAccessCodesQuery from '../../../../graphql/queries/all-access-codes.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import DataGrid from '../../../ui/data-grid';
import ErrorDialog from '../../../ui/error-dialog';
import Notification from '../../../ui/notification';
import EditDialog from './edit-dialog';

@withUser({ authenticated: [ROLES.ADMIN] })
@graphql(AllAccessCodesQuery, { name: 'accessCodes' })
@graphql(UpdateOrCreateAccessCodeMutation, {
  name: 'updateOrCreate',
  options: {
    refetchQueries: [{ query: AllAccessCodesQuery }]
  }
})
@graphql(UpdateOrCreateAccessCodeWithCompanyMutation, {
  name: 'updateOrCreateWithCompany',
  options: {
    refetchQueries: [{ query: AllAccessCodesQuery }]
  }
})
class AccessCodes extends Component {
  static propTypes = {
    accessCodes: PropTypes.shape({
      accessCodes: PropTypes.arrayOf(PropTypes.shape({})),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    updateOrCreate: PropTypes.func.isRequired,
    updateOrCreateWithCompany: PropTypes.func.isRequired
  };

  state = {
    accessCode: null,
    error: null,
    orderColumn: 'createdAt',
    orderDirection: 'descending',
    submitting: false,
    success: false,
    successMessage: null
  };

  render() {
    const { error, success, successMessage } = this.state;

    return (
      <div>
        <Button
          primary
          floated="right"
          onClick={() => {
            this.setState({ accessCode: {} });
          }}
        >
          <Icon name="plus" />
          Create Access Code
        </Button>
        <Header as="h1">Access Codes</Header>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
        <Notification
          open={success}
          onClose={() => {
            this.setState({ success: false });
          }}
        >
          <Icon name="check" color="green" /> {successMessage}
        </Notification>
        {this._renderAccessCodes()}
        {this._renderEditDialog()}
      </div>
    );
  }

  _renderAccessCodes() {
    const { accessCodes, loading } = this.props.accessCodes;
    const { orderColumn, orderDirection } = this.state;

    const orderedAccessCodes = orderBy(
      accessCodes,
      orderColumn,
      orderDirection === 'ascending' ? 'asc' : 'desc'
    );

    const columns = [
      {
        prop: 'name',
        label: 'Name',
        sortable: true
      },
      {
        prop: 'code',
        label: 'Code',
        sortable: true
      },
      {
        prop: 'company.name',
        label: 'Company'
      },
      {
        prop: 'callCredits',
        label: 'Call Credits',
        sortable: true
      },
      {
        prop: 'initialPrice',
        label: 'Initial Price',
        sortable: true
      },
      {
        prop: 'startsAt',
        label: 'Starts',
        formatter: 'dateTimeFromNow',
        sortable: true
      },
      {
        prop: 'endsAt',
        label: 'Ends',
        formatter: 'dateTimeFromNow',
        sortable: true
      },
      {
        prop: 'createdAt',
        label: 'Created',
        formatter: 'dateTimeFromNow',
        sortable: true
      }
    ];

    return (
      <div>
        <DataGrid
          columns={columns}
          data={orderedAccessCodes}
          loading={loading}
          sortColumn={orderColumn}
          sortDirection={orderDirection}
          onRowClick={accessCode => {
            this.setState({ accessCode });
          }}
          onSort={prop => {
            this._onSort(prop);
          }}
        />
      </div>
    );
  }

  _renderEditDialog() {
    const { accessCode, submitting } = this.state;

    if (!accessCode) {
      return null;
    }

    return (
      <EditDialog
        accessCode={accessCode}
        submitting={submitting}
        onClose={() => {
          this.setState({ accessCode: null });
        }}
        onSubmit={this._onSave}
      />
    );
  }

  _onSave = data => {
    const { updateOrCreate, updateOrCreateWithCompany } = this.props;
    const { accessCode } = this.state;

    const isNew = !accessCode.id;

    const variables = {
      id: accessCode.id || '',
      ...data
    };

    this.setState({ error: null, submitting: true });
    const mutation = data.companyId
      ? updateOrCreateWithCompany
      : updateOrCreate;
    mutation({ variables })
      .then(() => {
        this.setState({
          accessCode: null,
          submitting: false,
          success: true,
          successMessage: `Successfully ${
            isNew ? 'created' : 'updated'
          } access code`
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
      });
  };

  _onSort = name => {
    const { orderColumn, orderDirection } = this.state;

    if (name === orderColumn) {
      this.setState({
        orderDirection:
          orderDirection === 'ascending' ? 'descending' : 'ascending'
      });
    } else {
      this.setState({ orderColumn: name, orderDirection: 'ascending' });
    }
  };
}
export default AccessCodes;
