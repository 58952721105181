import qs from 'qs';

export const PAGE_SIZE = 10;
export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};
export const SEARCH_DEFAULTS = {
  page: 1,
  direction: SORT_DIRECTIONS.DESC,
  sort: 'createdAt'
};

export function parseSearch(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page,
    sort: search.sort || SEARCH_DEFAULTS.sort,
    direction: search.direction || SEARCH_DEFAULTS.direction
  };
}
