import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dimmer, Icon, Label, Loader, Popup } from 'semantic-ui-react';

import GuideCountsByChannelIdQuery from '../../../../../graphql/queries/guide-count-by-channel-id.graphql';
import graphql from '../../../../hoc/graphql';

@graphql(GuideCountsByChannelIdQuery, {
  name: 'guideCounts',
  skip: ({ channel }) => !channel,
  options: ({ channel }) => ({ variables: { channelId: channel.id } })
})
class ChannelInfo extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    }),
    guideCounts: PropTypes.shape({
      loading: PropTypes.bool,
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    })
  };

  render() {
    const { channel } = this.props;

    if (!channel) {
      return <div className="info-box empty" />;
    }

    const { loading, usersConnection } = this.props.guideCounts;

    if (loading) {
      return (
        <Dimmer active inverted style={{ position: 'relative' }}>
          <Loader />
        </Dimmer>
      );
    }

    const availableGuideAmnt = usersConnection.aggregate.count;
    const s = availableGuideAmnt !== 1 ? 's' : '';
    return (
      <div className="info-box">
        <Popup
          trigger={
            <Label basic>
              <Icon name="users" />
              {availableGuideAmnt}
            </Label>
          }
          header="Available Guides"
          content={`${availableGuideAmnt} 
            guide${s} 
            available in the ${channel.title} channel`}
        />
      </div>
    );
  }
}
export default ChannelInfo;
