import React, { PureComponent } from 'react';
//import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

//import { APP_ROOT } from '../../../consts';
import GenericContactError from '../../ui/contact-us/generic-contact-error.js';

export default class ServerErrorPage extends PureComponent {
  render() {
    let errorMsg = 'There was an error!';
    return (
      <div>
        <Container fluid text textAlign="center">
          {/* <p>
            Please <Link to={`${APP_ROOT}/contact`}>contact us</Link> if this
            issue persists!
          </p> */}
          <GenericContactError error={errorMsg} />
        </Container>
      </div>
    );
  }
}
