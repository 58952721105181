import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';

class SpouseInviteForm extends Component {
  static propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func,
    onValidate: PropTypes.func
  };

  state = {
    data: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: ''
    }
  };

  render() {
    const { children, onValidate } = this.props;
    const { data } = this.state;

    return (
      <MyForm data={data} onChange={this._onChange} onValidate={onValidate}>
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="firstName"
            label="Spouse's First Name"
            aria-label="Spouse's First Name"
            aria-required="true"
            validator={({ firstName }) => {
              if (!firstName) {
                throw new Error('First name is required');
              }
            }}
          />
          <FormField
            component={Input}
            name="lastName"
            label="Spouse's Last Name"
            aria-label="Spouse's Last Name"
            aria-required="true"
            validator={({ lastName }) => {
              if (!lastName) {
                throw new Error('Last name is required');
              }
            }}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="emailAddress"
          label="Spouse's E-mail"
          aria-label="Spouse's E-mail"
          aria-required="true"
          type="email"
          validator={({ emailAddress }) => {
            if (!emailAddress) {
              throw new Error('E-mail Address is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="phoneNumber"
          label="Spouse's Mobile Number"
          aria-label="Spouse's Mobile Phone Number"
          aria-required="true"
          type="text"
          autoComplete="false"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Phone number is required');
            }
            let digits = phoneNumber.replace(/\D/g, '');
            if (
              !(
                (digits[0] === '1' && digits.length === 11) ||
                digits.length === 10
              )
            ) {
              throw new Error('Mobile number must have 10 digits');
            }
          }}
        />
        {children}
      </MyForm>
    );
  }

  _onChange = data => {
    const { onChange } = this.props;

    const formatted = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    this.setState({ data: formatted });

    if (onChange) {
      onChange(formatted);
    }
  };
}
export default SpouseInviteForm;
