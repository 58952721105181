import './with-width.css';

import React from 'react';
import { Responsive } from 'semantic-ui-react';

const Aliases = {
  computer: Responsive.onlyComputer,
  desktop: Responsive.onlyDesktop,
  large: Responsive.onlyLargeScreen,
  mobile: Responsive.onlyMobile,
  tablet: Responsive.onlyTablet,
  wide: Responsive.onlyWideScreen
};

export default function withWidth(options = {}) {
  return function componentWrapper(Component) {
    class WithWidth extends React.Component {
      static displayName = `withWidth(${Component.displayName ||
        Component.name})`;

      constructor(props) {
        super(props);

        this.state = {
          width: 0
        };

        this._mounted = true;
      }

      componentWillUnmount() {
        this._mounted = false;
      }

      render() {
        const { width } = this.state;

        const classNames = ['with-width'];
        if (options.className) {
          classNames.push(options.className);
        }

        return (
          <Responsive
            fireOnMount
            onUpdate={this._onUpdate}
            className={classNames.join(' ')}
          >
            <Component width={width} {...this.props} />
          </Responsive>
        );
      }

      _onUpdate = (event, { width }) => {
        if (this._mounted) {
          // Responsive onUpdate incorrectly called,
          // receiving setState on unmounted component warning.
          // At least prevent our code from generating a warning as well.
          this.setState({ width });
        }
      };
    }

    return WithWidth;
  };
}

function getBreakpoint(name) {
  return Responsive[name] || Aliases[name];
}

export function isWidth(name, width) {
  const breakpoint = getBreakpoint(name);
  if (!breakpoint) {
    return false;
  }
  const isAbove = breakpoint.minWidth ? width > breakpoint.minWidth : true;
  const isBelow = breakpoint.maxWidth ? width < breakpoint.maxWidth : true;
  return isAbove && isBelow;
}

export function isWidthDown(name, width) {
  const breakpoint = getBreakpoint(name);
  if (!breakpoint) {
    return false;
  }
  return breakpoint.minWidth ? width < breakpoint.minWidth : true;
}

export function isWidthUp(name, width) {
  const breakpoint = getBreakpoint(name);
  if (!breakpoint) {
    return false;
  }
  return breakpoint.maxWidith ? width > breakpoint.minWidth : true;
}
