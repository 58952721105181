import '../../../ui/powerful-cta/index.css';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import BugsnagClient from '../../../../bugsnag';
import InviteClientMutation from '../../../../graphql/mutations/invite-client.graphql';
import UserQuery from '../../../../graphql/queries/user.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import ErrorDialog from '../../../ui/error-dialog';
import SpouseInviteForm from '../../../ui/spouse-invite-form';

@graphql(InviteClientMutation, {
  name: 'inviteClient',
  options: {
    refetchQueries: [
      {
        query: UserQuery
      }
    ]
  }
})
@withUser({ authenticated: true })
class SpouseInviteDialog extends Component {
  static propTypes = {
    inviteClient: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired
  };

  state = {
    data: null,
    error: null,
    isValid: false,
    submitting: false
  };

  render() {
    const { error, isValid, submitting } = this.state;

    return (
      <Modal
        className="spouse-invite-dialog"
        open
        size="tiny"
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={this._onClose}
      >
        <Modal.Header>Invite Your Spouse</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <SpouseInviteForm
              onChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="powerful"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}
          >
            Send Invite
          </Button>
        </Modal.Actions>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
      </Modal>
    );
  }

  _onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  _onDataChange = data => {
    this.setState({ data });
  };

  _onValidate = errors => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { inviteClient, onSubmit } = this.props;
    const { User } = this.props.user;
    const { data } = this.state;

    const [companyConnection] = User.companyIds;

    const variables = {
      ...data,
      companyId: companyConnection.companyId,
      createdById: User.id,
      isSpouse: true
    };

    this.setState({ error: null, submitting: true });
    inviteClient({ variables })
      .then(() => {
        this.setState({ submitting: false });

        if (onSubmit) {
          onSubmit(data);
        }
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'SpouseInviteDialog._onSubmit',
          request: {
            ...variables
          }
        });
      });
  };
}
export default SpouseInviteDialog;
