import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

import FormField from './form-field';

export { FormField };

export default class MyForm extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onValidate: PropTypes.func
  };

  UNSAFE_componentWillMount() {
    this._validate(this.props.data);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!isEqual(this.props.data, nextProps.data)) {
      this._validate(nextProps.data);
    }
  }

  _validate(data) {
    const { onValidate } = this.props;

    if (!onValidate) {
      return;
    }

    const validators = this._getValidators(this);
    const errors = validators.reduce((acc, { name, validator }) => {
      try {
        validator(data);
      } catch (error) {
        return { ...acc, [name]: error };
      }
      return acc;
    }, {});

    onValidate(errors);
  }

  _getValidators(node) {
    const { children } = node.props;

    let validators = [];

    React.Children.forEach(children, child => {
      if (React.isValidElement(child)) {
        if (child.type === FormField) {
          if (child.props.validator) {
            validators.push(child.props);
          }
        } else {
          validators = validators.concat(this._getValidators(child));
        }
      }
    });

    return validators;
  }

  render() {
    const { children, data, onChange, onValidate, ...remaining } = this.props;

    return (
      <Form {...remaining}>
        {React.Children.map(children, this._renderChild)}
      </Form>
    );
  }

  _renderChild = child => {
    const { data } = this.props;

    if (!React.isValidElement(child)) {
      return child;
    }

    const { children, onChange, validator, ...remaining } = child.props;

    let _props = {
      ...remaining
    };

    if (child.type === FormField) {
      const { component, name } = _props;

      if (component === Checkbox) {
        _props.checked = data[name];
      } else {
        _props.value = data[name];
      }

      _props.onChange = (event, fieldData) => {
        this._onChange(fieldData);

        if (onChange) {
          onChange(event, fieldData);
        }
      };

      if (validator) {
        try {
          validator(data);
        } catch (error) {
          _props.error = error;
        }
      }
    }

    return React.cloneElement(child, {
      children: React.Children.map(children, this._renderChild),
      ..._props
    });
  };

  _onChange({ checked, name, type, value }) {
    const { data, onChange } = this.props;

    const newData = {
      ...data
    };

    if (type === 'checkbox') {
      newData[name] = checked;
    } else {
      newData[name] = value;
    }

    onChange(newData);
  }
}
