import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import GuideAboutDetails from './details';
import GuideAboutSubjects from './subjects';
import GuideAboutSummary from './summary';

class GuideAbout extends Component {
  static propTypes = {
    guide: PropTypes.object.isRequired,
    compressed: PropTypes.bool.isRequired,
    onClickVideo: PropTypes.func
  };

  render() {
    const { guide, compressed, onClickVideo } = this.props;

    return (
      <div className="guide-about-container">
        <div className="guide-about">
          <GuideAboutSummary
            guide={guide}
            compressed={compressed}
            onClickVideo={onClickVideo}
          />
          <GuideAboutDetails guide={guide} />
          <GuideAboutSubjects guide={guide} />
        </div>
      </div>
    );
  }
}

export default GuideAbout;
