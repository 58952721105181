import './index.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { Icon, Modal, Segment } from 'semantic-ui-react';

import { APP_ROOT, ROLES } from '../../../consts';
import GuideByIdChannelIdQuery from '../../../graphql/queries/guide-by-id-channel-id.graphql';
import * as tracker from '../../../tracker';
import graphql from '../../hoc/graphql';
import withChannel from '../../hoc/with-channel';
import withCompanies from '../../hoc/with-companies';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import GuideAbout from '../guide-about';
import GuideDetailsVideo from '../guide-video';
import SendCallRequest from '../send-call-request-dialog/send-call-request';

const SEARCH_DEFAULTS = {
  page: 1,
  searchTerm: '',
  itemsPerPage: 6,
  searchSpeed: 500
};

function parseQS(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    searchTerm: search.searchTerm || SEARCH_DEFAULTS.searchTerm,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page
  };
}

@withRouter
@withChannel({ loader: <Segment basic loading />, required: true })
@withCompanies({ loader: <Segment basic loading /> })
@graphql(GuideByIdChannelIdQuery, {
  name: 'guide',
  options: ({ channel, guideId }) => {
    return {
      variables: {
        id: guideId,
        channelId: channel.channel.id
      }
    };
  }
})
@withWidth()
class GuideDetailsDialog extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string
      }),
      loading: PropTypes.bool
    }),
    guide: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      user: PropTypes.object
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showScheduler: PropTypes.bool.isRequired,
    showVideo: PropTypes.bool,
    companies: PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }),
    width: PropTypes.number.isRequired,
    onClickVideo: PropTypes.func,
    onCloseModal: PropTypes.func,
    onClickLearnMore: PropTypes.func
  };

  componentDidMount() {
    const { channel } = this.props.channel;
    const data = { channel: channel.slug };
    if (this.props.companies) {
      const { companies } = this.props.companies;
      const [company] = companies;
      if (company) {
        data.companyId = company.id;
      }
    }

    tracker.event('selectGuide', 1, data);
  }

  render() {
    const { guide, width } = this.props;

    const isMobile = isWidthDown('tablet', width);

    const content = guide.loading
      ? this._renderLoading()
      : this._renderContent();

    if (isMobile) {
      return ReactDOM.createPortal(
        <div className="guide-details-dialog-mobile">
          <div className="guide-details-dialog-close mobile">
            <Icon
              name="close"
              color="blue"
              className="lineawesome"
              size="big"
              onClick={this._closeModal}
            />
          </div>
          {content}
        </div>,
        document.body
      );
    }

    return (
      <Modal
        open={true}
        onClose={this._closeModal}
        className="guide-details-dialog-modal"
      >
        <div className="guide-details-dialog-close">
          <Icon
            name="close"
            className="lineawesome"
            size="big"
            onClick={this._closeModal}
          />
        </div>
        {content}
      </Modal>
    );
  }

  _renderLoading = () => {
    return (
      <div style={{ padding: '2rem' }}>
        <Segment basic loading />
      </div>
    );
  };

  _renderContent = () => {
    const {
      history,
      showVideo,
      showScheduler,
      guide,
      onClickVideo,
      onClickLearnMore
    } = this.props;

    if (
      !guide.user ||
      !guide.user.roles.some(role => role.name === ROLES.GUIDE)
    ) {
      history.replace(`${APP_ROOT}/404`);
      return;
    }

    let about = (
      <GuideAbout
        guide={guide.user}
        compressed={false}
        onClickVideo={onClickVideo}
      />
    );
    let scheduler = null;

    if (showScheduler) {
      about = (
        <GuideAbout
          guide={guide.user}
          compressed={true}
          onClickVideo={onClickVideo}
        />
      );
      scheduler = (
        <SendCallRequest
          to={guide.user}
          showVideo={showVideo}
          onClickLearnMore={onClickLearnMore}
        />
      );
    }

    if (showVideo) {
      about = <GuideDetailsVideo guide={guide.user} />;
    }

    return (
      <div className={`guide-details-dialog`}>
        {about}
        {scheduler}
      </div>
    );
  };

  _closeModal = () => {
    const { history, location, match, onCloseModal } = this.props;

    if (_.isFunction(onCloseModal)) {
      //Do nothing, because we're overriding this functionality
      return onCloseModal();
    }

    let _route = match.path.replace(/\/?:id\/.*/, '');
    let { page, searchTerm } = parseQS(location.search);
    const newParams = qs.stringify({
      page,
      searchTerm
    });
    _route = `${_route}?${newParams}`;
    history.push(_route);
  };
}

export default GuideDetailsDialog;
