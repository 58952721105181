import 'hamburgers/dist/hamburgers.css';

import './index.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  Loader,
  Menu,
  Responsive
} from 'semantic-ui-react';

import { APP_ROOT, ROLES } from '../../../consts';
import UpdateUserTimezoneMutation from '../../../graphql/mutations/update-user-timezone.graphql';
import withAuth0 from '../../hoc/with-auth0';
import withUser from '../../hoc/with-user';
import withWidth from '../../hoc/with-width';
import Avatar from '../../ui/avatar';

@graphql(UpdateUserTimezoneMutation, { name: 'updateUserTimezone' })
@withAuth0()
@withUser()
@withWidth()
@withRouter
class User extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    menuOpen: PropTypes.bool,
    toggleMenu: PropTypes.func,
    updateUserTimezone: PropTypes.func.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        firstName: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        ),
        timezone: PropTypes.string
      })
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  state = {
    busy: false
  };

  componentDidUpdate(previousProps) {
    if (!previousProps.user.User && this.props.user.User) {
      this._checkUserTimezone();
    }
  }

  _checkUserTimezone() {
    const { User } = this.props.user;

    const timezone = moment.tz.guess();
    if (User.timezone) {
      return;
    }

    this._saveUserTimezone(timezone);
  }

  _saveUserTimezone(timezone) {
    const { User } = this.props.user;
    const { updateUserTimezone } = this.props;

    const variables = {
      id: User.id,
      timezone
    };

    updateUserTimezone({ variables });
  }

  render() {
    const { loading, User } = this.props.user;

    const _loading = loading || this.state.busy;

    if (_loading) {
      return this._renderLoading();
    }
    if (!User || !User.roles.length) {
      return this._renderUnauthenticated();
    }

    return this._renderUser();
  }

  _renderLoading() {
    return (
      <div className="avatar-container">
        <Loader active inline="centered" className="user-loader" />
      </div>
    );
  }

  _logInUser = () => {
    const { auth0 } = this.props;

    this.setState({
      busy: true
    });

    return auth0.connect(null, '/login');
  };

  _renderUnauthenticated = () => {
    return (
      <div className="avatar-container">
        <Button basic className="login-button" onClick={this._logInUser}>
          Sign In
        </Button>
      </div>
    );
  };

  _renderUser() {
    const { width } = this.props;

    const isMobile = width < Responsive.onlyTablet.minWidth;

    return isMobile ? this._renderMobileUser() : this._renderDesktopUser();
  }

  _renderMobileUser() {
    const toggleMenu = this.props.toggleMenu;

    if (this._isRoot()) {
      return (
        <div className="avatar-container">
          <Button basic className="login-button" onClick={this._logoutUser}>
            Log Out
          </Button>
        </div>
      );
    }

    const toggleClass = this.props.menuOpen ? 'is-active' : '';

    return (
      <div className="avatar-container">
        <button
          className={`hamburger hamburger--spin ${toggleClass} hamberder-override`}
          type="button"
          onClick={toggleMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    );
  }

  _renderMenuItem(url, icon, title, onClick) {
    const { history } = this.props;

    const href = history.createHref({ pathname: url });

    return (
      <Menu.Item
        key={`user-menu-item-${title}`}
        position="left"
        link
        href={href}
        onClick={event => {
          event.preventDefault();
          if (onClick) {
            onClick();
          } else {
            history.push(url);
          }
        }}
      >
        {icon && <Icon name={icon} />}
        <span style={{ marginLeft: '1em' }}>{title}</span>
      </Menu.Item>
    );
  }

  _isRoot() {
    const { location } = this.props;

    const rootRegex = new RegExp(`^${APP_ROOT}\\/?$`);
    return !!location.pathname.match(rootRegex);
  }

  _renderMenuTrigger = () => {
    const { User } = this.props.user;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center'
          }}
        >
          <Avatar
            user={User}
            style={{
              height: 30,
              width: 30,
              marginLeft: 15
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            padding: '8px'
          }}
        >
          <span>{User.firstName}</span>
        </div>
      </div>
    );
  };

  _logoutUser = () => {
    const { history } = this.props;
    history.push(`${APP_ROOT}/logout`);
  };

  _renderDesktopUser = () => {
    const { history } = this.props;
    const { User } = this.props.user;

    const isClient = User.roles.some(role => role.name === ROLES.CLIENT);

    return (
      <div className="avatar-container">
        <Menu.Item className="avatar-menu-container">
          <Dropdown
            title="Edit Profile or Log Out."
            role="button"
            aria-haspopup="true"
            trigger={this._renderMenuTrigger()}
            className="avatar-menu"
            style={{
              display: 'flex !important'
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                role="button"
                onClick={() => {
                  history.push(`${APP_ROOT}/settings/profile`);
                }}
              >
                Your Profile
              </Dropdown.Item>
              {isClient && (
                <Dropdown.Item
                  role="button"
                  onClick={() => {
                    history.push(`${APP_ROOT}/settings/payment-options`);
                  }}
                >
                  Account
                </Dropdown.Item>
              )}
              <Dropdown.Item
                role="button"
                onClick={() => {
                  history.push(`${APP_ROOT}`);
                }}
              >
                Main Page
              </Dropdown.Item>
              <Dropdown.Item role="button" onClick={this._logoutUser}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </div>
    );
  };
}
export default User;
