import Baby from './baby/baby.jpg';
import Baby2x from './baby/baby@2x.jpg';
import Baby3x from './baby/baby@3x.jpg';
import Candle from './candle/candle.jpg';
import Candle2x from './candle/candle@2x.jpg';
import Candle3x from './candle/candle@3x.jpg';
import DrAndWomen from './drandwomen/drandwomen.jpg';
import DrAndWomenx2 from './drandwomen/drandwomen@2x.jpg';
import DrAndWomenx3 from './drandwomen/drandwomen@3x.jpg';
import FBPNG from './facebook/f_logo_RGB-Blue_58.png';
import FBSVG from './facebook/facebook-logo.svg';
import FireFighter from './firefighter/firefighter.jpg';
import FireFighter2x from './firefighter/firefighter@2x.jpg';
import FireFighter3x from './firefighter/firefighter@3x.jpg';
import GOOGSVG from './google/google-logo.svg';
import GrandpaAndGrandDaughter from './grandpa-and-granddaughter/grandpaandgranddaughter.jpg';
import GrandpaAndGrandDaughter2x from './grandpa-and-granddaughter/grandpaandgranddaughter@2x.jpg';
import GrandpaAndGrandDaughter3x from './grandpa-and-granddaughter/grandpaandgranddaughter@3x.jpg';
import GuideVideoSplash from './guide-video-splash/guy-in-chair@3x.png';
import HoldingHandsAtSunset from './holding-hands-at-sunset/holding-hands-at-sunset.jpg';
import HoldingHandsAtSunset2x from './holding-hands-at-sunset/holding-hands-at-sunset@2x.jpg';
import HoldingHandsAtSunset3x from './holding-hands-at-sunset/holding-hands-at-sunset@3x.jpg';
import LadyWithTablet from './ladywithtablet/ladywithtablet.jpg';
import LadyWithTablet2x from './ladywithtablet/ladywithtablet@2x.jpg';
import LadyWithTablet3x from './ladywithtablet/ladywithtablet@3x.jpg';
import VerticalLadyWithTablet from './ladywithtablet/verticalladywithtablet.jpg';
import VerticalLadyWithTablet2x from './ladywithtablet/verticalladywithtablet@2x.jpg';
import VerticalLadyWithTablet3x from './ladywithtablet/verticalladywithtablet@3x.jpg';
import LGSVG from './lglogos/lg-logo.svg';
import LG from './lglogos/lg.png';
import LG2x from './lglogos/lg@2x.png';
import LG3x from './lglogos/lg@3x.png';
import BeckyOnPhone1 from './phone-people/becky-on-phone-1.jpg';
import BeckyOnPhone2 from './phone-people/becky-on-phone-2.jpg';
import BusinessWomanAtDesk from './phone-people/business-woman-at-desk.jpg';
import ConstructionWorkerOnPhone from './phone-people/construction-worker-on-phone.jpg';
import FemaleHandsHoldingPhone from './phone-people/female-hands-holding-phone.jpg';
import WomanHeadOnPhone from './phone-people/woman-head-on-phone.jpg';
import WomanSmilingPhone from './phone-people/woman-smiling-phone.jpg';
import WomanWithIpods from './phone-people/woman-with-ipods.jpg';
import Ribbons from './ribbons/ribbons.jpg';
import Ribbons2x from './ribbons/ribbons@2x.jpg';
import Ribbons3x from './ribbons/ribbons@3x.jpg';
import SeniorCouple from './seniorcouple/seniorcouple.jpg';
import Seniors from './seniors/seniors.jpg';
import Seniors2x from './seniors/seniors@2x.jpg';
import Seniors3x from './seniors/seniors@3x.jpg';
import ServiceFamily from './servicefamily/servicefamily.jpg';
import ServiceFamily2x from './servicefamily/servicefamily@2x.jpg';
import ServiceFamily3x from './servicefamily/servicefamily@3x.jpg';
import Vigil from './vigil/vigil.jpg';
import Vigil2x from './vigil/vigil@2x.jpg';
import Vigil3x from './vigil/vigil@3x.jpg';

export default {
  Baby,
  Baby2x,
  Baby3x,
  BeckyOnPhone2,
  BeckyOnPhone1,
  BusinessWomanAtDesk,
  Candle,
  Candle2x,
  Candle3x,
  ConstructionWorkerOnPhone,
  DrAndWomen,
  DrAndWomenx2,
  DrAndWomenx3,
  FBPNG,
  FBSVG,
  FemaleHandsHoldingPhone,
  FireFighter,
  FireFighter2x,
  FireFighter3x,
  GOOGSVG,
  GrandpaAndGrandDaughter,
  GrandpaAndGrandDaughter2x,
  GrandpaAndGrandDaughter3x,
  HoldingHandsAtSunset,
  HoldingHandsAtSunset2x,
  HoldingHandsAtSunset3x,
  LG,
  LG2x,
  LG3x,
  LGSVG,
  LadyWithTablet,
  LadyWithTablet2x,
  LadyWithTablet3x,
  Ribbons,
  Ribbons2x,
  Ribbons3x,
  SeniorCouple,
  Seniors,
  Seniors2x,
  Seniors3x,
  ServiceFamily,
  ServiceFamily2x,
  ServiceFamily3x,
  VerticalLadyWithTablet,
  VerticalLadyWithTablet2x,
  VerticalLadyWithTablet3x,
  Vigil,
  Vigil2x,
  Vigil3x,
  WomanHeadOnPhone,
  WomanSmilingPhone,
  WomanWithIpods,
  GuideVideoSplash
};

export {
  Baby,
  Baby2x,
  Baby3x,
  BeckyOnPhone2,
  BeckyOnPhone1,
  BusinessWomanAtDesk,
  Candle,
  Candle2x,
  Candle3x,
  ConstructionWorkerOnPhone,
  DrAndWomen,
  DrAndWomenx2,
  DrAndWomenx3,
  FBPNG,
  FBSVG,
  FemaleHandsHoldingPhone,
  FireFighter,
  FireFighter2x,
  FireFighter3x,
  GOOGSVG,
  GrandpaAndGrandDaughter,
  GrandpaAndGrandDaughter2x,
  GrandpaAndGrandDaughter3x,
  HoldingHandsAtSunset,
  HoldingHandsAtSunset2x,
  HoldingHandsAtSunset3x,
  LG,
  LG2x,
  LG3x,
  LGSVG,
  LadyWithTablet,
  LadyWithTablet2x,
  LadyWithTablet3x,
  Ribbons,
  Ribbons2x,
  Ribbons3x,
  SeniorCouple,
  Seniors,
  Seniors2x,
  Seniors3x,
  ServiceFamily,
  ServiceFamily2x,
  ServiceFamily3x,
  VerticalLadyWithTablet,
  VerticalLadyWithTablet2x,
  VerticalLadyWithTablet3x,
  Vigil,
  Vigil2x,
  Vigil3x,
  WomanHeadOnPhone,
  WomanSmilingPhone,
  WomanWithIpods,
  GuideVideoSplash
};
