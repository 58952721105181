import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';

const VWIDTH = 1024;
const VHEIGHT = 576;
const VRATIO = VHEIGHT / VWIDTH;
const VGUTTER = 40;
const HGUTTER = 80;

class ExplainerVideo extends Component {
  state = {
    vWidth: 1024,
    vHeight: 576,
    isMobile: false
  };
  static propTypes = {
    videoOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func
  };
  updateDimensions = () => {
    const { innerWidth, innerHeight } = window;
    let maxWidth = Math.round(VWIDTH - VGUTTER);

    if (innerWidth > maxWidth) {
      if (this.state.vWidth != maxWidth) {
        this.setState({
          vWidth: maxWidth,
          vHeight: Math.floor(maxWidth * VRATIO),
          isMobile: false
        });
      }
    } else {
      let smallerWidth = Math.round(innerWidth - VGUTTER);
      let hGutter = 0;
      if (innerWidth > innerHeight) {
        hGutter = HGUTTER;
        smallerWidth = smallerWidth - hGutter;
      }
      if (this.state.vWidth != smallerWidth) {
        this.setState({
          vWidth: smallerWidth,
          vHeight: Math.floor(smallerWidth * VRATIO),
          isMobile: true
        });
      }
    }
  };
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { vWidth, vHeight } = this.state;

    return (
      <Modal
        open={this.props.videoOpen}
        onClose={this.props.onClose}
        className="video-modal"
        style={{
          width: vWidth,
          height: vHeight
        }}
        closeIcon={this.state.isMobile}
      >
        <iframe
          src="https://player.vimeo.com/video/323526295?byline=0&portrait=0"
          width={`100%`}
          height={`100%`}
          frameBorder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
        />
      </Modal>
    );
  }
}
export default ExplainerVideo;
