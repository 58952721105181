import './call-request.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, List } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import CallEntryBase from '../../ui/call-entry';
import GuideCallActions from '../communications/controlpanel';
import ReceiveCallRequestDialog from '../receive-call-request-dialog';

@withUser({ authenticated: true })
class CallRequestEntry extends Component {
  static propTypes = {
    callRequest: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string
      }),
      from: PropTypes.shape({
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            intakeAnswers: PropTypes.arrayOf(
              PropTypes.shape({
                answer: PropTypes.string,
                intakeQuestion: PropTypes.shape({
                  iconName: PropTypes.string,
                  title: PropTypes.string
                })
              })
            ),
            subChannels: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string
              })
            )
          })
        )
      })
    }).isRequired,
    isTablet: PropTypes.bool,
    isBanner: PropTypes.bool,
    updateCallRequestStatus: PropTypes.func,
    user: PropTypes.shape({
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    accepting: false
  };

  render() {
    const { callRequest, isBanner, isTablet } = this.props;
    const { User } = this.props.user;

    const isGuide = this._isGuide();
    const otherUser = this._otherUserOnCall();
    const subheader = this._renderSubheader();

    const shouldRenderOtherUseronCallinfo = isGuide && isTablet;

    return (
      <CallEntryBase
        channel={callRequest.channel}
        isTablet={isTablet}
        user={otherUser}
        curUser={User}
        userRole={isGuide ? 'GUIDE' : 'CLIENT'}
        isGuide={isGuide}
        subheader={subheader}
        isBanner={isBanner}
        className="requested-calls"
      >
        {shouldRenderOtherUseronCallinfo && this._renderOtherPersonOnCallInfo()}
        {isGuide && this._renderSuggestedTimes()}
        {isGuide && this._renderPickSuggestedTime()}
      </CallEntryBase>
    );
  }

  _getIntakeAnswers(user) {
    const { callRequest } = this.props;
    if (!user.channelProfiles) {
      return [];
    }
    const channelProfile = user.channelProfiles.find(
      p => p.channel.id === callRequest.channel.id
    );
    if (!channelProfile) {
      return [];
    }
    return channelProfile.intakeAnswers.map(ia => ia.answer);
  }

  _renderOtherPersonOnCallInfo = () => {
    const otherUser = this._otherUserOnCall();

    const answers = this._getIntakeAnswers(otherUser);

    if (!answers.length) {
      return null;
    }

    return (
      <List.Content
        style={{
          color: '#bebebe'
        }}
        /* took out width: '25%',
        padding: '0.5em 0em 0.5em 2em'*/
      >
        {answers.join(', ')}
      </List.Content>
    );
  };

  _renderSubheader = () => {
    const { isTablet, callRequest } = this.props;

    const isGuide = this._isGuide();

    return isGuide ? (
      !isTablet ? (
        <div className="intake-and-subchannel">
          <div className="intake">{this._renderIntakeAnswers()}</div>
          <div className="subchannel">{this._renderSubChannel()}</div>
          <div>
            <GuideCallActions callRequest={callRequest} />
          </div>
        </div>
      ) : null
    ) : (
      <div>
        <span>Awaiting Response to requested times</span>
        <div>
          <GuideCallActions callRequest={callRequest} />
        </div>
      </div>
    );
  };

  _renderIntakeAnswers() {
    const { callRequest } = this.props;
    const { from } = callRequest;

    const intakeAnswers = this._getIntakeAnswers(from);
    return intakeAnswers.join(', ');
  }

  _renderSubChannel() {
    const { callRequest } = this.props;
    const { from } = callRequest;

    if (!from || !from.channelProfiles) {
      return null;
    }
    const channelProfile = from.channelProfiles.find(
      p => p.channel.id === callRequest.channel.id
    );
    if (!channelProfile) {
      return null;
    }
    const subChannels = channelProfile.subChannels.map(sb => sb.title);
    return (
      <span style={{ fontFamily: 'Avenir Next', fontSize: '1rem' }}>
        {subChannels.join(', ')}
      </span>
    );
  }

  _renderSuggestedTimes = () => {
    return (
      <List.Content className="call-request-list-content">
        <Button
          primary
          fluid
          onClick={() => {
            this.setState({ accepting: true });
          }}
          className="call-request-pick-button"
        >
          Pick Suggested Time
        </Button>
      </List.Content>
    );
  };

  _renderPickSuggestedTime() {
    const { callRequest } = this.props;
    const { accepting } = this.state;
    return (
      <ReceiveCallRequestDialog
        callRequest={callRequest}
        open={accepting}
        onClose={() => {
          this.setState({ accepting: false });
        }}
        onSubmit={() => {
          this.setState({ accepting: false });
        }}
      />
    );
  }

  _isGuide = () => {
    const { User } = this.props.user;
    return User.roles.some(role => role.name === ROLES.GUIDE);
  };

  _callIsToMe = () => {
    const { callRequest } = this.props;
    let isToMe = false;
    if (callRequest.from && !callRequest.to) {
      isToMe = true;
    }

    return isToMe;
  };

  _otherUserOnCall = () => {
    const { callRequest } = this.props;
    return this._callIsToMe() ? callRequest.from : callRequest.to;
  };
}
export default CallRequestEntry;
