import './index.css';
import './portal.css';

// import Intercom from 'intercom-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { LGSVG } from '../../../assets';
import { APP_ROOT, CONCIERGE_PHONE_NUMBER } from '../../../consts';
import withUser from '../../hoc/with-user';
import UserComponent from '../user';

@withUser()
class PortalAppHeader extends Component {
  state = {
    //chatNow: false
  };

  static propTypes = {
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  // _chatNow = () => {
  //   this.setState({
  //     chatNow: true
  //   });
  // };

  render() {
    const { User } = this.props.user;
    // const { chatNow } = this.state;
    const phoneNumber =
      User && User.companyIds.length > 0
        ? User.companyIds[0].company.primaryPhone
        : CONCIERGE_PHONE_NUMBER;
    return (
      <div className={`app-header portal`}>
        <div className="appheader-logo-signin-container">
          <Link to={APP_ROOT}>
            <img src={LGSVG} alt="Lifeguides Logo" className="appheader-logo" />
          </Link>

          <div className="appheader-logo-login-divider" />
          <div className="appheader-user-component-container">
            <UserComponent />
          </div>
        </div>
        <div className="appheader-call-chat-cta-container">
          <div className="appheader-call-cta">
            <span className="portal-header-phone-label">
              {`Call us toll free at `}
            </span>
            <a
              className="portal-header-phone-digits fancy-underlined-link"
              href={`tel:${phoneNumber}`}
            >
              {phoneNumber}
            </a>
            {/* <span className="portal-header-phone-label">{` or `}</span> */}
          </div>
          {/* <div className="appheader-chat-cta">
            <Button onClick={this._chatNow} className="hollow-button">
              Chat Now!
            </Button>
            <Intercom
              open={chatNow}
              appId={process.env.REACT_APP_INTERCOM_APP_ID}
              onClose={() => {
                this.setState({
                  chatNow: false
                });
              }}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export default PortalAppHeader;
