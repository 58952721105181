import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Device } from 'twilio-client';

export const propTypes = {
  clearError: PropTypes.func.isRequired,
  connection: PropTypes.object,
  device: PropTypes.object.isRequired,
  error: PropTypes.object,
  setup: PropTypes.func.isRequired,
  status: PropTypes.string,
  tokenExpired: PropTypes.bool
};

const DeviceEvents = [
  'connect',
  'cancel',
  'disconnect',
  'error',
  'incoming',
  'offline',
  'ready'
];

function getToken() {
  return fetch(`${process.env.REACT_APP_TWILIO_API_URL}/token`).then(
    response => {
      return response.json().then(({ token }) => token);
    }
  );
}

export default function withTwilio(Component) {
  return class WithTwilio extends React.Component {
    static displayName = `withTwilio(${Component.displayName ||
      Component.name})`;

    constructor(props) {
      super(props);

      const device = new Device();

      this.state = {
        connection: null,
        device,
        error: null,
        status: null,
        tokenExpired: false
      };
    }

    componentDidMount() {
      this._listen();
    }

    componentWillUnmount() {
      const { device } = this.state;

      device.destroy();
    }

    _setup = () => {
      const { device } = this.state;

      getToken().then(token => {
        device.setup(token, { debug: true, closeProtection: true });
      });
    };

    _listen() {
      const { device } = this.state;

      DeviceEvents.forEach(eventName => {
        const handlerName = `_on${upperFirst(eventName)}`;
        const handler = this[handlerName];
        if (handler) {
          device.on(eventName, handler);
        }
        device.on(eventName, this._updateStatus);
      });
    }

    _updateStatus = () => {
      const { device } = this.state;

      const status = device.status();
      if (status !== this.state.status) {
        this.setState({ status });
      }
    };

    _onConnect = connection => {
      this.setState({ connection, error: null });
    };

    _onError = error => {
      // Token expired https://www.twilio.com/docs/api/client/errors
      if (error.code === 31205) {
        this.setState({ tokenExpired: true });
      } else {
        this.setState({ error });
      }
    };

    _onOffline = () => {
      const { connection } = this.state;

      if (connection && connection.status() === 'open') {
        // Token expired, will need to call .setup() again before next call
        this.setState({ tokenExpired: true });
      }
    };

    _clearError = () => {
      this.setState({
        error: null
      });
    };

    render() {
      return (
        <Component
          device={Device}
          clearError={this._clearError}
          setup={this._setup}
          {...this.state}
          {...this.props}
        />
      );
    }
  };
}
