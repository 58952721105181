import { get } from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Container,
  Dropdown,
  Header,
  Icon,
  Input,
  Pagination
} from 'semantic-ui-react';

import { APP_ROOT, ROLES } from '../../../../consts';
import AllCompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import UsersByCompanyRolesAdminPaginationQuery from '../../../../graphql/queries/users-by-company-roles-admin-pagination.graphql';
import UsersByRolesAdminPaginationQuery from '../../../../graphql/queries/users-by-roles-admin-pagination.graphql';
import UsersWithInvitesByCompanyRolesAdminPaginationQuery from '../../../../graphql/queries/users-with-invites-by-company-roles-admin-pagination.graphql';
import UsersWithInvitesByRolesAdminPaginationQuery from '../../../../graphql/queries/users-with-invites-by-roles-admin-pagination.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import Avatar from '../../../ui/avatar';
import DataGrid from '../../../ui/data-grid';

const PAGE_SIZE = 10;
const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC'
};
const SEARCH_DEFAULTS = {
  page: 1,
  sort: 'createdAt',
  direction: SORT_DIRECTIONS.DESC
};

function parseSearch(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page,
    sort: search.sort || SEARCH_DEFAULTS.sort,
    direction: search.direction || SEARCH_DEFAULTS.direction,
    roles: search.roles || [ROLES.CLIENT, ROLES.GUIDE],
    companyId: search.companyId || null
  };
}

function paginationVariables({ location }) {
  const search = parseSearch(location.search);
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;
  const first = PAGE_SIZE;
  const orderBy = `${search.sort}_${search.direction}`;
  const variables = {
    first,
    skip,
    orderBy,
    companyId: search.companyId,
    roles: search.roles,
    searchTerm: search.filter
  };

  return { variables };
}

function hasClientRoleParam({ location }) {
  const search = parseSearch(location.search);
  return search.roles.includes(ROLES.CLIENT);
}

function hasCompanyParam({ location }) {
  const search = parseSearch(location.search);
  return !!search.companyId;
}

@withUser({ authenticated: [ROLES.ADMIN] })
@graphql(AllCompaniesQuery, { name: 'companies' })
@graphql(UsersByCompanyRolesAdminPaginationQuery, {
  name: 'users',
  skip: props => hasClientRoleParam(props) || !hasCompanyParam(props),
  options: paginationVariables
})
@graphql(UsersByRolesAdminPaginationQuery, {
  name: 'users',
  skip: props => hasClientRoleParam(props) || hasCompanyParam(props),
  options: paginationVariables
})
@graphql(UsersWithInvitesByCompanyRolesAdminPaginationQuery, {
  name: 'users',
  skip: props => !hasClientRoleParam(props) || !hasCompanyParam(props),
  options: paginationVariables
})
@graphql(UsersWithInvitesByRolesAdminPaginationQuery, {
  name: 'users',
  skip: props => !hasClientRoleParam(props) || hasCompanyParam(props),
  options: paginationVariables
})
@withRouter
class Users extends Component {
  static propTypes = {
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    users: PropTypes.shape({
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string,
          emailAddress: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string
            })
          ),
          company: PropTypes.shape({
            name: PropTypes.string
          })
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired
  };

  render() {
    const { location, companies } = this.props;
    const roleOptions = [
      {
        key: 'all',
        text: 'All Roles',
        value: `${ROLES.CLIENT},${ROLES.GUIDE}`
      },
      {
        key: 'clients',
        text: 'Clients',
        value: ROLES.CLIENT
      },
      {
        key: 'guest',
        text: 'Guests',
        value: ROLES.GUEST
      },
      {
        key: 'guides',
        text: 'Guides',
        value: ROLES.GUIDE
      },
      {
        key: 'banned',
        text: 'Banned',
        value: ROLES.BANNED
      }
    ];

    let companyOptions = [
      {
        key: 'all',
        text: 'All Companies',
        value: 'All Companies'
      }
    ];
    if (!companies.loading) {
      companyOptions = companyOptions.concat(
        companies.companies
          .filter(company => company.isPublished)
          .map(company => {
            return {
              key: company.id,
              text: company.name,
              value: company.id
            };
          })
      );
    }

    const search = parseSearch(location.search);

    return (
      <div>
        <Header as="h1">
          Users
          <Button basic floated="right" onClick={this._onClickImport}>
            Bulk Import
          </Button>
        </Header>
        <Input
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          fluid
          value={search.filter || ''}
          onChange={this._onSearchChange}
          action
        >
          <input />
          <Dropdown
            button
            basic
            floating
            options={roleOptions}
            defaultValue={
              search.roles.join(',') || `${ROLES.CLIENT},${ROLES.GUIDE}`
            }
            onChange={this._onRoleFilterChange}
          />
          <Dropdown
            button
            basic
            floating
            options={companyOptions}
            defaultValue={search.companyId || 'All Companies'}
            onChange={this._onCompanyChange}
          />
        </Input>
        {this._renderUsers()}
      </div>
    );
  }

  _onCompanyChange = (event, { value }) => {
    this._search({
      companyId: value == 'All Companies' ? null : value,
      page: 1
    });
  };

  _renderUsers() {
    const { location } = this.props;
    const { users, loading } = this.props.users;

    const columns = [
      {
        formatter: (_, user) => <Avatar user={user} />
      },
      {
        prop: 'firstName',
        label: 'First Name',
        formatter: 'capitalizedText',
        sortable: true
      },
      {
        prop: 'lastName',
        label: 'Last Name',
        formatter: 'capitalizedText',
        sortable: true
      },
      {
        prop: 'emailAddress',
        label: 'E-Mail Address',
        sortable: true
      },
      {
        prop: 'role',
        label: 'Role',
        formatter: (_, user) => {
          const isRegistered = get(user, 'roles.length');
          const hasPendingClientInvite =
            get(user, 'clientInvite.status') === 'PENDING';

          return isRegistered
            ? user.roles.map(role => role.name).join(', ')
            : hasPendingClientInvite
            ? 'INVITED'
            : null;
        },
        sortable: false
      },
      {
        prop: 'company',
        label: 'Company',
        formatter: (_, user) => {
          const isRegistered = get(user, 'roles.length');
          const hasPendingClientInvite =
            get(user, 'clientInvite.status') === 'PENDING';

          return isRegistered
            ? user.companyIds
                .map(connection => get(connection, 'company.name'))
                .filter(name => !!name)
                .join(', ')
            : hasPendingClientInvite &&
                get(user, 'clientInvite.company.name', null);
        },
        sortable: false
      },
      {
        prop: 'createdAt',
        label: 'Created',
        formatter: 'dateTimeFromNow',
        sortable: true
      }
    ];

    const search = parseSearch(location.search);
    const direction =
      search.direction === SORT_DIRECTIONS.ASC ? 'ascending' : 'descending';

    return (
      <div style={{ paddingTop: '1em' }}>
        <DataGrid
          columns={columns}
          data={users}
          loading={loading}
          onRowClick={user => {
            this._onClickUser(user);
          }}
          onSort={this._onSort}
          sortColumn={search.sort}
          sortDirection={direction}
        />
        {this._renderPagination()}
      </div>
    );
  }

  _renderPagination() {
    const { location } = this.props;
    const { usersConnection } = this.props.users;

    if (!usersConnection) {
      return null;
    }

    const search = parseSearch(location.search);

    const count = usersConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    if (!count) {
      return null;
    }

    return (
      <Container textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle left lineawesome" />, icon: true }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle right lineawesome" />, icon: true }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </Container>
    );
  }

  _onClickImport = () => {
    const { history } = this.props;
    history.push(`${APP_ROOT}/admin/users/imports`);
  };

  _onClickUser = user => {
    const { history } = this.props;
    history.push(`${APP_ROOT}/admin/user/${user.id}`);
  };

  _onRoleFilterChange = (event, { value }) => {
    this._search({
      roles: value.split(','),
      page: 1
    });
  };

  _onSearchChange = (event, { value }) => {
    this._search({
      filter: value,
      page: 1
    });
  };

  _onSort = name => {
    const { location } = this.props;

    const search = parseSearch(location.search);

    const direction =
      search.sort === name
        ? search.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC
        : SORT_DIRECTIONS.ASC;

    this._search({
      sort: name,
      direction
    });
  };

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _search(params) {
    const { history, location } = this.props;

    const search = parseSearch(location.search);
    const updated = {
      ...search,
      ...params
    };

    const url = `${APP_ROOT}/admin/users?${qs.stringify(updated)}`;
    history.push(url);
  }
}
export default Users;
