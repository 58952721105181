import { get, isArray, isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dimmer, Loader, Message, Table } from 'semantic-ui-react';

import { getCellFormatter } from './data-formatters';

class DataGrid extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        formatter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        label: PropTypes.node,
        prop: PropTypes.string
      })
    ),
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    onRowClick: PropTypes.func,
    onSort: PropTypes.func,
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.string
  };

  render() {
    const { loading } = this.props;

    const content = this.columnsAreValid()
      ? this.renderDataGrid()
      : this.renderNoColumnsMessage();

    return (
      <Dimmer.Dimmable as="div" className="data-grid" blurring dimmed={loading}>
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>
        {content}
      </Dimmer.Dimmable>
    );
  }

  columnsAreValid = () => {
    const { columns } = this.props;

    if (!isArray(columns)) {
      return false;
    }

    if (columns.some(col => !isObject(col) && !(col.prop || col.formatter))) {
      return false;
    }

    return true;
  };

  renderNoColumnsMessage = () => {
    return (
      <Message negative>
        <Message.Header>No Columns Provided</Message.Header>
        <p>
          In order to use the DataGrid you must provide an array of columns that
          have the properties of label and prop.
        </p>
      </Message>
    );
  };

  renderTableHeader = () => {
    let { columns } = this.props;

    return (
      <Table.Header>
        <Table.Row>{columns.map(this._renderTableHeaderCell)}</Table.Row>
      </Table.Header>
    );
  };

  _renderTableHeaderCell = (column, i) => {
    const { onSort, sortColumn, sortDirection } = this.props;

    return (
      <Table.HeaderCell
        key={`column-${i}`}
        style={{
          cursor: column.sortable ? 'pointer' : 'auto'
        }}
        sorted={sortColumn === column.prop ? sortDirection : null}
        onClick={() => {
          if (column.sortable && onSort) {
            onSort(column.prop);
          }
        }}
      >
        {column.label}
      </Table.HeaderCell>
    );
  };

  renderNoTableDataMessage = () => {
    return (
      <Message negative style={{ width: '100' }}>
        <Message.Header>No Table Data</Message.Header>
        <p>
          In order to use the DataGrid you must provide an array of rows that
          have an array of cells that have the properties of label and prop.
        </p>
      </Message>
    );
  };

  rowsAreValid = () => {
    let AreValid = true;
    return AreValid;
  };

  renderTableBody = () => {
    const { columns, data } = this.props;

    if (!data) {
      return null;
    }

    return <Table.Body>{data.map(this.rowToHtml(data, columns))}</Table.Body>;
  };

  _renderTableBodyCell(data) {
    return <Table.Cell key={data.id}> {data} </Table.Cell>;
  }

  rowToHtml = (data, columns) => (row, datumIndex) => {
    return (
      <Table.Row key={`row-${datumIndex}`}>
        {columns.map(this.cellToHtml(row, columns, data))}
      </Table.Row>
    );
  };

  cellToHtml = (row, columns, data) => (column, columnIndex) => {
    const { onRowClick } = this.props;

    const unformattedCellData = get(row, column.prop, null);
    const getFormattedData = getCellFormatter(column.formatter);
    const cellValue = getFormattedData(unformattedCellData, row, column, data);

    return (
      <Table.Cell
        key={`cell-${columnIndex}`}
        style={{
          ...(onRowClick ? { cursor: 'pointer' } : null)
        }}
        onClick={() => {
          if (onRowClick) {
            onRowClick(row);
          }
        }}
      >
        {cellValue || ''}
      </Table.Cell>
    );
  };

  renderDataGrid = () => {
    const { onRowClick } = this.props;

    let tableErrorMessage = '';
    if (!this.rowsAreValid) {
      tableErrorMessage = this.renderNoTableDataMessage();
    }
    return (
      <div>
        <Table striped={true} selectable={!!onRowClick} sortable>
          {this.renderTableHeader()}
          {this.renderTableBody()}
        </Table>
        {tableErrorMessage}
      </div>
    );
  };
}

export default DataGrid;
