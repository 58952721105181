import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon, Segment } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import AdminDashboardQuery from '../../../graphql/queries/admin-dashboard.graphql';
import graphql from '../../hoc/graphql';

@graphql(AdminDashboardQuery, {
  options: {
    fetchPolicy: 'network-only',
    variables: {
      time: new Date().toISOString()
    }
  }
})
class AdminDashboard extends Component {
  static propTypes = {
    data: PropTypes.shape({
      accessCodesConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      guideApplicationsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      loading: PropTypes.bool.isRequired,
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  render() {
    return (
      <Card.Group stackable itemsPerRow={3}>
        {this._renderPendingGuideApplications()}
        {this._renderUsers()}
        {this._renderAccessCodes()}
      </Card.Group>
    );
  }

  _renderPendingGuideApplications() {
    const { guideApplicationsConnection, loading } = this.props.data;

    const content = loading ? (
      <Segment basic loading />
    ) : (
      this._renderCardContent(
        'clipboard',
        guideApplicationsConnection.aggregate.count
      )
    );

    return this._renderCard(
      content,
      'Pending Guide Applications',
      'admin/guide-applications',
      'View and Approve'
    );
  }

  _renderUsers() {
    const { usersConnection, loading } = this.props.data;

    const content = loading ? (
      <Segment basic loading />
    ) : (
      this._renderCardContent('users', usersConnection.aggregate.count)
    );

    return this._renderCard(
      content,
      'LifeGuides Users',
      'admin/users',
      'View and Manage'
    );
  }

  _renderAccessCodes() {
    const { accessCodesConnection, loading } = this.props.data;

    const content = loading ? (
      <Segment basic loading />
    ) : (
      this._renderCardContent('barcode', accessCodesConnection.aggregate.count)
    );

    return this._renderCard(
      content,
      'Active Access Codes',
      'admin/access-codes',
      'View and Manage'
    );
  }

  _renderCardContent(icon, number) {
    return (
      <div>
        <Icon
          name={icon}
          style={{
            marginLeft: '-1em',
            fontSize: '.9em',
            color: '#bebebe'
          }}
        />
        {number}
      </div>
    );
  }

  _renderCard(content, title, linkPath, linkText) {
    return (
      <Card style={{ textAlign: 'center' }}>
        <Card.Content style={{ padding: '2em 0' }}>
          <Card.Description
            style={{
              margin: '.5em 0',
              fontSize: '2.75em',
              fontWeight: 'bold',
              color: '#000'
            }}
          >
            {content}
          </Card.Description>
          <Card.Header style={{ fontSize: '1em' }}>{title}</Card.Header>
        </Card.Content>
        <Card.Content extra>
          <Link className="ui" to={`${APP_ROOT}/${linkPath}`}>
            {linkText}
          </Link>
        </Card.Content>
      </Card>
    );
  }
}
export default AdminDashboard;
