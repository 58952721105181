import qs from 'qs';

const SEARCH_DEFAULTS = {
  page: 1,
  searchTerm: '',
  itemsPerPage: 6,
  searchSpeed: 500
};

const parseQS = function parseQS(str) {
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    ...search,
    searchTerm: search.searchTerm || SEARCH_DEFAULTS.searchTerm,
    page: parseInt(search.page, 10) || SEARCH_DEFAULTS.page
  };
};

const paginationVariables = function paginationVariables(props) {
  const { page, searchTerm } = parseQS(props.location.search);

  const skip = page > 0 ? (page - 1) * SEARCH_DEFAULTS.itemsPerPage : 0;
  const first = SEARCH_DEFAULTS.itemsPerPage;
  return { variables: { skip, first, searchTerm } };
};

export { SEARCH_DEFAULTS, parseQS, paginationVariables };
