import './index.css';

import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { format } from 'phone-fns';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Segment
} from 'semantic-ui-react';

import BugsnagClient from '../../../../bugsnag';
import {
  createOrUpdateIntakeAnswer,
  updateUserChannelProfileSubChannels
} from '../../../../channel-profile';
import { ROLES } from '../../../../consts';
import UpdateUserProfileMutation from '../../../../graphql/mutations/update-user-profile.graphql';
import UploadFileMutation from '../../../../graphql/mutations/upload-file.graphql';
import ChannelsByIdsQuery from '../../../../graphql/queries/channels-by-ids.graphql';
import UserChannelProfilesByUserIdQuery from '../../../../graphql/queries/user-channel-profiles-by-user-id.graphql';
import graphql from '../../../hoc/graphql';
import withUser from '../../../hoc/with-user';
import withWidth, { isWidthDown } from '../../../hoc/with-width';
import ErrorDialog from '../../../ui/error-dialog';
import { FormField } from '../../../ui/form';
import Notification from '../../../ui/notification';
import AvatarSelector from './avatar-selector';
import BasicInfoForm from './basic-info-form';
import SpouseInfo from './spouse-info';

@withUser({ authenticated: true })
@graphql(ChannelsByIdsQuery, {
  name: 'channels',
  options: ({ user: { User } }) => ({
    variables: { ids: User.channelProfiles.map(p => p.channel.id) }
  })
})
@graphql(UserChannelProfilesByUserIdQuery, {
  name: 'channelProfiles',
  options: ({ user: { User } }) => ({
    fetchPolicy: 'network-only',
    variables: { userId: User.id }
  })
})
@graphql(UpdateUserProfileMutation, {
  name: 'updateUserProfile'
})
@graphql(UploadFileMutation, {
  name: 'uploadFile'
})
@withWidth({ className: 'profile-settings' })
class Profile extends Component {
  static propTypes = {
    channels: PropTypes.shape({
      channels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    channelProfiles: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      userChannelProfiles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        avatarUrl: PropTypes.string
      })
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      error: null,
      file: null,
      intakeAnswerData: [],
      isValid: false,
      subChannelsData: [],
      submitting: false,
      success: false
    };
  }

  render() {
    const { width } = this.props;
    const { User } = this.props.user;
    const { error, isValid, submitting, success } = this.state;

    const isClient = !!(
      User && User.roles.find(role => role.name === ROLES.CLIENT)
    );
    const hasCompanies = !!(User && User.companyIds.length);
    const isSpouse = !!(
      User &&
      User.clientInvite &&
      User.clientInvite.isSpouse
    );
    const shouldShowSpouse = isClient && hasCompanies && !isSpouse;
    const isTablet = isWidthDown('computer', width);

    return (
      <Container text>
        <Grid
          stackable
          reversed="tablet computer"
          style={isTablet ? { marginBottom: '80px' } : null}
        >
          <Grid.Row>
            <Grid.Column width={5} textAlign={isTablet ? 'center' : null}>
              <AvatarSelector
                onError={this._onError}
                onFileSelect={this._onFileSelect}
                submitting={submitting}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <Header>Your Profile</Header>
              <BasicInfoForm
                onChange={this._onDataChange}
                onValidate={this._onValidate}
              />
              {this._renderChannelProfiles()}
              <Form.Button
                primary
                fluid
                size="huge"
                loading={submitting}
                disabled={!isValid || submitting}
                onClick={this._onSubmit}
                className="save"
              >
                Save Profile
              </Form.Button>
              {shouldShowSpouse && (
                <>
                  <Header>Spouse</Header>
                  <SpouseInfo />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ErrorDialog
          error={error}
          onClose={() => {
            this.setState({ error: null });
          }}
        />
        <Notification
          open={success}
          onClose={() => {
            this.setState({ success: false });
          }}
        >
          <Icon name="check" color="green" /> Your profile has been updated
        </Notification>
      </Container>
    );
  }

  _renderChannelProfiles() {
    const { loading, userChannelProfiles } = this.props.channelProfiles;

    if (loading) {
      return <Segment basic loading />;
    }
    if (!userChannelProfiles.length) {
      return null;
    }
    return userChannelProfiles.map(this._renderChannelProfile);
  }

  _renderChannelProfile = channelProfile => {
    const { channels, loading } = this.props.channels;
    const { User } = this.props.user;

    if (loading) {
      return <Segment basic loading />;
    }
    const channel = channels.find(
      c =>
        c.id === channelProfile.channel.id || c.id === channelProfile.channelId
    );
    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);
    const subChannelLimit = isGuide ? 3 : 2;

    if (!channel) {
      return null;
    }

    return (
      <div key={`channel-profile-${channelProfile.id}`}>
        <Divider horizontal>{channel.shortTitle}</Divider>
        {channel.intakeQuestions.map(intakeQuestion => {
          const intakeAnswer = channelProfile.intakeAnswers.find(
            ia =>
              ia.intakeQuestion && ia.intakeQuestion.id === intakeQuestion.id
          );

          return (
            <div key={`intake-question-${intakeQuestion.id}`}>
              <Header>{intakeQuestion.title}</Header>
              <FormField
                component={Dropdown}
                name={`intakeQuestion-${intakeQuestion.id}`}
                placeholder={intakeQuestion.title}
                aria-placeholder={intakeQuestion.title}
                // disabled
                fluid
                upward
                selection
                onChange={(event, data) => {
                  this._setIntakeAnswer(
                    channelProfile,
                    intakeQuestion,
                    data.value
                  );
                }}
                options={intakeQuestion.options.map(option => ({
                  text: option,
                  value: option
                }))}
                defaultValue={intakeAnswer ? intakeAnswer.answer : ''}
              />
            </div>
          );
        })}
        <Header>
          {isGuide
            ? `Experience`
            : `What subjects would you like to discuss with your LifeGuide?`}
        </Header>
        <FormField
          component={Dropdown}
          name={`subChannels-${channel.id}`}
          placeholder={
            isGuide
              ? `Select up to 3 Experiences`
              : `Select up to 2 subjects to discuss`
          }
          // disabled
          fluid
          upward
          multiple
          selection
          onChange={(event, data) => {
            if (data.value.length > subChannelLimit) {
              data.value.shift();
            }
            this._setSubChannels(channelProfile, data.value);
          }}
          options={channel.subChannels.map(subChannel => ({
            text: subChannel.title,
            value: subChannel.id
          }))}
          defaultValue={channelProfile.subChannels.map(s => s.id)}
        />
      </div>
    );
  };

  _onError = error => {
    this.setState({ error });
  };

  _onFileSelect = file => {
    this.setState({ file });
  };

  _onDataChange = data => {
    this.setState({ data });
  };

  _onValidate = errors => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _setIntakeAnswer(channelProfile, intakeQuestion, answer) {
    const { intakeAnswerData } = this.state;

    const data = {
      channelProfile,
      intakeQuestion,
      answer
    };
    const copyArr = [...intakeAnswerData];
    const index = copyArr.find(p => p.channelProfile.id === channelProfile.id);
    if (index == -1) {
      copyArr.push(data);
    } else {
      copyArr.splice(index, 1, data);
    }

    this.setState({ intakeAnswerData: copyArr });
  }

  _setSubChannels(channelProfile, subChannelIds) {
    const { subChannelsData } = this.state;

    const data = {
      channelProfile,
      subChannelIds
    };
    const copyArr = [...subChannelsData];
    const index = copyArr.find(p => p.channelProfile.id === channelProfile.id);
    if (index == -1) {
      copyArr.push(data);
    } else {
      copyArr.splice(index, 1, data);
    }

    this.setState({ subChannelsData: copyArr });
  }

  _submitIntakeAnswers = () => {
    const { intakeAnswerData } = this.state;

    return Promise.all(
      intakeAnswerData.map(data => {
        const { channelProfile, intakeQuestion, answer } = data;
        return createOrUpdateIntakeAnswer(
          channelProfile,
          intakeQuestion,
          answer
        );
      })
    );
  };

  _submitSubChannels = () => {
    const { subChannelsData } = this.state;

    return Promise.all(
      subChannelsData.map(data => {
        const { channelProfile, subChannelIds } = data;
        return updateUserChannelProfileSubChannels(
          channelProfile,
          subChannelIds.map(id => ({ id }))
        );
      })
    );
  };

  _onSubmit = () => {
    const { User } = this.props.user;
    const { data, file } = this.state;

    const variables = {
      id: User.id,
      ...User,
      ...data,
      age: isString(data.age)
        ? parseInt(data.age.replace(/\D/g, ''), 10)
        : data.age,
      phoneNumber: format('NNNNNNNNNN', data.phoneNumber.replace(/\D/g, ''))
    };

    this.setState({ error: null, submitting: true });
    const fileRequest = file
      ? this._uploadFile().then(img => {
          if (img) {
            variables.avatarUrl = img.url;
          }
        })
      : Promise.resolve();

    fileRequest
      .then(this._submitIntakeAnswers)
      .then(this._submitSubChannels)
      .then(() => {
        this._updateUserProfile(variables);
      });
  };

  _updateUserProfile(variables) {
    const { updateUserProfile, user } = this.props;

    return updateUserProfile({ variables })
      .then(res => {
        user.refetch();
        this.setState({
          submitting: false,
          success: true,
          file: null
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'Profile._updateUserProfile',
          request: {
            ...variables
          }
        });
      });
  }

  _uploadFile() {
    const { uploadFile } = this.props;
    const { file } = this.state;

    const variables = { file };
    return uploadFile({ variables })
      .then(({ data }) => {
        this.setState({ file: null, filePreview: null });
        return data.uploadFile;
      })
      .catch(error => {
        this.setState({ error });
        BugsnagClient.notify(error, {
          context: 'Profile._uploadFile'
        });
      });
  }
}
export default Profile;
