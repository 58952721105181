import './guides-list.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Header } from 'semantic-ui-react';

import GuideCard from './guide-card';

@withRouter
class GuidesList extends Component {
  static propTypes = {
    guides: PropTypes.array.isRequired,
    guidesCount: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTablet: PropTypes.bool.isRequired
  };

  render() {
    return <div className="guides-list-container">{this._renderGuides()}</div>;
  }

  _renderGuides() {
    const {
      guides,
      guidesCount,
      location,
      match,
      isMobile,
      isTablet
    } = this.props;

    const hasChildRoute = match.url !== location.pathname;

    // Hacky, hide the list if dialog is open on mobile to prevent scrolling past dialog
    if (isMobile && hasChildRoute) {
      return null;
    }

    let smallSetClass = '';

    //this maintains a small max-width for smaller set sizes.
    if (guidesCount < 8) {
      smallSetClass = 'small-set';
    }

    return (
      <div className={`guides-list ${smallSetClass}`}>
        <Header
          size="large"
          style={
            isTablet
              ? { fontSize: '18px', marginBottom: '35px' }
              : { marginBottom: '25px' }
          }
        >
          Guides
          <Header.Subheader
            style={{ display: 'inline', margin: '0 1em', color: '#767676' }}
          >
            {guidesCount}
          </Header.Subheader>
        </Header>
        <Card.Group stackable>{guides.map(this._renderGuide)}</Card.Group>
      </div>
    );
  }

  _renderGuide = guide => {
    return <GuideCard key={`guide-${guide.id}`} guide={guide} />;
  };
}
export default GuidesList;
