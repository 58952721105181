import './details.css';

import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Feed, Icon, Tab } from 'semantic-ui-react';

/*const mockPanes = [
  {
    title: 'About',
    content:
      'Kristy took on the battle of her struggling father with Alzheimers. Losing her father in 2014 was difficult but because of her supporting friends and family she pushed through a lot of understanding and now wants to use her experience to ease other’s same challenges. Kristy'
  },
  {
    title: 'Experience',
    content:
      'father in 2014 was difficult but because of her supporting friends and family she pushed through a lot of understanding and now wants to use her experience to ease other’s same challenges. Kristy'
  }
  // {
  //   title: 'Reviews (12)',
  //   renderAs: 'feed',
  //   content: [
  //     { title: 'sdfsdf!', content: 'Why am I typing this?' },
  //     { title: 'Woot!', content: 'First Post?' }
  //   ]
  // }
];*/

class GuideAboutDetails extends Component {
  static propTypes = {
    guide: PropTypes.object.isRequired
  };

  render() {
    const { guide } = this.props;
    return (
      <div className="guide-details-container">
        {/* <Tab
          className="guide-details-menu"
          menu={{ secondary: true, pointing: true }}
          panes={this._renderPanes()}
        /> */}
        <div className="guide-details">
          <div className="guide-details-bio-title">About</div>
          <div className="guide-details-bio">{guide.bio}</div>
        </div>
      </div>
    );
  }

  _buildPanes = () => {
    const { guide } = this.props;

    const mockPanes = [
      {
        title: 'About',
        content: guide.bio
      }
      // {
      //   title: 'Highlights',
      //   renderAs: 'contentWithHeaders',
      //   content: [
      //     {
      //       title: 'Intro',
      //       textContent: guide.shortIntro,
      //       icon: 'user lineawesome'
      //     },
      //     {
      //       title: 'Education',
      //       textContent: guide.educationLevel,
      //       icon: 'certificate lineawesome'
      //     },
      //     {
      //       title: 'Loved one cared for',
      //       textContent: guide.relationship.title,
      //       icon: 'heart lineawesome'
      //     }
      //   ]
      // }
      // {
      //   title: 'Reviews (12)',
      //   renderAs: 'feed',
      //   content: [
      //     { title: 'sdfsdf!', content: 'Why am I typing this?' },
      //     { title: 'Woot!', content: 'First Post?' }
      //   ]
      // }
    ];

    return mockPanes;
  };

  _renderPanes = () => {
    return this._buildPanes().map(pane => {
      return {
        menuItem: pane.title,
        render: () => (
          <Tab.Pane attached={false}>{this._renderPaneConent(pane)}</Tab.Pane>
        )
      };
    });
  };

  /*Content*/

  _renderPaneConent = pane => {
    const _contentStrategies = {
      default: this._renderContentDefault,
      feed: this._renderContentFeed,
      contentWithHeaders: this._renderContentWithHeaders
    };

    const renderAs =
      _contentStrategies[pane.renderAs] || _contentStrategies.default;
    return renderAs(pane.content);
  };

  _renderContentDefault = content => {
    return <p>{content}</p>;
  };

  _formatCertifications = t => {
    if (!t) {
      return '';
    }
    return t
      .split('_')
      .map(stringFragment => {
        return capitalize(stringFragment);
      })
      .join(' ');
  };

  /** get colors and font family from the guide badges, and put them in here */
  _renderContentWithHeaders = sections => {
    const contentSection = sections.map((section, i) => {
      const { title, textContent, icon } = section;

      let content = textContent;

      if (title === 'Education') {
        content = this._formatCertifications(content);
      }

      return (
        <Feed.Event key={`feed-${title}`}>
          <Feed.Label>
            <Icon name={`${icon} outline`} />
          </Feed.Label>
          <Feed.Content>
            <Feed.Date>{title}</Feed.Date>
            <Feed.Summary>{content}</Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      );
    });
    return <Feed>{contentSection}</Feed>;
  };

  _renderContentFeed = content => {
    const feedItems = content.map((feedEvent, i) => {
      return (
        <Feed.Event key={`fi-${i}`}>
          <Feed.Label image="https://camo.githubusercontent.com/4b8c9e5287f74582c13ed661ed8af36d9e9c19d9/68747470733a2f2f6269742e6c792f6663632d6b697474656e73" />
          <Feed.Content>
            <Feed.Summary>
              <a>Joe Henderson</a> posted on his page
              <Feed.Date>3 days ago</Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>
              {`Ours is a life of constant reruns. We're always circling back to
              where we'd we started, then starting all over again. Even if we
              don't run extra laps that day, we surely will come back for more
              of the same another day soon.`}
            </Feed.Extra>
            <Feed.Meta>
              <Feed.Like>
                <Icon name="like" />5 Likes
              </Feed.Like>
            </Feed.Meta>
          </Feed.Content>
        </Feed.Event>
      );
    });

    return <Feed>{feedItems}</Feed>;
  };
}

export default GuideAboutDetails;
