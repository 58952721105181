import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';

import { APP_ROOT } from '../../../../consts';
import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import Notification from '../../../ui/notification';
import CallRequests from './call-requests';
import CallRoutes from './calls-routes';

@withRouter
@withUser({ authenticated: [ROLES.CONCIERGE] })
class ConciergeCalls extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    notification: null
  };

  render() {
    return (
      <div className="concierge-calls">
        {this._renderCallRequests()}
        {this._renderDialogRoutes()}
      </div>
    );
  }

  _renderDialogRoutes = () => {
    return (
      <CallRoutes
        onClose={this._onCloseDialog}
        onSubmit={this._onSubmitClient}
      />
    );
  };

  _renderCallRequests() {
    return (
      <div className="call-requests">
        <div className="header-and-new-requests">
          <Header>Call Requests</Header>
          <Button primary onClick={this._navTo('create-client')}>
            <Icon name="plus" />
            New User
          </Button>
          <Button primary onClick={this._navTo('create-call')}>
            <Icon name="plus" />
            New Call
          </Button>
        </div>
        <br />
        <CallRequests />
        {this._renderNotification()}
      </div>
    );
  }

  _renderNotification = () => {
    const { notification } = this.state;
    return (
      <Notification
        open={!!notification}
        onClose={() => {
          this.setState({ notification: null });
        }}
      >
        <Icon name="check" color="green" /> {notification}
      </Notification>
    );
  };

  _navTo = to => () => {
    const { history, match, location } = this.props;

    let _to = `${match.url}/${to}?${location.search}`
      .split('?')
      .filter(x => x)
      .join('?');

    history.push(_to);
  };

  _onCloseDialog = () => {
    const { history, location } = this.props;
    const _to = `${APP_ROOT}/concierge/call-scheduling?${location.search}`
      .split('?')
      .filter(x => x)
      .join('?');
    history.push(_to);
  };

  _onSubmitCallRequest = data => {
    this._onCloseDialog();
    this.setState({
      notification: 'Call Request Created'
    });
  };

  _onSubmitClient = data => {
    this._onCloseDialog();
    this.setState({ notification: 'Client Created' });
  };
}
export default ConciergeCalls;
