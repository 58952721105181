import './subjects.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

class GuideAboutSubjects extends Component {
  static propTypes = {
    guide: PropTypes.object.isRequired
  };

  render() {
    return (
      <div className="guide-subjects">
        <div className="subjects-title">Experience in: </div>
        <div className="expert-subjects">
          {this._renderExpertSubjectsList()}
        </div>
      </div>
    );
  }

  _renderExpertSubjectsList = () => {
    const { channelProfiles } = this.props.guide;

    return channelProfiles
      .reduce(
        (acc, channelProfile) => acc.concat(channelProfile.subChannels),
        []
      )
      .slice(0, 3)
      .map(this._formatSubject);
  };

  _formatSubject = (subject, i) => {
    let delimiter = '';
    if (subject) {
      if (i > 0) {
        delimiter = <span className="expert-subject-li-delimiter">{';'}</span>;
      }
      return (
        <span
          key={`expert-subject-${subject.id}`}
          className="expert-subject-li"
        >
          {delimiter}
          <span className="expert-subject-li-title">{subject.title}</span>
        </span>
      );
    }
  };

  _renderSubject = (subject, i) => {
    return (
      <div key={`expert-subject-${subject.id}`} className="expert-subject-li">
        <span className="expert-subject-li-dot">•</span> {subject.title}
      </div>
    );
  };
}

export default GuideAboutSubjects;
