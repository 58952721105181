import '../powerful-cta/index.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Countdown extends Component {
  static propTypes = {
    date: PropTypes.object,
    isTablet: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      timeUntil: moment.duration(
        moment(this.props.date).diff(moment(new Date())),
        'seconds'
      ),
      interval: null
    };
  }

  componentDidMount() {
    let interval = setInterval(() => this._tick(), 1000);
    this.setState({ interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  _tick() {
    let time = moment.duration(
      moment(this.props.date).diff(moment(new Date())),
      'seconds'
    );
    this.setState({
      timeUntil: time
    });
  }

  render() {
    const { timeUntil } = this.state;

    let date =
      timeUntil.asSeconds() > 0
        ? moment.utc(timeUntil.asSeconds()).format('HH:mm:ss')
        : '00:00:00';
    return (
      <div className="callentry-countdown powerful animated">
        <p>{date}</p>
      </div>
    );
  }
}
