import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { APP_ROOT } from '../../../consts';
import withUser from '../../hoc/with-user';

@withRouter
@withUser({ authenticated: true })
class Settings extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { history } = this.props;

    history.push(`${APP_ROOT}/settings/profile`);
  }

  render() {
    return null;
  }
}
export default Settings;
