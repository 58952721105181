import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Button, Confirm } from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import { ROLES } from '../../../consts';
import CancelCall from '../../../graphql/mutations/cancel-call.graphql';
import ClientCallCountsQuery from '../../../graphql/queries/client-call-counts.graphql';
import ClientDashboardQuery from '../../../graphql/queries/client-dashboard.graphql';
import GuideCallCountsQuery from '../../../graphql/queries/guide-call-counts.graphql';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import UserClientCallsQuery from '../../../graphql/queries/user-client-calls.graphql';
import UserGuideCallsQuery from '../../../graphql/queries/user-guide-calls.graphql';
import * as tracker from '../../../tracker';
import withUser from '../../hoc/with-user';

@withUser({ authenticated: true })
@graphql(CancelCall, {
  name: 'cancelCall',
  options: props => {
    const { call } = props;
    const { User } = props.user;

    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);

    const variables = {
      ...(!isGuide && { channelId: call.channel.id }),
      userId: User.id
    };

    const refetchQueries = [
      {
        query: isGuide ? GuideDashboardQuery : ClientDashboardQuery,
        variables
      },
      {
        query: isGuide ? GuideCallCountsQuery : ClientCallCountsQuery,
        variables
      }
    ];

    return {
      refetchQueries,
      update: (
        store,
        {
          data: {
            cancelCall: { id }
          }
        }
      ) => {
        const scheduledVariables = {
          ...variables,
          statuses: ['SCHEDULED', 'STARTED']
        };
        const query = isGuide ? UserClientCallsQuery : UserGuideCallsQuery;

        let data = null;
        try {
          data = store.readQuery({
            query,
            variables: scheduledVariables
          });
        } catch (e) {
          /* noop */
        }
        if (!data) {
          return;
        }

        const { calls } = data;
        const index = calls.findIndex(call => call.id === id);
        if (index >= 0) {
          calls.splice(index, 1);
        }

        store.writeQuery({
          query,
          variables: scheduledVariables,
          data
        });
      }
    };
  }
})
class CancelCallAction extends Component {
  static propTypes = {
    //args
    call: PropTypes.shape({
      callNotes: PropTypes.array,
      client: PropTypes.object,
      duration: PropTypes.number,
      guide: PropTypes.object,
      rating: PropTypes.number,
      scheduledTime: PropTypes.date,
      startedAt: PropTypes.string
    }).isRequired,

    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,

    //withUser
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired,

    //graphql
    cancelCall: PropTypes.func.isRequired
  };

  state = {
    submitting: false,
    error: null,

    success: false,
    successMessage: null
  };

  _renderCancelPendingCall() {
    const { call, open, onClose } = this.props;
    const { User } = this.props.user;
    const { submitting } = this.state;

    if (!open) {
      return null;
    }

    const isClient = User.roles.some(role => role.name === ROLES.CLIENT);
    const threshold = moment.utc(call.scheduledTime).subtract(4, 'hours');
    const isLate = moment.utc().isBetween(threshold, call.scheduledTime);
    const willCharge = isClient && isLate;

    let message = `Are you sure you want to cancel this scheduled call?`;

    if (willCharge) {
      message = `
        ${message} Cancelling this close to the scheduled time will result in a cancellation fee.
      `;
    }

    let cancelCta = <Button disabled={submitting}>Nevermind</Button>;
    let confirmCta = (
      <Button disabled={submitting} loading={submitting}>
        Yes, please cancel
      </Button>
    );

    if (this.state.error) {
      message = `Cancellation Error: ${this.state.error.toString()}`;
      confirmCta = null;
      cancelCta = <Button disabled={submitting}>OK</Button>;
    }

    return (
      <Confirm
        open
        content={message}
        cancelButton={cancelCta}
        confirmButton={confirmCta}
        onCancel={onClose}
        onConfirm={this._onConfirmCancelCall}
      />
    );
  }

  _onConfirmCancelCall = () => {
    const { call, cancelCall, onClose } = this.props;

    const variables = {
      id: call.id
    };

    this.setState({ error: null, submitting: true, success: false });

    cancelCall({ variables }, { partialRefetch: true })
      .then(() => {
        tracker.event('cancelCall', 1);

        this.setState({
          submitting: false,
          success: true,
          successMessage: 'Call Cancelled'
        });

        onClose();
      })
      .catch(error => {
        tracker.event('cancelCall', 0);

        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'ScheduledCallEntry._onConfirmCancelCall',
          request: {
            ...variables
          }
        });
      });
  };

  render() {
    return (
      <div className="cancelcall-modal">{this._renderCancelPendingCall()}</div>
    );
  }
}
export default CancelCallAction;
