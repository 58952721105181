import PropTypes from 'prop-types';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';

import { ref as WorkspaceRef } from '../container/app-workspace';

class ScrollToTop extends Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object
  };

  componentDidUpdate(prevProps) {
    if (this.props.location === prevProps.location) {
      return;
    }
    if (!WorkspaceRef.current) {
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const workspace = ReactDOM.findDOMNode(WorkspaceRef.current);
    workspace.scrollTop = 0;
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
