import React, { Component } from 'react';

class AdminReporting extends Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          height: '100%'
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://datastudio.google.com/embed/reporting/1mtOI6fAopJrwpHGMQHpjERmogTV2LT4Y/page/fwpz"
          frameBorder="0"
          style={{
            border: 0
          }}
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default AdminReporting;
