import './index.css';
import './user-avatar.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Icon, Loader, Menu } from 'semantic-ui-react';

import { LGSVG } from '../../../assets';
import { APP_ROOT, LAST_CHANNEL_KEY, ROLES } from '../../../consts';
import UserCallCreditsStripeInfo from '../../../graphql/queries/user-call-credits-stripe-info.graphql';
import withCompanies from '../../hoc/with-companies';
import withUser from '../../hoc/with-user';
import { calculateUserCallCreditsRemaining } from '../call/call-credits-calculations';
import CallInfo from './call-info';
import MenuItems from './menu-items';
import UserAvatar from './user-avatar';

const ExternalURLPattern = /^(:?https?)?:\/\//;

const SignupRoutes = [
  '/application',
  '/onboard',
  '/register',
  '/suggested-guides'
];

const ChannelRoutes = [
  '/calls',
  '/dashboard',
  '/guides',
  '/onboard',
  '/resources',
  '/suggested-guides'
];

@withRouter
@withUser()
@withCompanies()
@graphql(UserCallCreditsStripeInfo, {
  name: 'stripeUser',
  options: props => ({
    skip: true
  })
})
class AppNav extends Component {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            channel: PropTypes.shape({
              slug: PropTypes.string
            })
          })
        ),
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired,
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(PropTypes.shape({}))
    })
  };

  _getMenuItems() {
    const { User } = this.props.user;

    if (!User || !User.roles.length) {
      return [];
    }

    const channelSlug = this._getChannelSlug();

    const byRole = menuItem => {
      return menuItem.roles.some(
        role => !!User.roles.find(r => r.name === role)
      );
    };
    const noChannel = menuItem => {
      if (!menuItem.to.includes(':channelName')) {
        return true;
      }
      return !!channelSlug;
    };

    return MenuItems.filter(i => !i.isSubmenuItem)
      .filter(byRole)
      .filter(noChannel);
  }

  _isRoot() {
    const { location } = this.props;

    const rootRegex = new RegExp(`^${APP_ROOT}\\/?$`);
    return !!location.pathname.match(rootRegex);
  }

  _isSignupRoute() {
    const { location } = this.props;

    return SignupRoutes.some(route => {
      const channelRegex = new RegExp(`^${APP_ROOT}(\\/[\\w-]+)?${route}`);
      return !!location.pathname.match(channelRegex);
    });
  }

  _getDefaultChannelSlug() {
    const { User } = this.props.user;

    const slug = localStorage.getItem(LAST_CHANNEL_KEY);
    if (slug) {
      return slug;
    }

    if (User) {
      const [channelProfile] = User.channelProfiles;
      if (channelProfile) {
        return channelProfile.channel.slug;
      }
    }

    return null;
  }

  _getChannelSlug() {
    const { location } = this.props;

    const channelRoute = ChannelRoutes.find(route =>
      location.pathname.includes(route)
    );
    if (!channelRoute) {
      return this._getDefaultChannelSlug();
    }

    const channelRegex = new RegExp(`^${APP_ROOT}\\/([\\w-]+)${channelRoute}`);
    const match = location.pathname.match(channelRegex);
    if (!match) {
      return this._getDefaultChannelSlug();
    }

    const [, slug] = match;
    return slug;
  }

  render() {
    if (this._isSignupRoute()) {
      return null;
    }

    return (
      <div className={`app-nav ${this._isRoot() ? 'root' : ''}`}>
        <Menu fluid className="app-nav-menu-container">
          <div className="app-nav-menu">
            {this._renderHeader()}
            {this._renderMenu()}
          </div>
        </Menu>
        {this._renderUserAvatar()}
      </div>
    );
  }

  _renderUserAvatar = () => {
    const { toggleMenu, menuOpen } = this.props;
    return <UserAvatar toggleMenu={toggleMenu} menuOpen={menuOpen} />;
  };

  _renderCallCreditsLink = () => {
    const { stripeUser } = this.props;

    let creds = (
      <Loader active inline size="mini" style={{ marginLeft: '0.5rem' }} />
    );

    if (stripeUser && !stripeUser.loading) {
      creds = (
        <span className="app-nav-callcredit-amt avenir bold">{`${calculateUserCallCreditsRemaining(
          stripeUser.User
        )} credits`}</span>
      );
    }

    return (
      <Link to="/settings/payment-options">
        <div className="app-nav-callcredit-readout avenir">
          <span>
            {`Balance:`} {creds}
          </span>
        </div>
      </Link>
    );
  };

  _renderHeader() {
    const { companies } = this.props;
    const { User } = this.props.user;
    const isClient = User && User.roles.some(r => r.name === ROLES.CLIENT);

    let company = null;
    if (companies && companies.companies) {
      [company] = companies.companies;
    }

    const shouldShowCompany = !this._isRoot() && !!company && isClient;
    const shouldShowCallCredits = !this._isRoot() && isClient;

    return (
      <div className="app-nav-logo-container">
        <Link to={APP_ROOT} className="app-nav-menu-logo">
          <img src={LGSVG} alt="Lifeguides Logo" className="app-nav-logo" />
        </Link>
        {shouldShowCompany && (
          <div className="app-nav-company-logo">
            <span className="for-company">for &nbsp;</span>

            <div className="company-name">{company && company.name}</div>
          </div>
        )}

        {shouldShowCallCredits && this._renderCallCreditsLink()}
      </div>
    );
  }

  _renderMenu() {
    if (this._isSignupRoute() || this._isRoot()) {
      return null;
    }

    const menuItems = this._getMenuItems();
    return menuItems.length ? (
      menuItems.map(this._renderMenuItem)
    ) : (
      <div key={`lifeguides-menu-item-placeholder`} />
    );
  }

  _renderMenuItem = (menuItem, index) => {
    const { history, location } = this.props;

    let url = menuItem.to;
    const channelSlug = this._getChannelSlug();
    if (channelSlug) {
      url = url.replace(':channelName', channelSlug);
    }
    const href = history.createHref({ pathname: url });

    const isRoot = this._isRoot();
    const isActive = !isRoot && location.pathname.startsWith(url);
    const isExternal = !!url.match(ExternalURLPattern);

    const activeStyle = {
      borderRadius: '4px',
      color: '#ffffff',
      backgroundColor: 'rgba(221,227,233,0.2)'
    };
    const passiveStyle = {
      //backgroundColor: '#2a62f0'
    };

    const style = {
      ...{
        color: '#fff'
      },
      ...(isActive ? activeStyle : passiveStyle)
    };

    return (
      <Menu.Item
        key={`lifeguides-menu-item-${menuItem.name}`}
        name={`${menuItem.name}`}
        className="app-nav-menu-item"
        link
        style={style}
        href={href}
        target={isExternal ? '_blank' : 'self'}
        onClick={event => {
          if (!isExternal) {
            event.preventDefault();
            history.push(url);
            this._closeOpenMenu();
          }
        }}
      >
        {menuItem.icon && <Icon className={`${menuItem.icon} lineawesome`} />}
        <span>{menuItem.label}</span>
      </Menu.Item>
    );
  };

  _closeOpenMenu = () => {
    const { menuOpen, toggleMenu } = this.props;

    if (menuOpen && _.isFunction(toggleMenu)) {
      toggleMenu();
    }
  };

  _renderUserCallInfo() {
    const { User } = this.props.user;

    if (this._isRoot() || this._isSignupRoute()) {
      return null;
    }

    if (!User) {
      return null;
    }

    const isClient = User.roles.find(role => role.name === ROLES.CLIENT);
    if (!isClient) {
      return null;
    }

    return <CallInfo channelSlug={this._getChannelSlug()} />;
  }
}

export default AppNav;
