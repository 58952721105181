import './dialog.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Modal, Segment } from 'semantic-ui-react';

import BugsnagClient from '../../../bugsnag';
import {
  createOrUpdateIntakeAnswer,
  ensureUserChannelProfileExists,
  updateUserChannelProfileSubChannels
} from '../../../channel-profile';
import withChannel from '../../hoc/with-channel';
import OptionSelect from './option-select';

const SubChannelLimit = 2;

@withRouter
@withChannel({ loader: <Segment basic loading />, required: true })
class OnboardDialog extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        intakeQuestions: PropTypes.arrayOf(PropTypes.shape({})),
        subChannels: PropTypes.arrayOf(PropTypes.shape({}))
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    onComplete: PropTypes.func.isRequired,
    open: PropTypes.bool,
    history: PropTypes.object.isRequired
  };

  state = {
    selectedAnswer: null,
    selectedSubChannels: [],
    step: 0,
    submitting: false
  };

  render() {
    const { open } = this.props;
    const { step } = this.state;

    return (
      <Modal open={open} size="tiny">
        <Modal.Content className="onboard-dialog">
          {step === 0 && this._renderIntakeQuestions()}
          {step === 1 && this._renderSelectSubChannel()}
        </Modal.Content>
      </Modal>
    );
  }

  _renderIntakeQuestions() {
    const { onComplete } = this.props;
    const { loading, channel } = this.props.channel;
    const { selectedAnswer, submitting } = this.state;

    if (loading) {
      return <Segment basic loading />;
    }

    const [intakeQuestion] = channel.intakeQuestions;
    if (!intakeQuestion) {
      this._goToNextStep();
      return null;
    }

    const title = intakeQuestion['clientTitle'];
    const subTitle = intakeQuestion['clientSubTitle'];

    return (
      <div className="intake-questions">
        <Header as="h2" className="header-and-subheader">
          <div className="header">{title}</div>
          <div className="subheader">
            <Header.Subheader>{subTitle}</Header.Subheader>
          </div>
        </Header>
        <div className="options-and-btns">
          <OptionSelect
            options={intakeQuestion.options.map(option => ({
              label: option,
              value: option
            }))}
            onChange={({ value }) => {
              this.setState({ selectedAnswer: value });
            }}
          />
          <div className="modal-action-btns">
            <Button
              basic
              onClick={onComplete}
              className="skip-btn hollow-button"
            >
              Skip these Steps
            </Button>
            <Button
              loading={submitting}
              disabled={!selectedAnswer || submitting}
              onClick={this._onSubmitAnswer}
              className="continue-btn"
              primary
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    );
  }

  _renderSelectSubChannel() {
    const { onComplete } = this.props;
    const { loading, channel } = this.props.channel;
    const { selectedSubChannels } = this.state;

    if (loading) {
      return <Segment basic loading />;
    }

    return (
      <div className="subchannel">
        <Header as="h2" className="header-and-subheader">
          <div className="header">
            What subjects would you like to talk about with your LifeGuide?
          </div>
          <div className="subheader">
            <Header.Subheader>
              Please choose your top two areas to start our conversation.
            </Header.Subheader>
          </div>
        </Header>

        <div className="select-subchannel">
          <OptionSelect
            options={channel.subChannels.map(subChannel => ({
              label: subChannel.title,
              value: subChannel.id
            }))}
            multiple
            limit={SubChannelLimit}
            onChange={options => {
              const selectedSubChannels = channel.subChannels.filter(
                subChannel =>
                  !!options.find(option => option.value === subChannel.id)
              );
              this.setState({ selectedSubChannels });
            }}
          />
          <Header as="h4" className="selected-subchannels-amt">
            {selectedSubChannels.length} of {SubChannelLimit} Selected
          </Header>
          <div className="modal-action-btns">
            <Button
              basic
              onClick={onComplete}
              className="skip-btn hollow-button"
            >
              Skip these Steps
            </Button>

            <Button
              disabled={
                !selectedSubChannels.length ||
                selectedSubChannels.length > SubChannelLimit
              }
              onClick={this._onSubmitSubChannels}
              className="continue-btn save-btn"
              primary
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }

  _onSubmitAnswer = () => {
    const { channel } = this.props.channel;
    const { selectedAnswer } = this.state;

    const [intakeQuestion] = channel.intakeQuestions;

    this.setState({ error: null, submitting: true });
    ensureUserChannelProfileExists(channel)
      .then(userChannelProfile => {
        return createOrUpdateIntakeAnswer(
          userChannelProfile,
          intakeQuestion,
          selectedAnswer
        ).then(() => {
          this.setState({ submitting: false });
          this._goToNextStep();
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'OnboardDialog._onSubmitAnswer'
        });
      });
  };

  _onSubmitSubChannels = () => {
    const { channel } = this.props.channel;
    const { selectedSubChannels } = this.state;

    this.setState({ error: null, submitting: true });
    ensureUserChannelProfileExists(channel)
      .then(userChannelProfile => {
        return updateUserChannelProfileSubChannels(
          userChannelProfile,
          selectedSubChannels
        ).then(() => {
          this.setState({ submitting: false });
          this._goToNextStep();
        });
      })
      .catch(error => {
        this.setState({ error, submitting: false });
        BugsnagClient.notify(error, {
          context: 'OnboardDialog._onSubmitSubChannels'
        });
      });
  };

  _goToNextStep() {
    const { onComplete } = this.props;
    const { step } = this.state;

    if (step < 1) {
      this.setState({ step: step + 1 });
    } else {
      onComplete();
    }
  }
}
export default OnboardDialog;
