import toPairs from 'lodash/toPairs';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { US_STATES_AND_TERRITORIES } from 'us-regions';

import MyForm, { FormField } from '../../../ui/form';

const DATE_FORMAT = moment.HTML5_FMT.DATE;

class InviteClientForm extends Component {
  static propTypes = {
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func
  };

  state = {
    data: {
      address: '',
      city: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      state: '',
      zipcode: ''
    }
  };

  _isValidZipCode = zipcode => {
    let zipValidator = new RegExp('^[0-9a-zA-Z ]{5,8}$', 'gimu');

    return zipValidator.test(zipcode.zipcode);
  };

  render() {
    const { onValidate } = this.props;
    const { data } = this.state;

    return (
      <MyForm
        className="concierge-create-call-request-form"
        data={data}
        onChange={this._onDataChange}
        onValidate={onValidate}
      >
        <FormField
          component={Input}
          name="emailAddress"
          label="E-mail Address *"
          type="email"
          autoComplete="false"
          validator={({ emailAddress }) => {
            if (!emailAddress) {
              throw new Error('E-mail address is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="firstName"
          label="First Name *"
          type="text"
          autoComplete="false"
          validator={({ firstName }) => {
            if (!firstName) {
              throw new Error('First name is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="lastName"
          label="Last Name *"
          type="text"
          autoComplete="false"
          validator={({ lastName }) => {
            if (!lastName) {
              throw new Error('Last name is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="phoneNumber"
          label="Personal Mobile Number"
          type="text"
          autoComplete="false"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              return;
            }
            let digits = phoneNumber.replace(/\D/g, '');
            if (
              !(
                (digits[0] === '1' && digits.length === 11) ||
                digits.length === 10
              )
            ) {
              throw new Error('Mobile number must have 10 digits');
            }
          }}
        />
        <FormField
          component={Input}
          type="text"
          name="address"
          label="Address"
          aria-placeholder="Address"
        />
        <Form.Group widths="equal" aria-placeholder="Enter your city and state">
          <FormField
            component={Input}
            name="city"
            label="City"
            aria-placeholder="City"
          />
          <FormField
            component={Dropdown}
            name="state"
            label="State"
            aria-placeholder="US States and Terrotories"
            fluid
            selection
            search
            options={toPairs(US_STATES_AND_TERRITORIES).map(
              ([abbreviation, name]) => ({
                text: name,
                value: abbreviation
              })
            )}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="zipcode"
          type="text"
          maxLength="8"
          label="Zipcode"
          aria-placeholder="Zipcode"
          validator={zipcode => {
            if (zipcode.zipcode && !this._isValidZipCode(zipcode)) {
              throw new Error(
                'Zipcode formats currently accepted are USA (00000) and Canada (000 000).'
              );
            }
          }}
        />
      </MyForm>
    );
  }

  _onDataChange = data => {
    const { onDataChange } = this.props;

    const formatted = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    this.setState({
      data: formatted
    });

    if (onDataChange) {
      onDataChange(formatted);
    }
  };
}
export default InviteClientForm;
