import toPairs from 'lodash/toPairs';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, Form, Input, Radio } from 'semantic-ui-react';
import { US_STATES_AND_TERRITORIES } from 'us-regions';

import AllClientsAndInvites from '../../../../graphql/queries/all-clients-and-invites.graphql';
import CompaniesQuery from '../../../../graphql/queries/all-companies.graphql';
import graphql from '../../../hoc/graphql';
import Avatar from '../../../ui/avatar';
import MyForm, { FormField } from '../../../ui/form';
import TimezoneSelector from '../../../ui/timezone-selector';

const DATE_FORMAT = moment.HTML5_FMT.DATE;

@graphql(CompaniesQuery, {
  name: 'companies'
})
@graphql(AllClientsAndInvites, {
  name: 'clients'
})
class CreateClientForm extends Component {
  static propTypes = {
    clientInvite: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.string
      }),
      isSpouse: PropTypes.bool,
      user: PropTypes.shape({
        corporateEmailAddress: PropTypes.string,
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    }),
    clients: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          avatarUrl: PropTypes.string,
          companyIds: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              company: PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string
              })
            })
          ),
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          timezone: PropTypes.string
        })
      )
    }).isRequired,
    companies: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      )
    }).isRequired,
    emailAddress: PropTypes.string,
    zipCode: PropTypes.string,
    onDataChange: PropTypes.func,
    onValidate: PropTypes.func
  };

  constructor(props) {
    super(props);

    const { clientInvite } = this.props;

    const data = {
      address: '',
      city: '',
      companyId: null,
      corporateEmailAddress: props.emailAddress || '',
      emailAddress: props.emailAddress || '',
      firstName: '',
      lastName: '',
      isSpouse: false,
      password: '',
      passwordConfirmation: '',
      phoneNumber: '',
      spouseId: null,
      state: '',
      timezone: moment.tz.guess(),
      zipcode: ''
    };

    this.state = {
      data: {
        ...data,
        ...(clientInvite && {
          ...(clientInvite.company && { companyId: clientInvite.company.id }),
          corporateEmailAddress: clientInvite.isSpouse
            ? ''
            : clientInvite.user.corporateEmailAddress,
          address: clientInvite.user.address || '',
          city: clientInvite.user.city || '',
          emailAddress: clientInvite.isSpouse
            ? clientInvite.user.emailAddress
            : '',
          firstName: clientInvite.user.firstName,
          isSpouse: clientInvite.isSpouse,
          lastName: clientInvite.user.lastName,
          phoneNumber: clientInvite.user.phoneNumber || '',
          state: clientInvite.user.state || '',
          zipcode: clientInvite.user.zipcode || ''
        })
      }
    };
  }

  _isValidZipCode = zipcode => {
    let zipValidator = new RegExp('^[0-9a-zA-Z ]{5,8}$', 'gimu');

    return zipValidator.test(zipcode.zipcode);
  };

  render() {
    const { clientInvite, onValidate } = this.props;
    const { loading: clientsLoading, users: clients } = this.props.clients;
    const { loading: companiesLoading, companies } = this.props.companies;
    const { data } = this.state;

    return (
      <MyForm
        className="concierge-create-user-form"
        data={data}
        onChange={this._onDataChange}
        onValidate={onValidate}
      >
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="firstName"
            label="First Name *"
            aria-label="First Name"
            aria-required="true"
            type="text"
            autoComplete="false"
            validator={({ firstName }) => {
              if (!firstName) {
                throw new Error('First name is required');
              }
            }}
          />
          <FormField
            component={Input}
            name="lastName"
            label="Last Name *"
            aria-label="Last Name"
            aria-required="true"
            type="text"
            autoComplete="false"
            validator={({ lastName }) => {
              if (!lastName) {
                throw new Error('Last name is required');
              }
            }}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="emailAddress"
          label="Personal (Login) E-mail Address *"
          aria-label="E-mail Address"
          aria-required="true"
          type="email"
          autoComplete="false"
          validator={({ emailAddress }) => {
            if (!emailAddress) {
              throw new Error('E-mail is required');
            }
          }}
        />
        <FormField
          component={Input}
          name="phoneNumber"
          label="Personal Mobile Number *"
          aria-label="Personal Mobile Number"
          aria-required="true"
          type="text"
          autoComplete="false"
          validator={({ phoneNumber }) => {
            if (!phoneNumber) {
              throw new Error('Mobile number is required');
            }
            let digits = phoneNumber.replace(/\D/g, '');
            if (
              !(
                (digits[0] === '1' && digits.length === 11) ||
                digits.length === 10
              )
            ) {
              throw new Error('Mobile number must have 10 digits');
            }
          }}
        />
        <FormField
          component={Input}
          type="text"
          name="address"
          label="Address"
          aria-placeholder="Address"
        />
        <Form.Group widths="equal" aria-placeholder="Enter your city and state">
          <FormField
            component={Input}
            name="city"
            label="City"
            aria-placeholder="City"
          />
          <FormField
            component={Dropdown}
            name="state"
            label="State"
            aria-placeholder="US States and Terrotories"
            fluid
            selection
            search
            options={toPairs(US_STATES_AND_TERRITORIES).map(
              ([abbreviation, name]) => ({
                text: name,
                value: abbreviation
              })
            )}
          />
        </Form.Group>
        <FormField
          component={Input}
          name="zipcode"
          type="text"
          maxLength="8"
          label="Zipcode"
          aria-placeholder="Zipcode"
          validator={zipcode => {
            if (zipcode.zipcode && !this._isValidZipCode(zipcode)) {
              throw new Error(
                'Zipcode formats currently accepted are USA (00000) and Canada (000 000).'
              );
            }
          }}
        />
        <FormField
          component={TimezoneSelector}
          name="timezone"
          label="Timezone"
          aria-label="Timezone"
          aria-required="true"
          fluid
          search
          selection
          validator={({ timezone }) => {
            if (!timezone) {
              throw new Error('Timezone is required');
            }
          }}
        />
        <Form.Field>
          <Radio
            toggle
            label="Is Spouse"
            checked={data.isSpouse}
            disabled={!!clientInvite}
            onChange={() => {
              const isSpouse = !data.isSpouse;

              this._onDataChange({
                ...data,
                corporateEmailAddress: isSpouse
                  ? ''
                  : data.corporateEmailAddress,
                isSpouse
              });
            }}
          />
        </Form.Field>
        {data.isSpouse && (
          <FormField
            component={Dropdown}
            loading={clientsLoading}
            name="spouseId"
            label="Spouse *"
            aria-label="Spouse"
            aria-required="true"
            fluid
            search
            selection
            options={[{ text: '', value: null }].concat(
              clients
                ? clients.map(user => ({
                    image: <Avatar user={user} />,
                    text: `${user.firstName} ${user.lastName} (${user.emailAddress})`,
                    value: user.id
                  }))
                : []
            )}
            validator={({ isSpouse, spouseId }) => {
              if (isSpouse && !spouseId) {
                throw new Error('Spouse is required');
              }
            }}
          />
        )}
        <FormField
          component={Dropdown}
          loading={companiesLoading}
          name="companyId"
          label="Company *"
          aria-label="Company"
          aria-required="true"
          disabled={data.isSpouse || !!clientInvite}
          fluid
          search
          selection
          options={[{ text: '', value: null }].concat(
            companies
              ? companies
                  .filter(company => company.isPublished)
                  .map(company => ({
                    text: company.name,
                    value: company.id
                  }))
              : []
          )}
          validator={({ companyId }) => {
            if (!companyId) {
              throw new Error('Company is required');
            }
          }}
        />
        {!data.isSpouse && (
          <FormField
            component={Input}
            name="corporateEmailAddress"
            label="Corporate E-mail Address *"
            aria-label="Corporate E-mail Address"
            aria-required="true"
            type="email"
            autoComplete="false"
            validator={({ corporateEmailAddress, isSpouse }) => {
              if (!isSpouse && !corporateEmailAddress) {
                throw new Error('Corporate e-mail is required');
              }
            }}
          />
        )}
        <Form.Group widths="equal">
          <FormField
            component={Input}
            name="password"
            label="Password *"
            aria-label="Password"
            aria-required="true"
            type="password"
            validator={({ password }) => {
              if (!password) {
                throw new Error('Must enter a password');
              }
              if (password.length < 8) {
                throw new Error('Password must have 8 characters or more');
              }
            }}
          />
          <FormField
            component={Input}
            name="passwordConfirmation"
            label="Confirm Password *"
            aria-label="Confirm Password"
            aria-required="true"
            type="password"
            validator={({ password, passwordConfirmation }) => {
              if (!passwordConfirmation) {
                throw new Error('Must confirm password');
              }
              if (password !== passwordConfirmation) {
                throw new Error('Does not match given password');
              }
            }}
          />
        </Form.Group>
      </MyForm>
    );
  }

  _onDataChange = data => {
    const { onDataChange } = this.props;
    const { users: clients } = this.props.clients;
    const { companies } = this.props.companies;

    const selectedSpouse =
      clients && clients.find(client => client.id === data.spouseId);
    const clientInvite = selectedSpouse && selectedSpouse.clientInvite;
    const isClientInvitePending =
      clientInvite && clientInvite.status === 'PENDING';
    const companyIds = isClientInvitePending
      ? [clientInvite.companyId]
      : (selectedSpouse &&
          selectedSpouse.companyIds.map(connection => connection.companyId)) ||
        [];
    const spouseCompany =
      companies && companies.find(company => companyIds.includes(company.id));

    const updated = {
      ...data,
      companyId: spouseCompany ? spouseCompany.id : data.companyId,
      corporateEmailAddress: data.isSpouse ? '' : data.corporateEmailAddress,
      phoneNumber: data.phoneNumber.replace(/\D/g, '')
    };

    this.setState({ data: updated });

    if (onDataChange) {
      onDataChange(updated);
    }
  };
}
export default CreateClientForm;
