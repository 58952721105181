import GoogleTagManager from 'react-gtm-module';

const DATA_LAYER_NAME = 'dataLayer';

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  GoogleTagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW
  });
}

export function dataLayer(dl) {
  if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
    return;
  }

  GoogleTagManager.dataLayer({
    dataLayer: dl,
    dataLayerName: DATA_LAYER_NAME
  });
}

export function event(event, value, data) {
  const dl = { event, ...data };
  if (value !== null) {
    dl.conversionValue = value;
  }
  dataLayer(dl);
}
