import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon as RIKIcon } from 'react-icons-kit';
import { selectCircle } from 'react-icons-kit/metrize/selectCircle';
import { Dimmer, Icon, Label, Loader, Popup } from 'semantic-ui-react';

import ClientByIdQuery from '../../../../../graphql/queries/client-by-id-concierge.graphql';
import graphql from '../../../../hoc/graphql';
import { calculateUserCallCreditsRemaining } from '../../../call/call-credits-calculations';

@graphql(ClientByIdQuery, {
  name: 'client',
  skip: ({ id }) => !id,
  options: ({ id }) => ({ variables: { id } })
})
class ClientInfo extends Component {
  static propTypes = {
    id: PropTypes.string,
    client: PropTypes.shape({
      user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        timezone: PropTypes.string
      })
    })
  };

  render() {
    const { id } = this.props;

    if (!id) {
      return <div className="info-box empty" />;
    }

    const { loading, user } = this.props.client;

    if (loading) {
      return (
        <Dimmer active inverted style={{ position: 'relative' }}>
          <Loader />
        </Dimmer>
      );
    }

    const callCredits = calculateUserCallCreditsRemaining(user);
    const negCreditPopUpMsg =
      callCredits < 1 ? `Booking will result in negative credits.` : null;
    const creditPopUpMsg = (
      <>
        {' '}
        {user.firstName} {user.lastName} has {callCredits} Call Credits
        Available. {negCreditPopUpMsg}
      </>
    );

    return (
      <div className="info-box">
        <Popup
          trigger={
            <Label basic color={callCredits < 1 ? 'red' : 'grey'}>
              <RIKIcon icon={selectCircle} className="match-fa" />
              {callCredits}
            </Label>
          }
          header="Call Credits"
          content={creditPopUpMsg}
        />
        <Popup
          trigger={
            <Label basic>
              <Icon name="clock" />
              {/* {`${user.timezone}`} */}
              {` (${moment.tz(user.timezone).format('z')})`}
            </Label>
          }
          header="Timezone"
          content={`${user.firstName} is in the ${user.timezone} region`}
        />
      </div>
    );
  }
}
export default ClientInfo;
