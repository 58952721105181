import './index.css';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Header, Modal } from 'semantic-ui-react';

export default class PrivacyPolicy extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool
  };

  title = () => {
    return `Privacy Policy`;
  };

  content = () => {
    return (
      <div className="txtNew" id="comp-jb5lebkr">
        <h4 className="font_4">
          <span>
            <span>PRIVACY POLICY</span>
          </span>
        </h4>

        <p className="font_8">
          <span>Last updated November 1, 2017</span>
        </p>

        <p className="font_8">
          <span>
            LifeGuides, PBC, a public benefit corporation (“we” or “us” or
            “our”) respects the privacy of our users (“user” or “you”). This
            Privacy Policy explains how we collect, use, disclose, and safeguard
            your information when you visit our website{' '}
            <span>
              <a
                href="https://www.lifeguides.com/"
                target="_blank"
                rel="noopener noreferrer"
                data-content="https://www.lifeguides.com/"
                data-type="external"
              >
                www.lifeguides.com{' '}
              </a>
            </span>
            and our mobile application, and any other media form, media channel,
            mobile website, or mobile application related or connected thereto,
            or use any services provided through our website or such media forms
            (collectively, the “Site”). Please read this Privacy Policy
            carefully. IF YOU DO NOT AGREE WITH THE TERMS OF&nbsp; THIS PRIVACY
            POLICY, YOU MAY NOT ACCESS OR USE THE SITE.
          </span>
        </p>

        <p className="font_8">
          <span>
            We reserve the right to make changes to this Privacy Policy at any
            time and for any reason. We will alert you{' '}
          </span>
          <span>about</span>
          <span>
            {' '}
            any changes by updating the “Revised” date of this Privacy Policy.
            Any changes or modifications will be effective immediately upon
            posting the updated Privacy Policy on the Site, and you waive the
            right to receive specific notice of each such change or
            modification. You are encouraged to periodically review this Privacy
            Policy to stay informed of updates. You will be deemed to have been
            made aware of, will be subject to, and will be deemed to have
            accepted the changes in any revised Privacy Policy by your continued
            use of the Site after the date such revised Privacy Policy is
            posted.
            <span>
              <a>[LW1]</a>
            </span>
            &nbsp;
            <span>
              <a>[mc2]</a>
            </span>
            &nbsp;
          </span>
        </p>

        <p className="font_8">
          <span>
            <span>
              <span>
                <span className="wixGuard">&#8203;</span>
              </span>
            </span>
          </span>
        </p>

        <h5 className="font_5">COLLECTION OF YOUR INFORMATION</h5>

        <p className="font_8">
          <span>
            We may collect information about you in a variety of ways. The
            information we may collect on the Site includes:
          </span>
        </p>

        <h5 className="font_5">Personal Data You Provide</h5>

        <p className="font_8">
          <span>
            Personally identifiable information, such as your name, shipping
            address, email address, and telephone number, and demographic
            information, such as your age, gender, hometown, and interests, that
            you voluntarily give to us when you register with the Site or our
            mobile application, or when you choose to participate in various
            activities related to the Site and our mobile application, such as
            online chat and message boards. Furthermore
            <span>
              <a>[mc3</a>
            </span>
          </span>
          <span>
            <a>
              <span>]</span>
            </a>
          </span>
          <span>&nbsp;,</span>
          <span>
            {' '}
            your Guide<sup>™</sup> will periodically collect information during
            any phone calls and some of that information is entered into our
            database, so that, among other purposes, we can optimize the
            delivery of content related to your Life Challenge™. You are under
            no obligation to provide us with personal information of any kind,
            but your refusal to do so may prevent you from using certain
            features of the Site and our mobile application.
          </span>
        </p>

        <h5 className="font_5">&nbsp;</h5>

        <h5 className="font_5">Financial Data We Collect From You</h5>

        <p className="font_8">
          <span>
            Financial information, such as data related to your payment method
            (e.g. valid credit card number, card brand, expiration date) that we
            may collect when you purchase, order, return, exchange, or request
            information about our services from the Site or our mobile
            application. We store only very limited, if any, financial
            information that we collect. Otherwise, all financial information is
            stored by our payment processors: PayPal and Stripe, and you are
            encouraged to review their privacy policies and contact them
            directly for responses to your questions.
          </span>
        </p>

        <h5 className="font_5">Data From Third Party Social Networks</h5>

        <p className="font_8">
          <span>
            User information from social networking sites, such as Facebook,
            Google+, including your name, your social network username,
            location, gender, birth date, email address, profile picture, and
            public data for contacts, if you link or connect your account to
            such social networks. If you are using our mobile application, this
            information may also include the contact information{' '}
          </span>
          <span>of</span>
          <span>
            {' '}
            anyone you invite to use and/or join our mobile application.
          </span>
        </p>

        <h5 className="font_5">&nbsp;</h5>

        <h5 className="font_5">Data From Contests, Giveaways, and Surveys</h5>

        <p className="font_8">
          <span>Personal and other information</span>
          <span>
            {' '}
            you may provide when entering contests or giveaways and/or
            responding to surveys.
            <span>
              <a>[LW4]</a>
            </span>
            &nbsp;
            <span>
              <a>[mc5]</a>
            </span>
            &nbsp;
          </span>
        </p>

        <h5 className="font_5">Mobile Application Information</h5>

        <p className="font_8">
          <span>If you connect using our mobile application:</span>
        </p>

        <ul className="font_8">
          <li>
            <p className="font_8">
              <span>
                Mobile Device Access. We may request access or permission to
                certain features from your mobile device, including your mobile
                device’s microphone, camera, location data, SMS messages, and
                other features. If you wish to change our access or permissions,
                you may do so in your device’s settings.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Mobile Device Data.&nbsp; We may collect usage data from your
                mobile device, including the following types of information:
                operating system, device serial number, log files, firmware
                version, battery and signal strength, settings, error rates,
                session counts, technical specifications, pages viewed, time
                spent viewing pages, time spent in{' '}
              </span>
              <span>mobile application</span>
              <span>
                , features used, internet and mobile data connection
                information, browser type, language and time zone, mobile phone
                number and IP address.
              </span>
            </p>
          </li>
        </ul>

        <h5 className="font_5">&nbsp;</h5>

        <h5 className="font_5">TRACKING TECHNOLOGIES</h5>

        <p className="font_8">
          <span>
            We also collect information through the use of tracking software on
            our Site and our mobile application in the following ways:
          </span>
        </p>

        <h5 className="font_5">Cookies and Web Beacons</h5>

        <p className="font_8">
          <span>
            We may use cookies, web beacons, tracking pixels, and other tracking
            technologies on the Site and our mobile application to help
            customize the Site and our mobile application and improve your
            experience. For more information on how we use cookies, please refer
            to our Cookie Policy posted on the Site
            <span>
              <a>[LW6]</a>
            </span>
            &nbsp;
            <span>
              <a>[mc7</a>
            </span>
          </span>
          <span>
            <a>
              <span>]</span>
            </a>
          </span>
          <span>&nbsp;,</span>
          <span>
            {' '}
            which is incorporated into this Privacy Policy. By using the Site,
            you consent to the terms of our Cookie Policy.
          </span>
        </p>

        <h5 className="font_5">Website Analytics</h5>

        <h5 className="font_5">
          <span className="wixGuard">&#8203;</span>
        </h5>

        <p className="font_8">
          <span>
            We may also partner with selected third-party vendors, such as
            Google Analytics, to allow tracking technologies and remarketing
            services on the Site and our mobile application through the use of{' '}
          </span>
          <span>first party</span>
          <span>
            {' '}
            cookies and third&shy;party cookies, to, among other things, analyze
            and track users’ use of the Site and our mobile application,
            determine the popularity of certain content and better understand
            online activity. By accessing the Site, our mobile application, you
            consent to the collection and use of your information by these
            third&shy;party vendors. You are encouraged to review their privacy
            policy and contact them directly for responses to your questions. We
            do not transfer personal information to these third&shy;party
            vendors. However, If you do not want any information to be collected
            and used by tracking technologies, you can visit the third&shy;
            party vendor or{' '}
            <span>
              <a
                href="https://developers.facebook.com/docs/facebook-login/permissions"
                target="_blank"
                rel="noopener noreferrer"
                data-content="https://developers.facebook.com/docs/facebook-login/permissions"
                data-type="external"
              >
                Network Advertising Initiative Opt&shy;Out Tool{' '}
              </a>
            </span>
            or{' '}
            <span>
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener noreferrer"
                data-content="http://www.aboutads.info/choices/"
                data-type="external"
              >
                Digital Advertising Alliance Opt&shy;Out Tool
              </a>
            </span>
            .
          </span>
        </p>

        <p className="font_8">
          <span>
            You should be aware that getting a new computer, installing a new
            browser, upgrading an existing browser, or erasing or otherwise
            altering your browser’s cookies files may also clear certain
            opt&shy;out cookies, plug&shy;ins, or settings.
          </span>
        </p>

        <h5 className="font_5">USE OF YOUR INFORMATION</h5>

        <p className="font_8">
          <span>
            Having accurate information about you permits us to provide you with
            a smooth, efficient, and customized experience. Specifically, we may
            use information collected about you via the Site or our&nbsp;
            mobile&nbsp; application to:
          </span>
        </p>

        <ul className="font_8">
          <li>
            <p className="font_8">
              <span>
                Provide and support the services for which you have paid.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Administer sweepstakes, promotions, and contests.
                <span>
                  <a>[LW8]</a>
                </span>
                &nbsp;
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Create and manage your account.</span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Email you regarding your account </span>
              <span>or &nbsp;order</span>
              <span>.</span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Fulfill and manage purchases, orders, payments, and other
                transactions related to the Site and our mobile application.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Generate a personal profile about you to make future visits to
                the Site and our mobile application more personalized.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Make improvements to the Site, our mobile application{' '}
              </span>
              <span>and</span>
              <span>
                {' '}
                services we provide through the Site and mobile application.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Monitor and analyze usage and trends to improve your experience
                with the Site and our mobile application.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Notify you of updates to the Site and our mobile application.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Offer new products, services, mobile applications, and/or
                recommendations to you.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Process payments and refunds.</span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Request feedback and contact you about your use of the Site and
                our mobile application.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Resolve disputes and troubleshoot problems.</span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Respond to product and customer service requests.</span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Send you a newsletter.</span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Perform other business activities as needed to facilitate or
                support any of the foregoing.
              </span>
            </p>
          </li>
        </ul>

        <p className="font_8">
          <span>
            We may use anonymized, non-personal information collected from our
            users in the aggregate to improve our services and for other
            business and administrative purposes.
          </span>
        </p>

        <h5 className="font_5">DISCLOSURE OF YOUR INFORMATION</h5>

        <p className="font_8">
          <span>
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </span>
        </p>

        <h5 className="font_5">By Law or to Protect Rights</h5>

        <p className="font_8">
          <span>
            If we believe the release of information about you is necessary to
            respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and
            safety of ourselves or others, we may share your information. This
            includes exchanging information with other entities for fraud
            protection and credit risk reduction.
          </span>
        </p>

        <h5 className="font_5">Interactions with Other Users</h5>

        <p className="font_8">
          <span>
            If you interact with other users of the Site and our mobile
            application, those users may see your name, profile photo, and
            descriptions of your activity, including sending invitations to
            other users, chatting with other users, liking posts, following
            blogs.
          </span>
        </p>

        <h5 className="font_5">Online Postings</h5>

        <p className="font_8">
          <span>
            When you post comments, contributions or other content to the Site
            or our mobile applications, your posts may be viewed by all users
            and may be publicly distributed outside the Site and our mobile
            application in perpetuity.
          </span>
        </p>

        <h5 className="font_5">Affiliates</h5>

        <p className="font_8">
          <span>
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners or other companies that we control or that are
            under common control with us.
          </span>
        </p>

        <h5 className="font_5">Business Partners</h5>

        <p className="font_8">
          <span>
            We may share your information with our business partners to offer
            you certain products, services or promotions, including third-party
            service providers to facilitate our provision of services to you and
            to support such services.&nbsp; [We do not require such business
            partners to comply with the terms of this Privacy Policy.&nbsp; You
            understand that such business partners will treat your information
            in accordance with their own privacy practices, and you hereby
            consent to such treatment.]
            <span>
              <a>[LW9]</a>
            </span>
            &nbsp;
            <span>
              <a>[mc10]</a>
            </span>
            &nbsp;
          </span>
        </p>

        <h5 className="font_5">Social Media Contacts</h5>

        <p className="font_8">
          <span>
            If you connect to the Site or our mobile application through a
            social network, your contacts on the social network will see your
            name, profile photo, and descriptions of your activity.
          </span>
        </p>

        <h5 className="font_5">Other Third Parties</h5>

        <p className="font_8">
          <span>
            We may share your information with advertisers and investors for the
            purpose of conducting{' '}
          </span>
          <span>general business analysis</span>
          <span>
            . We may also share your information with such third parties for
            marketing purposes, as permitted by law. &nbsp;[We do not require
            such third parties to comply with the terms of this Privacy
            Policy.&nbsp; You understand that such third parties will treat your
            information in accordance with their own privacy practices, and you
            hereby consent to such treatment.]
          </span>
        </p>

        <h5 className="font_5">Sale or Bankruptcy</h5>

        <p className="font_8">
          <span>
            If we reorganize or sell all or a portion of our assets, undergo a
            merger, or are acquired by another entity, we may transfer your
            information to the successor entity. If we go out of business or
            enter bankruptcy, your information would be an asset transferred or
            acquired by a third party. You acknowledge that such transfers may
            occur and that the transferee may decline honor commitments we made
            in this Privacy Policy.
          </span>
        </p>

        <p className="font_8">
          <span>
            We are not responsible for the actions of third parties with whom
            you share personal or sensitive data, and we have no authority to
            manage or control third&shy;party solicitations. If you no longer
            wish to receive correspondence, emails or other communications from
            third parties, you are responsible for contacting the third party
            directly and/or simply clicking on their opt-out link in emails.
          </span>
        </p>

        <p className="font_8">
          <span>
            <span className="wixGuard">&#8203;</span>
          </span>
        </p>

        <h5 className="font_5">Anonymized Data</h5>

        <p className="font_8">
          <span>
            We may share anonymized, non-personal data collected from our users
            in the aggregate with outside analysts and other business partners.
          </span>
        </p>

        <h5 className="font_5">THIRD&shy;PARTY WEBSITES</h5>

        <p className="font_8">
          <span>
            The Site and our mobile application may contain links to
            third&shy;party websites and applications of interest, including
            advertisements and external services, that are not affiliated with
            us. Once you have used these links to leave the Site or our mobile
            application, any information you provide to these third parties is
            not covered by this Privacy Policy, and we cannot guarantee the
            safety and privacy of your information. Before visiting and
            providing any information to any third&shy;party websites, you
            should inform yourself of the privacy policies and practices (if
            any) of the third party responsible for that website, and should
            take those steps necessary to, in your discretion, protect the
            privacy of your information. We are not responsible for the content
            or privacy and security practices and policies of any third parties,
            including other sites, services or applications that may be linked
            to or from the Site or our mobile application.
          </span>
        </p>

        <h5 className="font_5">SECURITY OF YOUR INFORMATION</h5>

        <p className="font_8">
          <span>
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or{' '}
          </span>
          <span>other type</span>
          <span>
            {' '}
            of misuse. Any information disclosed online is vulnerable to
            interception and misuse by unauthorized parties. Therefore, we
            cannot guarantee complete security if you provide personal
            information.
          </span>
        </p>

        <h5 className="font_5">POLICY FOR CHILDREN</h5>

        <p className="font_8">
          <span>
            We do not knowingly accept, request, or solicit information from or
            market to children under the age of 18. Therefore, in accordance
            with the U.S. Children’s Online Privacy Protection Act, if we
            receive actual knowledge that anyone under the age of 13 has
            provided personal information to us without the requisite and
            verifiable parental consent, we will delete that information from
            the Site as quickly as is reasonably practical. If you become aware
            of any data we have collected from children under age 13, please
            contact us using the contact information provided below.
          </span>
        </p>

        <h5 className="font_5">CONTROLS FOR DO&shy;NOT&shy;TRACK FEATURES</h5>

        <p className="font_8">
          <span>
            Most web browsers and some mobile operating systems and our mobile
            applications include a Do&shy;Not&shy;Track (“DNT”) feature or
            setting you can activate to signal your privacy preference not to
            have data about your online browsing activities monitored and
            collected. No uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this Privacy Policy.
          </span>
        </p>

        <h5 className="font_5">OPTIONS REGARDING YOUR INFORMATION</h5>

        <h5 className="font_5">Account Information</h5>

        <p className="font_8">
          <span>
            You may at any time review or change the information in your account
            or terminate your account by:
          </span>
        </p>

        <ul className="font_8">
          <li>
            <p className="font_8">
              <span>
                Logging into user account settings and updating the user
                account.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Contacting us using the contact information provided.</span>
            </p>
          </li>
        </ul>

        <p className="font_8">
          <span>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, some information may be retained in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with legal requirements.
          </span>
        </p>

        <h5 className="font_5">Emails and Communications</h5>

        <p className="font_8">
          <span>
            If you no longer wish to receive correspondence, emails, or other
            communications from us, you may opt&shy;out by:
          </span>
        </p>

        <ul className="font_8">
          <li>
            <p className="font_8">
              <span>
                Noting your preferences at the time you register your account
                with the Site.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>
                Logging into your account settings and updating your
                preferences.
              </span>
            </p>
          </li>
          <li>
            <p className="font_8">
              <span>Contacting us using the contact information provided.</span>
            </p>
          </li>
        </ul>

        <p className="font_8">
          <span>
            If you no longer wish to receive correspondence, emails, or other
            communications from third parties, you are responsible for
            contacting the third party directly.
          </span>
        </p>

        <h5 className="font_5">CALIFORNIA PRIVACY RIGHTS</h5>

        <p className="font_8">
          <span>
            California residents may request and obtain from us, once a year and
            free of charge, information about categories of personal information
            (if any) we disclosed to third parties for direct marketing purposes
            and the names and addresses of all third parties with which we
            shared personal information in the immediately preceding calendar
            year. If you are a California resident and would like to make such a
            request, please submit your request in writing to us using the
            contact information provided below.
          </span>
        </p>

        <p className="font_8">
          <span>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Site or our mobile application, you have
            the right to request removal of unwanted data that you publicly post{' '}
          </span>
          <span className="gr-progress">on&nbsp;the</span>
          <span>
            {' '}
            Site or our mobile application. To request removal of such data,
            please contact us using the contact information provided below, and
            include the email address associated with your account and a
            statement that you reside in California. We will make sure the data
            is not publicly displayed on the Site or our mobile application, but
            please be aware that the data may not be completely or
            comprehensively removed from our systems.
          </span>
        </p>

        <h5 className="font_5">CONTACT US</h5>

        <p className="font_8">
          <span>
            If you have questions or comments about this Privacy Policy, please
            contact us at
          </span>
          <span>:</span>
          <span> LifeGuides, PBC, a public benefit corporation,</span>
        </p>

        <p className="font_8">
          <span>1821 SW 28th Street, Ocala, Florida, &nbsp;34471</span>
        </p>

        <p className="font_8">
          <span>(415) 294-0592</span>
        </p>

        <p className="font_8">
          <span>
            <span>
              <a
                href="mailto:hello@lifeguides.com"
                target="_self"
                data-content="hello@lifeguides.com"
                data-type="mail"
              >
                hello@lifeguides.com
              </a>
            </span>
          </span>
        </p>

        <p className="font_8">
          <span className="wixGuard">&#8203;</span>
        </p>
      </div>
    );
  };

  render() {
    const { visible, onClose } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <Modal size="large" closeIcon onClose={onClose} open={visible}>
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center' }}>
            <Header as="h1" style={{ fontWeight: 'normal' }}>
              {this.title()}
            </Header>
            <div style={{ padding: '1em 2em' }}>{this.content()}</div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
