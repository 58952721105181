import './index.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Header, Loader, Menu, Segment, Tab } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import ClientCallCountsQuery from '../../../graphql/queries/client-call-counts.graphql';
import GuideCallCountsQuery from '../../../graphql/queries/guide-call-counts.graphql';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import CallsTab from './calls-tab';

const Tabs = [
  {
    name: 'scheduled',
    prop: 'scheduledCallsConnection',
    title: 'Upcoming Calls',
    Component: CallsTab,
    props: {
      statuses: ['SCHEDULED', 'STARTED']
    }
  },
  {
    name: 'history',
    prop: 'historyCallsConnection',
    title: 'Call History',
    Component: CallsTab,
    props: {
      statuses: ['CANCELLED', 'COMPLETED']
    }
  }
];

function queryOptions(props) {
  const { user } = props;

  const variables = {
    userId: user.User.id
  };

  return { variables };
}

@withChannel({ loader: <Segment basic loading />, required: true })
@withUser({ authenticated: true, roles: [ROLES.CLIENT, ROLES.GUIDE] })
@graphql(ClientCallCountsQuery, {
  skip: props => {
    const { User } = props.user;
    return !User.roles.some(role => role.name === ROLES.CLIENT);
  },
  options: props => {
    const { channel } = props.channel;
    const options = queryOptions(props);
    options.variables.channelId = channel.id;
    return options;
  }
})
@graphql(GuideCallCountsQuery, {
  skip: props => {
    const { User } = props.user;
    return !User.roles.some(role => role.name === ROLES.GUIDE);
  },
  options: queryOptions
})
@withRouter
@withWidth()
class CallTabs extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({})
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      }).isRequired
    }).isRequired,
    width: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    const { location } = props;

    let activeIndex = 0;

    if (location.hash) {
      const [, name] = /^#(\w+)/.exec(location.hash);
      const index = Tabs.findIndex(tab => tab.name === name);
      if (index >= 0) {
        activeIndex = index;
      }
    }

    this.state = {
      activeIndex
    };
  }

  render() {
    const { width } = this.props;
    const { activeIndex } = this.state;

    const isMini = width < 400;

    return (
      <Tab
        className="call-tab"
        menu={{
          color: 'blue',
          secondary: true,
          pointing: true,
          stackable: isMini
        }}
        panes={this._renderPanes()}
        activeIndex={activeIndex}
        onTabChange={this._onTabChange}
      />
    );
  }

  _renderPanes() {
    const { data } = this.props;
    const { width } = this.state;

    const isTablet = isWidthDown('computer', width);

    return Tabs.map(({ name, prop, title, Component, props }) => {
      const count = data.loading ? (
        <Loader active inline size="mini" />
      ) : (
        data[prop] && data[prop].aggregate.count
      );

      return {
        menuItem: (
          <Menu.Item
            key={`tab-${name}`}
            style={{ fontSize: isTablet ? '0.8rem' : '1rem' }}
          >
            {title}
            <Header.Subheader style={{ display: 'inline', margin: '0 1em' }}>
              {count}
            </Header.Subheader>
          </Menu.Item>
        ),
        render: () => {
          return (
            <Component
              name={name}
              className={`${name}-list-view`}
              title={title}
              {...props}
            />
          );
        }
      };
    });
  }

  _onTabChange = (event, { activeIndex }) => {
    const { history, location } = this.props;

    const tab = Tabs[activeIndex];
    const url = `${location.pathname}#${tab.name}`;
    history.push(url);

    this.setState({ activeIndex });
  };
}
export default CallTabs;
