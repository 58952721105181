import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Divider,
  Header,
  Icon,
  Image,
  List,
  Loader,
  Modal,
  Segment
} from 'semantic-ui-react';

import UpdateGuideApplicationStatus from '../../../../graphql/mutations/update-guide-application-status.graphql';
import GuideApplicationsQuery from '../../../../graphql/queries/guide-applications-by-status.graphql';
import graphql from '../../../hoc/graphql';

const statuses = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};

@graphql(GuideApplicationsQuery, {
  name: 'applications',
  options: ({ status }) => ({
    variables: { status }
  })
})
@graphql(UpdateGuideApplicationStatus, {
  name: 'updateGuideApplication',
  options: {
    refetchQueries: [
      {
        query: GuideApplicationsQuery,
        variables: {
          status: 'PENDING'
        }
      },
      {
        query: GuideApplicationsQuery,
        variables: {
          status: 'ACCEPTED'
        }
      },
      {
        query: GuideApplicationsQuery,
        variables: {
          status: 'REJECTED'
        }
      }
    ]
  }
})
@withRouter
class GuideApplications extends Component {
  static propTypes = {
    applications: PropTypes.shape({
      guideApplications: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          createdAt: PropTypes.string,
          user: PropTypes.shape({
            channelProfiles: PropTypes.arrayOf(
              PropTypes.shape({
                intakeAnswers: PropTypes.arrayOf(
                  PropTypes.shape({
                    answer: PropTypes.string,
                    intakeQuestion: PropTypes.shape({
                      title: PropTypes.string
                    })
                  })
                ),
                subChannels: PropTypes.arrayOf(
                  PropTypes.shape({
                    title: PropTypes.string
                  })
                )
              })
            ),
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            avatarUrl: PropTypes.string,
            phoneNumber: PropTypes.string,
            emailAddress: PropTypes.string,
            roles: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string
              })
            )
          })
        })
      ),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    status: PropTypes.string.isRequired,
    updateGuideApplication: PropTypes.func.isRequired
  };

  state = {
    confirmApplicant: null,
    updating: false
  };

  updateGuideApplicationStatus = (id, newStatus) => {
    this.setState({
      updating: true
    });

    return this.props
      .updateGuideApplication({
        variables: {
          id: id,
          status: newStatus
        }
      })
      .then(() => {
        this.cancelApplicantDialog();
        this.setState({
          updating: false
        });
      });
  };

  confirmApplicant = application => {
    this.updateGuideApplicationStatus(application.id, statuses.ACCEPTED);
  };

  rejectApplicant = application => {
    this.updateGuideApplicationStatus(application.id, statuses.REJECTED);
  };

  cancelApplicantDialog = () => {
    this.setState({
      confirmApplicant: null
    });
  };

  loader = action => {
    let msg = `Loading Applicants`;

    if (action === 'updating') {
      msg = `Updating Status`;
    }

    return (
      <Segment basic style={{ minHeight: '8em' }}>
        <Dimmer active inverted>
          <Loader inverted size="massive">
            {msg}
          </Loader>
        </Dimmer>
      </Segment>
    );
  };

  emptyView() {
    return (
      <Segment basic>
        There are no {this.props.status.toLowerCase()} applications to show
      </Segment>
    );
  }

  modalActions = application => {
    const { user } = application;

    const { firstName, roles } = user;

    const isRegistered = !!roles.length;
    const paneStatus = this.props.status;

    const nevermind = (
      <Button
        key="cancel"
        basic
        color="grey"
        onClick={this.cancelApplicantDialog}
      >
        Nevermind
      </Button>
    );

    const approve = (
      <Button
        key="approve"
        color="green"
        onClick={() => {
          this.confirmApplicant(application);
        }}
      >
        <Icon name="thumbs up" /> {`Approve ${firstName} !`}
      </Button>
    );

    const reject = (
      <Button
        key="reject"
        color="red"
        onClick={() => {
          this.rejectApplicant(application);
        }}
      >
        <Icon name="thumbs down" /> {`Reject ${firstName}`}
      </Button>
    );

    const actions = [nevermind];

    if (!isRegistered) {
      if ([statuses.PENDING, statuses.ACCEPTED].includes(paneStatus)) {
        actions.push(reject);
      }
      if ([statuses.PENDING, statuses.REJECTED].includes(paneStatus)) {
        actions.push(approve);
      }
    }

    return <Modal.Actions>{actions}</Modal.Actions>;
  };

  approveApplicantModal = application => {
    const { user } = application;

    const {
      channelProfiles,
      firstName,
      lastName,
      avatarUrl,
      phoneNumber,
      emailAddress,
      roles
    } = user;

    const headshot = avatarUrl ? (
      <Image
        wrapped
        size="medium"
        src={avatarUrl}
        style={{
          maxHeight: `350px`,
          minHeight: `250px`
        }}
      />
    ) : (
      <Icon name="user" size="massive" />
    );

    return (
      <Modal open onClose={this.cancelApplicantDialog}>
        <Modal.Header>Do you want to approve this applicant?</Modal.Header>
        <Modal.Content image>
          {headshot}
          <Modal.Description>
            <Header>{`Current Role:  ${
              roles.length
                ? roles.map(role => role.name).join(', ')
                : 'STATUS PENDING'
            }`}</Header>
            <Header>{`${firstName} ${lastName}`}</Header>
            {`Applied: ${moment(application.createdAt).fromNow()}`}
            <Header>{`Contact Info`}</Header>
            <List>
              <List.Item>
                <List.Icon name="phone" />
                <List.Content>{`${phoneNumber}`}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="mail" />
                <List.Content>{`${emailAddress}`}</List.Content>
              </List.Item>
            </List>
            {channelProfiles.map(channelProfile => (
              <div key={`channel-profile-${channelProfile.id}`}>
                <Divider horizontal>
                  {channelProfile.channel.shortTitle}
                </Divider>
                <Header>Sub Channels</Header>
                <List>
                  {channelProfile.subChannels.map(subChannel => (
                    <List.Item key={`sub-channel-${subChannel.id}`}>
                      <List.Icon name="check circle outline" />
                      <List.Content>{subChannel.title}</List.Content>
                    </List.Item>
                  ))}
                </List>
                <Header>Intake Answers</Header>
                <List>
                  {channelProfile.intakeAnswers.map(intakeAnswer => (
                    <List.Item key={`intake-answer-${intakeAnswer.id}`}>
                      {intakeAnswer.intakeQuestion && (
                        <List.Icon
                          name={intakeAnswer.intakeQuestion.iconName}
                        />
                      )}
                      <List.Content>
                        {intakeAnswer.intakeQuestion && (
                          <List.Header>
                            {intakeAnswer.intakeQuestion.title}
                          </List.Header>
                        )}
                        <List.Description>
                          {intakeAnswer.answer}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </div>
            ))}
          </Modal.Description>
        </Modal.Content>
        {this.modalActions(application)}
      </Modal>
    );
  };

  applicantToHtml = application => {
    const { createdAt, user } = application;
    const { firstName, lastName } = user;
    return (
      <List.Item
        key={`application-${application.id}`}
        onClick={() => {
          this.setState({
            confirmApplicant: application
          });
        }}
      >
        <List.Content>
          <List.Header>{`${firstName} ${lastName}`}</List.Header>
          <List.Description
            title={moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          >
            {`Applied: ${moment(createdAt).fromNow()}`}
          </List.Description>
        </List.Content>
      </List.Item>
    );
  };

  render() {
    const { loading, guideApplications } = this.props.applications;
    const { confirmApplicant } = this.state;

    if (this.state.updating) {
      return this.loader('updating');
    }

    if (loading) {
      return this.loader('loading');
    }

    const applicantList = _.chain(guideApplications)
      .reject(application => !application.user)
      .map(this.applicantToHtml)
      .value();

    if (!applicantList.length) {
      return this.emptyView();
    }

    return (
      <div className="guide-applications">
        <List relaxed="very" selection>
          {applicantList}
        </List>
        {confirmApplicant ? this.approveApplicantModal(confirmApplicant) : null}
      </div>
    );
  }
}

export default GuideApplications;
