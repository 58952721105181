import './guide-card.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import Avatar from '../../ui/avatar';
import FormattedName from '../../ui/formatted-name';
import { parseQS } from './params';

@withRouter
class GuideCard extends Component {
  static propTypes = {
    guide: PropTypes.shape({
      avatarUrl: PropTypes.string,
      channelProfiles: PropTypes.arrayOf(
        PropTypes.shape({
          subChannels: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string
            })
          )
        })
      ),
      firstName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isFeatured: PropTypes.bool,
      lastName: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { guide } = this.props;
    const { firstName, id, lastName } = guide;

    return (
      <Card
        key={`guide-${id}`}
        className="guide guide-card"
        onClick={this._toGuideSubroute(this._url('schedule'))}
        raised={false}
      >
        <Card.Content>
          <div className="guide-card-faceplate">
            <Avatar user={guide} isFeatured={guide.isFeatured} />
            <Card.Header className="guide-name avenir bold">
              <FormattedName firstName={firstName} lastName={lastName} />
            </Card.Header>
          </div>
          <div className="guide-card-content avenir">
            <Card.Description className="expert-shortIntro avenir light">
              <div className="expert-shortIntro-text">{guide.shortIntro}</div>
            </Card.Description>
          </div>

          <div className="guidecard-actions">
            <Button
              className="schedule-cta"
              primary
              fluid
              onClick={this._toGuideSubroute(this._url('schedule'))}
            >
              Schedule a Call
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  }

  _formatName = firstName => {
    let name = firstName;
    const maxNameLength = 15;
    if (firstName.length > maxNameLength) {
      name = `${name.slice(0, maxNameLength)}...`;
    }
    return name;
  };

  _renderTopics() {
    const { channelProfiles } = this.props.guide;

    return channelProfiles
      .reduce(
        (acc, channelProfile) => acc.concat(channelProfile.subChannels),
        []
      )
      .slice(0, 3)
      .map(this._renderTopicLabels);
  }

  _renderTopicLabels = topic => {
    return (
      <span className="expert-subject" key={`topic-${topic.id}`}>
        {topic.title}
      </span>
    );
  };

  _toGuideSubroute = url => e => {
    const { history } = this.props;
    e.stopPropagation();
    history.push(url);
  };

  _url(subroute) {
    const { guide, location, match } = this.props;

    const { page, searchTerm } = parseQS(location.search);

    const newParams = qs.stringify({
      page,
      searchTerm
    });

    const parts = location.pathname.split('/');
    const path = match.path
      .replace(/\/?:id\/.*/, '')
      .split('/')
      .map((param, index) => {
        if (param.startsWith(':')) {
          return parts[index];
        }
        return param;
      })
      .join('/');

    let _route = `${APP_ROOT}${path}/${guide.id}`;
    if (subroute) {
      _route = `${_route}/${subroute}`;
    }
    _route = `${_route}?${newParams}`;
    return _route;
  }
}
export default GuideCard;
