import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Input } from 'semantic-ui-react';

import MyForm, { FormField } from '../../ui/form';

class CorporateEmailForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool
  };

  state = {
    data: {
      corporateEmailAddress: ''
    },
    isValid: false
  };

  render() {
    const { submitting } = this.props;
    const { data, isValid } = this.state;

    return (
      <div className="corporate-email-form">
        <MyForm
          data={data}
          onChange={data => {
            this.setState({ data });
          }}
          onValidate={errors => {
            this.setState({ isValid: isEmpty(errors) });
          }}
        >
          <FormField
            component={Input}
            name="corporateEmailAddress"
            label="Corporate E-mail Address"
            aria-label="Corporate E-mail Address"
            aria-required="true"
            type="email"
            validator={({ corporateEmailAddress }) => {
              if (!corporateEmailAddress) {
                throw new Error('Corporate e-mail Address is required');
              }
            }}
          />
          <Form.Button
            className="continue-btn"
            primary
            fluid
            size="huge"
            content="Continue"
            loading={submitting}
            disabled={!isValid || submitting}
            onClick={this._onSubmit}
          />
        </MyForm>
      </div>
    );
  }

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    onSubmit(data);
  };
}
export default CorporateEmailForm;
