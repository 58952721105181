import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, List } from 'semantic-ui-react';

import { ROLES } from '../../../../consts';
import withUser from '../../../hoc/with-user';
import CallEntryBase from '../../../ui/call-entry';
import CallNotesDialog from '../../../ui/call-notes-dialog';

@withUser({ authenticated: true })
class CancelledCallEntry extends Component {
  static propTypes = {
    call: PropTypes.shape({
      callNote: PropTypes.shape({
        text: PropTypes.string
      }),
      client: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
      }),
      guide: PropTypes.object,
      scheduledTime: PropTypes.date,
      startedAt: PropTypes.string
    }).isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired,
    isTablet: PropTypes.bool
  };

  state = {
    showingNote: false
  };

  render() {
    const { call, isTablet } = this.props;
    const { User } = this.props.user;
    const { showingNote } = this.state;

    const isGuide = User.roles.some(role => role.name === ROLES.GUIDE);
    const otherUser = isGuide ? call.client : call.guide;
    if (!otherUser) {
      return null;
    }

    return (
      <CallEntryBase
        channel={call.channel}
        user={otherUser}
        guide={call.guide}
        isTablet={isTablet}
        cancelled
        scheduled={false}
        date={call.scheduledTime}
        subheader="CANCELLED"
      >
        {isGuide && call.callNote && (
          <CallNotesDialog
            call={call}
            callNotes={[call.callNote]}
            client={call.client}
            user={User}
            open={showingNote}
            onClose={() => {
              this.setState({ showingNote: false });
            }}
          />
        )}
        {isGuide && call.callNote && (
          <List.Content
            className="notes-icon-container"
            onClick={() => {
              this.setState({ showingNote: true });
            }}
          >
            <Icon name="comment" size="big" />
            Notes
          </List.Content>
        )}
      </CallEntryBase>
    );
  }
}
export default CancelledCallEntry;
