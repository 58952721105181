import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Header, Modal } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import UpdateCallDiscussedSubChannelsMutation from '../../../graphql/mutations/update-call-discussed-sub-channels.graphql';
import UpdateCallStatusMutation from '../../../graphql/mutations/update-call-status.graphql';
import GuideCallCountsQuery from '../../../graphql/queries/guide-call-counts.graphql';
import GuideDashboardQuery from '../../../graphql/queries/guide-dashboard.graphql';
import UserClientCallsQuery from '../../../graphql/queries/user-client-calls.graphql';
import * as tracker from '../../../tracker';
import withUser from '../../hoc/with-user';
import CallNotes from '../call-notes';

@withUser({ authenticated: true })
@graphql(UpdateCallDiscussedSubChannelsMutation, {
  name: 'updateDiscussedSubChannels'
})
@graphql(UpdateCallStatusMutation, {
  name: 'updateCallStatus',
  options: props => {
    const { User } = props.user;

    return {
      refetchQueries: [
        { query: GuideCallCountsQuery, variables: { userId: User.id } },
        { query: GuideDashboardQuery, variables: { userId: User.id } }
      ],
      update: (store, response) => {
        if (!response) {
          return;
        }
        const {
          data: { updateCall }
        } = response;

        const variables = {
          statuses: ['CANCELLED', 'COMPLETED'],
          userId: User.id
        };

        let data = null;
        try {
          data = store.readQuery({
            query: UserClientCallsQuery,
            variables
          });
        } catch (e) {
          /* noop */
        }
        if (!data) {
          return;
        }

        const { calls } = data;
        calls.push(updateCall);

        store.writeQuery({
          query: UserClientCallsQuery,
          variables,
          data
        });
      }
    };
  }
})
@withRouter
class CompleteCallDialog extends Component {
  static propTypes = {
    call: PropTypes.shape({
      channel: PropTypes.shape({
        slug: PropTypes.string,
        subChannels: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string
          })
        )
      }),
      discussedSubChannelIds: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    history: PropTypes.object.isRequired,
    twilioCall: PropTypes.shape({
      duration: PropTypes.number
    }),
    updateDiscussedSubChannels: PropTypes.func.isRequired,
    updateCallStatus: PropTypes.func.isRequired
  };

  state = {};

  render() {
    const { call, onCancel, open } = this.props;

    return (
      <Modal open={open} size="small">
        <Modal.Content scrolling>
          <Header size="huge" style={{ margin: 0 }}>
            Way to Go!
          </Header>
          <Header size="medium" style={{ margin: 0, fontWeight: 'normal' }}>
            Take some time to finish up your call
          </Header>
          <Header size="tiny">Notes</Header>
          <CallNotes call={call} />
          <Header size="tiny">Topics Discussed</Header>
          {call.channel.subChannels.map(subChannel => (
            <div key={`sub-channel-${subChannel.id}`}>
              <Checkbox
                label={subChannel.title}
                checked={call.discussedSubChannelIds.includes(subChannel.id)}
                style={{
                  fontSize: '1.2em',
                  padding: '.5em 0'
                }}
                onChange={() => {
                  this._toggleSubChannelDiscussed(subChannel);
                }}
              />
            </div>
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>Nevermind</Button>
          <Button primary onClick={this._completeCall}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _toggleSubChannelDiscussed = subChannel => {
    const { call, updateDiscussedSubChannels } = this.props;

    const discussedSubChannelIds = call.discussedSubChannelIds.slice(0);
    const index = discussedSubChannelIds.indexOf(subChannel.id);
    if (index >= 0) {
      discussedSubChannelIds.splice(index, 1);
    } else {
      discussedSubChannelIds.push(subChannel.id);
    }

    const variables = {
      id: call.id,
      discussedSubChannelIds
    };

    updateDiscussedSubChannels({ variables });
  };

  _completeCall = () => {
    const { call, history, twilioCall, updateCallStatus } = this.props;

    const variables = {
      id: call.id,
      status: 'COMPLETED',
      duration: twilioCall ? twilioCall.duration : null
    };

    updateCallStatus({ variables }).then(() => {
      tracker.event('completeCall', 1, { channel: call.channel.slug });

      history.push(`${APP_ROOT}/${call.channel.slug}/dashboard`);
    });
  };
}
export default CompleteCallDialog;
