import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe
} from 'react-stripe-elements';
import { Button, Form, Label, Message } from 'semantic-ui-react';

const Styles = {
  input: {
    fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
    lineHeight: '1.21428571em',
    padding: '0.67857143em 1em',
    background: '#fff',
    border: '1px solid rgba(34, 36, 38, 0.15)',
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius: '0.28571429rem',
    boxShadow: '0 0 0 0 transparent inset'
  }
};

@injectStripe
export default class StripeCardForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
    submitting: PropTypes.bool,
    stripe: PropTypes.object.isRequired
  };

  state = {
    dirty: {
      cvc: false,
      expiry: false,
      number: false
    },
    fields: {
      cvc: {
        complete: false,
        error: null,
        empty: true,
        value: null
      },
      expiry: {
        complete: false,
        error: null,
        empty: true,
        value: null
      },
      number: {
        brand: null,
        complete: false,
        error: null,
        empty: true,
        value: null
      }
    },
    error: null,
    submitting: false
  };

  _shouldShowError(name) {
    const { dirty, fields } = this.state;
    return dirty[name] && fields[name].error;
  }
  render() {
    const { submitButtonText, submitting } = this.props;
    const { fields } = this.state;

    const complete = Object.values(fields).every(field => field.complete);

    return (
      <div>
        {this._renderError()}
        <Form onSubmit={this._onSubmit}>
          <Form.Field error={this._shouldShowError('number')}>
            <div style={Styles.input}>
              <CardNumberElement
                onChange={this._onFieldChange.bind(this, 'number')}
                onBlur={this._onFieldBlur.bind(this, 'number')}
              />
            </div>
            {this._shouldShowError('number') && (
              <Label basic color="red" pointing>
                {fields.number.error.message}
              </Label>
            )}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field error={this._shouldShowError('expiry')}>
              <div style={Styles.input}>
                <CardExpiryElement
                  onChange={this._onFieldChange.bind(this, 'expiry')}
                  onBlur={this._onFieldBlur.bind(this, 'expiry')}
                />
              </div>
              {this._shouldShowError('expiry') && (
                <Label basic color="red" pointing>
                  {fields.expiry.error.message}
                </Label>
              )}
            </Form.Field>
            <Form.Field error={this._shouldShowError('cvc')}>
              <div style={Styles.input}>
                <CardCVCElement
                  onChange={this._onFieldChange.bind(this, 'cvc')}
                  onBlur={this._onFieldBlur.bind(this, 'cvc')}
                />
              </div>
              {this._shouldShowError('cvc') && (
                <Label basic color="red" pointing>
                  {fields.cvc.error.message}
                </Label>
              )}
            </Form.Field>
          </Form.Group>
          <Button
            primary
            size="huge"
            fluid
            loading={this.state.submitting || submitting}
            disabled={this.state.submitting || submitting || !complete}
          >
            {submitButtonText}
          </Button>
        </Form>
      </div>
    );
  }

  _renderError() {
    const { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <Message negative>
        <p>{error.message}</p>
      </Message>
    );
  }

  _onFieldChange = (name, event) => {
    const { fields } = this.state;
    fields[name] = event;
    this.setState({ fields });
  };

  _onFieldBlur = name => {
    const { dirty } = this.state;
    dirty[name] = true;
    this.setState({ dirty });
  };

  _onSubmit = () => {
    const { onSubmit, stripe } = this.props;

    this.setState({ error: null, submitting: true });
    stripe.createToken().then(({ error, token }) => {
      this.setState({ error, submitting: false });

      if (token) {
        onSubmit(token);
      }
    });
  };
}
