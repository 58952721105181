import './calls-tab.css';
import './index.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, List, Message, Pagination, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import UserClientCallsQuery from '../../../graphql/queries/user-client-calls.graphql';
import UserGuideCallsQuery from '../../../graphql/queries/user-guide-calls.graphql';
import graphql from '../../hoc/graphql';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';
import withWidth, { isWidthDown } from '../../hoc/with-width';
import CallEntry from '../call-entry';

const PAGE_SIZE = 10;

function parseSearch(hash) {
  const [, str] = hash.split('?');
  if (!str) {
    return {
      page: 1
    };
  }
  const search = qs.parse(str, { ignoreQueryPrefix: true });
  return {
    page: parseInt(search.page, 10) || 1
  };
}

function searchToVariables(search) {
  const first = PAGE_SIZE;
  const skip = search.page > 0 ? (search.page - 1) * PAGE_SIZE : 0;

  return {
    first,
    skip
  };
}

function queryOptions(props) {
  const { location, statuses, user } = props;

  const search = parseSearch(location.search || location.hash);

  const variables = searchToVariables(search);
  return {
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      statuses,
      userId: user.User.id
    }
  };
}

@withChannel({ loader: <Segment basic loading /> })
@withUser({ authenticated: true, roles: [ROLES.CLIENT, ROLES.GUIDE] })
@graphql(UserClientCallsQuery, {
  skip: props => {
    const { User } = props.user;
    return !User.roles.some(role => role.name === ROLES.GUIDE);
  },
  options: queryOptions
})
@graphql(UserGuideCallsQuery, {
  skip: props => {
    const { User } = props.user;
    return !User.roles.some(role => role.name === ROLES.CLIENT);
  },
  options: props => {
    const { channel } = props.channel;
    const options = queryOptions(props);
    options.variables.channelId = channel.id;
    return options;
  }
})
@withRouter
@withWidth()
class CallsTab extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    data: PropTypes.shape({
      calls: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      ),
      callsConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      }),
      error: PropTypes.shape({
        message: PropTypes.string
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired
  };

  state = {
    loading: false
  };

  _refetch(search, statuses) {
    const { data } = this.props;

    this.setState({ loading: true });
    const variables = searchToVariables(search);
    data
      .refetch({
        ...variables,
        statuses
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    const { location, statuses } = this.props;

    if (JSON.stringify(prevProps.statuses) !== JSON.stringify(statuses)) {
      this._refetch(location.search || location.hash, statuses);
    }
  }

  render() {
    return this._renderError() || this._renderLoading() || this._renderCalls();
  }

  _renderError() {
    const { error } = this.props.data;

    if (!error) {
      return null;
    }

    return (
      <Message negative>
        <Message.Header>An error has occurred</Message.Header>
        <p>{error.message}</p>
      </Message>
    );
  }

  _renderLoading() {
    if (!this.props.data.loading && !this.state.loading) {
      return null;
    }

    return <Segment basic loading />;
  }

  _renderCalls() {
    const { title } = this.props;
    const { calls } = this.props.data;

    if (!calls.length) {
      return <p className="no-calls">You have no {title.toLowerCase()}</p>;
    }

    return (
      <div className="calls-tab">
        <List className="calls-tab-list" celled verticalAlign="middle">
          {calls.map(this._renderCall)}
        </List>
        {this._renderPagination()}
      </div>
    );
  }

  _renderCall = call => {
    const { width } = this.props;

    const isTablet = isWidthDown('computer', width);

    return (
      <CallEntry
        className="calls-tab-list-call-entry tab"
        key={`call-entry-${call.id}`}
        call={call}
        isTablet={isTablet}
      />
    );
  };

  _renderPagination() {
    const { location } = this.props;
    const { callsConnection } = this.props.data;

    const search = parseSearch(location.search || location.hash);

    const count = callsConnection.aggregate.count;
    const totalPages = Math.ceil(count / PAGE_SIZE);

    if (!count) {
      return null;
    }

    return (
      <div textAlign="center">
        <Pagination
          activePage={search.page}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true
          }}
          firstItem={null}
          lastItem={null}
          prevItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle left lineawesome" />, icon: true }
              : null
          }
          nextItem={
            count > PAGE_SIZE
              ? { content: <Icon name="angle right lineawesome" />, icon: true }
              : null
          }
          onPageChange={this._onPageChange}
          totalPages={totalPages}
        />
      </div>
    );
  }

  _onPageChange = (e, { activePage }) => {
    this._search({
      page: activePage
    });
  };

  _search(params) {
    const { history, location, statuses } = this.props;

    const [tabName] = location.hash.split('?');
    const search = parseSearch(location.search || location.hash);
    const updated = {
      ...search,
      ...params
    };

    this._refetch(updated, statuses);

    const url = `${location.pathname}${tabName}?${qs.stringify(updated)}`;
    history.push(url);
  }
}
export default CallsTab;
