import './user-avatar.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Loader } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import withUser from '../../hoc/with-user';
import Avatar from '../../ui/avatar';
import MenuItems from './menu-items';

@withUser()
@withRouter
class UserAvatar extends Component {
  static propTypes = {
    history: PropTypes.object,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        emailAddress: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        companyIds: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            companyId: PropTypes.string
          })
        ),
        channelProfiles: PropTypes.arrayOf(
          PropTypes.shape({
            channel: PropTypes.shape({
              slug: PropTypes.string
            })
          })
        ),
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  state = {
    menuOpen: false
  };

  _renderLoader = () => {
    return (
      <div className="user-avatar-loader">
        <Loader active inline />
      </div>
    );
  };

  _openMenu = () => {
    this.setState({ menuOpen: true });
  };

  _closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  _toggleMenu = () => {
    const { menuOpen } = this.state;

    return menuOpen ? this._closeMenu() : this._openMenu();
  };

  _nav = location => () => {
    const { history } = this.props;
    history.push(location);
    this._closeOpenParentMenu();
  };

  _isClient = () => {
    return this.props.user.User.roles.some(role => role.name === ROLES.CLIENT);
  };

  _hasNoCompanies = () => {
    return this.props.user.User.companyIds.length === 0;
  };

  _hasPayment = () => {
    return this._isClient() && this._hasNoCompanies();
  };

  _menuItems = () => {
    const { User } = this.props.user;

    if (!User || !User.roles.length) {
      return [];
    }

    const byRole = menuItem => {
      return menuItem.roles.some(
        role => !!User.roles.find(r => r.name === role)
      );
    };
    const submenuItems = MenuItems.filter(i => i.isSubmenuItem).filter(byRole);

    return submenuItems;
  };

  _menuItemToHtml = menuOpen => (item, index) => {
    let timing = 70 * index + 300;

    const animation = menuOpen
      ? `fadeIn ${125}ms ease-in-out ${timing}ms forwards`
      : `fadeOut ${125}ms ease-in-out 0ms forwards`;

    return (
      <div
        key={`user-menu-item-${item.name}`}
        className="link item user-avatar-menu-item item"
        title={item.description}
        onClick={this._nav(item.to)}
        style={{
          animation,
          opacity: menuOpen ? 0 : 1
        }}
      >
        <Icon className={item.icon} />
        <span>{item.label}</span>
      </div>
    );
  };

  _renderSubmenu = (menuItems, menuOpen) => {
    let showClass = menuOpen ? 'open' : '';
    let userAvatarHeight = menuOpen ? menuItems.length * 45 : 0;
    return (
      <div
        className={`user-avatar-menu ${showClass}`.trim()}
        style={{
          height: userAvatarHeight
        }}
      >
        {menuItems.map(this._menuItemToHtml(menuOpen))}
      </div>
    );
  };

  _renderAvatarContent = () => {
    const { emailAddress, firstName, lastName } = this.props.user.User;
    const { menuOpen } = this.state;
    const openClass = menuOpen ? 'open' : '';
    const icon = `cog lineawesome`;
    const menuItems = this._menuItems();

    const submenu = this._renderSubmenu(menuItems, menuOpen);

    const name =
      firstName && lastName
        ? `${firstName} ${lastName && lastName.charAt(0)}.`
        : emailAddress;

    return (
      <div className={`user-avatar ${openClass}`.trim()}>
        <div className="user-avatar-controlpanel" onClick={this._toggleMenu}>
          <div className="user-avatar-glyph">
            <Avatar
              user={this.props.user.User}
              style={{
                height: 30,
                width: 30
              }}
            />
          </div>
          <div className="user-avatar-name">
            <span>{name}</span>
          </div>
          <div className="user-avatar-settings-stateindicator">
            <Icon className={icon} />
          </div>
        </div>
        {submenu}
      </div>
    );
  };

  _closeOpenParentMenu = () => {
    const { menuOpen, toggleMenu } = this.props;

    if (menuOpen && _.isFunction(toggleMenu)) {
      toggleMenu();
    }
  };

  render() {
    let content = this._renderLoader();

    if (this.props.user.User && !this.props.user.loading) {
      content = this._renderAvatarContent();
    }

    return <div className="app-nav-user-avatar-container">{content}</div>;
  }
}

export default UserAvatar;
