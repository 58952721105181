import './rte-output.css';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Accordion, Label } from 'semantic-ui-react';

import Avatar from '../avatar/index';

export default class CallNote extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    call: PropTypes.shape({
      channel: PropTypes.shape({
        shortTitle: PropTypes.string
      })
    }),
    note: PropTypes.shape({
      call: PropTypes.shape({
        channel: PropTypes.shape({
          shortTitle: PropTypes.string
        }),
        guide: PropTypes.shape({
          id: PropTypes.string
        })
      }),
      createdAt: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired,
    onClick: PropTypes.func,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  };

  render() {
    const { active, note, onClick, user } = this.props;

    const call = this.props.call || note.call;

    const guide = call.guide || note.call.guide;
    const isGuide = guide && guide.id === user.id;

    return (
      <div>
        <Accordion.Title active={active} onClick={onClick}>
          {!isGuide && guide && (
            <Avatar user={guide} style={{ float: 'right' }} />
          )}
          {!isGuide && guide && (
            <span style={{ float: 'right', lineHeight: '28px' }}>
              {guide.firstName} {guide.lastName[0]}
            </span>
          )}
          {`${moment(note.createdAt).format('MMMM Do, YYYY')}`}{' '}
          {call && call.channel && <Label>{call.channel.shortTitle}</Label>}
        </Accordion.Title>
        <Accordion.Content
          active={active}
          style={{ backgroundColor: '#f3f3f3' }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: note.text }}
            className="rte-output"
          />
        </Accordion.Content>
      </div>
    );
  }
}
