import './index.css';

import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

class FullscreenLoader extends Component {
  render() {
    return (
      <div className="fullscreen-loader">
        <Segment
          basic
          loading
          size="massive"
          className="fullscreen-loader-spinner"
        />
      </div>
    );
  }
}

export default FullscreenLoader;
