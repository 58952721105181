import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, Label } from 'semantic-ui-react';

export default class FormField extends PureComponent {
  static propTypes = {
    component: PropTypes.func.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string
    }),
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    validator: PropTypes.func
  };

  state = {
    dirty: false
  };

  render() {
    const { component, error, validator, ...remaining } = this.props;
    const { dirty } = this.state;

    const showError = dirty && !!error;

    const Component = component;
    const props = {
      ...remaining,
      onBlur: this._onBlur
    };

    let isCheck = Component.name === 'Checkbox';
    let seperateLabel =
      props.label && !isCheck && typeof props.label === 'string';
    let label = '';

    if (seperateLabel) {
      label = props.label;
      delete props.label;
    }

    return (
      <Form.Field error={showError}>
        {seperateLabel && label && <label>{label}</label>}
        <Component {...props} />
        {showError && (
          <Label basic color="red" pointing aria-invalid="true">
            {error.message}
          </Label>
        )}
      </Form.Field>
    );
  }

  _onBlur = () => {
    const { onBlur } = this.props;

    this.setState({ dirty: true });

    if (onBlur) {
      onBlur();
    }
  };
}
