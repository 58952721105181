import { APP_ROOT } from '../../../../consts';

const ProfileSteps = [
  {
    title: 'Add a Photo',
    subtitle: 'Go to Profile Settings',
    icon: 'camera circular lineawesome',
    to: `${APP_ROOT}/settings/profile`,
    condition: user => {
      return !user.avatarUrl;
    }
  },
  {
    title: 'Add a Location',
    subtitle: 'Go to Profile Settings',
    icon: 'globe lineawesome circular',
    to: `${APP_ROOT}/settings/profile`,
    condition: user => {
      return !user.city || !user.state;
    }
  },
  {
    title: 'Share Your Story',
    subtitle: 'Go to Profile Settings',
    icon: 'pencil circular lineawesome',
    to: `${APP_ROOT}/settings/profile`,
    condition: user => {
      return !user.bio;
    }
  },
  {
    title: 'Add Your Education',
    subtitle: 'Go to Profile Settings',
    icon: 'graduation cap circular lineawesome',
    to: `${APP_ROOT}/settings/profile`,
    condition: user => {
      return !user.educationLevel;
    }
  }
];

export default ProfileSteps;
