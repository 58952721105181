import './guides-pager.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Pagination } from 'semantic-ui-react';

import { SEARCH_DEFAULTS, parseQS } from './params';

@withRouter
class GuidesPager extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="guides-pager-container">{this._renderPagination()}</div>
    );
  }

  _renderPagination() {
    const { count, onChangePage } = this.props;
    const { page } = parseQS(location.search);

    return (
      <Pagination
        className="guides-pager"
        activePage={page}
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" />,
          icon: true
        }}
        firstItem={null}
        lastItem={null}
        prevItem={
          count > SEARCH_DEFAULTS.itemsPerPage
            ? {
                content: <Icon name="angle left" className="lineawesome" />,
                icon: true
              }
            : null
        }
        nextItem={
          count > SEARCH_DEFAULTS.itemsPerPage
            ? {
                content: <Icon name="angle right" className="lineawesome" />,
                icon: true
              }
            : null
        }
        onPageChange={onChangePage}
        totalPages={Math.ceil(count / SEARCH_DEFAULTS.itemsPerPage)}
      />
    );
  }
}
export default GuidesPager;
