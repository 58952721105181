import './guide-routes.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import GuideDetailsDialog from '../guide-details-dialog';

@withRouter
class GuideRoutes extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        channelName: PropTypes.string.isRequired
      }).isRequired,
      url: PropTypes.string.isRequired
    })
  };

  render() {
    const { match } = this.props;
    const rootProps = this.props;

    const { channelName } = match.params;

    return (
      <div className="guide-routes-container">
        <Switch>
          <Route
            path={`${match.url}/:id`}
            exact
            render={props => {
              return (
                <Redirect
                  channelName={channelName}
                  to={`${props.match.url}/schedule`}
                />
              );
            }}
          />
          <Route
            path={`${match.url}/:id/learn-more`}
            render={props => {
              return (
                <GuideDetailsDialog
                  {...rootProps}
                  channelName={channelName}
                  guideId={props.match.params.id}
                  showScheduler={false}
                  showVideo={false}
                />
              );
            }}
          />
          <Route
            path={`${match.url}/:id/schedule`}
            render={props => {
              return (
                <GuideDetailsDialog
                  {...rootProps}
                  channelName={channelName}
                  guideId={props.match.params.id}
                  showScheduler={true}
                  showVideo={false}
                />
              );
            }}
          />
          <Route
            path={`${match.url}/:id/video`}
            render={props => {
              return (
                <GuideDetailsDialog
                  {...rootProps}
                  channelName={channelName}
                  guideId={props.match.params.id}
                  showScheduler={true}
                  showVideo={true}
                />
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}
export default GuideRoutes;
