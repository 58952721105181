import './index.css';

import { debounce } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Label, Segment } from 'semantic-ui-react';

import { ROLES } from '../../../consts';
import UpdateOrCreateCallNoteMutation from '../../../graphql/mutations/update-or-create-call-note.graphql';
import ClientCallNotesQuery from '../../../graphql/queries/client-call-notes.graphql';
import ClientCallNoteCreatedOrUpdatedSubscription from '../../../graphql/subscriptions/client-call-note-created-or-updated.graphql';
import withUser from '../../hoc/with-user';
import CallNotesDialog from '../../ui/call-notes-dialog';
import RichTextEditor from '../../ui/rich-text-editor';

@withUser({ authenticated: [ROLES.GUIDE] })
@graphql(ClientCallNotesQuery, {
  name: 'clientCallNotes',
  options: ({ call: { client } }) => ({
    variables: { clientId: client.id }
  })
})
@graphql(UpdateOrCreateCallNoteMutation, {
  name: 'updateOrCreateCallNote',
  options: ({ clientCallNotes: { variables } }) => {
    return {
      update: (proxy, { data: { upsertCallNote } }) => {
        const data = proxy.readQuery({
          query: ClientCallNotesQuery,
          variables
        });

        const index = data.callNotes.findIndex(
          callNote => callNote.id === upsertCallNote.id
        );
        if (index >= 0) {
          data.callNotes.splice(index, 1, upsertCallNote);
        } else {
          data.callNotes.push(upsertCallNote);
        }

        proxy.writeQuery({ query: ClientCallNotesQuery, variables, data });
      }
    };
  }
})
class CallNotes extends Component {
  static propTypes = {
    call: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    clientCallNotes: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      callNotes: PropTypes.array
    }),
    updateOrCreateCallNote: PropTypes.func.isRequired,
    user: PropTypes.shape({
      User: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);

    const note = this._getNote(props);

    this.state = {
      callNotesOpen: false,
      dirty: false,
      submitting: false,
      value: (note && note.text) || ''
    };
  }

  componentDidMount() {
    this._subscribeToMore();
  }

  componentDidUpdate(prevProps) {
    const { dirty, submitting } = this.state;

    if (submitting) {
      return;
    }

    const oldNote = this._getNote(prevProps);
    const newNote = this._getNote(this.props);

    if (!newNote) {
      return;
    }

    if (!oldNote || moment(newNote.updatedAt).isAfter(oldNote.updatedAt)) {
      if (dirty) {
        this.setState({ dirty: false });
      } else {
        this.setState({ value: newNote.text });
      }
    }
  }

  componentWillUnmount() {
    if (this._unsubscribeClientCallNotes) {
      this._unsubscribeClientCallNotes();
    }
  }

  _getNote(props) {
    const { call } = props;
    const { callNotes } = props.clientCallNotes;

    if (!callNotes) {
      return null;
    }

    return callNotes.find(note => note.call && note.call.id === call.id);
  }

  _subscribeToMore() {
    const { clientCallNotes } = this.props;

    this._unsubscribeClientCallNotes = clientCallNotes.subscribeToMore({
      document: ClientCallNoteCreatedOrUpdatedSubscription,
      variables: clientCallNotes.variables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const { node } = subscriptionData.data.callNote;
        const callNotes = prev.callNotes.slice(0);

        const index = callNotes.findIndex(note => note.id === node.id);
        if (index >= 0) {
          callNotes.splice(index, 1, node);
        } else {
          callNotes.push(node);
        }

        return {
          ...prev,
          callNotes
        };
      },
      onError: error => {
        // eslint-disable-next-line no-console
        console.log('subscription error', error);
      }
    });
  }

  render() {
    const { loading } = this.props.clientCallNotes;
    const { value } = this.state;

    if (loading) {
      return <Segment loading />;
    }

    return (
      <div className="call-notes">
        <RichTextEditor format="html" value={value} onChange={this._onChange} />

        {this._renderPreviousNotes()}
      </div>
    );
  }

  _renderPreviousNotes() {
    const { callNotes } = this.props.clientCallNotes;
    const { User } = this.props.user;
    const { call } = this.props;
    const { callNotesOpen } = this.state;

    if (!callNotes) {
      return null;
    }

    const previousCallNotes = callNotes.filter(
      callNote => callNote.call.id !== call.id
    );

    if (!previousCallNotes.length) {
      return null;
    }

    return (
      <Segment
        className="previous-notes"
        onClick={() => {
          this.setState({ callNotesOpen: true });
        }}
      >
        Previous Call Notes
        <Label circular color="blue">
          {previousCallNotes.length}
        </Label>
        <CallNotesDialog
          callNotes={previousCallNotes}
          client={call.client}
          user={User}
          open={callNotesOpen}
          onClose={() => {
            this.setState({ callNotesOpen: false });
          }}
        />
      </Segment>
    );
  }

  _onChange = newValue => {
    const { value } = this.state;

    if (newValue === value) {
      return;
    }

    this.setState({ dirty: true, value: newValue }, () => {
      this._updateOrCreate(newValue);
    });
  };

  _updateOrCreate = debounce(value => {
    const { callNotes } = this.props.clientCallNotes;
    const { call, updateOrCreateCallNote } = this.props;
    const { submitting } = this.state;

    if (submitting) {
      return;
    }

    const note = callNotes && callNotes.find(note => note.call.id === call.id);

    const variables = {
      id: (note && note.id) || '',
      callId: call.id,
      text: value
    };

    this.setState({ submitting: true }, () => {
      updateOrCreateCallNote({
        variables
      }).then(() => {
        this.setState({ submitting: false });
      });
    });
  }, 500);
}
export default CallNotes;
