import './index.css';

import React, { Component } from 'react';

import Routes from '../../routes';

const ref = React.createRef();

class AppWorkspace extends Component {
  render() {
    return (
      <div className="app-workspace" ref={ref}>
        <Routes className="app-workspace-routes" />
      </div>
    );
  }
}

export { ref };
export default AppWorkspace;
