import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Label, Modal } from 'semantic-ui-react';

import AvailabilityForm from './availability-form';

class AvailabilityDialog extends Component {
  static propTypes = {
    event: PropTypes.shape({
      end: PropTypes.object,
      resource: PropTypes.object,
      start: PropTypes.object
    }),
    timezone: PropTypes.string,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool
  };

  state = {
    data: null,
    isValid: false
  };

  render() {
    const { event, onClose, onDelete, submitting, timezone } = this.props;
    const { isValid } = this.state;

    return (
      <Modal
        open
        size="tiny"
        closeOnDimmerClick={false}
        closeIcon
        onClose={onClose}
      >
        <Modal.Header>
          <Label>{moment.tz(timezone).format('z ([GMT] Z)')}</Label>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AvailabilityForm
              event={event}
              timezone={timezone}
              onDataChange={this._onDataChange}
              onValidate={this._onValidate}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {event.resource && (
            <Button floated="left" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button disabled={submitting} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={submitting || !isValid}
            onClick={this._onSubmit}
          >
            {event && event.resource ? 'Update' : 'Create'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  _onDataChange = data => {
    this.setState({ data });
  };

  _onValidate = errors => {
    this.setState({ isValid: isEmpty(errors) });
  };

  _onSubmit = () => {
    const { onSubmit } = this.props;
    const { data } = this.state;

    if (onSubmit) {
      onSubmit(data);
    }
  };
}
export default AvailabilityDialog;
