import './send-call-request.css';

import { range } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import UserCallCreditsQuery from '../../../graphql/queries/user-call-credits-stripe-info.graphql';
import CallCreditsPurchaseRequestsSubscription from '../../../graphql/subscriptions/call-credits-purchase-requests.graphql';
import UserCallCreditsSubscription from '../../../graphql/subscriptions/user-call-credits.graphql';
import withUser from '../../hoc/with-user';
import CallScheduler from './call-scheduler';
import NoCredits from './nocredits';

const SuggestionCount = 3;

@graphql(UserCallCreditsQuery, {
  name: 'userCallCredits',
  options: { fetchPolicy: 'network-only' }
})
@withRouter
@withUser()
class SendCallRequest extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    showVideo: PropTypes.bool,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        id: PropTypes.string,
        callCreditsPurchaseRequests: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            callCredits: PropTypes.number
          })
        ),
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        ),
        sentCallRequests: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string
          })
        )
      })
    }),
    userCallCredits: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        callCredits: PropTypes.number
      })
    }),
    onClickLearnMore: PropTypes.func
  };

  state = {
    completedCallCreditPurchaseRequests: [],
    error: null,
    submitting: false,
    suggestedTimes: range(SuggestionCount).map(i =>
      moment()
        .startOf('hour')
        .add(i + 1, 'hours')
        .add(1, 'days')
    )
  };

  UNSAFE_componentWillMount() {
    this._initializeSubscriptions(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this._initializeSubscriptions(nextProps);
  }

  componentWillUnmount() {
    if (this._unsubscribeUserCallCredits) {
      this._unsubscribeUserCallCredits();
    }
    if (this._unsubscribeCallCreditsPurchaseRequests) {
      this._unsubscribeCallCreditsPurchaseRequests();
    }
  }

  render() {
    const { user, userCallCredits } = this.props;

    let content = this._renderLoading();
    let loading = user.loading || userCallCredits.loading;
    if (!loading) {
      if (this._shouldShowNoCredits()) {
        content = this._renderNoCredits();
      } else {
        content = this._renderSuggestedTimes();
      }
    }
    return <div className="send-call-request">{content}</div>;
  }

  _shouldShowNoCredits = () => {
    const { User } = this.props.user;
    const isAuthenticated = User && User.roles.length;
    const callCredits = this._calculateCallCredits();
    return isAuthenticated && callCredits <= 0;
  };

  _calculateCallCredits = () => {
    const { userCallCredits } = this.props;
    const callCreditsUser = userCallCredits.User;

    return callCreditsUser
      ? callCreditsUser.callCredits +
          callCreditsUser.callCreditsPurchaseRequests.reduce(
            (a, r) => a + r.callCredits,
            0
          ) -
          callCreditsUser.sentCallRequests.length
      : 0;
  };

  _renderLoading = () => {
    return (
      <div className="send-call-request loader">
        <Segment basic loading />
      </div>
    );
  };

  _renderNoCredits = () => {
    const { to } = this.props;
    return <NoCredits firstName={to.firstName} />;
  };

  _renderSuggestedTimes = () => {
    const { to, user, showVideo, onClickLearnMore } = this.props;
    return (
      <CallScheduler
        to={to}
        user={user}
        showVideo={showVideo}
        onClickLearnMore={onClickLearnMore}
      />
    );
  };

  _initializeSubscriptions(props) {
    const { userCallCredits } = props;
    const { User } = props.user;

    if (!User) {
      return;
    }

    const variables = { userId: User.id };

    if (!this._unsubscribeUserCallCredits) {
      this._unsubscribeUserCallCredits = userCallCredits.subscribeToMore({
        document: UserCallCreditsSubscription,
        variables,
        updateQuery: (prev, { subscriptionData }) => {
          const { completedCallCreditPurchaseRequests } = this.state;

          if (!subscriptionData) {
            return prev;
          }

          const { callCredits } = subscriptionData.data.User.node;
          const prevCredits = prev.user ? prev.user.callCredits : 0;
          const callCreditsPurchaseRequests = prev.user
            ? prev.user.callCreditsPurchaseRequests.slice()
            : [];

          if (
            callCredits > prevCredits &&
            completedCallCreditPurchaseRequests.length
          ) {
            // Call credits incremented on user, remove completed call credit purchase requests
            completedCallCreditPurchaseRequests.forEach(
              callCreditsPurchaseRequest => {
                const index = callCreditsPurchaseRequests.findIndex(
                  r => r.id === callCreditsPurchaseRequest.id
                );
                if (index >= 0) {
                  callCreditsPurchaseRequests.splice(index, 1);
                }
              }
            );
          }

          const user = {
            ...prev.user,
            callCredits,
            callCreditsPurchaseRequests
          };

          return { ...prev, user };
        }
      });
    }

    if (!this._unsubscribeCallCreditsPurchaseRequests) {
      this._unsubscribeCallCreditsPurchaseRequests = userCallCredits.subscribeToMore(
        {
          document: CallCreditsPurchaseRequestsSubscription,
          variables,
          updateQuery: (prev, { subscriptionData }) => {
            const { completedCallCreditPurchaseRequests } = this.state;

            if (!subscriptionData) {
              return prev;
            }

            const callCreditsPurchaseRequests = prev.user
              ? prev.user.callCreditsPurchaseRequests.slice()
              : [];
            const { node } = subscriptionData.data.CallCreditsPurchaseRequest;

            const index = callCreditsPurchaseRequests.findIndex(
              r => r.id === node.id
            );

            if (node.status === 'PENDING' && index < 0) {
              callCreditsPurchaseRequests.push(node);
            } else if (node.status !== 'PENDING' && index >= 0) {
              if (node.status === 'COMPLETED') {
                // Remove after callCredits incremented on user
                completedCallCreditPurchaseRequests.push(node);
                this.setState({ completedCallCreditPurchaseRequests });
              } else if (node.status === 'FAILED') {
                callCreditsPurchaseRequests.splice(index, 1);
                this.setState({ error: new Error(node.error) });
              }
            }

            const user = {
              ...prev.user,
              callCreditsPurchaseRequests
            };

            return { ...prev, user };
          }
        }
      );
    }
  }
}
export default SendCallRequest;
