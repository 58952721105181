import { padStart } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class DurationTimer extends Component {
  static propTypes = {
    startTime: PropTypes.instanceOf(Date).isRequired
  };

  state = {
    startTime: null
  };

  componentDidMount() {
    this._interval = window.setInterval(() => {
      this.forceUpdate();
    }, 500);
  }

  componentWillUnmount() {
    window.clearInterval(this._interval);
  }

  render() {
    const { startTime } = this.props;

    if (!startTime) {
      return null;
    }

    const duration = moment.duration(new Date() - startTime);
    const seconds = duration.seconds();
    const minutes = duration.minutes();
    const hours = Math.floor(duration.subtract(duration.asMinutes()).asHours());

    const time = [hours, minutes, seconds]
      .map(unit => padStart(unit, 2, '0'))
      .join(':');

    return <span>{time}</span>;
  }
}
