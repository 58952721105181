import PropTypes from 'prop-types';
import React from 'react';

const FormattedName = props => {
  const { firstName, lastName, maxNameLength = 15 } = props;
  let name = firstName;

  if (name.length > maxNameLength) {
    name = `${name.slice(0, maxNameLength)}...`;
  }
  return (
    <span>
      {name} {lastName[0]}.
    </span>
  );
};

FormattedName.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  maxNameLength: PropTypes.number
};

export default FormattedName;
