import 'react-big-scheduler/lib/css/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import './index.css';
import '../create-call-request-form/call-select-form-layout.css';

import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import Scheduler, {
  DemoData,
  SchedulerData,
  ViewTypes
} from 'react-big-scheduler';
import DatePicker from 'react-datepicker';
import { Popup } from 'semantic-ui-react';

import withDndContext from '../../../../hoc/with-dnd-context';
import MyForm, { FormField } from '../../../../ui/form';
import ScheduledCallEntry from '../../../call-entry/call/scheduled';

const DATE_FORMAT = moment.HTML5_FMT.DATE;
const TIME_FORMAT = 'hh:mm A';

class CalendarSelector extends Component {
  state = {
    selectedDayOf: new Date()
  };

  render() {
    const { selectedDateTime, onChange } = this.props;

    let _selectedDate = this.props.selectedDayOf || this.state.selectedDayOf;

    return (
      <div className="call-request-form-bookingtime-selector my-form-container">
        <DatePicker
          selected={_selectedDate}
          onChange={d => {
            this.setState({
              selectedDayOf: d
            });

            if (_.isFunction(onChange)) {
              onChange({
                selectedDayOf: d
              });
            }
          }}
          inline
          fixedHeight
          renderDayContents={this.dayToDecoratedDay}
        />
      </div>
    );
  }

  dayToDecoratedDay = (day, date) => {
    const dayNumber = moment(date).format('DD');
    const dayCell = <span>{day}</span>;
    //schedulerData.headers -> if !nonWorkingTime then they're available that day

    return dayCell;

    // return (
    //   <Popup
    //     trigger={dayCell}
    //     header={moment(date).format('MMM Do YY')}
    //     content={'Guide cannot work this day'}
    //   />
    // );
  };

  _onValidate = () => {};

  _onDataChange = data => {
    const { onChange } = this.props;

    if (_.isFunction(onChange)) {
      onChange(data);
    }
  };
}

export default withDndContext(CalendarSelector);
