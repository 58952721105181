import PropTypes from 'prop-types';
import React, { Component } from 'react';

import withWidth from '../../../hoc/with-width';

@withWidth()
class Scrollarea extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    width: PropTypes.number.isRequired
  };

  render() {
    const { children, className, width } = this.props;

    return (
      <div
        className={className}
        style={{ width: width * 0.9 - 200, overflowX: 'scroll' }}
      >
        {children}
      </div>
    );
  }
}
export default Scrollarea;
