import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { logout } from '../../../auth';
import { APP_ROOT } from '../../../consts';
import withAuth0 from '../../hoc/with-auth0';

@withAuth0()
@withRouter
class Logout extends Component {
  static propTypes = {
    auth0: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { auth0, history, location } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (search.complete) {
      let redirected = false;
      const redirect = () => {
        if (!redirected) {
          history.replace(APP_ROOT);
          redirected = true;
        }
      };

      logout().then(redirect);

      // ApolloClient.resetStore seems to have a bug in current version where the promise isn't resolving
      // Add a 1 second timeout
      window.setTimeout(redirect, 1000);
    } else {
      auth0.logout();
    }
  }

  render() {
    return null;
  }
}
export default Logout;
