import 'hamburgers/dist/hamburgers.css';

import './index.css';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon, Segment } from 'semantic-ui-react';

import { APP_ROOT, CONCIERGE_PHONE_NUMBER } from '../../../consts';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';

@withUser()
@withRouter
@withChannel({
  required: false,
  preventRedirect: true
})
class AppHeader extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    isMobile: PropTypes.bool,
    channel: PropTypes.object,
    toggleMenu: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    }).isRequired
  };

  _responsiveClass = className => {
    const { isMobile } = this.props;
    const mobileClass = isMobile ? 'mobile' : '';
    return `${className} ${mobileClass}`.trim();
  };
  _nav = location => () => {
    const { history } = this.props;
    history.push(location);
  };

  _getChannelName = () => {
    const { channel, location } = this.props;
    if (channel && channel.channel) {
      return channel.channel.shortTitle;
    }
    return _.startCase(location.pathname.split('/').pop());
  };

  /* TODO: Need to get the group the channel was on. */
  _getChannelGrouplName = () => {
    const { channel, location } = this.props;

    if (channel && channel.channel) {
      return null;
    }

    let frags = location.pathname.split('/');
    frags.pop();
    return _.startCase(frags.pop());
  };

  _renderBreadcrumb = () => {
    const { isMobile } = this.props;

    const channelGroupName = this._getChannelGrouplName();
    const channelName = this._getChannelName();
    const breadcrumbRoot = isMobile ? null : (
      <>
        <Link className="app-header-breadcrumb-crumb" to={`${APP_ROOT}/`}>
          Home
        </Link>
        {channelGroupName && (
          <>
            <Icon
              name="chevron right"
              className="app-header-breadcrumb-divider"
            />

            <span className="app-header-breadcrumb-crumb">
              {channelGroupName}
            </span>
          </>
        )}
        <Icon name="chevron right" className="app-header-breadcrumb-divider" />
      </>
    );
    return (
      <div className={this._responsiveClass(`app-header-breadcrumb`)}>
        {breadcrumbRoot}
        <span className="app-header-breadcrumb-crumb">{channelName}</span>
      </div>
    );
  };

  _renderHelpline = () => {
    const { isMobile } = this.props;
    const { User } = this.props.user;
    const phoneNumber =
      User && User.companyIds.length > 0
        ? User.companyIds[0].company.primaryPhone
        : CONCIERGE_PHONE_NUMBER;
    const colon = isMobile ? '' : ':';
    return (
      <div className={this._responsiveClass(`app-header-help-cta`)}>
        <span className="app-header-help-cta-label">
          {`Get Help Now ${colon}`.trim()}
        </span>
        <a
          href={`tel:${phoneNumber}`}
          className="app-header-help-cta-tel"
        >{`${phoneNumber}`}</a>
      </div>
    );
  };

  _renderMenuToggle = () => {
    const { isMobile, toggleMenu, menuOpen } = this.props;
    let toggleClass = menuOpen ? 'is-active' : '';
    const hamburger = isMobile ? (
      <button
        className={`hamburger hamburger--spin ${toggleClass} hamberder-override`}
        type="button"
        onClick={toggleMenu}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    ) : null;
    return hamburger;
  };

  render() {
    return (
      <div className={this._responsiveClass(`app-header`)}>
        {this._renderBreadcrumb()}
        {this._renderHelpline()}
        {this._renderMenuToggle()}
      </div>
    );
  }
}

export default AppHeader;
