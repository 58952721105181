import './index.css';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Icon, Image } from 'semantic-ui-react';

import { DEFAULT_AVATAR_URL } from '../../../consts';

export default class Avatar extends PureComponent {
  static propTypes = {
    isFeatured: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
    user: PropTypes.shape({
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  };

  render() {
    const { isFeatured, onClick, style, user, ...remaining } = this.props;

    const src = (user && user.avatarUrl) || DEFAULT_AVATAR_URL;
    const alt = user ? `${user.firstName} ${user.lastName}` : null;

    return (
      <Image
        avatar
        wrapped
        {...remaining}
        onClick={onClick ? onClick : null}
        style={{ ...style, ...(onClick ? { cursor: 'pointer' } : null) }}
      >
        <img src={src} alt={alt} />
        {isFeatured && (
          <Icon
            name="star"
            color="blue"
            inverted
            circular
            className="featured"
          />
        )}
      </Image>
    );
  }
}
