import './index.css';

import marked from 'marked';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Checkbox, List, Segment } from 'semantic-ui-react';

import UpdateCallDiscussedSubChannelsMutation from '../../../graphql/mutations/update-call-discussed-sub-channels.graphql';
import ConversationStartersQuery from '../../../graphql/queries/conversation-starters-by-channel-id.graphql';

@graphql(ConversationStartersQuery, {
  name: 'conversationStarters',
  options: ({ call: { channelId } }) => ({ variables: { channelId } })
})
@graphql(UpdateCallDiscussedSubChannelsMutation, {
  name: 'updateDiscussedSubChannels'
})
class DiscussionTopics extends Component {
  static propTypes = {
    conversationStarters: PropTypes.shape({
      channel: PropTypes.shape({
        subChannels: PropTypes.arrayOf(
          PropTypes.shape({
            conversationStarters: PropTypes.arrayOf(
              PropTypes.shape({
                text: PropTypes.string
              })
            )
          })
        )
      }),
      loading: PropTypes.bool.isRequired
    }).isRequired,
    call: PropTypes.shape({
      discussedSubChannelIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    updateDiscussedSubChannels: PropTypes.func.isRequired
  };

  render() {
    return (
      <Segment style={{ marginTop: '0.5em' }}>
        <div className="discussion-topics">
          <div className="call-client-header">Conversation Starters</div>
          <div className="call-client-helper-text">
            Select topics as they come up, collect information and view helpful
            conversation questions
          </div>
          {this._renderSubChannels()}
        </div>
      </Segment>
    );
  }

  _renderSubChannels() {
    const { channel } = this.props.conversationStarters;

    if (!channel) {
      return null;
    }

    const { subChannels } = channel;

    return (
      <div style={{ padding: 0 }}>
        <List divided>{subChannels.map(this._renderSubChannel)}</List>
      </div>
    );
  }

  _renderSubChannel = subChannel => {
    const { call } = this.props;
    const { channel } = this.props.conversationStarters;

    const channelProfile = call.client.channelProfiles.find(
      p => p.channel.id === channel.id
    );

    const isConcerned =
      channelProfile &&
      channelProfile.subChannels.some(s => s.id === subChannel.id);
    const isChecked = call.discussedSubChannelIds.includes(subChannel.id);

    return [
      <List.Item
        key={`sub-channel-${subChannel.id}`}
        className="topic-item"
        style={{
          padding: '1.5em',
          ...(isConcerned ? { borderLeft: '3px solid #275ce0' } : null)
        }}
      >
        <Checkbox
          checked={isChecked}
          label={subChannel.title}
          style={{
            ...(isConcerned ? { marginLeft: '-3px' } : null)
          }}
          onChange={() => {
            this._toggleSubChannelDiscussed(subChannel);
          }}
        />
      </List.Item>,
      isChecked && this._renderConversationStarters(subChannel)
    ];
  };

  _renderConversationStarters(subChannel) {
    return (
      <List.Item
        key={`sub-channel-conversation-starters-${subChannel.id}`}
        style={{ background: '#f1f6fa', padding: '2em 4em' }}
      >
        <List.Content>
          <List.Header style={{ margin: '1em 0' }}>
            Conversation Starters
          </List.Header>
          <List.Description>
            {subChannel.conversationStarters.map(
              this._renderConversationStarter
            )}
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }

  _renderConversationStarter = conversationStarter => {
    const body = marked(conversationStarter.text);

    return (
      <div
        key={`conversation-starter-${conversationStarter.id}`}
        style={{ margin: '1em 0', color: '#767676' }}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  };

  _toggleSubChannelDiscussed = subChannel => {
    const { call, updateDiscussedSubChannels } = this.props;

    const discussedSubChannelIds = call.discussedSubChannelIds.slice(0);
    const index = discussedSubChannelIds.indexOf(subChannel.id);
    if (index >= 0) {
      discussedSubChannelIds.splice(index, 1);
    } else {
      discussedSubChannelIds.push(subChannel.id);
    }

    const variables = {
      id: call.id,
      discussedSubChannelIds
    };

    updateDiscussedSubChannels({ variables });
  };
}
export default DiscussionTopics;
