import './index.css';

import PropTypes from 'prop-types';
import qs from 'qs';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import AllGuidesPagination from '../../../graphql/queries/all-guides-pagination.graphql';
import * as tracker from '../../../tracker';
import withChannel from '../../hoc/with-channel';
import withUser from '../../hoc/with-user';
import Guides from './guides';
import { SEARCH_DEFAULTS, parseQS } from './params';

const paginationVariables = function paginationVariables(props) {
  const { channel } = props.channel;

  const { page, searchTerm } = parseQS(props.location.search);
  const skip = page > 0 ? (page - 1) * SEARCH_DEFAULTS.itemsPerPage : 0;
  const first = SEARCH_DEFAULTS.itemsPerPage;

  return {
    variables: {
      skip,
      first,
      searchTerm,
      channelId: channel.id
    }
  };
};

@withRouter
@withChannel({ loader: <Segment basic loading />, required: true })
@withUser({ authenticated: true })
@graphql(AllGuidesPagination, {
  name: 'allGuides',
  options: paginationVariables
})
class GuidesContainer extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    allGuides: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      users: PropTypes.array,
      usersConnection: PropTypes.shape({
        aggregate: PropTypes.shape({
          count: PropTypes.number
        })
      })
    }).isRequired
  };

  state = {
    searchValue: ''
  };

  componentDidMount() {
    const { channel } = this.props.channel;

    const data = { channel: channel.slug };

    tracker.event('browseGuides', 1, data);

    this._checkSearchValue();
  }

  render() {
    const { loading, users, usersConnection } = this.props.allGuides;
    const { searchValue } = this.state;

    let count = 0;

    if (usersConnection) {
      count = usersConnection.aggregate.count;
    }

    return (
      <Guides
        {...this.props}
        fetching={loading}
        searchTerm={searchValue}
        onChangePage={this._onChangePage}
        guides={users || []}
        guidesCount={count}
      />
    );
  }

  _checkSearchValue = () => {
    const { location } = this.props;
    const { searchTerm } = parseQS(location.search);
    this.setState({ searchValue: searchTerm });
  };

  _onChangePage = (e, { activePage }) => {
    const { history, location } = this.props;
    const { channel } = this.props.channel;

    const { searchTerm } = parseQS(location.search);
    const newParams = qs.stringify({
      page: activePage,
      searchTerm
    });

    history.push(`/${channel.slug}/guides?${newParams}`);
  };
}
export default GuidesContainer;
