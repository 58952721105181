import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import CreateClientDialog from '../users/create-client-dialog';
import CreateCallRequestDialog from './create-call-request-form/dialog';

@withRouter
class CallRoutes extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    const rootProps = this.props;

    return (
      <div className="call-routes-container">
        <Switch>
          <Route
            path={`${match.url}/create-call`}
            exact
            render={props => {
              return <CreateCallRequestDialog {...rootProps} />;
            }}
          />
          <Route
            path={`${match.url}/create-client`}
            exact
            render={props => {
              return <CreateClientDialog {...rootProps} />;
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default CallRoutes;
