import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';

import { APP_ROOT } from '../../../consts';
import UserCallCreditsStripeInfo from '../../../graphql/queries/user-call-credits-stripe-info.graphql';
import { calculateUserCallCreditsRemaining } from '../call/call-credits-calculations';

@graphql(UserCallCreditsStripeInfo, {
  name: 'stripeUser',
  options: props => ({
    skip: true
  })
})
class CallInfo extends Component {
  static propTypes = {
    channelSlug: PropTypes.string.isRequired,
    stripeUser: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      User: PropTypes.object
    }).isRequired
  };

  render() {
    const { channelSlug } = this.props;

    return (
      <div className="nav-client-call-credits-container">
        <div className="client-call-credits">{this._renderCallCredits()}</div>
        <div className="client-call-schedule">
          <Button className="client-call-schedule-button">
            <Link to={`${APP_ROOT}/${channelSlug}/guides`}>
              <span>Schedule a Call</span>
            </Link>
          </Button>
        </div>
        <div className="client-call-purchase">
          <Link to={`${APP_ROOT}/settings/payment-options`}>
            Purchase Credits
          </Link>
        </div>
      </div>
    );
  }

  _renderCallCredits() {
    const { loading, User } = this.props.stripeUser;

    if (loading) {
      return <Segment basic loading />;
    }
    if (!User) {
      return null;
    }

    const callCredits = calculateUserCallCreditsRemaining(User);
    if (callCredits > 0) {
      return (
        <span className="client-call-credits-remaining">
          {callCredits} calls remaining
        </span>
      );
    }

    return (
      <span className="client-call-credits-none-remaining">
        No credits remaining
      </span>
    );
  }
}

export default CallInfo;
